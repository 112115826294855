import { call, put } from 'redux-saga/effects'

import axios from 'axios'
import api from '../api.json'
import { getCompanyId } from '../helpers'

const abertoFechado = api.abertoFechado

function* asyncCall(action) {
    try {

        const res = yield call(
            axios.get,
            action.payload
        )
        
        const { data } =
            yield call(
                axios.get,
                `${abertoFechado}/${getCompanyId}`
            )
            // : {
            //     data: {
            //         data: {
            //             bloqueado: false,
            //             aberto: true,
            //             motivo_fechado: ""
            //         }
            //     }
            // }

        if (res.data && res.data.departamento) {
            res.data.departamento.forEach(element => {
                element.categorias.sort((ant, at) => ((ant?.opcao ?? 0) - at.opcao))
            })
        }

        yield put({
            type: action.typeTo,
            payload: res.data,
            aberto: data.data
        })

    } catch (err) {
        yield put({
            type: action.typeTo,
            payload: { data: err }
        })
    }
}

export default asyncCall