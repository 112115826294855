import { useSelector } from "react-redux";

function useMoeda() {
    
    const { moeda } = useSelector( state => state.companyData.data.payload ); 
    
    if ( moeda !== null )
        return moeda;
    return "R$";

}
export default useMoeda;