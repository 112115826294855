import { confirmAlert } from "react-confirm-alert"
// eslint-disable-next-line no-unused-vars
import { PromoItens, PromoPizzaCarrinho, PromoPizzaCarrinhoItens, ProximoModal } from "../../../typing/PizzaPromo"

/**
 * * Verifica se tem borda e faz voltar para o modal de bordas se não tiver nenhuma
 * @param {string | number | ProximoModal} proximo 
 * @param {PromoItens} promoItens 
 * @param {PromoPizzaCarrinho} productCartData 
 * @param {(string | number | ProximoModal) => void} setShowModal 
 * @returns 
 */
export const handleBorda = (
    proximo,
    promoItens,
    productCartData,
    setShowModal
) => {
    /**
    * * Id da pizza que tem essa borda
    */
    let ind = (parseInt(proximo.substring(1)) - 1)

    const conjunto = promoItens.conjuntos[ind]
    /**  
     * * Pega a pizza do index
     */
    const prod = productCartData.itens.find(pro => pro.index === ind)

    const temBordaConjunto = (conjunto?.produto[0]?.bordas?.length > 0)

    // Verifica se tem borda
    if (!temBorda(prod) && temBordaConjunto) {
        // Se não tiver, mostra uma mensagem
        confirmAlert({
            title: "Adicione uma borda",
            message: "Você não adicionou nenhuma borda, se não quiser nenhuma mesmo, escolha: \"Sem borda\"",
            buttons: [
                {
                    label: "Ver bordas",
                    onClick: () => {
                        const modal = new ProximoModal()
                        modal.borda = true
                        modal.conjunto = ind
                        modal.produtoIndex = prod?.produtoIndex
                        setShowModal(modal)
                    }
                }
            ]
        })
        return "deu ruim"
    }
}

/**
 * 
 * @param {PromoPizzaCarrinhoItens} item 
 */
export function temBorda(item) {
    if (item && item.borda) {
        return Object.keys(item.borda).length > 0
    }
    return false
}