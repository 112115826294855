/**
 * 
 */

const getPrice = (item, i) => {

    if (i === "sabor") { // se for
        const como_cobrar = item.como_cobrar_pizza ? item.como_cobrar_pizza : 'divide';

        const prices = item[i].map(e => e ? e.valor : 0);
        let sum = prices.reduce((prev, cur) => prev + cur, 0);

        switch (como_cobrar) {
            case "divide":
                sum = sum / item.sabor.length;
                break;
            case "dividecara":
                if (item.sabor.length > 2)
                    sum = Math.max(...prices);

                else
                    sum = sum / item.sabor.length;
                break;
            case "cara":
                sum = Math.max(...prices);
                break;
            default:
                sum = sum / item.sabor.length;
                break;
        }
        if (item.sabor.length >= 2 && item.tx_pizza_sabores)
            sum += item.tx_pizza_sabores;
        return sum;
    }

    let adicionais = [];
    let adicionaisValor = 0;

    if (item[i] && item[i].adicionais) {
        adicionais = item[i].adicionais.map(e => {
            const times = e.qtd ? e.qtd : 1;
            return e.valor * times;
        });
        adicionaisValor = adicionais.reduce((prev, cur) => prev + cur, 0);
    }
    if (i === "adicionais" && item && item.adicionais) {

        adicionais = item.adicionais.map(e => {

            const times = e.qtd ? e.qtd : 1;
            return e.valor * times;
        });

        adicionaisValor = adicionais.reduce((prev, cur) => prev + cur, 0);
    }
    let times = item[i] && item[i].qtd ? item[i].qtd : 1;

    if (item.valor_produto && i === 'valor_produto') {

        const itensValor = Object.keys(item[i].itens).map(e => {

            const times = item[i].itens[e].qtd ? item[i].itens[e].qtd : 1;

            return item[i].itens[e].valor * times;
        }
        );
        adicionaisValor += itensValor.reduce((prev, cur) => prev + cur, 0);
    }

    return item[i] && item[i].valor ? (item[i].valor * times) + adicionaisValor : 0;
};

const calculatePrice = item => {
    let prices = [];

    prices = Object.keys(item).map(i => {
        if (
            item[i] // tem a chave atual
            && !item.descritiva // não é descritiva
            && ( // e...
                (
                    item.tipo === "promo_geral" // é promo geral 
                    && item.valor_produto // te tem o valor do produto
                )
                || (item.tipo !== "promo_geral") // ou não é promo geral
            )
            && item.tipo !== 'promo_pizza' // e não é promo pizza
        ) {
            const flavor = Object.keys(item[i]).map(index => getPrice(item[i], index));

            return flavor.reduce((prev, cur) => prev + cur, 0);
        } return 0;
    });

    let valorNoArray = 0;

    if (item.valor) {

        const times = item.qtd ? item.qtd : 1;

        valorNoArray = item.valor * times;
    }
    prices.push(valorNoArray);

    const finalPrice = prices.reduce((prev, cur) => parseFloat(prev) + parseFloat(cur), 0);

    return parseFloat(finalPrice);
};

export default calculatePrice;