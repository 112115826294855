import React from 'react';

export default ({
    title,
    complement,
    opt = [],
    select,
    setSelect,
    mostrar = null,
    valor = null,
    semSeta = false,
    placeholder
}) => {

    return (
        <div>
            <h6 className="size-normal-small text-grey has-text-weight-bold">

                {title}

                <small className="has-text-weight-medium is-lowercase">{complement}</small>
            </h6>
            <div className={`select mt-2 ${semSeta ? 'no-arrow' : ''}`}>
                <select
                    value={select}
                    onChange={e => setSelect(e.target.value)}
                    onFocus={e => setSelect(e.target.value)}
                    className="size-small is-capitalized"
                >
                    <option value="fff">{placeholder}</option>
                    {
                        typeof opt.map === 'function' ?
                            opt?.map((e, i) => (
                                <option
                                    className="is-capitalized"
                                    key={i}
                                    value={e[valor] ? e[valor] : e}
                                >
                                    { e[mostrar] ? e[mostrar].toLowerCase() : e}
                                </option>
                            )) ?? null
                            : null
                    }
                </select>
            </div>
        </div>
    );
};