import { useEffect } from "react";

function useExistiu(
    cart,
    data,
    setAdded,
    existiu,
    setExistiu,
    setStateHolder,
    setProductCartData,
    setProductCartDataHolder,
    inicial,
) {
    // eslint-disable-next-line
    useEffect(() => {
        const existe = cart.filter(e => e.id === data.id);

        if (existe.length > 0) {
            setExistiu(true);
        } else {

            if (existiu === true && existe.length === 0) {
                setAdded(0);
                setStateHolder(1);
                setExistiu(false);
                setProductCartData(inicial);
                setProductCartDataHolder([inicial]);
            }
        }
    });
}

export default useExistiu;