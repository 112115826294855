import trueIfExists from "./trueIfExists";
import { updateItenInCart, addItenToCart } from "../actions";

/**
 * 
 * @param {*} dispatch 
 * @param {*} productCartData 
 * @param {*} cart 
 */
const saveToCart = ( dispatch, productCartData, cart, update = false ) => {

    const addOrUpdate = trueIfExists(cart, productCartData.id);

    if( addOrUpdate && update ) 
        dispatch(updateItenInCart(productCartData));
    else 
        dispatch(addItenToCart(productCartData));

    // dispatch(removeCartItem(productCartData));
}

export default saveToCart;