/**
 * returns true if element exists
 * 
 * @param {*} search 
 * @param {*} product 
 * @returns {boolean}
 */
const trueIfExists = (search, product) => {
    // loop the search
    for (let i in search) {
        // check if xists id
        if (search[i].id === product)
        return true; // returns a beautiul true
    } 
    return false; // you think the false is ugly? No! It is beautiful too
}
// No idea for more exporting phrases
export default trueIfExists;