/**
 * 
 * @param {obejct} pizza 
 */
function dadosDaPizza( pizza, borda_nome = "borda" ){
    if (pizza.tipo === "pizza") {
        
        const adic = pizza.adicionais ? pizza.adicionais.map( ad => `${ad.nome} ${ ad.qtd ? ad.qtd : 1 }x\n` ) : [];

        let sabores = pizza.sabor ? pizza.sabor.map( pz => {

            let toReturn = pz.nome;

            if (pizza.sabor.length > 1)
                toReturn = "1/" + pizza.sabor.length + " " + pz.nome

            return toReturn
        }) : [];

        const borda =  pizza.borda && borda_nome ? `${ borda_nome }: ${pizza.borda.nome}\n` : "";

        let obs = pizza.observacao ? "<strong>Observação:</strong> \n" + pizza.observacao : "";

        obs = pizza.bebida ? `${ obs } \nbebida: ${ pizza.bebida.nome }` : obs;

        return sabores.join("\n") +"\n" + borda + adic.join("") + obs;
    }
}

export default dadosDaPizza;