/**
 * Retorna o productCartData certo para cada tipo de produto
 * 
 * @param {string} tipo o tipo do produto (pizza, bebida, produto etc)
 * @param {object} dados um objeto de onde serão tirados os dados
 */
function useCarrinhoInicial(tipo = "pizza", dados = {}) {

    let cartDataInicial = {}

    switch (tipo) {
        // caso seja uma pizza normal
        case 'pizza':
            cartDataInicial = {
                nome: dados.tamanho,
                catNome: dados.catNome,
                descricao: dados.descricao,
                qtde_sabores: dados.qtde_sabores,
                tamanho: dados.tamanho,
                tipo: "pizza",
                observacao: "",
                id: dados.id,
                como_cobrar_pizza: dados.como_cobrar_pizza,
                tx_pizza_sabores: dados.tx_pizza_sabores,
                adicionais: [],
                sabor: [],
                pizza_cat_id: dados.pizza_id
            }
            break
        case 'monte':
            cartDataInicial = {
                tipo: "monte",
                id: dados.id,
                id_monte: dados.id_monte,
                nome: `${dados?.nome_cat ? dados.nome_cat + ' - ' : ''}${dados.nome}`,
                comCategoria: true,
                observacao: "",
                img: dados.img,
                valor: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
                adicionais: [],
                id_categoria: dados.id_categoria ? dados.id_categoria : null,
                cat: dados.nome_cat,
            }
            break
        // caso de bebidas
        case 'monte_2':
            cartDataInicial = {
                tipo: "monte",
                qtd: 1,
                id: dados.id,
                id_monte: dados.id_monte,
                nome: dados.nome,
                id_categoria: dados.id_categoria,
                img: dados.img,
                observacao: "",
                valor: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
                adicionais: [],
            }
            break
        case 'bebidas':
            cartDataInicial = {
                tipo: "bebidas",
                id: dados.id,
                nome: dados.nome,
                descricao: dados.descricao,
                img: dados.img,
                valor_tem_desconto: dados.valor_tem_desconto,
                valor: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
            }
            break
        // usar em produto
        case 'produto':
            cartDataInicial = {
                id: dados.id,
                tipo: "produto",
                nome: dados.nome,
                descricao: dados.descricao,
                valor_tem_desconto: dados.valor_tem_desconto,
                valor: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
                id_categoria: dados.id_categoria,
                adicionais: [],
                acompanhamento: [],
            }
            break

        // Para promoção gerais
        case 'promo_geral':
            cartDataInicial = {
                tipo: "promo_geral",
                id: dados.id,
                nome: dados.nome,
                descricao: dados.descricao,
                valor_tem_desconto: dados.valor_tem_desconto,
                valor: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
                cobrar_bebida: dados.cobrar_bebida,
                itens: [],
            }
            break

        // Usar em promo pizza
        case 'promo_pizza':

            cartDataInicial = {
                id: dados.id,
                nome: dados.nome,
                descricao: dados.descricao,
                tipo: "promo_pizza",
                valor: dados.valor ? dados.valor : 0,
                price: dados.valor,
                valor_sem_desconto: dados.valor_sem_desconto,
                valor_tem_desconto: dados.valor_tem_desconto,
                observacao: "",
                itens: []
            }

            break

        // Para pizzaData
        case 'promo_item':

            cartDataInicial = {
                nome: dados.tamanho,
                descricao: dados.descricao,
                type: "pizza",
                tipo: "pizza",
                qtde_sabores: dados.qtde_sabores,
                tamanho: dados.tamanho,
                id: dados.id,
                adicionais: [],
                index: dados.index,
                sabor: [],
                observacao: "",
                como_cobrar_pizza: dados.como_cobrar_pizza,
                tx_pizza_sabores: dados.tx_pizza_sabores,
                cobrar_vl_pizza: dados.cobrar_vl_pizza ? dados.cobrar_vl_pizza : false,
                cobrar_borda: dados.cobrar_borda ? dados.cobrar_borda : false,
                produtoIndex: dados.produtoIndex,
                borda: {},
            }

            break

        default:
            cartDataInicial = {}
            break
    }

    return cartDataInicial
}

export default useCarrinhoInicial