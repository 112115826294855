import { useState, useEffect } from 'react';
import { reduceToCategory } from '../helpers';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

export default() => {
    
    const [category, setCategory] = useState({});

    const match = useParams();

    const Data = useSelector(state => reduceToCategory(state, match));

    useEffect(() => {
        setCategory(Data && Data.itens ? Data.itens : {});
    }, [Data]);

    return {
        category,
        Data,
        id_categoria: Data && Data.id_categoria ? Data.id_categoria : 0
    }
}
