import React from 'react'
import { getWhatsapp } from '../../json'
import { useSelector } from 'react-redux'
import useGetPrimary from '../../helpers/useGetPrimary'
import { Link, useHistory } from 'react-router-dom'
import { getCompanyId } from '../../helpers'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import { useLimparDados } from '../../hooks/useLimparDados'

export default () => {

    const history = useHistory()

    const traducao = useConteudoTraduzido()

    const { telefone, tel_confirma_pedido, envia_whatsapp } = useSelector(state => state.companyData.data.payload)

    const { textPrimary, bgPrimary } = useGetPrimary()

    useLimparDados()

    let celular = ""

    const replaceStuff = rep => {
        if (typeof rep.replace === 'function')
            return rep.replace(/\+\s-\(\)/g)
        else
            return rep
    }

    if (typeof tel_confirma_pedido === 'string') {
        celular = replaceStuff(tel_confirma_pedido)

    } else if (typeof telefone === 'string') {
        celular = replaceStuff(telefone)

    } else {
        celular = null
    }

    const mes = traducao.geral.retorno_whatsapp

    let mesUrl = mes.replace(/\s/g, "%20")

    const wpp = getWhatsapp()

    const retornarMensagem = (
        wpp === null
        || wpp === undefined
        || typeof wpp !== 'string'
        || wpp.includes('undefined')
        || wpp.length < 10
    ) ? mesUrl : wpp

    const status = () => {
        history.push(`/${getCompanyId}/status`)
    }

    return (
        <article
            className="finish has-text-white has-text-centered"
            style={bgPrimary}
        >
            <span className="material-icons size-biggest has-text-white">
                done
            </span>
            <h3 className="has-text-weight-bold">
                {traducao.geral.realizado_com_sucesso}
            </h3>
            <p className="size-normal-small">
                {traducao.geral.em_breve_recebera}
            </p>
            {
                celular && !envia_whatsapp ?
                    <div className="mt-5">
                        <a
                            href={`https://api.whatsapp.com/send?phone=${celular}&text=${retornarMensagem}`}
                            target="blank"
                            rel="noreferer noopener"
                            className="button has-text-weight-bold"
                            style={{ ...textPrimary }}
                            onClick={status}
                        >
                            {traducao.geral.enviar_pelo_whatsapp}
                        </a>
                    </div>
                    :
                    <Link
                        style={{ ...textPrimary }}
                        className="button has-text-weight-bold mt-5"
                        to={`/${getCompanyId}`}
                    >
                        {traducao.geral.voltar_cardapio}
                    </Link>
            }
            <Link to={`/${getCompanyId}`}>
                <span className="pt-4 size-bigger material-icons has-text-white">home</span>
            </Link>
        </article>
    )
}