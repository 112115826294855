import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { adicionarOuRemoverProduto } from '../../../ga';
import { replacer } from '../../../helpers';
import { Desconto, Price } from '../../../styled/Text';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import useMoeda from '../../../traducao/useMoeda';

export default ({
    data,
    title,
    getValue,
    productCartData,
    rollId,
    next,
    toFalse = false,
    setToFalse,
}) => {
    const traducao = useConteudoTraduzido();
    const moeda = useMoeda();

    const name = replacer(title);
    const value = replacer(data.label) + "-uniq-" + parseInt(Math.random() * 55);

    const [inputCheck, setInputCheck] = useState(false);
    // console.log(data);
    function check() {

        setToFalse(false);
        setInputCheck(!inputCheck);

        let sabor = productCartData.sabor;

        if (!inputCheck) {
            getValue([
                ...sabor,
                {
                    id: data.id,
                    nome: data.label,
                    valor: data.valor ? data.valor : 0,
                    category: data?.cat ?? null
                }]);

            adicionarOuRemoverProduto('adicionou', `sabor da pizza (${data.label})`)

        } else {
            const toUpdate = sabor.find((e, i) => e.id === data.id);
            const index = sabor.indexOf(toUpdate);
            sabor.splice(index, 1);
            getValue([...sabor]);
            adicionarOuRemoverProduto('removeu', `sabor da pizza (${data.label})`, 0)
        }
    }

    function addMore(checked) {

        if (document.querySelector(`#${checked}`).checked === false) {

            let forms = document.querySelectorAll(`#${rollId} input[name=${name}]`);

            let checkedCount = 0;
            for (let e of forms) {
                if (e.checked)
                    checkedCount += 1;
            }

            if (checkedCount < data.max)
                check();

            if (checkedCount + 1 >= data.max) {
                setTimeout(() => next(), 450);
            }

            if (checkedCount < data.max - 1) {
                return confirmAlert({
                    title: `${traducao.produtos.incluiu_o_sabor} ${data.label}`,
                    message: traducao.produtos.deseja_incluir_mais,
                    buttons: [
                        {
                            label: traducao.geral.nao,
                            onClick: () => next()
                        }, {
                            label: traducao.geral.sim,
                            onClick: () => null
                        }
                    ]
                });
            }
        } else check();
    }

    useEffect(() => {
        if (toFalse === true)
            setInputCheck(false);
    }, [toFalse]);

    return (
        <div
            onClick={() => addMore(value)}
            className="space-between border-bottom"
        >
            <p className="flex-11">
                <label
                    className={
                        `mini-title size-small
                        ${data.subtitle !== null ? 'has-text-weight-medium' : 'has-text-weight-bold'} 
                    `}
                >
                    {data.label}
                </label>
                <small className="mini-title mt-0">{data.subtitle !== null ? data.subtitle : ''}</small>
            </p>
            <p>
                <input
                    checked={inputCheck}
                    onChange={() => check()}
                    type="checkbox"
                    name={name}
                    id={value}
                    value={value}
                />
                <span></span>
            </p>
            <p>
                {
                    data.tem_desconto && typeof data.desconto === 'number' ?
                        <Desconto>
                            {moeda}{data.desconto.toFixed(2)}
                        </Desconto>
                        : null
                }
                {
                    data.valor ?
                        <Price>
                            {moeda}{parseFloat(data.valor).toFixed(2)}
                        </Price>
                        : null
                }
            </p>
        </div>
    );
}
