import React from 'react';
import useGetPrimary from '../../helpers/useGetPrimary';
import useMoeda from '../../traducao/useMoeda';

export default ({ 
    children, 
    title, 
    subtitle, 
    price, 
    qty, 
    titleClass,
    onClick = () => null, 
}) => {

    const { bgPrimary } = useGetPrimary();
    const moeda = useMoeda();

    return(
        <li className="space-between border-bottom" onClick={ () => onClick() }>
            <div className="center-align flex-8">
                {
                    qty !== undefined ?
                    <div className="flex-2">
                        <span style={ bgPrimary } className="quantity center-no-wrap"> 
                            {qty}
                        </span>
                    </div>
                    : ''
                }

                <div className="flex-column flex-9 text-left">
                    <h6 className={`mini-title mt-0 text-grey size-normal-small ${titleClass}`}>
                        {title}
                    </h6>
                    {
                        typeof subtitle === 'string' ?
                            <small className="text-grey size-normal-small pre-wrap"
                                dangerouslySetInnerHTML={{
                                    __html: subtitle
                                }}
                            />
                        : 
                        <small className="text-grey size-normal-small pre-wrap">
                                { subtitle }
                        </small>
                    }

                    {
                        price ? 
                        <span className="price size-normal-small">
                            { moeda }{price.toFixed(2)}
                        </span>
                        : ''
                    }

                </div>
            </div>
            { children }
        </li>
    );
}