import React, { useState, useEffect, useRef } from 'react'
import SnackData from '../../forms/SnackData'
import SnackModalForm from '../../forms/SnackModalForm'
import CallShowModal from '../../forms/CallShowModal'
import NumberMinMax from '../../forms/inputs/NumberMinMax'
import { getCompanyId, nextMonte2, saveToCart } from '../../../helpers'
import AddToCartConfirm from '../../request/AddToCartConfirm'
import { useParams, useHistory } from 'react-router'
import useRequiredItens from '../../../hooks/useRequiredItens'
import { useDispatch, useSelector } from 'react-redux'
import MonteAdd from '../../forms/inputs/MonteAdd'
import useGetPrimary from '../../../helpers/useGetPrimary'
import { removeTab } from '../../../actions'
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido'
import useItens from '../../../hooks/useItens'
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial'
import TituloProduto from '../../general/TituloProduto'
import { confirmAlert } from 'react-confirm-alert'
import ModalCompressed from '../../forms/ModalCompressed'
import NumberInputControlled from '../../forms/inputs/NumberInputControlled'
import { MiniTitle } from '../../../styled/Titles'

export default ({ data, direto }) => {

    const { textPrimary } = useGetPrimary()

    const history = useHistory()
    const dispatch = useDispatch()
    const { cart } = useSelector(state => state.cartIten)
    const traducao = useConteudoTraduzido()

    const [showModal, setShowModal] = useState(false)
    const [required, setRequired] = useState([1])
    const [miss, setMiss] = useState("")

    const [observacao, setObservacao] = useState("")

    const { id_categoria } = useItens()

    const inicial = useCarrinhoInicial('monte_2', { ...data, id_categoria })

    const [productCartData, setProductCartData] = useState(inicial)

    const { department, type, cat } = useParams()


    useEffect(() => {
        dispatch(removeTab(!true))
        // eslint-disable-next-line
    }, [showModal])

    useRequiredItens(
        data ? data.itens : [],
        val => setRequired(val),
        productCartData && productCartData.adicionais ? productCartData.adicionais : [],
        val => setMiss(val),
        required,
        false,
    )

    const finalizarTudo = () => {
        if (!miss) {
            saveToCart(val => dispatch(val), productCartData, cart)
            history.push(`/${getCompanyId}/${department}/${type}/${cat}`)
        }
    }

    const toNext = () => {
        let maxEachCategory = {}
        productCartData.adicionais.forEach((adc) => {
            maxEachCategory[adc.cat] = (maxEachCategory[adc.cat] || 0) + (adc.qtd || 1)
        })
        let validy = true
        data.itens.forEach((adic) => {
            const item = maxEachCategory[adic.nome] || 0
            if (item <= adic.qtde_permitida && validy === true) {
                validy = true
            } else {
                validy = adic.nome
            }
        })

        if (direto && productCartData.qtd < data.qtde_obrigatoria) {
            confirmAlert({
                title: "A quantidade minima para este produto é de " + data.qtde_obrigatoria,
                message: "Você precisa adicionar mais desse produto",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            })
            return
        }

        if (validy === true) {

            function finalizar() {
                setShowModal('quantidade')
            }

            nextMonte2(
                showModal,
                val => setShowModal(val),
                data.itens,
                miss,
                finalizar,
                productCartData,
                cart,
                data && data.adicionais && data.adicionais.length > 0,
            )
        } else {
            confirmAlert({
                title: "Ultrapassou o limite permitido de " + validy,
                message: "Retire algum item de \"" + validy + "\" pois ultrapassou o limite permitido",
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            })
        }
    }

    const carrinhoMensagem = () => {
        if ((showModal !== 'adicionais' && data.itens && showModal + 1 !== data.itens.length) && !(data.itens.length === 0) && showModal !== false)
            return traducao.produtos.proximo_passo
        else if (data && data.adicionais && data.adicionais.length > 0 && data.itens && (showModal + 1 === data.itens.length || showModal === true))
            return 'Ver adicionais'
        return traducao.geral.adicionar_ao_carrinho
    }

    const setComentario = valor => {
        setObservacao(valor)
        setProductCartData({
            ...productCartData,
            observacao: valor
        })
    }

    const adicionaisQtd = useRef([])

    const aumentarQtd = (val) => {

        const qtd = productCartData.qtd || 1

        adicionaisQtd.current = val.adicionais

        let novosAdicionais = []

        adicionaisQtd.current.forEach((el, ind) => {
            // console.log(adicionaisQtd.current[ind]);
            // console.log(adicionaisQtd.current[ind], qtd);
            if (!adicionaisQtd.current[ind].mtpl)
                novosAdicionais.push({ ...el, valor: adicionaisQtd.current[ind].valor * qtd, mtpl: true })
            else
                novosAdicionais.push({ ...el })
        })

        return setProductCartData({ ...productCartData, adicionais: novosAdicionais })
    }

    return (
        <div className="trans-6">
            {
                !direto ?
                    <TituloProduto
                        nome={data?.nome}
                        descricao={data?.descricao}
                        valor={data?.valor}
                    />
                    : null
            }
            {
                data.itens && data.itens.length > 0 ?
                    data.itens.map((monte, index) => {

                        const alreadyInCart = productCartData.adicionais ? productCartData.adicionais.filter(e => e.cat === monte.nome) : null

                        let faltando = alreadyInCart.length > 0 && typeof miss === 'string' && miss.includes(monte.nome) ? miss.substring(miss.indexOf(monte.nome)) : null

                        if (faltando !== null)
                            faltando = monte.qtde_obrigatoria - faltando.substring(faltando.indexOf("(") + 1, faltando.indexOf("x"))
                        else
                            faltando = null

                        faltando = miss ? faltando : null

                        return (

                            <div key={index} className="forms options-open-modal border-bottom">
                                <section className="space-between center-align size-normal">
                                    <SnackData
                                        title={monte.nome}
                                        icon="expand_more"
                                        desconto={{ valor_sem_desconto: monte.valor_sem_desconto, valor_tem_desconto: monte.valor_tem_desconto }}
                                        data={{ ...monte, img: monte.img || data.img }}
                                        price={monte.valor}
                                        showModal={val => setShowModal(val ? index : false)}
                                        showImg={true}
                                    >
                                        <div className="flex-4 flex-end" onClick={() => setShowModal(index)}>
                                            {
                                                alreadyInCart.length > 0 && ((typeof miss === 'string' && !miss.includes(monte.nome)) || miss === null) ?
                                                    <span className="has-text-success material-icons _center">
                                                        check_circle
                                                    </span>
                                                    : null
                                            }
                                            {
                                                faltando !== null && !isNaN(faltando) ?
                                                    <span className="has-text-danger size-small _center">
                                                        {faltando + "/" + monte.qtde_obrigatoria}
                                                    </span>
                                                    : null
                                            }
                                            <CallShowModal
                                                setShowModal={() => setShowModal(index)}
                                                showModal={showModal === index}
                                                icon="expand_more"
                                            />
                                        </div>
                                    </SnackData>
                                    {
                                        monte.itens && monte.itens.length > 0 ?
                                            <SnackModalForm adicionais
                                                title={monte.nome}
                                                price={monte.valor}
                                                subtitle={traducao.produtos.personalize + monte?.nome?.toLowerCase()}
                                                setShowModal={() => setShowModal(false)}
                                                show={showModal === index}
                                            >
                                                <NumberMinMax
                                                    data={monte}
                                                    title={monte.nome}
                                                    parenteName={true}
                                                    productCartData={productCartData}
                                                    setProductCartData={val => setProductCartData(val)}
                                                    next={() => toNext()}
                                                />
                                                <div className="pt-3 w-100">
                                                    <h5
                                                        style={textPrimary}
                                                        className="size-small has-text-weight-medium is-uppercased"
                                                    >
                                                        {traducao.produtos.alguma_observacao}
                                                    </h5>
                                                    <div className="flex-12 mt-2">
                                                        <input
                                                            value={observacao}
                                                            onChange={e => setComentario(e.target.value)}
                                                            type="text"
                                                            className="observation"
                                                            placeholder={traducao.produtos.alguma_observacao}
                                                        />
                                                    </div>
                                                </div>
                                            </SnackModalForm>
                                            : ''
                                    }
                                </section>
                            </div>
                        )
                    })
                    : null
            }{
                data.itens && data.itens.length === 0 ?
                    <div className="forms options-open-modal border-bottom container">
                        <section className="space-between center-align">
                            <SnackData
                                title={data.nome}
                                icon="expand_more"
                                data={data}
                                desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                                price={data.valor}
                                showModal={() => setShowModal(!showModal)}
                                aditionalTitle="Adicionais"
                                showImg={true}
                            >
                                <div
                                    className='flex-3 _center'
                                    style={{ display: 'flex' }}
                                >
                                    <MonteAdd
                                        productCartData={productCartData}
                                        carrinhoMensagem={carrinhoMensagem}
                                        setProductCartData={val => setProductCartData(val)}
                                        data={data}
                                        setShowModal={() => setShowModal(!showModal)}
                                        show={showModal}
                                        next={() => toNext()}
                                    />
                                </div>
                            </SnackData>
                        </section>
                    </div>
                    : null
            }{
                data.adicionais && data.adicionais.length > 0 ?
                    <div className="forms options-open-modal border-bottom">
                        <section className="space-between center-align size-normal">
                            <SnackData
                                title={"Adicionais"}
                                icon="expand_more"
                                showModal={val => setShowModal(val ? "adicionais" : false)}
                            >
                                <div className="flex-4 flex-end" onClick={() => setShowModal("adicionais")}>
                                    {
                                        productCartData.adicionais.filter(e => e.cat === undefined).length > 0 ?
                                            <span className="has-text-success material-icons _center">
                                                check_circle
                                            </span>
                                            : null
                                    }
                                    <CallShowModal
                                        setShowModal={() => setShowModal("adicionais")}
                                        showModal={showModal === "adicionais"}
                                        icon="expand_more"
                                    />
                                </div>
                            </SnackData>

                            <SnackModalForm
                                title={"Adicionais"}
                                setShowModal={() => setShowModal(false)}
                                show={showModal === "adicionais"}
                            >
                                <NumberMinMax
                                    data={data.adicionais}
                                    title={data.adicionais.nome}
                                    parenteName={true}
                                    productCartData={productCartData}
                                    setProductCartData={val => aumentarQtd(val)}
                                    holder="id_adicional"
                                    idName="holder"
                                />
                                <div className="pt-3 w-100">
                                    <h5
                                        style={textPrimary}
                                        className="size-small has-text-weight-medium is-uppercased"
                                    >
                                        {traducao.produtos.alguma_observacao}
                                    </h5>
                                    <div className="flex-12 mt-2">
                                        <input
                                            value={observacao}
                                            onChange={e => setComentario(e.target.value)}
                                            type="text"
                                            className="observation"
                                            placeholder={traducao.produtos.alguma_observacao}
                                        />
                                    </div>
                                </div>
                            </SnackModalForm>

                            <ModalCompressed
                                title={`Quantidade de ${productCartData.nome}`}
                                show={showModal === 'quantidade'}
                                setShowModal={() => setShowModal(false)}
                            >
                                <div className="space-between center-align border-bottom">
                                    <div className="flex-end flex-8 flex-column space-between">

                                        <MiniTitle aria-setsize={1} bold>
                                            Quantidade de {productCartData.nome}
                                        </MiniTitle>
                                        <MiniTitle size={1}>
                                            Quantos itens assim deseja?
                                        </MiniTitle>
                                        {
                                            productCartData?.adicionais?.map((adic) => (
                                                <div className='flex'>
                                                    <p className='flex-8'>{adic.nome}</p>
                                                    <p className='flex-3'>x{adic?.qtd ?? 1}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <NumberInputControlled
                                        setValue={val => setProductCartData(val)}
                                        getValue={
                                            val => setProductCartData({
                                                ...productCartData,
                                                ...val,
                                            })
                                        }
                                        value={productCartData.qtd}
                                    />
                                </div>
                            </ModalCompressed>
                        </section>
                    </div>
                    : null
            }{
                typeof data.valor !== undefined ?
                    <AddToCartConfirm
                        show={showModal !== false}
                        setShow={() => setShowModal(false)}
                        buttonMessage={carrinhoMensagem()}
                        cart={productCartData}
                        index={showModal}
                        next={
                            !miss && showModal === 'quantidade' ?
                                finalizarTudo
                                :
                                toNext
                        }
                        submit={miss}
                        ayw={true}
                    />
                    : null
            }
        </div>
    )
}