import React, { useState, useEffect } from 'react'
import SnackData from '../../../forms/SnackData'
import ProdutoAdd from '../../../forms/inputs/ProdutoAdd'
import useCarrinhoInicial from '../../../../hooks/useCarrinhoInicial'
import ProdutoSimples from '../../../forms/inputs/ProdutoSimples'
import { getCompanyId } from '../../../../helpers'
export default ({ data = {} }) => {

    const [adicionaisOpen, setAdicionaisOpen] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [adicAcomp, setAdicAcomp] = useState(null)

    const inicial = useCarrinhoInicial('produto', data)

    const [productCartData, setProductCartData] = useState(inicial)

    useEffect(() => {
        if (data.acompanhamento_itens && data.acompanhamento_itens.length > 0)
            setAdicAcomp("acomp")
        else if (data.adicional_itens && data.adicional_itens.length > 0)
            setAdicAcomp("adic")
        else
            setAdicAcomp(null)
    }, [data.acompanhamento_itens, data.adicional_itens])

    return (
        <div className="forms options-open-modal border-bottom">
            <section className="space-between center-align">

                <SnackData
                    title={data.nome}
                    data={data}
                    desconto={{ valor_tem_desconto: data.valor_tem_desconto, valor_sem_desconto: data.valor_sem_desconto }}
                    price={data.valor}
                    showModal={val => {
                        adicAcomp === "adic" || adicAcomp === null || getCompanyId === "9982"?
                            setShowModal(val)
                            : setAdicionaisOpen(val)
                    }}
                >
                    {
                        !((data.adicional_itens && data.adicional_itens.length === 0)
                            && (data.acompanhamento_itens && data.acompanhamento_itens.length === 0)) ?
                            <ProdutoAdd
                                productCartData={productCartData}
                                setProductCartData={val => setProductCartData(val)}
                                data={data}
                                setShowModal={val => setShowModal(val)}
                                show={showModal}
                                adicionaisOpen={adicionaisOpen}
                                setAdicionaisOpen={val => setAdicionaisOpen(val)}
                            />
                            :
                            <ProdutoSimples
                                productCartData={productCartData}
                                setProductCartData={val => setProductCartData(val)}
                                data={data}
                                setShowModal={val => setShowModal(val)}
                                show={showModal}
                                adicionaisOpen={adicionaisOpen}
                                setAdicionaisOpen={val => setAdicionaisOpen(val)}
                            />
                    }

                </SnackData>

            </section>
        </div>
    )
}