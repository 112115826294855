import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { calculatePrice } from '../../helpers';
import { addItenToCart, removeAll } from '../../actions';
import useGetPrimary from '../../helpers/useGetPrimary';
import useMoeda from '../../traducao/useMoeda';

export default ({ buttonMessage }) => {

    const dispatch = useDispatch();
    const moeda = useMoeda(); 

    const { textPrimary, bgPrimary } = useGetPrimary();

    const { cart } = useSelector(state => state.cartData);

    const size = cart.length > 0;

    const addToCart = () => {

        dispatch( addItenToCart(cart) );
        dispatch( removeAll() );
    }

    return(
        <>
        <div className={`${ !size ? 'none-add-cart' : ''} add-to-cart show-submit z-if-none-out z-max`}>
            <p style={ textPrimary } className="text-bold">
                { moeda }{ calculatePrice(cart, true).toFixed(2) }
            </p>
            <button  style={ bgPrimary } type="button" className="btn" onClick={ () => addToCart() }>
                { buttonMessage }
            </button>
        </div>
        <div className={`after-add-to-cart ${ !size ? 'none' : ''}`}></div>
        </>
    );
}
