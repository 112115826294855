import React from 'react'
import Row from '../general/Row';
import { calculatePrice, calculatePriceItem, dadosDaPizza, dadosAdicionais } from '../../helpers';

export default ({ cart }) => {

    return(
        <ul>
            {
                Array.isArray(cart) ?
                cart.map(e => {
                    let qtd = e.qtd ? e.qtd : 1;

                    return (
                        <Row 
                            key={ e.id + Math.random() }
                            qty={ qtd }
                            title={`${e.nome}${e.cat ? ' - ' + e.cat : ''}`}
                            titleClass="has-text-weight-bold"
                            subtitle={ e.tipo === "pizza" ? 
                                dadosDaPizza( e ) : dadosAdicionais( e ) 
                            }
                            price={ 
                                e.tipo === "pizza"?
                                calculatePrice(e)
                                :
                                calculatePriceItem(e) 
                            }
                        />

                    )
                })
                :
                Object.keys(cart).map( key => {

                    let qtd = cart[key].quantidade ? cart[key].quantidade : 1;

                    return (
                        <Row 
                            key={ cart[key].id + Math.random() }
                            qty={ qtd }
                            title={ cart[key].nome }
                            titleClass="has-text-weight-bold"
                            subtitle={ cart[key].tipo === "pizza" ? 
                                dadosDaPizza( cart[key] ) : dadosAdicionais( cart[key] ) 
                            }
                            price={ 
                                cart[key].valor ? 
                                cart[key].valor 
                                :
                                    cart[key].tipo === "pizza"?
                                    calculatePrice(cart[key])
                                    :
                                    calculatePriceItem(cart[key]) 
                            }
                        />

                    )
                })
            }
        </ul>
    );
}