import React, { useState, useEffect } from 'react';
import InputForm from '../InputForm';
import NumberInputMinMax from './NumberInputMinMax';
import addAdicional from '../../../helpers/addAdicional';
import { useParams } from 'react-router';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import useMoeda from '../../../traducao/useMoeda';
import { confirmAlert } from 'react-confirm-alert';

export default ({
    data,
    title,
    productCartData,
    setProductCartData,
    parenteName = false,
    parent = "nome",
    field = "adicionais",
    min = "qtde_obrigatoria",
    max = "qtde_permitida",
    showTitle = true,
    next = false,
    idName = "id_adicional",
    holder = false,
    apagar = null,
    setApagar = null,
}) => {

    const traducao = useConteudoTraduzido();
    const moeda = useMoeda();

    const { catId } = useParams();

    const [options, setOptions] = useState([]);

    const maximo = typeof traducao.validacoes.maximo_items === 'string'
        && traducao.validacoes.maximo_items.length > 0
        ? traducao.validacoes.maximo_items
        : "Você atingiu o máximo de itens desse tipo";

    useEffect(() => {
        setOptions([]);
    }, [catId]);

    useEffect(() => {
        if (apagar === true)
            setOptions([]);
    }, [apagar]);

    const addProducts = (val, element) => {

        setOptions(val);

        if (parenteName && val && val[0])
            val[0].cat = data[parent];

        if (holder !== false)
            element.holder = element[holder] + "adic";

        if (!data[max] || (typeof data[max] === 'number' && options.length <= data[max])) {
            setProductCartData({
                ...productCartData,
                [field]: addAdicional(element, productCartData[field], val, idName)
            })
        } else if (typeof data[max] === 'number' && options.length > data[max]) {
            confirmAlert({
                title: maximo,
                buttons: [
                    {
                        label: "Ok",
                    }
                ]
            });
        }
    }

    const ate = traducao.produtos.ate_adicionais.split("{%s}");

    return (
        <InputForm
            title={title}
            border={false}
            subtitle={data[max] && showTitle ? `(${ate[0]} ${data[max]})` : ''}
        >
            <h5>
                {data.nome}
            </h5>
            {
                data.itens && data.itens.map((e, ind) => (
                    <NumberInputMinMax
                        key={e.nome + ind}
                        product={e}
                        options={options}
                        getValue={val => addProducts(val, e)}
                        max={data[max]}
                        next={next}
                        apagar={apagar}
                        setApagar={setApagar}
                    >
                        <p className="flex-6">
                            <label className="mini-title size-normal-small text-bold">
                                {e.nome}
                            </label>
                            <small className="mini-title mt-0">
                                {e.descricao}
                            </small>
                            {
                                e.valor ?
                                    <small className="price">
                                        {moeda}{e.valor.toFixed(2)}
                                    </small>
                                    : ''
                            }
                        </p>

                    </NumberInputMinMax>
                ))
            }
            {
                data && Array.isArray(data) ?
                    data.map(e => (
                        <NumberInputMinMax
                            key={e.nome}
                            product={e}
                            options={options}
                            getValue={val => addProducts(val, e)}
                            max={data[max]}
                            min={data[min]}
                            apagar={apagar}
                            setApagar={setApagar}
                        >
                            <p className="flex-6">
                                <label className="mini-title size-normal-small text-bold">
                                    {e.nome}
                                </label>
                                <small className="mini-title mt-0">
                                    {e.descricao}
                                </small>
                                {
                                    e.valor ?
                                        <small className="price">
                                            {moeda}{e.valor.toFixed(2)}
                                        </small>
                                        : ''
                                }
                            </p>

                        </NumberInputMinMax>
                    ))
                    : null
            }
        </InputForm>
    );
}