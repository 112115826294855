/**
 * Do not judge by the name, here we find any index array if
 * the departments follows the sintax department[index].id
 * 
 * @param {int} findId what id I want to find
 * @param {object[]} departments the department for loop the search 
 */
const findDepartment = (findId, departments) => {
    // loop it
    for (let i in departments) {
        // the id is like the id we are searching?
        if (parseInt(departments[i].id) === parseInt(findId))
            return i; // just give me it then
    }
    // No? returns a default value in 0 index
    return 0;
}

// let's everybody find its departments
export default findDepartment;