/**
 * 
 * @param {*} address 
 * @param {*} entrega 
 * @param {*} location 
 * @param {*} erro 
 * @param {*} setShow 
 * @param {*} salvar 
 */
const calcularRota = async (
    address,
    entrega,
    location,
    erro,
    setShow,
    salvar
) => {

    let novoEndereco = address

    novoEndereco.coordenadas = location

    const origin1 = new window.google.maps.LatLng(novoEndereco.coordenadas.lat, novoEndereco.coordenadas.lng)

    const destinationB = new window.google.maps.LatLng(entrega.latitude, entrega.longitude)

    const maps = window.google.maps

    const service = new window.google.maps.DistanceMatrixService()

    await service.getDistanceMatrix(
        {
            origins: [origin1],
            destinations: [destinationB],
            travelMode: maps.TravelMode.DRIVING, //'DRIVING',
        }, (res, status) => {

            if (status.match(/ok/i)) {
                let fromMatriz
                let km = { ...novoEndereco }

                fromMatriz = res.rows[0].elements[0].distance

                if (fromMatriz) {

                    const distancia = parseFloat((fromMatriz.value / 1000).toFixed(1))

                    const valor = entrega.kms.filter((e, i) => ((distancia <= e.km) || (distancia > e.km && i + 1 === entrega.kms.length)))
                    const precos = valor.map(e => e.valor)

                    const valorFinal = Math.min(...precos)

                    km.km = {
                        id: 0,
                        km: distancia,
                        valor: valorFinal,
                    }
                    km.regiao = address.regiao ? { regiao: address.regiao } : []
                    km.metodo_entrega = "km"

                    if (km.km.km && Math.floor(km.km.km) > entrega.km_max_permitido) {
                        const err = "Este estabelecimento não faz entregas nessa distância (" + distancia + "km)"
                        erro({ g: err })
                        return err
                    }

                    else if (km.km === undefined || km.km.km === undefined) {
                        const err = "Houve um problema ao processar este endereço"
                        erro({ g: err })
                        return err
                    }

                    else if (km.km.km !== undefined && km.km.valor !== undefined) {
                        salvar(km)
                        setShow(false)
                        return 1
                    }
                    else {
                        const err = "Houve um problema"
                        erro({ g: err })
                    }
                }
                else {
                    const err = "Houve um problema com a busca do endereço, tente novamente e se o problema persistir, nos comunique"
                    erro({ g: err })
                    return err
                }
            } 
        }
    )
}

export default calcularRota

