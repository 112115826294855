import {calculatePrice, calcSabores} from '../helpers';
import {GenerateJsonType, JsonFinalType, SkinnyDataType} from './generateJson.type';

/**
 * * Retorna o json do pedido para o cupom e para a rota final
 */
const generateJson: GenerateJsonType = (cart, preferences, dados) => {
    const dadosDoPedido: SkinnyDataType[] = cart.map((e: any) => {
        const val = e.tipo === 'pizza' ?
            calcSabores(e, dados.como_cobrar_pizza, dados.tx_pizza_sabores)
            : e.valor;

        const data: SkinnyDataType = {
            nome: e.nome,
            id: e.id,
            tipo: e.tipo ? e.tipo : e.type,
            quantidade: e.qtd ? e.qtd : 1,
            adicionais: e.adicionais ? e.adicionais : [],
            acompanhamentos: e.acompanhamento ? e.acompanhamento : [],
            valor: val,
            observacao: e.observacao ? e.observacao : ''
        };


        if (e.tipo === 'promo_geral' || e.tipo === 'promo_pizza')
            data.itens = e.itens;

        if (e.tipo === 'pizza') {
            data.sabor = e.sabor;
            data.massa = e.massa ? e.massa : [];
            data.borda = e.borda;

            const catNome = e.catNome ? e.catNome + ': ' : '';

            data.nome = catNome + data.nome;
            data.observacao = e.bebida ?
                `${data.observacao} bebida: ${e.bebida.nome}`
                : data.observacao;
        }

        return data;
    });

    let valor_dos_produtos = parseFloat(calculatePrice(cart, true).toFixed(2));

    if (valor_dos_produtos >= dados.desc_tx_proc_vl) {
        const valor = valor_dos_produtos * (dados.desc_tx_proc / 100);
        valor_dos_produtos = valor_dos_produtos - valor;
    }


    if (preferences.entrega && !preferences.entrega.cep)
        preferences.entrega.cep = '';

    const telefone = preferences.telefone.replace(/\D/g, '');

    const jsonFinal: JsonFinalType = {
        produtos: {...dadosDoPedido},
        cliente: {
            ...preferences,
            telefone: telefone,
            cartao: [],
            presente: preferences.presente ? preferences.presente : [],
            avaliacao: preferences.avaliacao ? preferences.avaliacao : {estrela: [], comentarios: ''},
            pergunta: preferences.pergunta ? preferences.pergunta : {sim_nao: null, texto: '',},
        },
        canal: dados.canal,
        coupon: preferences.coupon && preferences.coupon.coupon ? preferences.coupon.coupon : null,
        presente: preferences.presente ? preferences.presente : [],
        valor_produtos: valor_dos_produtos,
        agendar: preferences.agendar ? preferences.agendar : [],
        pago: false,
        metodo_pagamento: preferences.metodo_pagamento ?
            preferences.metodo_pagamento.split(' ').join('_')
            : 'dinheiro',
        valor_entrega: 0,
        metodo_entrega: preferences.metodo_entrega ?
            preferences.metodo_entrega.split(' ').join('_')
            : 'balcao',
        valor_desconto: 0,
        tipo: 'link',
        tipo_pedido: 'link',
        valor: 0,
        valor_total: 0,
        provider_payment: preferences?.metodo_pagamento?.match('online') ? 'iugu' : 'internal',
        iugu: preferences?.metodo_pagamento?.match('online') ? {
            type: preferences.metodo_pagamento?.match('cartao') ? 'credit' : 'pix',
            hash: preferences?.iugu ?? undefined
        } : undefined
    };

    if (dados.cupom && !jsonFinal.coupon) {
        jsonFinal.coupon = dados.cupom;
    }

    if (
        preferences.metodo_entrega === 'entrega'
        && ((
            preferences.entrega && preferences.entrega.metodo_entrega === 'km'
        ) || (preferences.entrega && preferences.entrega.metodo_entrega === 'fixo'))
    ) {
        jsonFinal.valor_entrega = preferences.entrega.km.valor;

        if (dados.desc_tx_entrega && valor_dos_produtos >= dados.desc_tx_entrega) {
            jsonFinal.entrega = 0;
            jsonFinal.valor_entrega = 0;
        }

        jsonFinal.valor_total = jsonFinal.valor_produtos + preferences.entrega.km.valor;
        jsonFinal.valor = jsonFinal.valor_produtos;
    } else if (
        preferences.metodo_entrega === 'entrega'
        && preferences.entrega
        && preferences.entrega.metodo_entrega === 'regiao'
    ) {
        jsonFinal.valor_entrega = preferences.entrega.regiao.valor;

        if (dados.desc_tx_entrega && valor_dos_produtos >= dados.desc_tx_entrega) {
            jsonFinal.entrega = 0;
            jsonFinal.valor_entrega = 0;
        }
    }

    jsonFinal.valor_total = jsonFinal.valor_produtos + jsonFinal.valor_entrega;
    jsonFinal.valor_total_sem_desconto = jsonFinal.valor_produtos + jsonFinal.valor_entrega;


    if (dados.desc_tx_entrega && valor_dos_produtos >= dados.desc_tx_entrega) {
        jsonFinal.entrega = 0;
        jsonFinal.valor_entrega = 0;
    }


    if (preferences.acrescimo && preferences.acrescimo > 0) {
        jsonFinal.valor_total = jsonFinal.valor_total + (jsonFinal.valor_total * (preferences.acrescimo / 100));
    }
    if (preferences.desconto && preferences.desconto > 0) {
        jsonFinal.valor_total = jsonFinal.valor_total - (jsonFinal.valor_total * (preferences.desconto / 100));
    }
    jsonFinal.valor_total = jsonFinal.valor_total.toFixed(2) ?
        parseFloat(jsonFinal.valor_total.toFixed(2))
        : jsonFinal.valor_total;
    jsonFinal.valor_total_sem_desconto = jsonFinal.valor_total_sem_desconto.toFixed(2) ?
        parseFloat(jsonFinal.valor_total_sem_desconto.toFixed(2))
        : jsonFinal.valor_total_sem_desconto;

    if (
        preferences.coupon
        && preferences.coupon
        && typeof (preferences.coupon.vl_desconto) === 'number'
        && typeof (preferences.coupon.valor_do_pedido_com_desconto) === 'number'
    ) {
        jsonFinal.valor_total = preferences.coupon.valor_do_pedido_com_desconto + jsonFinal.valor_entrega;
        jsonFinal.valor_desconto = preferences.coupon.vl_desconto;
    }

    if (process.env.NODE_ENV === 'development') {
        console.log(JSON.stringify(jsonFinal));
    }


    jsonFinal.valor = Number(jsonFinal?.valor?.toFixed(2) ?? 0);
    jsonFinal.valor_entrega = Number(jsonFinal?.valor_entrega?.toFixed(2) ?? 0);
    jsonFinal.valor_produtos = Number(jsonFinal?.valor_produtos?.toFixed(2) ?? 0);

    localStorage.setItem('@ultima-venda@alphi', JSON.stringify(jsonFinal));
    return {
        enviar: jsonFinal,
        valor: jsonFinal.valor_produtos,
        valorTotal: jsonFinal.valor_total,
        valor_sem_desconto: valor_dos_produtos,
        metodo_pagamento: jsonFinal.metodo_pagamento,
    };
};

// Send our hard work to the world
export default generateJson;
