import React from 'react';
import { replacer } from '../../helpers';
import Text from './inputs/Text';

export default ({
    right = {},
    setRight,
    left = {},
    setLeft,
}) => {

    return (
        <div className="field is-horizontal">
            <div className="field-body columns is-mobile">
                <div className="field column is-8">
                    <h6 className="size-normal-small text-grey has-text-weight-bold">
                        {left.title}
                    </h6>
                    <div className="control is-expanded">
                        <Text
                            initialValue={left.initial}
                            placeholder={left.placeholder}
                            setValue={val => setLeft(val)}
                            classNameInput={left.classes ? left.classes : ''}
                            id={replacer(left.title)}
                        />
                        {
                            left.invalid ?
                                <em className="size-smaller has-text-danger">
                                    {left.invalid}
                                </em>
                                : null
                        }

                    </div>
                </div>

                <div className="field column is-4">
                    <h6 className="size-normal-small text-grey has-text-weight-bold">
                        {right.title}
                    </h6>
                    <div className="control is-expanded">
                        <Text
                            initialValue={right.initial}
                            placeholder={right.placeholder}
                            setValue={val => setRight(val)}
                            classNameInput={right.classes ? right.classes : ''}
                            id={replacer(right.title)}
                        />
                        {
                            right.invalid ?
                                <em className="size-smaller has-text-danger">
                                    {right.invalid}
                                </em>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
