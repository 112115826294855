import * as type from './actionTypes';

/**
 *  Will be used to get values from COMPANY_DATA
 *  Will not make the api call
 */
export function setData(value) {
    return {
        type: type.COMPANY_DATA,
        payload: value
    }
};

/**
 *  Will call the api and put it in COMPANY_DATA
 */
export function asyncSetData(value) {
    return {
        type: type.ASYNC_CALL,
        payload: value,
        typeTo: type.COMPANY_DATA,
    }
};

/**
 *  Will be used to get values from CATEGOTY_DATA
 *  Will not make the api call
 */
export function setCategoryData(value) {
    return {
        type: type.CATEGORY_DATA,
        payload: value
    }
};

/**
 *  Will call the api and put it in CATEGORY_DATA
 */
export function asyncSetCategoryData(value) {
    return {
        type: type.ASYNC_CALL,
        payload: value,
        typeTo: type.CATEGORY_DATA
    }
};

/**
 *  Will remove the category data from api
 */
export function removeCategoryData() {
    return {
        type: type.REMOVE_CATEGORY_DATA,
        payload: {}
    }
};

/**
 *  This action will add the value to the cart list,
 *  not excatly to the cart, but to the queue to be sent to cart 
 */
export function addToCart(value) {
    return {
        type: type.ADD_TO_CART,
        payload: value
    }
};

/**
 *  Change is complete status and message
 */
export function isComplete(value) {
    return {
        type: type.IS_COMPLETE,
        payload: value
    }
};

/**
 *  Adds the valu to the cart
 */
export function addItenToCart(value) {
    return {
        type: type.ADD_ITEN_TO_CART,
        payload: value
    }
};

/**
 *  Will update the value before sent to cart
 */
export function updateCart(value) {
    return {
        type: type.UPDATE_VALUE_IN_CART,
        payload: value
    }
};

/**
 *  Will update the item in the cart
 */
export function updateItenInCart(value) {
    return {
        type: type.UPDATE_ITEN_IN_CART,
        payload: value
    }
};

/**
 *  Will remove the value from the queue
 */
export function removeCartItem(value) {
    return {
        type: type.REMOVE_VALUE_IN_CART,
        payload: value
    }
};

/**
 *  Will remove the value from the queue
 */
export function removeAllItens() {
    return {
        type: type.REMOVE_ALL_ITENS,
    }
};

/**
 *  Will remove the value from the cart
 */
export function removeItenInCart(value) {
    return {
        type: type.REMOVE_ITEN_IN_CART,
        payload: value
    }
};


/**
 *  Will remove the value from the cart
 */
export function removeOne(value) {
    return {
        type: type.REMOVE_ONE,
        payload: value
    }
};


/**
 * Removes all data from cart data, will be used to
 * remove the data from the queue when adding to the cart
 */
export function removeAll() {
    return {
        type: type.REMOVE_ALL,
    }
};

/**
 * Removes the scroll from scroll-x classes in css, usefull for
 * prevent bugs with scroll bar
 */
export function removeScroll(value) {
    return {
        type: type.SCROLLBAR,
        payload: value
    }
};

export function removeTab(value) {
    return {
        type: type.REMOVE_TAB,
        payload: value
    }
};

/**
 *  Sets the value to user address
 */
export function userAddress(value) {
    return {
        type: type.USER_ADDRESS,
        payload: value
    }
};

/**
 * If the user want to schedule a time to the delivery, this
 * action will set it
 */
export function userSchedule(value) {
    return {
        type: type.USER_SCHEDULE,
        payload: value
    }
};

/**
 * sets the user payment method
 */
export function userPayment(value) {
    return {
        type: type.USER_PAYMENT,
        payload: value
    }
};

/**
 * Sets the delivery method
 */
export function deliveryMethod(value) {
    return {
        type: type.DELIVERY_METHOD,
        payload: value
    }
};

/**
 * 
 */
export function userName(value) {
    return {
        type: type.USER_NAME,
        payload: value
    }
};


/**
 *  Sets the user phone
 */
export function userPhone(phone, country = "br") {
    return {
        type: type.USER_PHONE,
        payload: { phone: phone, country: country }
    }
};


/**
 * This action will be used to some general types
 */
export function userGeneral(dataType, value) {
    return {
        type: dataType,
        payload: value
    }
};

/**
 * Sets the gift data
 */
export function userPresente(value) {
    return {
        type: type.GIFT,
        payload: value
    }
};

/**
 * Will be used in the component mount to set all the user data 
 * stored in sessionStorage
 */
export function userSetAll(value) {
    return {
        type: type.USER_SET_ALL,
        payload: value
    }
};

/**
 * Altera um campo em especifico em cliente
 */
export function setCliente(field, value) {
    return {
        type: type.SET_CLIENT,
        payload: { field, value }
    }
}

/**
 * Altera todos os valores em cliente
 */
export function setClienteAll(value) {
    return {
        type: type.SET_CLIENT_ALL,
        payload: value
    }
}
/**
 * 
 */
export function setClienteInicial() {
    return {
        type: type.SET_CLIENT_INITIAL,
    }
}
