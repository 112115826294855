import Axios from 'axios';
import React from 'react';
import { Price } from '../../../styled/Titles';

export default ({
    title,
    complement,
    placeholder,
    classes,
    setValue,
    phone,
    invalid,
    setErr,
    tira_cep,
    tipo,
}) => {


    function allowSubmit(e) {

        const number = /\d{5}-?\d{3}/;

        if (e.match(number)) {

            setValue("cep", number);

            const onlyNumbers = e.replace(/\D/g, "");

            Axios.get(`https://viacep.com.br/ws/${onlyNumbers}/json/`)
                .then((res) => {

                    if (!res.data.erro) {
                        setValue({
                            "cep": e,
                            "endereco": res.data.logradouro,
                            "complemento": res.data.complemento,
                            "bairro": res.data.bairro,
                            "estado": res.data.uf,
                            "cidade": res.data.localidade,
                        });
                    } else {
                        setErr({
                            cep: "Houve um problema ao processar o endereço"
                        });
                    }
                }).catch((err) => {
                    setErr({
                        cep: "Houve um problema ao processar o endereço"
                    });
                });

        }
        else if (e === "")
            setValue(e);
    }

    return (
        tira_cep === true || tipo === 'simples'? 
            null
            :
            <div>
                <h6 className={`size-normal-small text-grey has-text-weight-bold ${classes}`}>
                    {
                        title
                    }
                    <small className="has-text-weight-medium is-lowercase">{complement}</small>
                </h6>
                <input
                    type="text"
                    id="cep"
                    value={phone}
                    onChange={e => allowSubmit(e.target.value)}
                    className={`input ${invalid ? 'invalid' : ''}`}
                    placeholder={placeholder}
                />
                <Price style={{ fontSize: ".7rem" }}>
                    Você pode digitar o cep e o endereço vai ser preenchido automaticamente
            </Price>
            </div>
    )
};