import {
	GET_CART_ITEN,
	ADD_ITEN_TO_CART,
	UPDATE_ITEN_IN_CART,
	REMOVE_ITEN_IN_CART,
	REMOVE_ALL_ITENS,
	REMOVE_ONE
} from "../actions/actionTypes"
import { findId } from "../helpers"
import { setCart } from "../json"

// estado inicial
const initialState = {
	cart: [] // apenas o carrinho com array vazio
}

/**
 * CRUD do carrinho
 */
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_CART_ITEN:

			// retorna o carrinho todo
			return {
				...state,
				cart: action
			}
		case ADD_ITEN_TO_CART:
			// adiciona um item

			// Um objeto que terá os indexes do carrinho para adicionar ou alterar
			let dados = {
				iguais: [], // serão alterados pois já existem
				novos: [], // adicionados, novos
			}

			// vamos preencher os iguais, começa com o loop no carrinho existente
			dados.iguais = state.cart.map((carrinho, index) => {

				// crio um vetor
				let prod = []

				// pegar o(s) item(ns)
				prod = Array.isArray(action.payload) ? // é um array?
					action.payload // se sim, coloca ele normal 
					: [action.payload] // se não, deixa o item num array
				// Fazendo assim, só preciso trabalhar com um dado que sei que é um array

				// um array para os repetidos
				let repetido = []

				// faço um loop em cima dos itens vindo
				prod.forEach(cart => {
					if ( // se...
						carrinho && cart // existem esses dois
						&& carrinho.id === cart.id // e eles tem o mesmo id
						&& carrinho.nome === cart.nome // e o mesmo nome
						&& ( // e...
							(
								!cart.adicionais
								|| cart.adicionais.length === 0
							) // não tem adicionais em cart
							&& (
								!carrinho.adicionais
								|| carrinho.adicionais.length === 0
							) // não tem adicionais em carrinho
						) && ( // e...
							(!cart.acompanhamentos || cart.acompanhamentos.length === 0) // não tem acompanhamentos em cart
							&& (!carrinho.acompanhamentos || carrinho.acompanhamentos.length === 0) // não tem acompanhamentos em carrinho
						) && ( // e...
							(!cart.acompanhamento || cart.acompanhamento.length === 0) // não tem acompanhamento em cart
							&& (!carrinho.acompanhamento || carrinho.acompanhamento.length === 0) // não tem acompanhamento em carrinho
						) && ( // e...
							!cart.itens || cart.itens.length === 0 // não tem itens
						) && cart.tipo !== 'pizza'
					)
						repetido.push(index) // é um produto repetido
				})

				return repetido.length === 0 ? false : repetido // devolvo false ou uma matriz com os repetidos
			})

			// vamos preencher os novos, começa com o loop no carrinho existente
			dados.novos = state.cart.map(carrinho => {

				// matriz para os itens adicionados
				let prod = []

				// coloco na amtriz um array com os produtos
				prod = Array.isArray(action.payload) ?
					action.payload : [action.payload]

				// matriz para os novos
				let novo = []

				// faço um loop nos produtos a serem adicionados
				prod.forEach((cart, index) => {

					// const ig = state.cart.filter( item => {

					// 	let itemSemQtd = { ...item, qtd: 0 };
					// 	let cartSemQtd = { ...cart, qtd: 0 };

					// 	return JSON.stringify(itemSemQtd) === JSON.stringify(cartSemQtd) 
					// });
					const existe = findId(cart.id, state.cart, "id")

					if (cart && // existe cart?
						((
							(cart.adicionais && cart.adicionais.length !== 0) // tem adicionais ?
							|| (cart.acompanhamentos && cart.acompanhamentos.length !== 0) // ou tem acompanhamentos?
							|| (cart.acompanhamento && cart.acompanhamento.length !== 0) // ou tem acompanhamento?
							|| (cart.itens && cart.itens.length !== 0) // tem items?
							|| existe === false // já está no carrinho?
							|| cart.sabor // tem sabor?
						)
						)) {
						novo.push(index) // adiciono o index dele em relação aos produtos a serem adicionados
					} else if (cart && // existe cart?
						((
							(cart.adicionais && cart.adicionais.length !== 0) // tem adicionais ?
							|| (cart.acompanhamentos && cart.acompanhamentos.length !== 0) // ou tem acompanhamentos?
							|| (cart.acompanhamento && cart.acompanhamento.length !== 0) // ou tem acompanhamento?
							|| (cart.itens && cart.itens.length !== 0) // tem items?
							|| (
								typeof existe === 'string'
								&& state.cart[existe]?.adicionais.length > 0
							) // já está no carrinho?
							|| cart.sabor // tem sabor?
						)
						)) {
						novo.push(index) // adiciono o index dele em relação aos produtos a serem adicionados

					}
				})

				return novo.length === 0 ? false : novo // retorno false ou uma matriz
			})



			// o carrinho
			let tryIt = state.cart

			if (
				dados.iguais // tem iguais?
				&& dados.iguais.length > 0 // com alguma coisa dentro?
				&& tryIt.length > 0  // tem carrinho com algo?
			) {
				// loop nos iguais
				dados.iguais.forEach(el => {

					// matriz dos itens a serem adicionados
					let novoP = []

					// coloco aqui os itens
					novoP = Array.isArray(action.payload) ?
						action.payload : [action.payload]

					// se o el for um array
					if (el !== false && Array.isArray(el)) {

						// faço um loop nos produtos iguais
						el.forEach((rep, ind) => {

							// altero a qtd desse produto no index atual
							novoP[ind].qtd = tryIt[rep] && tryIt[rep].qtd ? // tem quantidade aqui?
								tryIt[rep].qtd + novoP[ind].qtd // eu somo a quantidade a ser adicionada com a existente
								: novoP[ind].qtd // se não, coloco a quantidade vinda agora

							if (tryIt[rep].observacao && novoP[ind].observacao && tryIt[rep].observacao.length > 0)
								novoP[ind].observacao = tryIt[rep].observacao + "; " + novoP[ind].observacao

							// recorto o carrinho nesse lugar e adiciono o novo com a quantidade certa
							tryIt.splice(rep, 1, novoP[ind])

						})
					}
					// } else if ( ind + 1 === tryIt.length ){

					// 	tryIt = tryIt.concat(novoP);
					// }
				})
			}
			if (
				dados.novos // tem novos?
				&& dados.novos.length > 0 // com quantidade sinificativa? 
				&& tryIt.length > 0 // e tem algo no carrinho atual?
			) {
				// loop nos novos
				dados.novos.forEach(el => {

					// matriz dos dados vindo
					let novoP = []

					novoP = Array.isArray(action.payload) ?
						action.payload : [action.payload]

					// el é um array?
					if (el !== false && Array.isArray(el)) {
						// faço um loop concatenando os elementos novos
						el.forEach(elem => {
							tryIt = tryIt.concat(novoP[elem])
						})
					}
				})
			}
			// não tem nada no carrinho?
			if (tryIt.length === 0)
				tryIt = tryIt.concat(action.payload) // só coloco no carrinho então

			// tiro possiveis itens repetidos
			const cart = tryIt.filter((elem, index, self) => {
				return index === self.indexOf(elem)
			})

			// coloco no sessionstorage
			setCart(cart)

			// finalizo o carrinho
			return {
				...state,
				cart: cart
			}

		case UPDATE_ITEN_IN_CART:
			// altera o carrinho

			// o carrinho atual
			let oldCart = state.cart

			// vamos ser sinceros que não tenho ideia agora
			const toUpdate = oldCart.reduce((e, i) => e.id === action.payload.id ? i : false, {})

			// ???????????
			const index = oldCart.indexOf(toUpdate)

			// eu tava doidão aqui
			oldCart.splice(index, 1)

			// adiciono alguma coisa no carrinho
			const newCart = oldCart.concat(action.payload)

			// coloco no sessionstorage
			setCart(newCart)

			// retorno pro carrinho
			return {
				cart: newCart
			}

		case REMOVE_ITEN_IN_CART:
			// remove um item

			// carrinho atual
			let untouchedState = state.cart

			// o que está sendo excluido
			const removeIndex = action.payload

			// retirando o item
			const removedItemCart = untouchedState.splice(removeIndex, 1)

			// colocando no sessionstorage
			setCart(untouchedState)

			// retornando o novo carrinho
			return {
				...state,
				removedItemCart
			}
		case REMOVE_ONE:

			const carrinho = state.cart

			const searchIndex = action.payload

			let cartMinusOne = carrinho[searchIndex].qtd -= 1

			if (cartMinusOne <= 0)
				carrinho.splice(searchIndex, 1)

			setCart(carrinho)

			return {
				...state,
			}

		case REMOVE_ALL_ITENS:
			// remove todos os itens

			return {
				cart: [] // apenas retorna um array vazio
			}
		default:
			return state
	}
}