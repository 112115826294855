export const fases = [
    { icone: "attach_money", nome: "Pagamento aprovado", numero: 0 },
    { icone: "thumb_up", nome: "Pedido confirmado", numero: 1 },
    { icone: "local_pizza", nome: "Preparando", numero: 2 },
    { icone: "two_wheeler", nome: "Entrega", numero: 3 },
    { icone: "check_circle", nome: "Finalizado", numero: 4 },
    { icone: "close", nome: "Cancelado", numero: 5 },
]

/**
 * 
 * @param {'pendente' | 'preparando' | 'entrega' | 'finalizado' | 'cancelado' | 'atrasado' | 'enviado' | 'retirado'} status 
 * @param  {"pending" | "paid" | "recused" | ""} pagamento
 */
export const alterarParaNumero = (status, pagamento) => {
    let statusPedido = -1

    if (pagamento === "paid") statusPedido = 0

    if (pagamento === 'recused') {
        statusPedido = 5
        return statusPedido
    }

    if (status === "preparando") statusPedido = 2
    if (status === "entrega") statusPedido = 3
    if (status === "finalizado") statusPedido = 4
    if (status === "enviado") statusPedido = 4
    if (status === "retirado") statusPedido = 4
    if (status === "cancelado") statusPedido = 5

    return statusPedido
}
