
/**
 * * Mascara o número de telefone para o padrão (XX) XXXX-XXXX
 * @param {string} telefone o número de telefone a ser mascarado
 * @returns {string} O número de telefone mascarado
 */
export const telefoneMask = (telefone) => {

    if ( telefone.length > 10 )
        return telefone
            .replace(/\D/g, '') 
            .replace(/^(\d{2})(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            .replace(/(\d)(\d{4})$/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    else    
        return telefone
            .replace(/\D/g, '') 
            .replace(/(\d)(\d{4})$/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
}
