/**
 * Checks if exists bebidas in cart, and returns it
 *  
 * @param {object[]} cart the cart
 */
const getBebidas = cart => {
   // filter the cart finding for bebidas
   const bebidas = cart.filter(e => e.tipo === "bebidas");
   // returns what we find
   return bebidas
}
// exports the little guy
export default getBebidas;