import React from 'react'
import { ContainerIcone } from '../../styled/Containers.tsx'
import { ButtonIcone } from '../../styled/Button'

export default ({ setShowModal, showModal, icon, iconClass }) => {
    return (
        <ContainerIcone>
            <ButtonIcone
                onClick={() => setShowModal(!showModal)}
            >
                <span className={`material-icons text-grey size-bigger ${iconClass}`}>
                    {icon}
                </span>
            </ButtonIcone>
        </ContainerIcone>
    )
}