import React, { useState, useEffect, useRef } from 'react';
import ProdutoModalCall from '../../modal/modalCallers/produto/ProdutoModalCall';
import useItens from '../../../hooks/useItens';
import CategoriesRow from '../../general/CategoriesRow';
import Text from '../../forms/inputs/Text';
import { useDispatch } from 'react-redux';
import { removeTab } from '../../../actions';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import debounce from 'lodash.debounce';
import { normalize } from '../../../helpers';
import TituloProduto from '../../general/TituloProduto';

export default () => {

    const traducao = useConteudoTraduzido();
    const { Data } = useItens();
    const ref = useRef();

    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const [itens, setItens] = useState([]);
    const [mais, setMais] = useState(30);

    useEffect(() => {
        let holder = Data && Data.itens ? Data.itens : []

        setItens(holder.length > mais ? holder.slice(0, mais) : holder);
        // eslint-disable-next-line
    }, [Data])

    useEffect(() => {
        dispatch(removeTab(true));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const query = Data && Data.itens ? Data.itens.filter(
            e => {
                const normalized = normalize(e.nome).toLowerCase();

                const searchNormalized = normalize(search).toLowerCase();
                return normalized.includes(searchNormalized);
            }
        ) : null

        if (search !== "")
            setItens(query);
        else {
            let holder = Data && Data.itens ? Data.itens : []

            setItens(holder.length > mais ? holder.slice(0, mais) : holder);
        }
        // eslint-disable-next-line
    }, [search]);

    window.onscroll = debounce(() => {

        const proximo = ref.current ? ref.current.childNodes[mais / 1.5] : null;

        if (proximo && proximo.offsetTop < window.scrollY && mais < Data.itens.length) {

            let holder = Data && Data.itens ? Data.itens : []

            const plus = mais + 15 > Data.itens.length ? Data.itens.length : mais + 15;

            setItens(holder.length > mais ? holder.slice(0, mais + 15) : holder);

            setMais(plus);
        }

    }, 100);
    // console.log(Data);
    return (
        <article className="container">

            <CategoriesRow />

            <section ref={ref}>

                <TituloProduto
                    voltar={false}
                    nome={Data?.nome}
                    descricao={Data?.descricao}
                />

                {
                    Data && Data.itens && Data.itens.length > 20 ?
                        <Text
                            title={traducao.geral.pesquise_o_produto}
                            placeholder={traducao.geral.pesquise_aqui + "..."}
                            initialValue={search}
                            setValue={val => setSearch(val)}
                        />
                        : null
                }{
                    Data && Data.itens && itens ?
                        itens.map(e => (
                            <ProdutoModalCall key={e.id} data={e} />
                        ))
                        : null
                }

            </section>
        </article>
    );
}