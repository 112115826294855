import React, { useEffect } from 'react'
import NumberInputControlled from './NumberInputControlled'
import SnackData from '../SnackData'
import ModalCompressed from '../ModalCompressed'
import NumberSimple from './NumberSimple'
import AddCalculation from '../AddCalculation'
import SnackModalForm from '../SnackModalForm'
import ProdutoAdicional from './ProdutoAdicional'
import { TextoPequeno } from '../../../styled/Text'
import { MiniTitle } from '../../../styled/Titles'
import { addItenToCart } from '../../../actions'
import useRequiredItensProduct from '../../../hooks/useRequiredItensProduct.js'
import AddToCartConfirm from '../../request/AddToCartConfirm'
import useProdutoAdd from '../../../hooks/useProdutoAdd'
import useExistiu from '../../../hooks/useExistiu'
import addProdutoNoCarrinho from '../../../helpers/addProdutoNoCarrinho'
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial'

export default ({
    productCartData,
    setProductCartData,
    data,
    setShowModal,
    show,
}) => {

    const {
        dispatch, cart, existiu, setExistiu,
        miss, setMiss, required, setRequired,
        stateHolder, setStateHolder, traducao,
        added, setAdded, adicionaisList,
        setAdicionaisList, productCartDataHolder,
        setProductCartDataHolder, modalAdicional,
        setModalAdicional,
    } = useProdutoAdd()

    const inicial = useCarrinhoInicial('produto', data)

    useRequiredItensProduct(
        data ? data.acompanhamento_itens : [],
        val => setRequired(val),
        productCartDataHolder,
        val => setMiss(val),
        required,
        false,
    )

    const add = (val) => {
        setProductCartData({
            ...productCartData,
            ...val,
            noTabs: true,
        })
        setShowModal(!show)
    }

    useExistiu(cart, data, setAdded, existiu, setExistiu, setStateHolder, setProductCartData, setProductCartDataHolder, inicial)

    let itens = []

    let quantidade = productCartData.qtd && productCartData.qtd > 0 ? productCartData.qtd : 1

    for (let i = 0; i < quantidade; i++) {
        itens.push(
            <ProdutoAdicional
                key={i}
                index={i}
                holder={productCartDataHolder}
                setHolder={setProductCartDataHolder}
                productCartData={productCartData}
                data={data}
                setModal={setModalAdicional}
                modal={modalAdicional}
            />
        )
    }

    useEffect(() => {

        if (productCartDataHolder.length > quantidade) {
            const npcdh = [...productCartDataHolder]

            npcdh.splice(quantidade - 1, productCartDataHolder.length - 1)

            setProductCartDataHolder(npcdh)
        }
        // eslint-disable-next-line
    }, [quantidade])

    const salvarNoCarrinho = () => {
        addProdutoNoCarrinho(
            productCartDataHolder,
            stateHolder,
            addItenToCart,
            dispatch,
            setAdicionaisList,
            miss,
            data,
            setAdded,
            setProductCartData,
            inicial,
        )
    }

    const mostrarAdicionais = () => {
        setShowModal(false)
        setAdicionaisList(true)
    }

    return (
        <>
            <div className={`flex-4 flex-column flex-end`} onClick={() => setShowModal(true)}>
                <NumberSimple
                    setValue={setStateHolder}
                    data={{ containerClass: `flex-end flex-12`, id: data.id, title: data.nome }}
                    getValue={val => add(val)}
                    value={added}
                />
            </div>

            <ModalCompressed
                title={data.nome}
                price={data.valor}
                subtitle={traducao.produtos.personalize + data.nome}
                setShowModal={() => setShowModal(false)}
                show={show}
                classesAdicionais="small"
            >
                <section className="space-between center-align h-80">
                    <SnackData
                        price={data.valor}
                        desconto={{ valor_tem_desconto: data.valor_tem_desconto, valor_sem_desconto: data.valor_sem_desconto }}
                        data={{}}
                        title={traducao.produtos.preco_unitario}
                        showImg={false}
                    >
                        <div className="flex-end flex-5 flex-column _center space-between">

                            <MiniTitle bold>
                                {traducao.produtos.quantidade}
                            </MiniTitle>

                            <NumberInputControlled
                                setValue={setStateHolder}
                                data={{
                                    containerClass: `${stateHolder > 0 ? '_center' : 'flex-end'} flex-12`,
                                    id: data.id,
                                    title: data.nome
                                }}
                                getValue={
                                    val => setProductCartData({
                                        ...productCartData,
                                        ...val,
                                    })
                                }
                                value={stateHolder}
                            />
                        </div>

                        <AddCalculation
                            show={stateHolder > 0}
                            productCartData={productCartData}
                            toCart={mostrarAdicionais}
                            cartMessage={traducao.geral.adicionar_ao_carrinho}
                        />
                    </SnackData>
                </section>
            </ModalCompressed>

            <SnackModalForm
                title="Adicionais"
                setShowModal={salvarNoCarrinho}
                show={adicionaisList}
                iconRight="close"
                iconRightClasses="has-text-danger size-bigger"
                fechar={setAdicionaisList}
            >
                <TextoPequeno className="is-italic">
                    {traducao.produtos.escolha_adicionais}
                </TextoPequeno>
                <ul>
                    {itens}
                </ul>
            </SnackModalForm>

            <AddToCartConfirm
                show={adicionaisList && modalAdicional === false && !show}
                setShow={() => setAdicionaisList(false)}
                buttonMessage={traducao.geral.adicionar_ao_carrinho}
                cart={productCartDataHolder}
                submit={miss}
                next={salvarNoCarrinho}
                index={{ atual: 1, tamanho: 2 }}
                dispatchAqui={false}
            />
        </>
    )
}
