import React from 'react';
import ModalForm from '../forms/ModalForm';
import Row from '../general/Row';
import Button from '../forms/Button';
import { useSelector, useDispatch } from 'react-redux';
import { removeItenInCart } from '../../actions';
import { calculatePrice, calculatePriceItem, dadosDaPizza, dadosAdicionais } from '../../helpers';
import GoToCheckout from './GoToCheckout';
import useGetPrimary from '../../helpers/useGetPrimary';
import { telefoneGa } from '../../ga';
import { MiniTitle } from '../../styled/Titles';
import { getCart } from '../../json';

export default ({ show, setShowModal, esconder = false }) => {

    const cart = esconder === false ? useSelector(state => state.cartIten.cart) : getCart();

    const { cor_padrao } = useGetPrimary();

    const { aberto } = useSelector(state => state.companyData.data.aberto);

    const permiteAgendamento = useSelector(state =>
        state.companyData.data.payload.entrega ?
         state.companyData.data.payload.entrega.agendamento.permite : false
    );

    const dispatch = useDispatch();

    return (
        <div className="forms options-open-modal">

            <ModalForm
                titleUppercase
                data={{ title: "Carrinho" }}
                show={show}
                setShowModal={() => setShowModal(false)}
            >
                {
                    cart.length > 0 ?
                        <>
                            <ul>
                                {
                                    cart.map((e, index) => {

                                        const qtd = e.qtd ? e.qtd : 1;
                                        // console.log(e);
                                        return (
                                            <Row
                                                key={e.id + Math.random()}
                                                qty={qtd}
                                                title={`${e.nome}${e.cat && !e.comCategoria ? ' - ' + e.cat : ''}`}
                                                titleClass="has-text-weight-bold"
                                                subtitle={e.tipo === "pizza" ?
                                                    dadosDaPizza(e) : dadosAdicionais(e)
                                                }
                                                price={
                                                    e.tipo === "pizza" ?
                                                        calculatePrice(e)
                                                        :
                                                        calculatePriceItem(e)
                                                }
                                            >
                                                {/* <div className="flex-column">
                                            <Button bg={false} classes="clean-button" click={ () => dispatch(removeOne(index)) }>
                                                <span className="material-icons has-text-warning size-big">exposure_neg_1</span>
                                            </Button> */}
                                                {
                                                    !esconder &&
                                                    <Button bg={false} click={() => dispatch(removeItenInCart(index))} classes="clean-button">
                                                        <span className="material-icons text-danger size-big">close</span>
                                                    </Button>
                                                }
                                                {/* </div> */}
                                            </Row>

                                        );
                                    })
                                }
                            </ul>
                            {
                                !aberto ?
                                    <MiniTitle bold color={cor_padrao}>
                                        {
                                            permiteAgendamento ?
                                                'Estamos fechado no momento, mas você ainda pode agendar seu pedido'
                                                :
                                                'Estamos Fechados no momento, assim que abrirmos, você poderá completar seu pedido'
                                        }

                                    </MiniTitle>
                                    : null
                            }

                        </>
                        :
                        <div className="has-text-centered is-italic">
                            Sem itens
                        </div>
                }

            </ModalForm>
            {
                esconder === false &&
                <GoToCheckout
                    buttonMessage="Concluir"
                    show={cart.length > 0 && show && (aberto || permiteAgendamento)}
                    onClick={() => telefoneGa()}
                    className="z-max"
                    cartItensQtd
                />
            }
        </div>
    );
};