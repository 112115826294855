const espanhol = {
    dados_usuario: {
        nome_endereco: "Referencia de la dirección",
        regiao: "Barrio",
        endereco: "Dirección",
        numero: "Número",
        complemento: "Complemento",
        bairro: "Barrio",
        cidade: "Ciudad",
        sua_cidade: "Su ciudad",
        estado: "Departamento",
        seu_estado: "Su departamento",
        cadastrar_novo_endereco: "Registrar nueva dirección",
        nome_cartao: "Nombre (titular) de la tarjeta",
        numero_cartao: "Numero de la tarjeta",
        vencimento_cartao: "Vencimiento",
        codigo_cartao: "Código",
        taxa_entrega: "Valor de entrega",
        acrescimo_pagamento: "Recárgo",
        desconto_pagamento: "Descuento",
        desconto_cupom: "descuento del cupón",
        pagamento: "Pago",
        troco: "Cambio para",
        nome_cliente: "Nombre",
        telefone: "Teléfono",
        nao_precisa_troco: "No precisa cambio",
        numero_de_telefone: "Número de teléfono",
        seu_numero: "Su numero",
        sem_nome: "Sin nombre",
        pesente: "Regalo",
        desejo_enviar_presente: "Deseo enviar un regalo",
        de: "De",
        para: "Para",
        mensagem: "Mensaje",
        outras_informacoes: "Otras informaciones",
        cadastratar_endereco: "Registrar nueva direccion",
        agendar_horario: "Agendar horário",
        desejo_agendar: "Deseo agendar horário",
        hora: "Hora",
        nome_completo: "Nombre completo",
        metodo_de_entrega: "Metodo de entrega",
        cep: "códico",
    },
    validacoes: {
        entrar_contato_alphi: "PARA REALIZAR SU PEDIDO \n" +
            "POR FAVOR COMUNICARSE POR WHATSAPP\n" +
            ">59899372473<",
        enderecos_encontrados: "Direcciones encontradas",
        enderecos_similares: "Encontramos {%s} direcciones similares, cuál de ellas es la suya?",
        confirme_endereco: "Confirme su dirección",
        dia_valido: "Coloque un día válido",
        horario_valido: "Defina um horário válido",
        periodo_valido: "Defina um período válido",
        definir_horario: "Definir horário",
        endereco_invalido: "Dirección inválida",
        numero_invalido: "Número inválido",
        bairro_invalido: "Barrio inválido",
        cidade_invalido: "Ciudad inválida",
        estado_invalido: "Departamento inválido",
        regiao_invalido: "Región inválida",
        definir_nome: "Digite su nombre",
        definir_entrega: "Defina un método de entrega",
        definir_endereco: "Defina una dirección para la entrega",
        definir_pagamento: "Defina um metodo de págo",
        precisa_completar: "Usted precisa completar",
        verifique: "Verifique este espacio",
        maximo_items: "Usted ya agregó el máximo de itens de este tipo",
        recarregue_sua_pagina: "Por favor, recargue su página, hubo algun problema",
    },
    geral: {
        manha: "Por la mañana",
        tarde: "De tarde",
        noite: "Por la Noche",
        dia: "Día",
        definir_dia: "Definir día",
        periodo: "Período",
        aplicavel: "(Es aplicable)",
        horario: "Horário",
        bem_vindo: "Sea Bienvenido",
        observacao_pedido: "Observaciones del pedido",
        confirmar: "Confirmar",
        subtotal: "Subtotal",
        cupom_de_desconto: "Cupon de descuento",
        desconto_de: "Descuento de",
        codigo_promocional: "Coloque el código promocional, si tiene",
        validar_cupom: "Validar cupon",
        dados_entrega: "Datos de entrega/retirada",
        entrega: "Entrega",
        retirar: "Retirar en el local",
        consumir: "Consumir en el local",
        retorno_whatsapp: "Hola, acabe de hacer un Pedido por el aplicativo virtual.",
        realizado_com_sucesso: "Pedido realizado con éxito!",
        em_breve_recebera: "En breve usted recibirá su pedido",
        acompanhar_pelo_icone: " En Cualquier momento usted podrá acompañar su pedido clicando en este ícono en su página principal",
        enviar_pelo_whatsapp: "Enviar mi pedido por WhatsApp",
        voltar_cardapio: "Volver al MENU",
        cnpj: "Rut",
        email: "Email",
        pagamento: "Forma de pago",
        pagar_online: "Pagar online",
        acrescimo_de: "Recárgo de",
        cartoes_aceitos: "Tarjetas que aceptamos",
        sim: "Si",
        nao: "No",
        adicionar_ao_carrinho: "Agregar al pedido",
        continuar: "Continuar",
        carregando: "Cargando",
        pedidos: "Pedidos",
        cupom: "Cupon",
        valor: "Valor",
        sem_pedidos: "Sin pedidos registrados",
        pesquise_o_produto: "busque el producto",
        pesquise_aqui: "busque aqui",
        pesquise_sua_promocao: "Busque su promoción",
        cancelar: "Cancelar",
        salvar: "Guardar",
        excluir: "Eliminar",
        consultar_pedidos: "Consultar mis pedidos",
        fechado: "Cerrado",

    },
    produtos: {
        incluiu_o_sabor: "Usted agregó el sabor",
        deseja_incluir_mais: "Desea agregar alguno mas?",
        preco_unitario: "Precio unitario",
        quantidade: "Cantidad",
        alguma_observacao: "Alguna observación?",
        proximo: "Ir para el próximo",
        proximo_passo: "Próximo paso",
        ate_adicionais: "Hasta {%s} adicionales",
        personalize: "Personalice su pedido",
        escolha_adicionais: "Elija adicionales y agregue las obrervaciones para sus itens antes de agregar al pedido o clique en cerrar para continuar su pedido",
        adicionais: "Adicionales",
        bebida: "Bebida",
        escolha_bebida: "Elija una bebida",
        acompanhamento: "Acompañamientos",
        tipo_massa: "Tipo de masa",
        borda: "Borde",
        escolher_sabores: "Elija sabores",
        refazer_pedido: "Agregar al pedido",
        busca_rapida: "Búsqueda Rápida"
    },
    novoFinal: {
        "pedido_enviado": "Pedido enviado!",
        "pedido_enviado_aprovacao": "Su pedido fue enviado, usted puede ver las etapas aqui",
        "status_pedido": "Estado del pedido",
        "esperando_aprovacao": "Esperando aprobación del pago",
        "total": "Total",
        "entrega": "Entrega",
        "whatsapp": "Conversar por whatsapp",
        "ver_pedido": "Ver pedido",
        "voltaro_inicio": "Hacer otro pedido/ volver al inicio",
        "enviar_por_whatsapp": "Le gustaría enviar su pedido para el whatsapp de nuestro local?",
        "ver_status": "Ver el estado de mi pedido",
    }
}

export default espanhol;
