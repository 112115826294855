/**
 * 
 * @param {*} data 
 */
const path = ( data, pathname ) => {
    const last = pathname[pathname.length-1] === "/";

    let path = pathname.split("/");

    if (path.length > 5) {
        path.pop();
        path.pop();
    }

    path = path.join("/");

    const finalPath = last ? path.slice(0, path.length-1) : path;

    return `${ finalPath }/promocao/${ data.id }`
}

export default path;