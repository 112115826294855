import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import useGetPrimary from '../../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';

export default ({ 
    data = {},
    children,
    added,
    getValue,
    product = {},
}) => {

    let [value, setValue] = useState( 0 );
    const traducao = useConteudoTraduzido();
    const { textPrimary, borderPrimary } = useGetPrimary();

    function valueRemoved() {

        setValue( value-1 );

        if (value-1 <= 0)
            getValue( { qtd: 0 } );

        else if ( data.min && value-1 < data.min )
            setValue( data.min );

        else
            getValue({ 
                qtd: value-1, 
            });       
    }

    const valueAdded = () => {
        if ( data.max && value < data.max && data.max !== 0) {
            setValue( value+1 );
            getValue({ 
                qtd: value+1,
                ...product 
            });
        } else {
            setValue( value+1 );
            getValue({ qtd: value+1, ...product});
        }
    }

    useEffect(() => {
        if ( data.max && value > data.max){
            setValue( data.max );
            confirmAlert({
                title: traducao.validacoes.maximo_itens,
                buttons: [
                    {
                        label: "Ok",
                        onClick: () => null,
                    }
                ]
            });
        }
        // eslint-disable-next-line
    }, [value]);

    if (added !== undefined)
        useEffect(() => added(value));

    return(
        <div className={`space-between center-align ${data.containerClass}`}>

            { children }

            <div className={`_center flex-end number-input-container ${data.class !== undefined ? data.class : ''}`}>
                <input 
                    min={ data.min ? data.min : 0 } 
                    max={ data.max ? data.max : 100 } 
                    readOnly 
                    type="number" 
                    value={ value } 
                    className="none" 
                />

                { 
                    value !== 0 || value > 0 ?
                    <>
                        <button type="button" className="clean-button" onClick={() => valueRemoved()}>
                            <span 
                            style={ textPrimary }
                            className="material-icons">
                                remove
                            </span>
                        </button>

                        <p 
                        style={{ ...textPrimary, ...borderPrimary }}
                        className={`border-rounded _center ${'none'}`}>
                            <span>
                                { value }
                            </span>
                        </p>
                    </>
                    : ''
                }

                <button type="button" className="clean-button" onClick={() => valueAdded()}>
                    <span style={ textPrimary } className="material-icons">
                        add
                    </span>
                </button>
            </div>
        </div> 
    );
}