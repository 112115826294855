import React from 'react'
import { getCompanyId } from '../../helpers'
import { MiniTitle, Price } from '../../styled/Titles'
import maringa from '../../img/perereka-maringa.jpeg'
import sarandi from '../../img/perereka-sarandi.jpeg'
import Axios from 'axios'
import api from '../../api.json'
import { Erro } from '../../styled/Text'

const abertoFechado = api.abertoFechado

export default ({
   title = "",
   path,
   image = null,
   id = 0,
}) => {
   const [aberto, setAberto] = React.useState(null)

   let newImg = image

   React.useEffect(() => {
      Axios.get(`${abertoFechado}/${id}`)
         .then(({ data }) => {
            setAberto(data.data.aberto)
         })
         .catch((err) => {
            console.log(err)
         })
         // eslint-disable-next-line
   }, [])

   if (getCompanyId === "7229" || getCompanyId === "7999") {

      if (title?.toLowerCase()?.includes('maring')) {
         newImg = maringa
      } else if (title?.toLowerCase().includes('sarandi')) {
         newImg = sarandi
      }
   }

   return (
      <a href={path}>
         <div className="category-block">
            <div className={`${!image ? 'bg' : ''} _center`}>
               {
                  image !== null && image !== "" ?
                     <img src={newImg} alt={title} />
                     :
                     <span className="material-icons-outlined">camera_alt</span>
               }
            </div>
            <MiniTitle size={.8} uppercase="capitalize" bold
               className="text-center"
            >
               {title}
            </MiniTitle>
            <MiniTitle style={{ textAlign: 'center' }}>
               {
                  aberto === true &&
                  <Price>Aberto agora</Price>
               }
               {
                  aberto === false &&
                  <Erro style={{ fontStyle: 'normal' }}>Fechado agora</Erro>
               }
            </MiniTitle>
         </div>
      </a>
   )
}