import React, { useState } from 'react'
import Entrega from './Entrega'
import MetodosDePagamento from './MetodosDePagamento'
import Coupon from './Coupon'
import Gift from './Gift'
import MaisInformacoes from './MaisInformacoes'
import Text from '../forms/inputs/Text'
import Agendamento from './Agendamento'
import { ContainerMargin } from '../../styled/Containers.tsx'
import useBeforeConfirmation from '../../hooks/useBeforeConfirmation'
import { Erro } from '../../styled/Text'
import { TitlePrimary } from '../../styled/Titles'
import FinishTabs from '../request/FinishTabs'
import { useHistory } from 'react-router'
import {calculatePrice, getCompanyId} from '../../helpers'
import useGetPrimary from '../../helpers/useGetPrimary'
import { confirmAlert } from 'react-confirm-alert'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import {useSelector} from "react-redux";
// import { cpfMask, cnpjMask } from '../../mascara/cpf';


/**
 * * A página onde o usuário vai poder definir coisas como método de pagamento, endereço de entrega, etc.
 * 
 * @returns {React.ReactElement}
 */
const BeforeConfirmation = () => {

    const [errMessage, setErrMessage] = useState({
        nome: undefined,
        metodoEntrega: undefined,
        endereco: undefined,
        metodoPagamento: undefined,
        agendar: undefined,
    })
    const traducao = useConteudoTraduzido()
    // const cpfNotaRef = React.useRef(null);

    const {
        obs,
        name,
        nome,
        setObs,
        aberto,
        setName,
        entrega,
        usuario,
        // cpfNota,
        // setCpfNota,
        // cpfCnpj,
        permitir_presente,
        // setCpfCnpj,
    } = useBeforeConfirmation()

    const { cart } = useSelector((state) => state.cartIten)
    let valor = calculatePrice(cart, true)

    const history = useHistory()

    const { cor_padrao } = useGetPrimary()

    const continuar = usuario => {
        const mensagemDeErro = {
            nome: !usuario.nome || usuario.nome === "" ? traducao.validacoes.definir_nome : undefined,
            metodoEntrega: !usuario.metodo_entrega || usuario.metodo_entrega === "" ? traducao.validacoes.definir_entrega : undefined,
            endereco: usuario.metodo_entrega === "entrega" && !usuario.entrega ? traducao.validacoes.definir_endereco : undefined,
            metodoPagamento: !usuario.metodo_pagamento || usuario.metodo_pagamento === "" ? traducao.validacoes.definir_pagamento : undefined,
            agendar: (usuario.agendar === undefined || (usuario.agendar && usuario.agendar.length === 0)) && !aberto ? "Agende um Horário" : undefined,
            troco: (usuario.troco !== 0 && usuario.troco < valor) ? 'O troco não pode ser menor que o valor do pedido' : undefined,
        }
        setErrMessage(mensagemDeErro)

        const errors = Object.keys(mensagemDeErro).filter((key) => mensagemDeErro[key] !== undefined)
        if (errors.length === 0) {
            history.push(`/${getCompanyId}/checkout/confirmation`)
        }
        else {
            confirmAlert({
                title: traducao.validacoes.precisa_completar,
                message: `${traducao.validacoes.verifique}: ${errors.join(', ')}`,
                buttons: [
                    {
                        label: "ok"
                    }
                ]
            })
        }
    }

    const setNomeHolder = nome => {
        setName(nome === "" ? " " : nome)
    }

    // const setCpfCnpjTipo = (tipo) => {
    //     setCpfCnpj(tipo);
    //     if (cpfNotaRef.current) {
    //         cpfNotaRef.current.focus();
    //     }
    // };
    // const handleCpfNota = (vals) => {
    //     if (cpfCnpj === 'cpf' || (vals?.length < 14 && cpfCnpj !== "cnpj")) {
    //         const cpf = cpfMask(vals ?? "");
    //         setCpfNota(cpf);
    //     }
    //     else if (cpfCnpj === 'cnpj' || vals?.length < 18) {
    //         const cnpj = cnpjMask(vals ?? "");
    //         setCpfNota(cnpj);
    //     }
    // };

    return (
        <article className="container checkout forms">

            <ContainerMargin top={.75}>
                {
                    !nome ?
                        <div>
                            <Text
                                uppercase
                                primary
                                title={traducao.dados_usuario.nome_completo}
                                placeholder={traducao.dados_usuario.nome_completo}
                                value={name}
                                setValue={val => setName(val)}
                                classNameInput={errMessage.nome ? 'invalid' : ''}
                                classes={errMessage.nome ? 'has-text-danger' : ''}
                            />
                            {
                                errMessage.nome ?
                                    <Erro>
                                        {errMessage.nome}
                                    </Erro>
                                    : null
                            }
                        </div>
                        :
                        <div>
                            <TitlePrimary primary={cor_padrao}>
                                {traducao.geral.bem_vindo}
                            </TitlePrimary>
                            <input
                                value={name}
                                onChange={e => setNomeHolder(e.target.value)}
                                type="text"
                                className="input"
                            />
                        </div>
                }
            </ContainerMargin>

            <Entrega erro={{ entrega: errMessage.metodoEntrega, endereco: errMessage.endereco }} />

            {/*}
            <div>

                <TitlePrimary
                    as="h6"
                    uppercase={true}
                    primary={cor_padrao}
                >
                    CPF ou CNPJ na nota
                </TitlePrimary>
                <div className="border-bottom">
                    <p
                        onClick={() => setCpfCnpjTipo('cpf')}
                        className="flex between border-bottom"
                    >
                        <label style={{ fontWeight: 500, flex: 1 }} htmlFor="cpf_nota">
                            <small>CPF na nota</small>
                        </label>
                        <input id="cpf_nota" className="radio" type="radio" name="na_nota" value="cpf" />
                        <span />
                    </p>
                    <p
                        onClick={() => setCpfCnpjTipo('cnpj')}
                        className="flex between"
                    >
                        <label style={{ fontWeight: 500, flex: 1 }} htmlFor="cnpj_nota">
                            <small>CNPJ na nota</small>
                        </label>
                        <input id="cnpj_nota" type="radio" name="na_nota" value="cnpj" />
                        <span />
                    </p>
                </div>

                <input
                    id={"CPF_na_nota"}
                    value={cpfNota}
                    onChange={({ target }) => handleCpfNota(target.value)}
                    type="text"
                    className={`input`}
                    placeholder={"Seu CPF ou CNPJ"}
                    ref={cpfNotaRef}
                />
            </div>
*/}
            {
                entrega
                && entrega?.agendamento?.permite
                && Array.isArray(entrega.agendamento.dias_permitidos)
                && <Agendamento entrega={entrega} erro={{ agendar: errMessage.agendar }} />
            }

            {
                errMessage.metodoPagamento && <Erro>{errMessage.metodoPagamento}</Erro>
            }
            <MetodosDePagamento erro={!!errMessage.metodoPagamento} />

            <Text
                uppercase
                primary
                title={traducao.geral.observacao_pedido}
                placeholder={traducao.geral.observacao_pedido}
                value={obs}
                setValue={val => setObs(val)}
            />

            <Coupon erro={errMessage} setErro={val => setErrMessage(val)} usuario={usuario} />

            <MaisInformacoes />

            {
                permitir_presente && <Gift />
            }

            <FinishTabs
                buttonMessage={traducao.geral.confirmar}
                onClick={() => continuar(usuario)}
                desabilitar={false}
            />
            <div className="after-add-to-cart"></div>
        </article>
    )
}

export default BeforeConfirmation