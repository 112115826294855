import React from 'react';
import {Switch, Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import GetCNPJ from '../components/body/GetCNPJ';
import TypeCaller from '../components/category/TypeCaller';
import {useSelector} from 'react-redux';
import MonteModalTwo from '../components/modal/modalCallers/MonteModalTwo';
import EscolherLoja from '../components/body/EscolherLoja';
import Phone from '../components/checkout/Phone';
import Status from '../components/body/Status';
import StatusPhone from '../components/body/StatusPhone';
import {getCompanyId} from '../helpers';
import DepartmentCategory from '../components/body/DepartmentCategory';
import BeforeConfirmation from '../components/checkout/BeforeConfirmation';
import Confirmation from '../components/checkout/Confirmation';
import Finish from '../components/checkout/Finish';
import {getDefaultApp, getFiliais} from '../json';
import {ouvirHistoricoGa} from '../ga';
import PromocaoDePizzaModalCall from '../components/modal/modalCallers/promo/PromocaoDePizzaModalCall';
import PesquisaProduto from '../components/pesquisa/PesquisaProduto';
import ProdutoUnico from '../components/modal/modalCallers/produto/ProdutoUnico';
import Avaliacao from '../components/checkout/Avaliacao';
import StatusPedido from '../components/body/StatusPedido';
import {PagarPix} from '../components/body/PagarPix';

const Routes = () => {

    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        return history.listen(location => {
            ouvirHistoricoGa(location.pathname);
        });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        ouvirHistoricoGa(location.pathname);
        // eslint-disable-next-line
    }, []);

    const {permitir_cnpj, tem_lojas, entrega} = useSelector(state => state.companyData.data.payload);
    const {aberto} = useSelector(state => state.companyData.data.aberto);
    const usuario = useSelector(state => state.userData ? state.userData : {});
    const filiais = getFiliais();
    return (
        <>
            {permitir_cnpj && !usuario.CNPJ ?
                <Switch>
                    <Route path="/:id?" component={GetCNPJ}/>
                </Switch>
                :
                <Switch>

                    <Route path="/:id/pagar-pix" component={PagarPix}/>
                    <Route path="/:id/status-pedido" component={StatusPedido}/>
                    <Route path="/:id/status">
                        {
                            usuario.telefone && usuario.telefone.length > 5 ?
                                <Status/>
                                :
                                <StatusPhone/>
                        }
                    </Route>

                    <Route path="/:id/pesquisa" component={PesquisaProduto}/>

                    <Route path="/:id/lojas" component={EscolherLoja}/>

                    <Route path="/:id/:department/:type/pesquisa/:cat/:catId" component={ProdutoUnico}/>

                    <Route path="/:id/:department/:type/:cat/monte_2/:catId?" component={MonteModalTwo}/>
                    <Route path="/:id/:department/:type/:cat/promocao/:catId" component={PromocaoDePizzaModalCall}/>
                    <Route path="/:id/:department/:type/:cat/:catId?" component={TypeCaller}/>

                    <Route exact path="/:id/:department" component={DepartmentCategory}>
                        {
                            tem_lojas && (!filiais || (filiais && filiais.filiais && filiais.filiais.includes(getCompanyId) === -1)) ?

                                <Redirect to={`/${getCompanyId}/lojas`}/>

                                : <DepartmentCategory/>
                        }
                    </Route>

                    <Route exact path="/:id">
                        {
                            tem_lojas && (!filiais || (filiais && filiais.filiais && filiais.filiais.includes(getCompanyId) === -1)) ?

                                <Redirect to={`/${getCompanyId}/lojas`}/>

                                : <DepartmentCategory/>
                        }
                    </Route>

                    <Route path="/:id/checkout/phone">
                        {
                            (aberto || entrega.agendamento.permite) ?
                                <Phone/>
                                : <Redirect to={`/${getCompanyId}`}/>
                        }
                    </Route>

                    <Route path="/:id/checkout/before-confirm">
                        {
                            (usuario && usuario.telefone && (aberto || entrega.agendamento.permite)) ?
                                <BeforeConfirmation/>
                                : <Redirect to={`/${getCompanyId}/checkout/phone`}/>
                        }
                    </Route>

                    <Route path="/:id/checkout/confirmation">
                        {
                            (usuario && usuario.telefone && (aberto || entrega.agendamento.permite)) || getCompanyId === '6840' ?
                                <Confirmation/>
                                : <Phone/>
                        }
                    </Route>

                    <Route path="/:id/checkout/aval">
                        {
                            (usuario && usuario.telefone && (aberto || entrega.agendamento.permite)) || getCompanyId === '6840' ?
                                <Avaliacao/>
                                : <Phone/>
                        }
                    </Route>

                    <Route path="/:id/checkout/finish">
                        {
                            // usuario && usuario.telefone ?
                            <Finish/>
                            // : <Redirect to={`/${getCompanyId}`} />
                        }
                    </Route>

                    <Route exact path="/">
                        <Redirect to={`/${getDefaultApp()}`}/>
                    </Route>

                </Switch>
            }
        </>
    );
};

export default Routes;