import React, { useEffect, useState } from 'react'
import InputForm from "../forms/InputForm"
import CheckboxInputWithConfirm from "../forms/inputs/CheckboxInputWithConfirm"
import { replacer } from '../../helpers'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import { CarrinhoInicial } from '../../typing/CarrinhoInicial'

// eslint-disable-next-line
class SaboresType {
    data
    setProductCartData = function () { }
    /**
     * @type {CarrinhoInicial}
     */
    productCartData
    ready
    index = null
    next = function () { }
    showModal
}

/**
 * 
 * @param {SaboresType} param0 
 * @returns 
 */
const Sabores = ({
    data,
    setProductCartData,
    productCartData,
    ready,
    index = null,
    next,
    showModal,
}) => {

    const [toFalse, setToFalse] = useState(false)

    const normalized = replacer(data.tamanho)
    const traducao = useConteudoTraduzido()

    const id = normalized + parseInt(Math.random() * 10000)
    const rollId = index !== null ? normalized + "-" + index : normalized

    // eslint-disable-next-line
    useEffect(() => {
        let checkedCount = 0
        let forms = document.querySelectorAll(`#${rollId} input[type="checkbox"]`)

        for (let e of forms) {
            if (e.checked)
                checkedCount += 1
        }

        if (checkedCount > 0)
            ready(true)
        else
            ready(false)

        if (showModal === false && showModal !== undefined) {
            for (let e of forms) {
                e.checked = false
            }
            ready(false)
            setToFalse(true)
        }
    })

    const qtdSabores = data.qtde_sabores ? data.qtde_sabores : 1

    return (
        <InputForm border={false} title={`Escolha até ${qtdSabores} sabor${qtdSabores > 1 ? 'es' : ''}`}>
            {
                data && data.pizzas ?
                    data.pizzas.map(e => (
                        <div id={id} key={e.id}>
                            <h6 className="size-normal-small has-text-weight-bold">
                                {e.nome}
                            </h6>
                            {
                                e.sabores.map(el => (
                                    <CheckboxInputWithConfirm
                                        title={traducao.produtos.escolher_sabores}
                                        key={el.id_sabor}
                                        rollId={rollId}
                                        data={{
                                            title: el.nome_sabor,
                                            label: el.nome_sabor,
                                            subtitle: el.descricao,
                                            valor: el.valor,
                                            tem_desconto: el.valor_tem_desconto,
                                            desconto: el.valor_sem_desconto,
                                            id: el.id_sabor,
                                            max: qtdSabores,
                                            cat: e.nome
                                        }}
                                        ready={val => ready(val)}
                                        getValue={
                                            val => setProductCartData(
                                                productCartData = {
                                                    ...productCartData,
                                                    sabor: val
                                                }
                                            )
                                        }
                                        productCartData={productCartData}
                                        next={() => next()}
                                        toFalse={toFalse}
                                        setToFalse={setToFalse}
                                    />
                                ))
                            }

                        </div>
                    ))
                    : ''
            }
            {
                data && data.sabores ?
                    data.sabores.map(sabor => (

                        <div id={id} key={sabor.id_sabor}>
                            <CheckboxInputWithConfirm
                                title={traducao.produtos.escolher_sabores}
                                rollId={rollId}
                                data={{
                                    title: sabor.nome_sabor,
                                    label: sabor.nome_sabor,
                                    subtitle: sabor.descricao,
                                    id: sabor.id_sabor,
                                    tem_desconto: sabor.valor_tem_desconto,
                                    desconto: sabor.valor_sem_desconto,
                                    max: qtdSabores,
                                    valor: productCartData.cobrar_vl_pizza ? sabor.valor : undefined,
                                }}
                                ready={val => ready(val)}
                                getValue={
                                    val => setProductCartData(
                                        productCartData = {
                                            ...productCartData,
                                            sabor: val
                                        }
                                    )
                                }
                                productCartData={productCartData}
                                next={() => next()}
                                toFalse={false}
                                setToFalse={setToFalse}
                            />
                        </div>
                    ))
                    : ''
            }
        </InputForm>
    )
}

export default Sabores