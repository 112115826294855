import React, { useState } from 'react';
import Text from '../forms/inputs/Text';
import { useDispatch } from 'react-redux';
import { userGeneral } from '../../actions';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default () => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const [cnpj, setCNPJ] = useState("");

    const save = () => {
        dispatch(userGeneral('MORE', { key: "CNPJ", value: cnpj }));
    }

    return (
        <article className="container">
            <form>
                <Text
                    title={traducao.geral.cnpj}
                    placeholder={traducao.geral.cnpj}
                    initialValue={cnpj}
                    setValue={val => setCNPJ(val)}
                    id="cnpj"
                />

                {
                    cnpj.length < 10 ?
                        <label htmlFor="cnpj" className="btn checkout">
                            {traducao.geral.continuar}
                        </label>
                        :
                        <button className="btn checkout size-normal" onClick={() => save()}>
                            {traducao.geral.continuar}
                        </button>
                }
            </form>
        </article>
    );
}