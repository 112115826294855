import React from "react";
import {BodyStyled, ButtonStyled, FooterStyled, FundoStyled, HeaderStyled, PopupStyled, PopupTitle} from "./styled";
import {iPopup} from "./types";

export const Popup: React.FC<iPopup> = (props) => {
    const [aberto, setAberto] = React.useState(props?.aberto ?? false);

    if (typeof props.controller === 'function') {
        return (
            props.aberto ?
                <>
                    <Content
                        titulo={props.titulo}
                        message={props.message}
                        // @ts-ignore
                        onClick={() => props.controller(!props.aberto)}
                    />
                    {/* @ts-ignore*/}
                    <FundoStyled onClick={() => props.controller(!props.aberto)}/>
                </>
                : <></>
        )
    } else {
        return (
            aberto ?
                <>
                    <Content
                        titulo={props.titulo}
                        message={props.message}
                        onClick={() => setAberto(!aberto)}
                    />
                    <FundoStyled onClick={() => setAberto(!aberto)}/>
                </>
                : <></>
        )
    }
}

const Content: React.FC<{ titulo: string, message?: string | JSX.Element, onClick: () => void }> = (props) => (
    <PopupStyled>
        <HeaderStyled>
            <PopupTitle>
                {props.titulo}
            </PopupTitle>
        </HeaderStyled>
        <BodyStyled>
            {props.message}
        </BodyStyled>
        <FooterStyled>
            <ButtonStyled type={"button"} onClick={props.onClick}>
                Ok
            </ButtonStyled>
        </FooterStyled>
    </PopupStyled>
)