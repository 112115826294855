import React from 'react';
import useGetPrimary from '../../helpers/useGetPrimary';

export default ({ title, subtitle, children, border = true }) => {

    const { textPrimary } = useGetPrimary();

    return (
        <div className={`${border ? 'border-bottom' : ''}`}>
            {
                (title && title !== "") || (subtitle && subtitle !== "") ?
                    <h6
                        style={textPrimary}
                        className="text-bold mb-1"
                    >
                        {title}
                        <small
                            style={textPrimary}
                            className="pl-1 weight-400"
                        >
                            {subtitle}
                        </small>
                    </h6>
                    : null
            }
            { children}
        </div>
    );
}