import Axios from 'axios';
import generateJson from '../tratar-api/generateJson';
import getCompanyId from './getCompanyId';
import {confirmAlert} from 'react-confirm-alert';
import {setResultadoFinal, setWhatsapp} from '../json';
import {mensagemFinal, purchase} from '../ga';
import {FinalizarType} from './finalizar.type';

/**
 *
 * @param {object[]} cart o carrinho
 * @param {object} preferences as preferencias do usuario
 * @param {object} dados dados adicionais para montar o json (como forma de pagamento e canal)
 * @param {function} setSubmitErro a função para mensagem de err
 * @param {function} history referencia da função useHistory
 * @param {string} finalPedido a rota para enviar o pedido
 * @param {number} min valor minimo exigido
 * @param {string} moeda a moeda para cobrar
 * @param {function} desabilitar a função para desabilitar o click
 */
const finalizar: FinalizarType = async (
    cart,
    preferences,
    dados,
    setSubmitErro,
    history,
    finalPedido,
    min,
    moeda,
    desabilitar,
) => {

    desabilitar(true);

    const {enviar, valor_sem_desconto, valorTotal} = generateJson(cart, preferences, dados);

    if ((!min || valor_sem_desconto >= min) && (!cart || cart.length > 0)) {

        // if (true || process.env.NODE_ENV === 'production') {

        await Axios.post(finalPedido, enviar)
            .then(e => {
                if (e.data['cliente.telefone']) {
                    confirmAlert({
                        title: 'Houve um problema com seu telefone',
                        message: 'Houve um problema ao validar seu telefone, por favor, tente novamente no formato: (XX) XXXXX-XXXX',
                        // @ts-ignore
                        buttons: [{
                            label: 'Verificar número',
                            onClick: () => history.push(`/${getCompanyId}/checkout/phone`)
                        }]
                    });
                    return
                }
                if (e.status === 200 || e.status === 201) {
                    //setWhatsapp(e.data.data.whatsapp)

                    mensagemFinal(
                        'um usuário completou com sucesso o pedido'
                    );
                    purchase(
                        'um usuário completou com sucesso o pedido',
                        moeda,
                        valorTotal
                    );

                    setResultadoFinal(JSON.stringify(e.data));

                    if (preferences.metodo_pagamento === 'pix_online') {
                        history.push(`/${getCompanyId}/pagar-pix`);
                    } else {
                        history.push(`/${getCompanyId}/status-pedido`);
                    }
                } else {

                    setTimeout(() => desabilitar(false), 1000);

                    const erro1 = e.data && e.data.data && e.data.data.text ? e.data.data.text : 'Houve um problema ao completar esta ação';
                    const erroCartao = e.data.data.erro ? e.data.data.erro : '';
                    const erroHolder = e.data && e.data.data && e.data.data.text ? e.data.data.text : 'Por favor tente novamente em alguns instantes, se o problema continuar, nos informe';

                    setSubmitErro(erro1);

                    mensagemFinal(
                        `um usuário teve um problema, erros: ${erro1}, ${erroCartao.length > 3 ? erroCartao : erroHolder}`
                    );

                    confirmAlert({
                        title: 'Houve um problema',
                        message: erroCartao.length > 3 ? erroCartao : erroHolder,
                        // @ts-ignore
                        buttons: [{
                            label: 'Ok'
                        }]
                    });
                }
            })
            .catch((e) => {

                setTimeout(() => desabilitar(false), 1000);

                setSubmitErro(e.data && e.data.data && e.data.data.text ? e.data.data.text : 'Houve um problema ao completar esta ação');

                mensagemFinal(
                    'um usuário teve um problema relacionado ao servidor, como cors ou outros problemas que impediram de completar a ação'
                );

                confirmAlert({
                    title: 'Houve um problema',
                    message: e?.data?.message ?? 'Por favor tente novamente em alguns instantes, se o problema continuar, nos infome',
                    // @ts-ignore
                    buttons: [{
                        label: 'Ok'
                    }]
                });
            });

        // } else {
        //     history.push(`/${getCompanyId}/status-pedido`)
        // }

    } else if (cart.length === 0) {

        setTimeout(() => desabilitar(false), 4000);

        confirmAlert({
            title: 'Você não adicionou nenhum produto ao carrinho',
            buttons: [
                {
                    label: 'Voltar às categorias',
                    onClick: () => history.push(`/${getCompanyId}`),
                }
            ]
        });
    } else {

        setTimeout(() => desabilitar(false), 4000);

        confirmAlert({
            title: `Seu Pedido não atingiu o valor mínimo de ${moeda}${min.toFixed(2)}, adicione mais itens para fechar seu Pedido`,
            buttons: [
                {
                    label: 'Voltar às categorias',
                    onClick: () => history.push(`/${getCompanyId}`),
                }
            ]
        });
    }
};

export default finalizar;

// houve um problema, contate o estabelecimento
