import React from 'react';
import addAdicional from '../../helpers/addAdicional';
import useMoeda from '../../traducao/useMoeda';
import NumberInput from '../forms/inputs/NumberInput';

export default ({ 
    data, 
    setProductCartData, 
    productCartData 
}) => {

    const moeda = useMoeda();

    return (
        <NumberInput 
            key={data.id_adicional}
            isAditional
            data={{
                containerClass: "border-bottom",
                price: data.valor
            }}
            getValue={
                val => setProductCartData({
                    ...productCartData,
                    adicionais: addAdicional(data, productCartData.adicionais, val)
                })
            }
            >
            <p className="flex-6">
                <label className="mini-title size-normal-small text-bold">
                    { data.nome }
                </label>
                <small className="mini-title mt-0">
                    {data.descricao}
                </small>
                {
                    data.valor ? 
                    <small className="price">
                        { moeda }{data.valor.toFixed(2)}
                    </small>
                    : ''
                }
            </p>
        </NumberInput>
    );
}