import React from 'react';
import useGetPrimary from '../../helpers/useGetPrimary';

export default ({ 
    children, 
    title, 
    type = "button", 
    click, 
    classes,
    bg = true
}) => {

    const { bgPrimary, borderPrimary, textPrimary } = useGetPrimary();

    return(
        <button style={ bg ? bgPrimary : { ...borderPrimary, ...textPrimary }} type={type} onClick={ () => click() } className={classes}>
            { title }
            { children }
        </button>
    );
}