import React from 'react';
import {Popup} from "../../packages/Popup";

const Info: React.FC<InfoType> = (props) => {
    if (!props.texto_tempo_entrega && !props.cartoes_aceitos && props.aberto) {
        return <></>;
    }
    return (
        <Popup
            type={"aviso"}
            aberto={props.mostrarAviso}
            controller={props.controller}
            titulo={"Informações importantes"}
            message={
                <div style={{textAlign: 'center'}}>
                    {
                        props.texto_tempo_entrega ?
                            <>
                                <div className="size-normal-small"
                                     dangerouslySetInnerHTML={{
                                         __html: props.texto_tempo_entrega ? props.texto_tempo_entrega : ''
                                     }}
                                >
                                </div>
                                <br/>
                            </>
                            : null
                    }
                    {
                        props.cartoes_aceitos ?
                            <>
                                <h6 className="uppercase size-normal">{props.traducao.geral.cartoes_aceitos}</h6>
                                <p className="size-normal-small">{props.cartoes_aceitos}</p>
                                <br/>
                            </>
                            : null
                    }

                    {
                        !props.aberto ?
                            <>
                                <h6 className="size-normal has-text-weight-bold">{props.traducao.geral.fechado}</h6>
                                <p>
                                    {props.motivo_fechado ? props.motivo_fechado : null}
                                </p>
                            </>
                            : null
                    }
                </div>
            }
        />
    );
}


export default Info;

type InfoType = {
    texto_tempo_entrega: any
    cartoes_aceitos: any
    aberto: any
    motivo_fechado: any
    traducao: any
    controller: any
    mostrarAviso: boolean
}
