import { useEffect } from 'react';

/**
 * 
 * @param {*} addressData 
 * @param {*} entrega 
 * @param {*} setAddress 
 * @param {*} endereco 
 */
function useConfigurarEndereco(addressData, entrega, setAddress, endereco, show, setErrMessage) {

    useEffect(() => {

        let holder = addressData ? addressData : {};

        if (entrega.tipo === 'regiao' && holder.regiao) {
            // eslint-disable-next-line
            holder.regiao = holder.regiao;
        }
        else if (entrega.tipo !== 'regiao' && holder.regiao && holder.regiao.regiao)
            holder.regiao = holder.regiao.regiao;
        else
            holder.regiao = "";

        setAddress(holder);

        if (entrega.remover_cidade || entrega.remover_estado) {

            let cidade = holder.cidade ? holder.cidade : endereco[0].cidade;
            let estado = holder.estado ? holder.estado : endereco[0].estado;

            setAddress({
                ...holder,
                cidade,
                estado,
            });
        }
        // eslint-disable-next-line
    }, [setAddress, addressData]);

    useEffect(() => {
        setErrMessage({});
        // eslint-disable-next-line
    }, [show]);

}

export default useConfigurarEndereco;