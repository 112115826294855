import React from 'react';
import {getResultadoFinal} from "../../json";
import {MiniTitle} from "../../styled/Titles";
import {getCompanyId, useGetPrimary} from "../../helpers";
import {Status} from "./status.class";
import {getStatus, iStatus, iStatusSettings} from "./FinalizarOPedido";
import { useHistory } from "react-router";


export const PagarPix: React.FC = () => {
    const ref = React.useRef<HTMLInputElement>();
    const statusSettings: iStatusSettings = getResultadoFinal();
    const {cor_padrao} = useGetPrimary();
    const [status, setStatus] = React.useState<iStatus>(new Status() as iStatus);
    const [interval, setInverval] = React.useState<any>();

    const history = useHistory()

    React.useEffect(() => {
        getStatus(statusSettings, setStatus);
        const timer = setInterval(() => getStatus(statusSettings, setStatus), 30000);
        setInverval(timer);
        return () => clearInterval(timer);

        }, []);

    React.useEffect(() => {
        if (status.payment === "paid" && interval) {
            clearInterval(interval);
            history.push(`/${getCompanyId}/status-pedido`)
        }
    }, [history, interval, status]);


    const copiar = () => {
        ref.current?.select()
        document.execCommand("copy")
    }

    return (
        <article className="container checkout forms">
            <div>
                <p style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                }}>
                    <span
                        className={"_center"}
                        style={{
                            color: cor_padrao,
                            border: '2px solid ' + cor_padrao,
                            borderRadius: '50%',
                            fontSize: '2rem',
                            width: 50,
                            height: 50,
                            marginRight: 5,
                            fontWeight: 'normal'
                        }}>
                        1
                    </span>
                    Primeiro faça o pagamento do pix
                </p>
                <p>
                    <small>
                        O código do pix sairá da tela assim que confirmamos o pagamento
                    </small>
                </p>
            </div>
            <div className={"_center"}>
                <MiniTitle color={cor_padrao} size={'1rem'} bold>
                    Escaneie o código QR ou copie a chave abaixo
                </MiniTitle>
                <img alt={status?.pix_qrcode_text} style={{width: '100%'}} src={status?.pix_qrcode}/>
            </div>
            <div style={{marginBottom: 10}}>
                <input
                    // @ts-ignore
                    ref={ref}
                    style={{
                        width: '100%',
                        borderRadius: 5,
                        color: cor_padrao,
                        padding: '5px 10px',
                        border: '1px solid ' + cor_padrao,
                    }}
                    value={status?.pix_qrcode_text}
                    readOnly
                />
                <button
                    onClick={copiar}
                    type={'button'}
                    style={{
                        padding: '10px 15px',
                        borderRadius: 5,
                        marginTop: 5,
                        backgroundColor: 'transparent',
                        border: '2px solid ' + cor_padrao,
                        fontWeight: 'bold'
                    }}
                >
                    Copiar código
                </button>
            </div>
            {/*<StatusPedido/>*/}
        </article>
    );
};