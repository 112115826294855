import findDepartment from "./findDepartment";

/**
 *  A bit complex, right?
 *  Keep calm and read my comments and everything will be clear
 *  The state comes from the useSelector
 * 
 * @param {object} state
 * @param {object} match
 * @returns {object} category
 */
const reduceToCategory = (state, match) => {
    // First we reduce the params by finding the payload
    const payload = state.companyData.data.payload;
    const dpt = findDepartment(match.department, payload.departamento);
    // Then, we check if the payload and payload.departamento exists...
    // Why?? Because the data is comming from and api, if this function is
    // Being triggered before the api returns the data, everything breaks 
    if ( payload && payload.departamento && payload.departamento[dpt]) {
        // Foreach category in payload...categorias
        for( let category of payload.departamento[dpt].categorias ) {
            // We check if the id match the parameter cat (category param)
            if ( category.id === match.cat )
                // We return the category with all its data
                return category; 
        }
    }
}

export default reduceToCategory;