import React from 'react'
import { useDispatch } from 'react-redux'
import { userGeneral } from '../../../actions'
import useGetPrimary from '../../../helpers/useGetPrimary'
import { Erro, TextoPequeno } from '../../../styled/Text'
import ModalForm from '../../forms/ModalForm'
import CartaoSVG from './CartaoSVG'


const Iugu = window.Iugu


const CartaoModal = ({ show, setShow, accountID, setMostrarModal }) => {

    const { bgPrimary } = useGetPrimary()
    const dispatch = useDispatch()

    const data = { title: "Pagamento online" }
    const [number, setNumber] = React.useState("")
    const [name, setName] = React.useState("")
    const [expiry, setExpiry] = React.useState("")
    const [cvv, setCvv] = React.useState("")

    const [tipoCartao, setTipoCartao] = React.useState("")
    const [numeroValido, setNumeroValido] = React.useState(null)
    const [dataValida, setDataValida] = React.useState(null)
    const [carregando, setCarregando] = React.useState(false)


    function handleNumero(numero) {
        const apenasNumeros = numero.replace(/\D/g, "")
        const tipo = Iugu.utils.getBrandByCreditCardNumber(apenasNumeros)

        setTipoCartao(tipo)
        setNumber(apenasNumeros.replace(/([0-9]{4})/g, "$1 ").trim())

        if (apenasNumeros.length > 12) {
            const valido = Iugu.utils.validateCreditCardNumber(apenasNumeros)
            setNumeroValido(valido)
        }
    }

    function handleData(data) {
        const dataFormatada = data.replace(/^([0-9]{2})([0-9]{1})/, "$1/$2")
        setExpiry(dataFormatada)

        if (dataFormatada.length > 4) {
            const valida = Iugu.utils.validateExpirationString(dataFormatada)
            setDataValida(valida)
        }
    }

    function verifciarCartao() {
        setCarregando(true)
        Iugu.setAccountID(accountID)
        // Iugu.setTestMode(true)

        Iugu.setup()

        const nomeDoUsuario = name.split(' ')
        const dataExpiracao = expiry.split('/')

        const cc = Iugu.CreditCard(
            number,
            dataExpiracao[0],
            dataExpiracao[1],
            nomeDoUsuario[0],
            nomeDoUsuario[nomeDoUsuario.length - 1],
            cvv
        )

        Iugu.createPaymentToken(cc, function (response) {
            setCarregando(false)
            if (response.errors) {
                setMostrarModal({
                    show: true,
                    success: false,
                    title: "Houve um problema, verifique seus dados",
                })
            } else {
                setMostrarModal({
                    show: true,
                    success: true,
                    title: "Tudo certo! Pode continuar",
                })
                dispatch(userGeneral('MORE', { key: 'iugu', value: response.id }))
                setShow(false)
            }
        })
    }

    return (
        <ModalForm data={data} show={show} setShowModal={setShow} closeIcon={false}>

            <CartaoSVG tipo={tipoCartao} cvv={cvv} number={number} name={name} expiry={expiry} />

            <div className="field">
                <div className='control'>
                    <TextoPequeno as={"label"}>
                        Número do cartão
                    </TextoPequeno>
                    <p>
                        <input
                            onChange={(input) => handleNumero(input.target.value)}
                            className='input'
                            type={'text'}
                            name="number"
                            value={number}
                        />
                    </p>
                    {
                        numeroValido === false &&
                        <Erro>
                            Número inválido
                        </Erro>
                    }
                </div>
                <div className="control">
                    <TextoPequeno as={"label"}>
                        Nome no cartão
                    </TextoPequeno>
                    <p>
                        <input value={name} onChange={(input) => setName(input.target.value)} className='input' type={'text'} name="name" />
                    </p>
                </div>
                <div className='control columns flex'>
                    <div className='column'>
                        <TextoPequeno as={"label"}>
                            Data de vencimento
                        </TextoPequeno>
                        <p>
                            <input
                                onChange={({ target }) => handleData(target.value)}
                                className='input'
                                type={'text'}
                                name="expiry_date"
                                value={expiry}
                            />
                        </p>
                        {
                            dataValida === false &&
                            <Erro>
                                Data inválida!
                            </Erro>
                        }
                    </div>
                    <div className='column'>
                        <div className='control'>
                            <TextoPequeno as={"label"}>
                                CVV
                            </TextoPequeno>
                            <p>
                                <input onChange={(input) => setCvv(input.target.value)} className='input' type={'text'} name="cvv" />
                            </p>

                        </div>
                    </div>
                </div>
            </div>
            <div className="control _center">
                <button
                    type="button"
                    className="btn size-small"
                    style={bgPrimary}
                    onClick={verifciarCartao}
                >
                    {
                        carregando ?
                            'Verificando cartão...' :
                            'Pagar'
                    }
                </button>
            </div>


        </ModalForm>
    )
}

export default CartaoModal