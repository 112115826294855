import React, { useState, useEffect } from 'react'
import SnackData from '../../../forms/SnackData'
import ModalFormEffectless from '../../../forms/ModalFormEffectless'
import AditionalOption from '../../AditionalOption'
import Sabores from '../../Sabores'
import EdgeOption from '../../EdgeOption'
import DoughOption from '../../DoughOption'
import CallShowModal from '../../../forms/CallShowModal'
import { useSelector } from 'react-redux'
import useCarrinhoInicial from '../../../../hooks/useCarrinhoInicial'
import { MiniTitle } from '../../../../styled/Titles'
import useGetPrimary from '../../../../helpers/useGetPrimary'
import useConteudoTraduzido from '../../../../traducao/useConteudoTraduzido'
// eslint-disable-next-line no-unused-vars
import { PizzaDataProps } from '../../../../typing/PizzaPromo'


/**
 * * Componente para promoção de pizza
 * 
 * @param {PizzaDataProps} param0 
 * @returns 
 */
const PizzaData = ({
    data = {},
    setShowModal,
    showModal,
    arrayProdutos,
    produtoIndex,
    productCartData,
    setProductCartData,
    index,
    toNext = () => { },
}) => {

    const {
        como_cobrar_pizza,
        tx_pizza_sabores
    } = useSelector((state) => state.companyData.data.payload)
    /**  
     * * Definindo o valor inicial do pizzaData segundo o promo_item
     */
    let inicial = useCarrinhoInicial('promo_item', {
        ...data,
        como_cobrar_pizza,
        tx_pizza_sabores,
        index,
        produtoIndex
    })

    inicial = productCartData?.itens?.find((item) => item.index === index) ?? inicial


    const { cor_padrao } = useGetPrimary()
    const traducao = useConteudoTraduzido()

    const [pizzaData, setPizzaData] = useState(inicial)
    const setModal = (val = index, borda = false) => {

        if (arrayProdutos === 1) {
            if (borda)
                setShowModal(`b${val}`)
            else
                setShowModal(val)
        } else {

            if (borda)
                setShowModal({
                    valor: val,
                    produtoIndex,
                    conjunto: index,
                    borda: true,
                    tem_borda: data.bordas && data.bordas.length > 0,
                })
            else
                setShowModal(typeof val === 'number' ? {
                    valor: val,
                    produtoIndex,
                    conjunto: index,
                    borda: false,
                    tem_borda: data.bordas && data.bordas.length > 0,
                } : false)
        }
    }

    const setData = (val) => {
        setPizzaData(val)
    }
    const setObservation = (e) => {
        setData({ ...pizzaData, observacao: e })
    }

    useEffect(() => {
        setProductCartData({ ...pizzaData, index })
        // eslint-disable-next-line
    }, [pizzaData])

    const alreadyInCart = productCartData ? productCartData.filter(e => {

        return e.index === index && e.produtoIndex === produtoIndex
    }) : false

    return (
        <div className="forms options-open-modal border-bottom">
            <section className="space-between center-align">

                <SnackData
                    data={data}
                    title={data.tamanho}
                    showModal={() => setModal()}
                >
                    <div className="flex-4 flex-end" onClick={() => setModal()}>

                        {
                            alreadyInCart.length > 0 ?
                                <span className="has-text-success material-icons">
                                    check_circle
                                </span>
                                : null
                        }
                        <CallShowModal
                            setShowModal={() => setModal()}
                            showModal={showModal}
                            icon="expand_more"
                        />
                    </div>
                </SnackData>

                <ModalFormEffectless
                    title={data.tamanho}
                    subtitle={data.descricao}
                    show={
                        (arrayProdutos === 1 && showModal === index)
                        || (showModal.produtoIndex === produtoIndex && showModal.conjunto === index)
                    }
                    setShowModal={() => setModal(false)}
                    index={index}
                >
                    <DoughOption
                        data={data}
                        setProductCartData={setData}
                        productCartData={pizzaData}
                    />
                    <Sabores
                        data={data}
                        setProductCartData={setData}
                        productCartData={pizzaData}
                        ready={() => null}
                        index={index}
                        setShowModal={() => setModal(index, true)}
                        next={toNext}
                        showModal={undefined}
                    />

                    <div className="pt-3 w-100">

                        <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                            {traducao.produtos.alguma_observacao}
                        </MiniTitle>

                        <div className="flex-12 half-margin">
                            <input
                                value={pizzaData.observacao || ""}
                                onChange={(e) => setObservation(e.target.value)}
                                type="text"
                                className="observation"
                                placeholder={traducao.produtos.alguma_observacao}
                            />
                        </div>
                    </div>
                </ModalFormEffectless>

                {/* Inicio modal bordas */}
                <ModalFormEffectless
                    title={data.tamanho}
                    subtitle={data.descricao}
                    show={
                        (arrayProdutos === 1 && showModal === `b${index}`)
                        || (
                            (showModal.produtoIndex === produtoIndex || showModal.voltarBorda)
                            && showModal.conjunto === index
                            && showModal.borda === true

                        ) || showModal === "borda" + index
                    }
                    setShowModal={() => setModal(false)}
                    index={index}
                >
                    <EdgeOption
                        data={data}
                        setProductCartData={setData}
                        productCartData={pizzaData}
                        showPrice={productCartData.cobrar_borda}
                        index={index}
                    />
                    {
                        data.cobrar_vl_pizza ?
                            <AditionalOption
                                data={data}
                                setProductCartData={setData}
                                productCartData={pizzaData}
                                showPrice={true}
                            />
                            : null
                    }
                    <div className="pt-3 w-100">

                        <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                            {traducao.produtos.alguma_observacao}
                        </MiniTitle>

                        <div className="flex-12 half-margin">
                            <input
                                value={pizzaData.observacao || ""}
                                onChange={(e) => setObservation(e.target.value)}
                                type="text"
                                className="observation"
                                placeholder={traducao.produtos.alguma_observacao}
                            />
                        </div>
                    </div>
                </ModalFormEffectless>
            </section>
        </div>
    )
}

export default PizzaData