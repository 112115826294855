import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calculatePrice } from '../../helpers';
import { addItenToCart } from '../../actions';
import useGetPrimary from '../../helpers/useGetPrimary';
import useMoeda from '../../traducao/useMoeda';

export default ({
    buttonMessage,
    cart,
    show,
    setShow,
    noDispatch = false,
    className = "",
    adicionar
}) => {

    const dispatch = useDispatch();
    const moeda = useMoeda();
    const [efeito, setEfeito] = useState(false);

    const { textPrimary, bgPrimary } = useGetPrimary();

    const size = cart.length > 0;

    function addToCart() {
        if (!noDispatch && typeof adicionar !== 'function')
            dispatch(addItenToCart(cart));
        else if (typeof adicionar === 'function')
            adicionar();
        setShow(false)
    }

    useEffect(() => {

        setEfeito(!efeito);
        setTimeout(() => setEfeito(false), 800);
        // eslint-disable-next-line
    }, [cart]);

    return (
        <>
            <div className={`${!show ? 'none-add-cart' : ''} add-to-cart show-submit z-if-none-out z-max ${className}`}>
                <p style={textPrimary} className="text-bold">
                    {moeda}{calculatePrice(cart, true).toFixed(2)}
                </p>
                <button
                    style={bgPrimary}
                    type="button"
                    className={`btn ${efeito ? 'tremer' : ''}`}
                    onClick={() => addToCart()}
                >
                    {buttonMessage}
                </button>
            </div>
            <div className={`after-add-to-cart ${!size ? 'none' : ''}`}></div>
        </>
    );
}
