import { SET_CLIENT, SET_CLIENT_ALL, SET_CLIENT_INITIAL } from '../actions/actionTypes';

/**
 *  This reducer will hold all the categories data 
 */

const initialState = {
   id: null,
   nome: null,
   telefone: null,
   pedidos: [],
   enderecos: [],
};

export default (state = initialState, action) => {
   switch (action.type) {
      case SET_CLIENT:

         const { field, value } = action.payload;

         return {
            ...state,
            [field]: value
         };
      case SET_CLIENT_ALL:

         return { ...action.payload };
      case SET_CLIENT_INITIAL:

         return initialState;
      default:
         return state;
   }
};