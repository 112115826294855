import React, { useState, useEffect } from 'react';
import { findId } from '../../../helpers';
// import { confirmAlert } from 'react-confirm-alert';
import useGetPrimary from '../../../helpers/useGetPrimary';
import { useParams } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';

export default ({
    data = {},
    children,
    getValue,
    options,
    product = {},
    max,
    next,
    apagar = null,
    setApagar = null
}) => {
    const traducao = useConteudoTraduzido();
    const { catId } = useParams();

    const maximo = typeof traducao.validacoes.maximo_items === 'string'
        && traducao.validacoes.maximo_items.length > 0
        ? traducao.validacoes.maximo_items
        : "Você atingiu o máximo de itens desse tipo";

    let [value, setValue] = useState(0);

    useEffect(() => {
        setValue(0);
    }, [catId]);

    const { textPrimary, borderPrimary } = useGetPrimary();

    function handleChange(action, newValue) {
        if (typeof setApagar === 'function')
            setApagar(false);
        if (
            (max && options.length < max && action === "add")
            ||
            (!max && options.length < 1000 && action === "add")) {

            getValue([...options, product]);
            setValue(newValue)

        } else if (
            (max && !(options.length < max) && action === "add")
            || (!max && !(options.length < 1000) && action === "add")
        ) {
            const proximo = typeof (next) === 'function';
            confirmAlert({
                title: maximo,
                buttons: [
                    {
                        label: proximo ? traducao.produtos.proximo_passo : "Ok",
                        onClick: proximo ? () => next() : () => null,
                    }
                ]
            });

            return;

        } else if (value > 0 && action === "remove") {

            const index = findId(product.id_adicional, options);
            let cuttedProduct = options;
            cuttedProduct.splice(index, 1);

            getValue(cuttedProduct);

            setValue(newValue)
        }
    }

    useEffect(() => {
        if (apagar === true)
            setValue(0);
    }, [apagar]);

    return (
        <div className="space-between center-align border-bottom">

            { children}

            <div className={`_center flex-end number-input-container ${data.class !== undefined ? data.class : ''}`}>
                <input
                    readOnly
                    type="number"
                    value={value}
                    className="none"
                />

                {
                    value !== 0 || value > 0 ?
                        <>
                            <button type="button" className="clean-button" onClick={() => handleChange("remove", value - 1)}>
                                <span style={textPrimary} className="material-icons">
                                    remove
                            </span>
                            </button>

                            <p style={{ ...textPrimary, ...borderPrimary }} className={`border-rounded _center ${'none'}`}>
                                <span>
                                    {value}
                                </span>
                            </p>
                        </>
                        : ''
                }

                <button type="button" className="clean-button" onClick={() => handleChange("add", value + 1)}>
                    <span className="material-icons" style={textPrimary}>
                        add
                    </span>
                </button>
            </div>
        </div>
    );
}