import { combineReducers } from 'redux'; 
import companyData from './companyData';
import categoryData from './categoryData';
import cartData from './cartData';
import cartIten from './cartIten.js';
import removeScroll from './removeScroll';
import removeTabs from './removerTab';
import userData from './userData';
import cliente from './cliente';

/**
 *  Combine reducers and exports them
 */
export default combineReducers({
    companyData,
    categoryData,
    cartData,
    cartIten,
    removeScroll,
    userData,
    cliente,
    removeTabs
});