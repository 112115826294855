import React from 'react'
import InputForm from '../forms/InputForm';
import RadioInput from '../forms/inputs/RadioInput';

export default ({
    bebidas,
    setProductCartData,
    productCartData,
}) => {

    return (
        <InputForm>
            {
                bebidas ?
                    bebidas.map(beb => (
                        <RadioInput
                            key={beb.id}
                            title={"Bebidas"}
                            data={{
                                subtitle: beb.descricao,
                                label: beb.nome,
                                id: beb.id,
                                // valor: beb.valor,
                                // price: beb.valor,
                            }}
                            showPrice={true}
                            product={beb}
                            getValue={
                                (val) => setProductCartData(
                                    productCartData = {
                                        ...productCartData,
                                        bebida: { ...val, valor: 0 }
                                    }
                                )
                            }
                        />
                    ))
                    : null
            }
        </InputForm>
    );
}