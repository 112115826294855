import {useEffect} from 'react';
import {removeCart, removeCliente, setUserPreferences, getUserData} from '../json';
import {useDispatch} from 'react-redux';
import {removeAllItens, setClienteInicial, userPhone, userSetAll} from '../actions';

export const useLimparDados = () => {

    const dispatch = useDispatch();

    useEffect(() => {

        //const { telefone } = process.env.NODE_ENV === "production" ? getUserData() : { telefone: "(11) 1111-1111" };

        dispatch(removeAllItens());
        removeCliente();
        dispatch(setClienteInicial());
        setUserPreferences({});
        dispatch(userSetAll({
            nome: "",
            telefone: "",
            coupon: {},
            entrega: {},
            agendar: {},
            metodo_pagamento: "",
            acrescimo: 0,
            desconto: 0,
            metodo_entrega: "",
            presente: false,
            troco: 0,
        }));

        // dispatch(userPhone(telefone));

        // eslint-disable-next-line
    }, []);

    return JSON.parse(localStorage.getItem('@ultima-venda@alphi'))
};
