
// TODO: MAKE A FUNCTION IN THE ADICIONAIS SUM
// TODO: MAKE A FUNCTION IN THE ADICIONAIS SUM
// TODO: MAKE A FUNCTION IN THE ADICIONAIS SUM
// TODO: MAKE A FUNCTION IN THE ADICIONAIS SUM
// TODO: MAKE A FUNCTION IN THE ADICIONAIS SUM

/**
 *  Will be used to search for prices
 * 
 * @param {object} item the object we are searching 
 * @param {string|int} i the index 
 */
const getPrice = (item, i) => {

    // is the index of the object "sabor"?
    if (i === "sabor") {
        // yeah, it is

        // check how we need to calculate the sabor price
        const como_cobrar = item.como_cobrar_pizza ? item.como_cobrar_pizza : 'divide';

        // let's loop finding for prices
        const prices = item[i].map(e => e ? e.valor : 0);

        // and then, sum them
        let sum = prices.reduce((prev, cur) => prev + cur, 0);

        // the calculates way
        switch (como_cobrar) {
            case "divide":
                // here we get the median
                sum = sum / item.sabor.length;
                break; // stop man

            case "dividecara":
                // checks if the length is + than 2
                if (item.sabor.length > 2)
                    sum = Math.max(...prices); // if it is, we get the biggest
                else
                    sum = sum / item.sabor.length; // if not, we divide them
                break;
            case "cara":
                // we just get the most expensive
                sum = Math.max(...prices);
                break;
            default:
                // if none, we just divide it
                sum = sum / item.sabor.length;
                break;
        }
        if (item.sabor.length >= 2 && item.tx_pizza_sabores)
            sum += item.tx_pizza_sabores;
        // returns our hard work
        return sum;
    }

    // NOT, IT IS NOT SABOR, MAN!!!!

    // We create the adicionais array
    let adicionais = [];

    // and a default valor
    let adicionaisValor = 0;

    // does the item in index have adicionais?
    if (item[i] && item[i].adicionais) {
        // map through it
        adicionais = item[i].adicionais.map(e => {
            // how much? Do not want to say? Ok, it is 1
            const times = e.qtd ? e.qtd : 1;
            // and the price? No price? Ok, it is 0
            const valor = e.valor ? e.valor : 0;
            // the final price is the price * how much of it
            return valor * times;
        });
        // sum it and set the sum to adicionais valor created before
        adicionaisValor = adicionais.reduce((prev, cur) => prev + cur, 0);
    }
    // does i is adicionais and the item have a direct adicionais?
    if (i === "adicionais" && item && item.adicionais) {
        // loop it
        adicionais = item.adicionais.map(e => {
            // how many?
            const times = e.qtd ? e.qtd : 1;
            // how much?
            const valor = e.valor ? e.valor : 0;
            // how much * how many
            return valor * times;
        });
        // sum it
        adicionaisValor = adicionais.reduce((prev, cur) => prev + cur, 0);
    }
    // calm man...
    //  exists a qtd in the index? No? It is 1
    let times = item[i] && item[i].qtd ? item[i].qtd : 1;

    if (item[i] && item[i].valor_produto === true) {
        const itensValor = Object.keys(item[i].itens).map(e => {
            const times = item[i].itens[e].qtd ? item[i].itens[e].qtd : 1;

            return item[i].itens[e].valor * times;
        }
        );
        adicionaisValor = itensValor.reduce((prev, cur) => prev + cur, 0);
    } else if (item.valor_produto === true && i === 'itens') {

        const itensValor = Object.keys(item.itens).map(e => {
            const times = item.itens[e].qtd ? item.itens[e].qtd : 1;

            return item.itens[e].valor * times;
        }
        );
        adicionaisValor = itensValor.reduce((prev, cur) => prev + cur, 0);
    }

    // exists a valor in the index?
    return item[i] && item[i].valor ?
        (item[i].valor * times) + adicionaisValor // returns the valor * times + adicionaisValor
        : adicionaisValor; // just the adicionais valor is fine then
};

/**
 * Calculates the price of the product or the total price
 * 
 * @param {object} item the object we are searching 
 * @param {boolean} isAddToCart should I use the add to cart actions?
 */
const calculatePrice = (item, isAddToCart = false) => {

    // creates an empty array to hold the prices
    let prices = [];

    // here starts the struggle
    // the item is an object, so we need loop its keys and map them
    prices = Object.keys(item).map(i => {

        // if the type is pizza and we want add it to cart
        if (item[i] && item[i].tipo === "pizza" && isAddToCart) {

            // then we loop again the item in the index we are
            const flavor = Object.keys(item[i]).map(pizza => getPrice(item[i], pizza));

            // a last loop for now, we want to sum the result
            return flavor.reduce((prev, cur) => prev + cur, 0);
        }
        // no it is not pizza or we are not in the cart, just get the price
        return getPrice(item, i);
    });

    // Set a default calor if has no array
    let valorNoArray = 0;

    // the item has a direct valor?
    if (item.valor) {
        // check how many times
        const times = item.qtd ? item.qtd : 1;
        // multiply them
        valorNoArray = item.valor * times;
    }
    // push to prices array
    prices.push(valorNoArray);

    // sum everything
    const finalPrice = prices.reduce((prev, cur) => parseFloat(prev) + parseFloat(cur), 0);

    // parse it to float and return everything... wow I got tired after that
    return parseFloat(finalPrice);
};
// exports to world
export default calculatePrice;
