import React from 'react';
import { alterarParaNumero } from './status.fase';
import { MiniTitle } from '../../styled/Titles';


const FaseList = ({ fase, status, index }) => {
    if (fase.numero < 5) {
        if (fase.numero === 0 && status.payment === null) {
            return <div></div>;
        }
        const statusNumero = alterarParaNumero(status.status, status.payment);
        return (
            <React.Fragment key={fase.nome}>
                {index === 0 && status.status === 'pendente'
                    &&
                    <li>
                        <span className={'material-icons icone-ativo'}>
                            stop
                        </span>
                        <MiniTitle size={1} bold>
                            Pendente
                        </MiniTitle>
                    </li>
                }
                <li>
                    <span className={
                        `material-icons ${(
                            statusNumero >= fase.numero || (
                                status.payment === null
                                && fase.numero === 1
                            )
                        ) ?
                            'icone-ativo'
                            : ''
                        }`
                    }
                    >
                        {fase.icone}
                    </span>
                    <MiniTitle size={1} bold={statusNumero >= fase.numero}>
                        {fase.nome}
                    </MiniTitle>
                </li>
            </React.Fragment>
        );
    }
    else {
        return <li></li>;
    }
};

export default FaseList;