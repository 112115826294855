import { SCROLLBAR } from '../actions/actionTypes';

/**
 *  Tira o scroll das barras de rolagem
 */

const initialState = true;
  
export default (state = initialState, action) => {
    switch (action.type) {
        case SCROLLBAR:
            return action.payload;
        default:
            return state;
    }
}