import React, { useEffect, useState } from 'react';
import Row from '../general/Row';
import ModalForm from '../forms/ModalForm';
import { useDispatch, useSelector } from 'react-redux';

import ConsultarPedido from '../general/ConsultarPedido';
import { MiniTitle } from '../../styled/Titles';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import useMoeda from '../../traducao/useMoeda';
import getUserData from '../../helpers/getUserData';
import { setClienteJson } from '../../json';
import { setClienteAll, setClienteInicial } from '../../actions';

export default () => {

    const traducao = useConteudoTraduzido();
    const moeda = useMoeda();
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);
    const [dados, setDados] = useState({});

    const cliente = useSelector(state => state.cliente);
    const { telefone } = useSelector(state => state.userData);
    const { canal } = useSelector(state => state.companyData.data.payload);

    const abrirModalComProdutos = pedido => {
        setModalOpen(true);
        setDados(pedido);
    }

    useEffect(() => {
        async function fetch() {
            if (!cliente.pedidos || (cliente.pedidos && cliente.pedidos.length < 1)) {

                const cliente = await getUserData(telefone, canal);
                try {
                    if (cliente.code === 200 || cliente.code === 201) {

                        dispatch(setClienteAll(cliente.data));
                        setClienteJson(cliente.data);

                    } else if (cliente.code === 400) {
                        dispatch(setClienteInicial());
                        setClienteJson("{}");
                    }
                } catch (e) {
                    dispatch(setClienteInicial());
                    setClienteJson("{}");
                }
            }
        }
        fetch();
        // eslint-disable-next-line
    }, []);

    return (
        <article className="container">
            <ul>
                {
                    cliente && cliente.pedidos && cliente.pedidos.length > 0 ?
                        cliente.pedidos.map((pedido, ind) => {

                            const produtos = JSON.parse(pedido.products);
                            const entrega = JSON.parse(pedido.customer);
                            const cp = JSON.parse(pedido.coupon);

                            let lista = Object.keys(produtos).map(e => produtos[e].nome);

                            lista = lista.join(", ");

                            const coupon = cp && cp.coupon ? cp.coupon : false;

                            const metodo_entrega = entrega.metodo_entrega;

                            const para = metodo_entrega === 'entrega' ? `Para: ${entrega.entrega.endereco}` : ""

                            return (

                                <Row
                                    key={ind}
                                    title={pedido.nome}
                                    titleClass="has-text-weight-bold"
                                    onClick={() => abrirModalComProdutos(pedido)}
                                    subtitle={
                                        <div>
                                            <MiniTitle>
                                                <strong className="has-text-weight-bold pr-1">
                                                    {traducao.geral.pedidos}
                                                </strong>
                                                {lista}
                                            </MiniTitle>
                                            {coupon ? <MiniTitle>{traducao.geral.cupom}: {coupon} </MiniTitle> : null}
                                            <MiniTitle>
                                                <strong className="has-text-weight-bold">{traducao.dados_usuario.metodo_de_entrega}:</strong> {metodo_entrega}
                                            </MiniTitle>
                                            <MiniTitle><strong className="has-text-weight-bold">Valor:</strong> {moeda}{pedido.total_amount}</MiniTitle>
                                            <MiniTitle>
                                                {para}
                                            </MiniTitle>
                                            <span className="has-text-weight-bold">{pedido.created_at}</span>
                                        </div>
                                    }
                                >
                                    <div className="_center flex-4">
                                        <small className="size-smaller has-text-success">
                                            {pedido.status}
                                        </small>
                                        <span className="size-big material-icons">keyboard_arrow_down</span>
                                    </div>
                                </Row>
                            )
                        })
                        :
                        <p>
                            <em className="is-italic">{traducao.geral.sem_pedidos}</em>
                        </p>
                }
            </ul>
            <div className="forms options-open-modal">

                <ModalForm
                    titleUppercase
                    data={{ title: "Resumo do pedido" }}
                    show={modalOpen}
                    setShowModal={() => setModalOpen(false)}
                >
                    <ConsultarPedido dados={dados} />
                </ModalForm>

            </div>
        </article>
    );
}
