import React from 'react';
import { ContainerRow } from '../../../styled/Containers.tsx';
import { Erro, TextoNormal } from '../../../styled/Text';
import { MiniTitle } from '../../../styled/Titles';


export const MostrarErro = ({ submitErro }) => (

    submitErro ?
        <div className="border-bottom-danger">
            <Erro bold>
                {submitErro}
            </Erro>
        </div>
        : null
);

export const Agendar = ({ preferences }) => (

    preferences.agendar ?
        <section>
            <div>
                <MiniTitle className="pr-1" size={.9}>
                    {"Agendamento"}
                </MiniTitle>
                <MiniTitle mt={5} uppercase="uppercase" bold>
                    {preferences.agendar.dia}
                </MiniTitle>
                <MiniTitle className="pr-1" size={.9}>
                    {preferences.agendar.hora}
                </MiniTitle>
                <MiniTitle mt={5} uppercase="uppercase" bold>
                    {preferences.agendar.periodo}
                </MiniTitle>
            </div>
            <hr />
        </section>
        : null

);

export const Coupon = ({ preferences, traducao }) => (
    preferences.coupon && preferences.coupon.coupon ?
        <section>
            <div>
                <h3 className="size-normal-small has-text-weight-bold pb-0 has-text-grey">
                    {traducao.geral.cupom_de_desconto}
                </h3>
                <p className="size-normal-small has-text-grey">
                    {preferences.coupon.coupon}
                </p>
                <hr />
            </div>
        </section>
        : null

);

export const PagamentoTroco = ({ preferences, traducao, moeda }) => (
    <>
        {
            preferences.metodo_pagamento && preferences.metodo_pagamento.length > 0 ?
                <section>
                    <div>
                        <MiniTitle className="pr-1" size={.9}>
                            {traducao.geral.pagamento}
                        </MiniTitle>
                        <hr />
                    </div>
                </section>
                : null
        }{
            preferences.troco ?
                <section>
                    <div>
                        <MiniTitle className="pr-1" size={.9}>
                            {traducao.dados_usuario.troco}
                        </MiniTitle>
                        <MiniTitle mt={5} uppercase="uppercase" bold>
                            {moeda} {preferences.troco.toFixed(2)}
                        </MiniTitle>
                        <hr />
                    </div>
                </section>
                : null
        }
    </>
);

export const DadosPessoais = ({ preferences, traducao }) => (
    <section>
        <div>
            <MiniTitle className="pr-1" size={.9}>
                {traducao.dados_usuario.nome_cliente}
            </MiniTitle>
            <MiniTitle mt={5} uppercase="uppercase" bold>
                {preferences.nome}
            </MiniTitle>
            <MiniTitle className="pr-1" size={.9}>
                {traducao.dados_usuario.telefone}
            </MiniTitle>
            <MiniTitle mt={5} uppercase="uppercase" bold>
                {preferences.telefone}
            </MiniTitle>
        </div>
        <hr />
    </section>
);

export const TabelaValores = ({ preferences, traducao, moeda, valorEntrega, subTotal, total }) => (
    <section className="pb-2">
        <ContainerRow>
            <div className="column flex-8 pl-0">
                <MiniTitle as="h3" bold size={0.9} className="pb-0 mt-0">
                    {traducao.geral.subtotal}
                </MiniTitle>
                {
                    preferences.metodo_entrega === "entrega" &&
                        valorEntrega !== null && typeof valorEntrega === 'number' ?
                        <p className="size-normal-small text-grey">
                            {traducao.dados_usuario.taxa_entrega}
                        </p>
                        : null
                }{
                    preferences.acrescimo ?
                        <p className="size-normal-small text-grey">
                            {traducao.dados_usuario.acrescimo_pagamento}
                        </p>
                        : null
                }{
                    preferences.desconto ?
                        <p className="size-normal-small text-grey">
                            {traducao.dados_usuario.desconto_pagamento}
                        </p>
                        : null
                }{
                    preferences.coupon && preferences.coupon.vl_desconto ?
                        <p className="size-normal-small text-grey">
                            {traducao.dados_usuario.desconto_cupom}
                        </p>
                        : null
                }{
                    preferences.desc_tx_proc && subTotal >= preferences.desc_tx_proc_vl ?
                        <p className="size-normal-small text-grey">
                            {traducao.dados_usuario.desconto_compras} {moeda}{preferences.desc_tx_proc_vl.toFixed(2)}
                        </p>
                        : null
                }
            </div>

            <div className="column">
                <TextoNormal align="right">
                    {moeda}{subTotal.toFixed(2)}
                </TextoNormal>
                {
                    preferences.metodo_entrega === "entrega" &&
                        valorEntrega !== null && typeof valorEntrega === 'number' ?
                        <TextoNormal align="right">
                            {moeda}{valorEntrega.toFixed(2)}
                        </TextoNormal>
                        : null
                }{
                    preferences.acrescimo ?
                        <TextoNormal align="right">
                            {moeda}{(subTotal * (preferences.acrescimo / 100)).toFixed(2)}
                        </TextoNormal>
                        : null
                }{
                    preferences.desconto ?
                        <TextoNormal align="right">
                            {moeda}{(subTotal * (preferences.desconto / 100)).toFixed(2)}
                        </TextoNormal>
                        : null
                }{
                    preferences.coupon && preferences.coupon.vl_desconto ?
                        <TextoNormal align="right">
                            {moeda}{preferences.coupon.vl_desconto.toFixed(2)}
                        </TextoNormal>
                        : null
                }{
                    preferences.desc_tx_proc && subTotal >= preferences.desc_tx_proc_vl ?
                        <TextoNormal align="right">
                            -{moeda}{(subTotal * (preferences.desc_tx_proc / 100)).toFixed(2)}
                        </TextoNormal>
                        : null
                }

            </div>

        </ContainerRow>

        <hr className="my-1" />

        <div className="columns pt-3 total-right">
            <TextoNormal align="right">
                {moeda}{total.toFixed(2)}
            </TextoNormal>
        </div>
    </section>
);