import styled from "styled-components";

export const PopupStyled = styled.aside`
  background-color: ${(props) => props.theme.cores.claro};
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.cores.principal};
  position: fixed;
  z-index: 15;

  padding: .8rem;
  width: 80vw;
  transform: translate(-50%, -50%);
  min-height: 200px; 
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow: scroll;
  @media screen and (min-width: 800px) {
    width: 640px;
    height: 480px;
    top: 50%;
  }
`;

export const HeaderStyled = styled.header`
  margin: 8px 0;
`;

export const BodyStyled = styled.div`
  margin: 8px 0;
`;

export const FooterStyled = styled.footer`
  margin: 8px 0;
  width: 100%;
`;

export const ButtonStyled = styled.button`
  margin: auto;
  display: block;
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.cores.principal};
  padding: 10px 25px;
  border-radius: 5px;
  color: ${({theme}) => theme.cores.principal};
  cursor: pointer;
`;

export const PopupTitle = styled.h3`
  color: ${({theme}) => theme.cores.principal};
  font-weight: bold;
  text-align: center;
`;

export const FundoStyled = styled.aside`
  background-color: ${(props) => props.theme.cores.escuroTransparente};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
`;
