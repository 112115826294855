import React, { useEffect } from 'react'
import { adicionarOuRemoverProduto } from '../../../ga'
import useGetPrimary from '../../../helpers/useGetPrimary'
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido'

export default ({
    data = {},
    children,
    setValue,
    getValue,
    product = {},
    value = 0,
}) => {
    const traducao = useConteudoTraduzido()

    const { textPrimary, borderPrimary } = useGetPrimary()

    function valueRemoved() {
        setValue(value - 1)
        if (value - 1 <= 0)
            getValue({ qtd: 0 })
        if (data.min && value - 1 < data.min)
            setValue(data.min)
        else {
            getValue({
                qtd: value - 1,
            })
        }
        adicionarOuRemoverProduto('removeu', data.title ? data.title : `(${traducao.dados_usuario.sem_nome})`, value - 1)
    }

    function valueAdded() {

        if (data.max && value < data.max && data.max !== 0) {
            setValue(value + 1)
            getValue({
                ...product,
                qtd: value + 1,
            })
        } else {
            setValue(value + 1)
            getValue({ qtd: value + 1 })
        }
        adicionarOuRemoverProduto('adicionou', data.title ? data.title : `(${traducao.dados_usuario.sem_nome})`, value + 1)
    }

    useEffect(() => {
        if (data.max && value > data.max)
            setValue(data.max)
        // eslint-disable-next-line
    }, [value])

    return (
        <div className={`space-between center-align ${data.containerClass}`}>

            {children}

            <div className={
                `_center flex-end number-input-container ${data.class !== undefined
                    ? data.class
                    : ''
                }`
            }>

                <input
                    min={data.min ? data.min : 0}
                    max={data.max ? data.max : 200}
                    readOnly
                    type="number"
                    value={!isNaN(value) && value ? value : 1}
                    className="none"
                />

                {
                    (value !== 0 || value > 0) && !isNaN(value) ?
                        <>
                            <button type="button" className="clean-button" onClick={() => valueRemoved()}>
                                <span style={textPrimary} className="material-icons">
                                    remove
                                </span>
                            </button>

                            <p style={{ ...textPrimary, ...borderPrimary }} className={`border-rounded _center ${'none'}`}>
                                <span>
                                    {value}
                                </span>
                            </p>
                        </>
                        : null
                }

                <button type="button" className="clean-button" onClick={() => valueAdded()}>
                    <span style={textPrimary} className="material-icons">
                        add
                    </span>
                </button>
                {/* {product.qtd} */}
            </div>
        </div>
    )
}