export class Status {

    order_id = ""

    /**
     * @type {"pending" | "paid" | "recused" | ""}
     */
    payment = ""

    /**
     * @type {"iugu" | "interno"}
     */
    type = ""
    
    /**
     * @type {'pendente' | 'preparando' | 'entrega' | 'finalizado' | 'cancelado' | 'atrasado' | 'enviado' | 'retirado'}
     */
    status = ""


    /**
     * @type string | null
     */
    pix_qrcode = null
    
    /**
     * @type string | null
     */
    pix_qrcode_text = null
    
    /**
     * @type string | null
     */
    invoice_id = ""

}