import React from 'react'
import ModalForm from '../../forms/ModalForm'
import Button from '../../forms/Button'
import Text from '../../forms/inputs/Text'
import InputTwoColumns from '../../forms/InputTwoColumns'
import {Erro} from '../../../styled/Text'
import Carregando from '../../general/Carregando'
import {MiniTitle} from '../../../styled/Titles'
import Row from '../../general/Row'
// import { ButtonIcone } from '../../../styled/Button';
import calcularKm from '../../../distancia/calcularKm'
import calcularRota from '../../../distancia/calcularRota'
import useEnderecoVariaveis from '../../../hooks/useEnderecoVariaveis'
import {salvarEFechar, salvarEndereco} from '../../../distancia/enderecoHelper'
import useConfigurarEndereco from '../../../hooks/useConfigurarEndereco'
import Regiao from './endereco/Regiao'
import CidadeEstado from './endereco/CidadeEstado'
import Select from '../../forms/inputs/Select'
import CEP from '../../forms/inputs/CEP'
import {getCompanyId} from "../../../helpers";

export default ({
                    show,
                    setShow,
                    addressData = false,
                    save,
                    selecionar,
                    excluir,
                    setSuccess,
                }) => {

    const {
        cor_padrao, endereco, entrega, permitir_quadra,
        permitir_lote, tira_cep,
        errMessage, setErrMessage,
        traducao,
        carregando, setCarregando,
        modalSimilares, setModalSimilares,
        enderecosSimilares, setEnderecosSimilares,
        address, setAddress
    } = useEnderecoVariaveis()

    const enderecosSimilaresString = traducao.validacoes.enderecos_similares.split("{%s}")

    const salvar = d => {
        salvarEndereco(
            addressData,
            errMessage,
            save,
            selecionar,
            setShow,
            d
        )
    }

    const saveClose = () => {
        let addressHolder = address
        if (!address.estado) {
            address.estado = "-"
        }

        salvarEFechar(
            addressHolder,
            endereco,
            entrega,
            setCarregando,
            setErrMessage,
            setModalSimilares,
            setEnderecosSimilares,
            salvar,
            traducao
        )
    }

    useConfigurarEndereco(addressData, entrega, setAddress, endereco, show, setErrMessage)

    const setData = (field, value = "") => {
        if (typeof field !== 'object') {
            setAddress({
                ...address,
                [field]: value
            })
        } else {
            setAddress({
                ...address,
                ...field
            })
        }
    }

    const fazerCalculo = async (end, completo) => {
        setModalSimilares(false)
        let salvou = 0

        if (entrega.tipo === 'fixo' || (entrega.tipo === 'km' && entrega.tp_calculo === 0)) {
            salvou = await calcularKm(
                address,
                entrega,
                end,
                setErrMessage,
                salvar,
                completo
            )
        } else {
            salvou = await calcularRota(
                address,
                entrega,
                end,
                setErrMessage,
                setShow,
                salvar
            )
        }
        if (salvou === 1) {
            setSuccess({
                show: true,
                success: true,
                title: "Endereço escolhido com sucesso",
            })
        } else if (salvou && typeof salvou === 'string') {
            setSuccess({
                show: true,
                success: false,
                title: salvou,
            })
        }
    }
    const uruguay = getCompanyId === "6840"

    return (
        <div className="clean-close-modal">
            <ModalForm
                data={{title: traducao.dados_usuario.cadastratar_endereco}}
                show={show}
                setShowModal={() => setShow(false)}
                closeIcon={false}
                iconLeft={true}
            >
                <Erro>
                    {errMessage.g}
                </Erro>

                {
                    !uruguay &&
                    <>
                        <Select
                            title={traducao.dados_usuario.nome_endereco}
                            opt={['casa', 'trabalho', 'outro']}
                            setSelect={val => setData("nome", val)}
                            select={address.nome || "fff"}
                            mostrar="nome"
                            valor="id"
                            semSeta={false}
                            placeholder="Escolha um nome"
                        />
                        <em className="has-text-warning">
                            {errMessage.nome}
                        </em>
                    </>
                }

                <CEP
                    tira_cep={tira_cep}
                    title={traducao.dados_usuario.cep}
                    placeholder="Cep do endereço"
                    initialValue={address.cep ? address.cep : ''}
                    setValue={setData}
                    classNameInput={errMessage.cep ? 'warning' : ''}
                    setErr={setErrMessage}
                    tipo={entrega.tipo_endereco}
                />

                <Erro>
                    {errMessage.bairro}
                </Erro>

                <Regiao
                    tipo={entrega.tipo}
                    traducao={traducao}
                    entrega={entrega}
                    setData={setData}
                    address={address}
                    errMessage={errMessage}
                />

                <InputTwoColumns
                    left={{
                        title: "Endereço",
                        placeholder: "Nome da Rua",
                        initial: address.endereco ? address.endereco : '',
                        invalid: errMessage.endereco,
                        classes: errMessage.endereco ? 'invalid' : ''
                    }}
                    setLeft={val => setData("endereco", val)}
                    right={{
                        title: traducao.dados_usuario.numero,
                        placeholder: traducao.dados_usuario.numero,
                        initial: address.numero ? address.numero : '',
                        classes: (address.numero && address.numero.match(/\D/g)) || errMessage.numero ? 'invalid' : '',
                        invalid: errMessage.numero
                    }}
                    setRight={val => setData("numero", val)}
                />

                <Text
                    title={traducao.dados_usuario.complemento}
                    placeholder="Complemento"
                    initialValue={address.complemento ? address.complemento : ''}
                    setValue={val => setData("complemento", val)}
                />

                {
                    endereco.tipo_endereco !== "simples" && !uruguay &&
                    <>
                        <Text
                            title={traducao.dados_usuario.bairro}
                            initialValue={address.bairro ? address.bairro : ''}
                            placeholder={"Seu bairro"}
                            setValue={val => setData("bairro", val)}
                            classNameInput={errMessage.bairro ? 'invalid' : ''}
                        />
                        <Erro>
                            {errMessage.bairro}
                        </Erro>
                    </>
                }

                <CidadeEstado
                    entrega={entrega}
                    traducao={traducao}
                    address={address}
                    errMessage={errMessage}
                    setData={setData}
                    endereco={endereco}
                />

                {
                    permitir_quadra ?
                        <Text
                            title="Quadra"
                            initialValue={address.quadra ? address.quadra : ''}
                            placeholder={"Sua quadra"}
                            setValue={val => setData("quadra", val)}
                            classNameInput={errMessage.quadra ? 'invalid' : ''}
                        />
                        : null
                }{
                permitir_lote ?
                    <Text
                        title="Lote"
                        initialValue={address.lote ? address.lote : ''}
                        placeholder={"Seu lote"}
                        setValue={val => setData("lote", val)}
                        classNameInput={errMessage.quadra ? 'invalid' : ''}
                    />
                    : null
            }

                {
                    addressData.endereco && addressData.regiao && address.index ?
                        <div className="_center">
                            <Button
                                title={traducao.geral.excluir}
                                classes="button is-outlined is-danger"
                                click={() => excluir(address.index)}
                                bg={false}
                            />
                        </div>
                        : null
                }

                <div className="buttons columns is-mobile is-centered mt-3">
                    <Button
                        title={traducao.geral.cancelar}
                        classes="button is-outlined is-primary"
                        click={() => setShow(false)}
                        bg={false}
                    />
                    <Button
                        title={traducao.geral.salvar}
                        classes="button has-text-light"
                        click={saveClose}
                    />
                </div>
                <Carregando carregando={carregando}/>
            </ModalForm>

            <ModalForm
                data={{title: traducao.validacoes.enderecos_encontrados}}
                show={modalSimilares}
                setShowModal={() => setModalSimilares(false)}
                closeIcon={false}
                iconLeft={true}
            >
                <MiniTitle color={cor_padrao} bold>
                    {
                        enderecosSimilares.length > 1 ?
                            `${enderecosSimilaresString[0]} ${enderecosSimilares.length} ${enderecosSimilaresString[1]}`
                            : traducao.validacoes.confirme_endereco
                    }
                </MiniTitle>
                <br/>
                {
                    enderecosSimilares.map(end => (
                        <div key={Math.random()}>
                            {
                                <Row
                                    title={end.formatted_address}
                                    titleClass="has-text-weight-medium size-small"
                                    onClick={() => fazerCalculo(end.geometry.location, end)}
                                >
                                    <div className="flex-4 has-text-success has-text-weight-bold">
                                        Confirmar
                                        {/* <ButtonIcone onClick={() => setModalSimilares(false)}>
                                            <span className="material-icons">
                                                keyboard_arrow_right
                                            </span>
                                        </ButtonIcone> */}
                                    </div>
                                </Row>
                            }
                        </div>
                    ))
                }
            </ModalForm>
        </div>
    )
}