import useConteudoTraduzido from '../traducao/useConteudoTraduzido';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import useGetPrimary from '../helpers/useGetPrimary';

function useProdutoAdd() {
    
    const dispatch = useDispatch();

    const { cart } = useSelector(state => state.cartIten);

    const [existiu, setExistiu] = useState(false);
    const [miss, setMiss] = useState([]);
    const [required, setRequired] = useState([]);

    const { cor_padrao } = useGetPrimary();

    const traducao = useConteudoTraduzido();

    const [added, setAdded] = useState(0);
    const [stateHolder, setStateHolder] = useState(added && added > 0 ? added : 1);
    const [adicionaisList, setAdicionaisList] = useState(false);
    const [productCartDataHolder, setProductCartDataHolder] = useState([]);
    const [modalAdicional, setModalAdicional] = useState(false);
    const [obs, setObs] = useState("");
    
    return {
        dispatch, cart, existiu, setExistiu,
        miss, setMiss, required, setRequired,
        cor_padrao, stateHolder, setStateHolder,
        traducao, added, setAdded, adicionaisList, 
        setAdicionaisList, productCartDataHolder,
        setProductCartDataHolder, modalAdicional,
        setModalAdicional, obs, setObs,
    }
}

export default useProdutoAdd;