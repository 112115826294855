import findId from "./findId"

/**
 *  Checks if already exists the adicional product and 
 *  set it or reset if has more than one
 * 
 * @param {object} element the element to check if exists value
 * @param {object[]} adicionais the array to include
 * @param {object} val the value to include
 * @param {string} idName the field to search
 * 
 * @returns {object[]} adicional
 */
const addPromo = (search, val, idName = "id_adicional") => {
    const alreadyExists = findId(val[idName], search, idName)

    let adicional = []

    if (alreadyExists === false) {
        adicional = [
            ...search,
            val
        ]
    } else {
        search.splice(alreadyExists, 1)
        adicional = [
            ...search,
            val
        ]
    }

    const isZero = findId(0, adicional, "qtd")

    if (isZero !== false) {
        search.splice(isZero, 1)
        adicional = [
            ...search
        ]
    }

    return adicional
}

export default addPromo