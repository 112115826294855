import findId from "../helpers/findId";

function getEnderecoComRegiao(endereco, regioes) {

    const id = typeof (endereco.regiao) === 'string' || typeof (endereco.regiao) === 'number' ?
        endereco.regiao
        : endereco.regiao.id;

    const indexDados = findId(id, regioes, "id");

    let novoEndereco = {
        ...endereco,
        regiao: regioes[indexDados],
        metodo_entrega: 'regiao'
    }

    novoEndereco.metodo_entrega = "regiao";
    novoEndereco.km = {}
    novoEndereco.endereco_completo = `${novoEndereco.endereco}, ${novoEndereco.numero}, região: ${novoEndereco.regiao.regiao} - bairro: ${novoEndereco.bairro || "Não informado"}, ${novoEndereco.cidade}, ${novoEndereco.estado}`

    // console.log(novoEndereco);

    return novoEndereco
}

export default getEnderecoComRegiao;