import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { reduceToCategory } from '../helpers'
import { asyncSetCategoryData } from '../actions'

export default () => {

    const dispatch = useDispatch()

    const [category, setCategory] = useState({})
    const [itens, setItens] = useState({})
    const match = useParams()

    const Data = useSelector(state => reduceToCategory(state, match))

    const stateItens = useSelector(state => state.categoryData)

    useEffect(() => {
        setCategory(Data ? Data : {})
    }, [Data])

    useEffect(() => {

        if (category && category.itens) {
            const itens = category.itens

            dispatch(
                asyncSetCategoryData(itens)
            )
        }
    }, [dispatch, category])

    useEffect(() => {
        setItens(stateItens.data.payload.categorias)

    }, [stateItens])

    return {
        itens,
        Data,
        category,
    }
}