import { useSelector } from "react-redux";

function useGetPrimary() {
    
    function hexToRgbA( hex = "", alpha ) {
        if ( typeof hex.slice === 'function' ) {
                var r = parseInt(hex.slice(1, 3), 16),
                g = parseInt(hex.slice(3, 5), 16),
                b = parseInt(hex.slice(5, 7), 16);
        
            if (alpha) {
                return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
            } else {
                return "rgb(" + r + ", " + g + ", " + b + ")";
            }
        }
    }
    

    const { cor_padrao } = useSelector( state => state.companyData.data.payload );

    const padrao_claro = hexToRgbA( cor_padrao, .3 );

    // a cor primaria
    const textPrimary = {
        color: cor_padrao
    }
    // a cor primaria com opacidade
    const textPrimaryLight = {
        color: cor_padrao,
        opacity: ".7"
    }
    // a cor primaria como background
    const bgPrimary = {
        backgroundColor: cor_padrao
    }
    // a cor primaria como borda inferior
    const borderBottomPrimary = {
        borderBottomColor: cor_padrao
    }
    // a cor primaria como borda
    const borderPrimary = {
        borderColor: cor_padrao
    }


    return {
        cor_padrao,
        textPrimary,
        textPrimaryLight,
        bgPrimary,
        borderBottomPrimary,
        borderPrimary,
        padrao_claro,
    }
}

export default useGetPrimary;