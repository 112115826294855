import React, { useState, useEffect } from 'react';
// import ForumularioDoCartao from './modals/ForumularioDoCartao';
import { useSelector, useDispatch } from 'react-redux';
import { userPayment } from '../../actions';
import { TitlePrimary } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';
import PagamentoLoop from './PagamentoLoop';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import ModalPix from './modals/ModalPix';
import { calculatePrice, getCompanyId } from '../../helpers';
import CartaoModal from './modals/CartaoModal';
import { Success } from '../modal/Success';
import PixOnline from "./modals/PixOnline";
import useMoeda from "../../traducao/useMoeda";

export default ({ erro = false }) => {

    const { cart } = useSelector((state) => state.cartIten);

    let valor = calculatePrice(cart, true);


    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const { cor_padrao } = useGetPrimary();

    const {
        pagamento,
        chave_pix,
        cartoes_aceitos,
        account_iugu,
        online_pix,
        online_credit_card
    } = useSelector(state => state.companyData.data.payload);
    const { metodo_pagamento } = useSelector(state => state.userData ? state.userData : {});
    const [troco, setTroco] = useState("");

    const [openCard, setOpenCard] = useState(false);

    const [value, setValue] = useState({ id: metodo_pagamento });
    const [mostrarModal, setMostrarModal] = React.useState({});

    useEffect(() => {
        dispatch(userPayment({ ...value, troco }));
    }, [value, troco, dispatch]);


    const setTrocoHandler = e => {
        if (e.match(/[0-9]/g) || e === "")
            setTroco(e);
    };

    return (
        <section className={erro ? '' : 'mt-5'}>
            <TitlePrimary as="h6" uppercase primary={cor_padrao}
                className={erro ? 'has-text-danger' : ''}>
                {traducao.dados_usuario.pagamento}
            </TitlePrimary>
            <div>
                {
                    !!account_iugu && online_credit_card &&
                    <>
                        <div onClick={() => setOpenCard('pagar-online')}>
                            <PagamentoLoop
                                key={'Pagar online'}
                                dados={{
                                    nome: 'Pagar online',
                                    descricao: 'Pagar com cartão de crédito online',
                                    acrescimo: 0,
                                    desconto: 0,
                                }}
                                value={value}
                                setValue={setValue}
                                troco={troco}
                                setTroco={setTrocoHandler}
                            />
                        </div>

                        <CartaoModal
                            show={openCard === 'pagar-online'}
                            setShow={setOpenCard}
                            accountID={account_iugu}
                            setMostrarModal={setMostrarModal}
                        // pix={chave_pix}
                        />
                    </>
                }
                {
                    !!account_iugu && online_pix &&
                    <>
                        <div onClick={() => setOpenCard('pix-online')}>
                            <PagamentoLoop
                                key={'Pix online'}
                                dados={{
                                    nome: 'Pix online',
                                    descricao: 'Pagar com pix usando QR code ou chave aleatoria',
                                    acrescimo: 0,
                                    desconto: 0,
                                }}
                                value={value}
                                setValue={setValue}
                                troco={troco}
                                setTroco={setTrocoHandler}
                            />
                        </div>
                    </>
                }
                {
                    pagamento && pagamento.tipos ?
                        pagamento.tipos.map(e => (
                            e.nome ?
                                <PagamentoLoop
                                    key={e.nome}
                                    dados={e}
                                    value={value}
                                    setValue={setValue}
                                    troco={troco}
                                    setTroco={setTrocoHandler}
                                    cartoes_aceitos={cartoes_aceitos}
                                    valor={valor}
                                />
                                : null
                        ))
                        : null
                }{
                    chave_pix && typeof chave_pix === 'string' ?
                        <>
                            <div onClick={() => setOpenCard('pix')}>
                                <PagamentoLoop
                                    key={'Pagar com pix'}
                                    dados={{
                                        nome: 'Chave Pix',
                                        descricao: 'Pagar usando chave pix',
                                        acrescimo: 0,
                                        desconto: 0
                                    }}
                                    value={value}
                                    setValue={setValue}
                                    troco={troco}
                                    setTroco={setTrocoHandler}
                                />
                            </div>

                            <ModalPix show={openCard === 'pix'} setShow={setOpenCard} pix={chave_pix} />
                        </>
                        : null
                }
                <Success
                    success={mostrarModal}
                    setSuccess={setMostrarModal}
                />
            </div>
        </section>
    );
};
