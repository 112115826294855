import { getThemeColor } from "../json";
import { getCompanyId } from "../helpers";

// Pega a cor primaria do localStorage
let primary = getThemeColor(getCompanyId);

// // Verifica se tem alguma coisa por precaução, se não, usa o azul padrão
// primary = primary !== null && primary !== "" && primary !== undefined ? primary : "#1898DA";

// exporta a cor primary
export const color = primary;
// exporta o cinza escuro
export const greyDark = "#3a3a3a";
// exporta o cinza padrão
export const grey = "#4f4f4f";
// exporta o cinza medio
export const greyLight = "rgba(79, 79, 79, .9)";
// exporta o cinza claro padrão
export const greyLighter = "rgba(175, 175, 175, .4)";
// exporta o verde padrão
export const success = "#52C17C";
// exporta o cinza escuro padrão
export const dark = "#575757"
// exporta o vermelho padrão
export const danger = "#F00606";
