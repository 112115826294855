import React, { useEffect } from 'react'
import useGetPrimary from '../../../helpers/useGetPrimary'

export default ({
    data = {},
    children,
    setValue,
    getValue,
    product = {},
    value
}) => {

    const { textPrimary, borderPrimary } = useGetPrimary()

    function valueAdded() {
        if (data.max && value < data.max && data.max !== 0) {
            setValue(value + 1)
            getValue({
                qtd: value + 1,
                ...product
            })
        } else {
            setValue(value + 1)
            getValue({ qtd: value + 1, ...product })
        }
    }

    useEffect(() => {
        if (data.max && value > data.max)
            setValue(data.max)
        // eslint-disable-next-line
    }, [value])

    return (
        <div className={`space-between center-align ${data.containerClass}`}>

            {children}

            <div className={`_center flex-end number-input-container ${data.class !== undefined ? data.class : ''}`}>
                <input
                    min={data.min ? data.min : 0}
                    max={data.max ? data.max : 100}
                    readOnly
                    type="number"
                    value={value}
                    className="none"
                />

                {
                    value !== 0 || value > 0 ?
                        <>
                            <p style={{ ...textPrimary, ...borderPrimary }} className={`border-rounded _center ${'none'}`}>
                                <span>
                                    {value}
                                </span>
                            </p>
                        </>
                        :
                        <button
                        style={{ minHeight: 75, minWidth: 75 }}
                            type="button"
                            className="clean-button"
                            onClick={valueAdded}
                        >
                            <span
                                style={textPrimary}
                                className="material-icons"
                            >
                                add
                            </span>
                        </button>
                }
            </div>
        </div>
    )
}