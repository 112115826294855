import React, { useState, useEffect } from 'react';
import Text from '../forms/inputs/Text';
import { useDispatch, useSelector } from 'react-redux';
import { userGeneral } from '../../actions';
import { TitlePrimary } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default () => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido(); 

    const { cor_padrao } = useGetPrimary();

    const { permitir_dados_cnpj, permitir_dados_email } = useSelector( state => state.companyData.data.payload );

    const [ cnpj, setCNPJ ] = useState( "" );
    const [ email, setEmail ] = useState( "" );

    useEffect(() => {
        if ( cnpj.length > 10 )
            dispatch( userGeneral('MORE', { key: "CNPJ", value: cnpj }) );
        if ( email.length > 4 )
            dispatch( userGeneral('MORE', { key: "email", value: email }) );
    }, [cnpj, email, dispatch]);

    return (
        permitir_dados_cnpj || permitir_dados_email ?
            <section className="my-5 mt-6">

                <TitlePrimary as="h6" uppercase primary={ cor_padrao }>
                    { traducao.dados_usuario.outras_informacoes }
                </TitlePrimary>

                {
                    permitir_dados_cnpj ?
                        <Text 
                            value={ cnpj }
                            setValue={ val => setCNPJ(val) }
                            placeholder={ traducao.geral.cnpj }
                        />
                    : null
                }{
                    permitir_dados_email ? 
                        <Text 
                            value={ email }
                            setValue={ val => setEmail(val) }
                            placeholder={ traducao.geral.email }
                        /> 
                    : null
                }

            </section>
        : null
    );
}