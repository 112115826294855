import getEnderecoCalculo from "./getEnderecoCalculo";

/**
 * 
 * @param {*} address 
 * @param {*} entrega 
 * @param {*} location 
 * @param {*} erro 
 * @param {*} salvar 
 */
function calcularKm(address, entrega, location, erro, salvar, enderecoCompleto) {

    let novoEndereco = {
        ...address,
        // endereco: end,
        // numero: num,
        // endereco_completo: completo,
    };

    novoEndereco.coordenadas = location;

    const metros = getEnderecoCalculo(
        {
            lat: novoEndereco.coordenadas.lat,
            lng: novoEndereco.coordenadas.lng,
        }, {
        lat: entrega.latitude,
        lng: entrega.longitude,
    }
    );

    if (isNaN(metros)) {
        const err = "Houve um problema completar esta ação, por favor entre em contato com o estabelecimento";
        erro({ g: err });
        return err;
    }

    const km = parseFloat(metros) / 1000;

    if (isNaN(km)) {
        const err = "Houve um problema completar esta ação, por favor entre em contato com o estabelecimento";
        erro({ g: err });
        return err;
    }

    let valor;

    switch (entrega.tipo) {
        case 'km':
            valor = entrega.kms.filter((e, i) => {
                return ((km <= e.km) || (km > e.km && i + 1 === entrega.kms.length));
            });
            break;
        case 'fixo':
            valor = entrega.vl_tx_entrega_fixo;
            break;
        default:
            const err = "Houve um problema, por favor entre em contato com o estabelecimento";
            erro({ g: err });
            return err;
    }

    const valorM = Array.isArray(valor) && valor[0] && typeof valor[0].valor === 'number' ? valor.map(e => e.valor) : valor;

    const valorFinal = Array.isArray(valorM) ? Math.min(...valorM) : valor;

    if (entrega.km_max_permitido > 0 && km > entrega.km_max_permitido && entrega.tipo !== 'fixo') {
        const err = "Este estabelecimento não faz entregas nessa distancia (" + km + "km)";
        erro({ g: err });
        return err;
    }
    else {
        erro({ g: undefined });

        const retorno = {
            ...novoEndereco,
            km: {
                id: 0,
                km,
                valor: valorFinal,
            },
            metodo_entrega: entrega.tipo,
            regiao: address.regiao ? { regiao: address.regiao } : []
        };

        salvar(retorno);
        return 1;
    }
}

export default calcularKm;