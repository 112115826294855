import React from 'react';
import InputForm from '../forms/InputForm';
import NumberInput from '../forms/inputs/NumberInput';
import addAdicional from '../../helpers/addAdicional';
import useMoeda from '../../traducao/useMoeda';

export default ({ 
    data,
    setProductCartData,
    productCartData,
    showPrice = true,
}) => {

    const moeda = useMoeda();

    function adici(e, ad, val) {
        setProductCartData(
            productCartData = { 
            ...productCartData, 
            adicionais: addAdicional(e, ad, val)
        }) 
    }

    return(
        data && data.adicionais && data.adicionais.length > 0 ?
            <InputForm border={false} title="Adicionais">
            {
                data.adicionais.map(e => (
                    <NumberInput 
                        key={e.id_adicional}
                        data={{
                            containerClass: "border-bottom",
                            id: e.id_adicional,
                            title: e.nome,
                            price: showPrice ? e.valor : null 
                        }} 
                        product={{ ...e }}
                        getValue={ 
                            val => adici(e, productCartData.adicionais, val)
                        }
                        showPrice={ showPrice }
                    >
                        <p className="flex-6">
                            <label className="mini-title size-normal-small text-bold">
                                { e.nome }
                            </label>
                            <small className="mini-title mt-0">
                                {e.descricao}
                            </small>
                            {
                                showPrice ? 
                                <span className="price">
                                    { moeda }{e.valor.toFixed(2)}
                                </span>
                                : null
                            }
                        </p>
                    </NumberInput>
                ))
            }
        </InputForm>
        : null
    );
}