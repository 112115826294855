import React from 'react';
import { replacer, addPromo } from '../../helpers';
import RadioInputImg from './inputs/RadioInputImg';

export default ({
    data,
    productCartData,
    setProductCartData,
    valorProduto,
    ativo,
    setAtivo,
    desconto,
}) => {

    let form = replacer(data.nome);

    const setItens = (item, bebida = false) => {

        if (bebida === true)
            form = "bebida";

        if (valorProduto === true)
            productCartData.valor_produto = true;

        setAtivo({ ...ativo, [data.index]: item });

        setProductCartData({
            ...productCartData,
            itens: addPromo(productCartData.itens, item, "index"),
            pTipo: desconto.tipo,
        });
    };

    return (
        <div className="forms options-open-modal">
            <div className="space-between center-align">
                {
                    data.itens ?
                        data.itens.map(produto => {

                            let valor = valorProduto ? produto.valor : false;
                            let valorDesconto = null;

                            if (desconto.tipo === 8 && desconto.desconconto > 0) {
                                valorDesconto = {
                                    sem_desconto: valor,
                                    valor: parseFloat((valor - (valor * (desconto.desconconto/100))).toFixed(2))
                                }
                            }

                            return (
                                <RadioInputImg
                                    key={produto.id}
                                    title={form}
                                    data={{
                                        subtitle: produto.descricao,
                                        label: produto.nome,
                                        id: produto.id,
                                        price: valorDesconto ?? valor,
                                    }}
                                    isChecked={(ativo[data.index] && ativo[data.index].id === produto.id)}

                                    product={{ ...produto }}
                                    getValue={val => setItens({ ...val, index: data.index })}
                                />
                            );
                        })
                        : null
                }{
                    data.bebidas ?
                        data.bebidas.map(e =>
                            <RadioInputImg
                                key={e.id}
                                title={form}
                                data={{
                                    subtitle: e.descricao,
                                    label: e.nome,
                                    id: e.id,
                                }}
                                isChecked={productCartData.itens[data.index] && productCartData.itens[data.index].id === e.id}
                                product={{ ...e }}
                                getValue={val => setItens({ ...val, index: data.index }, true)}
                            />
                        )
                        : null
                }
            </div>
        </div>
    );
};