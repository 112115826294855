import React, { useState } from 'react';
import InputForm from '../../forms/InputForm';
import RadioInputControlled from '../../forms/inputs/RadioInputControlled';
import addAdicional from '../../../helpers/addAdicional';
import { cobrarPizza } from '../../../helpers';
import useGetPrimary from '../../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import { MiniTitle } from '../../../styled/Titles';

export default ({
    promo,
    setProductCartData,
    productCartData,
    id,
    valor_inicial
}) => {

    const [active, setActive] = useState({});
    const { cor_padrao } = useGetPrimary();
    const traducao = useConteudoTraduzido();
    
    const setProduct = (val, element) => {
        setActive(val);

        element.index = id;

        setProductCartData({
            ...productCartData,
            valor: cobrarPizza(productCartData, element, valor_inicial, "cobrar_bebida"),
            itens: addAdicional(element, productCartData.itens, val, "index", true)
        });
    };
    const setObs = (obs) => {
        setProductCartData({...productCartData, obs})
    }

    return (
        promo.produto.map(bebida => (
            bebida.bebidas && bebida.bebidas.length > 0 ?
                <InputForm
                    key={bebida.nome}
                    title={bebida.nome}
                >
                    {
                        bebida && bebida.bebidas && bebida.bebidas.length > 0 ?
                            bebida.bebidas.map(prod => (
                                <RadioInputControlled
                                    key={prod.id}
                                    data={{
                                        subtitle: prod.descricao,
                                        label: prod.nome,
                                        price: null,
                                        id: prod.id,
                                    }}
                                    getValue={val => setProduct(val, prod)}
                                    product={prod}
                                    selected={active}
                                />
                            ))
                            : null
                    }
                    <div className="pt-3 w-100">

                        <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                            {traducao.produtos.alguma_observacao}
                        </MiniTitle>

                        <div className="flex-12 half-margin">
                            <input
                                value={productCartData.observacao || ""}
                                onChange={(e) => setObs(e.target.value)}
                                type="text"
                                className="observation"
                                placeholder={traducao.produtos.alguma_observacao}
                            />
                        </div>
                    </div>
                </InputForm>
                : null
        ))
    );
};