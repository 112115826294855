import { useEffect } from 'react'
import { missing, addPromo } from '../helpers'


const addRequired = (productCartData) => {
    const valores = productCartData.map(e => ({
        id: e.id_categoria,
        nome: e.cat,
        qtd: e.holderQtd ? e.holderQtd : e.qtd
    }))

    let uniqueArray = []

    valores.forEach(element => {
        uniqueArray = addPromo(uniqueArray, element, "id")
    })

    return uniqueArray
}


/**
 * * Search and set the required itens
 * 
 * @param {object[] | object} data the data to find by required itens
 * @param {function} setRequired the function to set the required state
 * @param {object[]} productCartData the place wehre we will search if exists
 * @param {function} setMiss the funtion to set what is missing
 * @param {number[]} required the required ids
 */
const useRequiredItens = (
    data,
    setRequired,
    productCartData,
    setMiss,
    required,
    pizza = false,
) => {
    useEffect(() => {

        if (data && !pizza) {

            let req = data.map(e => ({
                qtd: e.qtde_obrigatoria ? e.qtde_obrigatoria : 0,
                id: e.qtde_obrigatoria && e.qtde_obrigatoria > 0 ? e.id : undefined,
                nome: e.qtde_obrigatoria && e.qtde_obrigatoria > 0 ? e.nome : undefined,
            }))

            setRequired(req)

        } else if (data && pizza) {
            if (data && data[0] && data[0].conjuntos) {

                let req = data[0].conjuntos.map(e => {

                    if (e.tp_conjunto === "bebida") {
                        return ({
                            qtd: 1,
                            id: e.index,
                            nome: "Bebida"
                        })
                    } else if (e.tp_conjunto === "pizza") {
                        return ({
                            qtd: 1,
                            id: e.index,
                            nome: e.produto[0].tamanho
                        })
                    }
                    return ({
                        qtd: 1,
                        id: e.id,
                        nome: e.nome
                    })
                })

                setRequired(req)
            }
        }
        // eslint-disable-next-line
    }, [data])

    // eslint-disable-next-line
    useEffect(() => {
        if (productCartData) {

            let cats = []

            if (!pizza) {
                cats = addRequired(productCartData, required)
            } else {

                cats = productCartData.map(e => {

                    if (e.tp_conjunto === "pizza") {
                        return ({
                            id: e.index,
                            nome: e.produto[0].tamanho
                        })
                    }
                    return ({
                        id: e.index,
                        nome: e.nome ? e.nome : e.tamanho
                    })
                })
            }

            setMiss(missing(required, cats))
        }
    })

}

export default useRequiredItens