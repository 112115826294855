import React, { useState, useEffect } from 'react';
import PizzaModalCall from '../../modal/modalCallers/PizzaModalCall';
import useApiItens from '../../../hooks/useApiItens';
import CategoriesRow from '../../general/CategoriesRow';
import Text from '../../forms/inputs/Text';
import Loading from '../../general/Loading';
import useGetPrimary from '../../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import { normalize } from '../../../helpers';

export default () => {

    const traducao = useConteudoTraduzido(); 

    const { itens, category } = useApiItens();
    const [ search, setSearch ] = useState( "" );
    const [ itensTotal, setItensTotal ] = useState( [] );

    const { textPrimary } = useGetPrimary();

    useEffect(() => {
        setItensTotal(itens && itens[0] && itens[0].produtos ? itens[0].produtos : []);
        // eslint-disable-next-line
    }, [itens])

    useEffect(() => {
        const query = itens && itens[0] && itens[0].produtos ? itens[0].produtos.filter(
            e => {
                const normalized = normalize(e.tamanho).toLowerCase();
                const searchNormalized = normalize(search).toLowerCase();
                return normalized.includes(searchNormalized);
            }
        ) : null

        if ( search !== "" )
            setItensTotal(query);
        else 
            setItensTotal(itens && itens[0] && itens[0].produtos ? itens[0].produtos : [])
        
        // eslint-disable-next-line
    }, [search]);

    return (
        <article className="container">

            <CategoriesRow />

            <h5 
                className="size-normal-small has-text-weight-medium mb-2"
                style={ textPrimary }
            >
                { category && category.descricao ? category.descricao : '' }
            </h5>


            {
                itens && itens[0] && itens[0].produtos && itens[0].produtos.length > 20 ?
                    <Text 
                        title={ traducao.geral.pesquise_o_produto }
                        placeholder={ traducao.geral.pesquise_aqui + "..." }
                        initialValue={ search }
                        setValue={ val => setSearch(val) }
                    />
                : null
            }

            <section>
                {
                    itens && itensTotal ?
                        itensTotal
                        .map(e => (
                            <PizzaModalCall 
                                key={ e.id } 
                                data={{ ...e, pizza_id: itens[0].id, catNome: category?.nome }}
                            />
                        ))
                    : 
                    <Loading />
                }
            </section>
        </article>
    );
}