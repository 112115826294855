import React from 'react'
import { addPromo, cobrarPizza } from '../../../../helpers'
import useConteudoTraduzido from '../../../../traducao/useConteudoTraduzido'
import CallShowModal from '../../../forms/CallShowModal'
import SnackData from '../../../forms/SnackData'
import SnackModalForm from '../../../forms/SnackModalForm'
import BebidaPizzaPromo from '../BebidaPizzaPromo'
import PizzaData from '../promo/PizzaData'

// eslint-disable-next-line
class SwitcPizzaPromoType {
    setShowModal = () => null
    showModal = false
    promo = {}
    productCartData = {}
    setProductCartData = () => null
    index = 0
    toNext = () => null
    promoItens = {}
    alreadyInCart = {}
}

/**
 * * Chooses between pizza and bebida types
 * 
 * @param {SwitcPizzaPromoType} props 
 * @returns {React.FunctionComponent} React.FunctionComponent
 */
const SwitchPizzaPromo = ({
    setShowModal = () => null,
    showModal = false,
    promo = {},
    productCartData = {},
    setProductCartData = () => null,
    index = 0,
    toNext = () => null,
    promoItens = {},
    alreadyInCart = {},
}) => {

    const traducao = useConteudoTraduzido()

    switch (promo.tp_conjunto) {
        case 'pizza':
            return (
                promo.produto.map((produto, produtoIndex) => (

                    <PizzaData
                        key={produtoIndex}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        produtoIndex={produtoIndex}
                        arrayProdutos={promo.produto.length}
                        data={produto}
                        productCartData={productCartData.itens}
                        index={index}
                        setProductCartData={
                            (val) => setProductCartData({
                                ...productCartData,
                                itens: addPromo(productCartData.itens, val, "index"),

                                valor: cobrarPizza(productCartData, val, promoItens?.valor || null)
                            })}
                        toNext={toNext}
                        arraySize={promoItens.conjuntos.length}
                    />
                ))
            )
        case 'bebida':
            return (
                <div className="forms options-open-modal border-bottom">
                    <section className="space-between center-align">
                        <SnackData
                            data={promo.produto}
                            title={"Bebida"}
                            showModal={() => setShowModal(index)}
                        >
                            <div className="flex-4 flex-end" onClick={() => setShowModal(index)}>
                                {
                                    alreadyInCart.length > 0 ?
                                        <span className="has-text-success material-icons">
                                            check_circle
                                        </span>
                                        : null
                                }
                                <CallShowModal
                                    setShowModal={() => setShowModal(index)}
                                    showModal={showModal === index}
                                    icon="expand_more"
                                />
                            </div>
                        </SnackData>
                        <SnackModalForm
                            title={"Bebida"}
                            subtitle={traducao.produtos.escolha_bebida}
                            show={showModal === index}
                            setShowModal={() => setShowModal(false)}
                        >
                            {
                                promo && promo.produto ?
                                    <BebidaPizzaPromo
                                        promo={promo}
                                        setProductCartData={val => setProductCartData(val)}
                                        productCartData={productCartData}
                                        id={index}
                                        valor_inicial={promoItens && promoItens[0] ? promoItens[0].valor : null}
                                    />
                                    : null
                            }
                        </SnackModalForm>
                    </section>
                </div>
            )
        default:
            return
    }
}

export default SwitchPizzaPromo