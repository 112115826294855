/**
 * Checks if exists bebidas in cart, and returns it
 *  
 * @param {object[]} cart the cart
 */
const getCategoria = ( cart, idCategoria ) => {
    // filter the cart finding for categoria
    const item = cart.filter(e => e.id_categoria === idCategoria);

    // returns what we find
    return item
 }
 // exports the little guy
 export default getCategoria;