import React from 'react';
import { useDispatch } from 'react-redux';
import { removeScroll } from '../actions';

const useRemoveScroll = (showModal, showAddToCart) => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (showModal !== false)
            dispatch(removeScroll(false));
        else
            dispatch(removeScroll(true));

        // eslint-disable-next-line
    }, [showModal, showAddToCart]);
};

export default useRemoveScroll;