import React from 'react';

// import CartList from '../request/CartList';
import { MiniTitle } from '../../styled/Titles';
import { DefaultContainer, ContainerRow } from '../../styled/Containers.tsx';
import { TextoNormal, Price } from '../../styled/Text';
import Loading from './Loading';
import CartList from '../request/CartList';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import useMoeda from '../../traducao/useMoeda';
// import Button from '../forms/Button';
// import { useSelector } from 'react-redux';
// import Axios from 'axios';


export default ({ dados = {} }) => {

    const cart = dados.products ? JSON.parse( dados.products ) : [];
    const traducao = useConteudoTraduzido(); 
    const moeda = useMoeda();
    // const { como_cobrar_pizza } = useSelector( state => state.companyData.data.payload );

    // const { departamento } = useSelector( state => state.companyData.data.payload );

    const coupon = dados.coupon ? JSON.parse( dados.coupon ) : false;
    const cliente = dados.customer ? JSON.parse( dados.customer ) : "";

    const entrega = dados.delivery_amount ? parseFloat(dados.delivery_amount) : 0;

    return(
            dados.id ?
                <DefaultContainer as="article">

                <CartList cart={ cart } />

                <section className="pb-2">
                    <ContainerRow>
                        <div className="column pl-0">
                            <MiniTitle as="h3" bold size={ 0.9 } className="pb-0 mt-0">
                               { traducao.geral.subtotal }
                            </MiniTitle>

                            {
                                entrega && entrega > 0 ? 
                                <p className="size-normal-small text-grey">
                                    { traducao.dados_usuario.taxa_entrega }
                                </p>
                                : null
                            }

                        </div>

                        <div className="column">

                            <TextoNormal align="right">
                                {  moeda }{ parseFloat(dados.products_amount).toFixed(2) }
                            </TextoNormal>
                            {
                                dados && dados.discount_amount > 0 ?
                                <TextoNormal align="right">
                                    { dados.desconto_cupom }{ parseFloat(dados.discount_amount).toFixed(2) }
                                </TextoNormal>
                                : null
                            }
                            {
                                entrega && entrega > 0 ?
                                <TextoNormal align="right">
                                    {  moeda }{ parseFloat(dados.delivery_amount).toFixed(2) }
                                </TextoNormal>
                                : null
                            }

                        </div>

                    </ContainerRow>

                    <hr className="my-1" />

                    <div className="has-text-right columns pt-3">
                        <Price>
                            { moeda }{ parseFloat(dados.total_amount).toFixed(2) }
                        </Price>
                    </div>
                </section>

                <section>
                    <MiniTitle uppercase="uppercase" bold>
                        { cliente.nome }
                    </MiniTitle>

                    <div>
                        
                        <h3 className="is-capitalized size-normal-small pb-0 has-text-grey">
                            { dados.tipo_retirar }
                        </h3>
                        {
                            cliente.metodo_entrega === "entrega" ?
                                <div>
                                    <p className="has-text-grey size-small">{ cliente.entrega.endereco },{ cliente.entrega.numero }</p>

                                    <p className="has-text-grey size-small">{ cliente.entrega.cidade }, { cliente.entrega.estado }</p>

                                </div>
                            : null
                        }
                        <hr />
                    </div>
                </section>
                {
                    coupon && !coupon.erro ?
                    <section>
                        <div>
                            <h3 className="size-normal-small has-text-weight-bold pb-0 has-text-grey">
                                { traducao.geral.cupom_de_desconto }
                            </h3>
                            <p className="size-normal-small has-text-grey">
                                { coupon.coupon }
                            </p>
                            <Price>
                                { parseFloat(coupon.vl_desconto).toFixed(2) }
                            </Price>
                            <hr />
                        </div>
                    </section>
                    : null
                }

            </DefaultContainer>
        :
        <Loading />
    );
}