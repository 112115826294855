/**
 * Remove one iem from cart
 */
export const REMOVE_ONE = 'REMOVE_ONE';

/**
 *  This type will call a saga to call the api 
 *  The api will give us the needed data
 */
export const ASYNC_CALL = 'ASYNC_CALL';

/**
 *  This type will hold all the basic company data got by the api
 */
export const COMPANY_DATA = 'COMPANY_DATA'; 

/**
 *  This type will hold all the basic category data comming from api
 */
export const CATEGORY_DATA = 'CATEGORY_DATA';

/**
 *  This action is a queue to send to the cart
 *  before the user click "enviar ao carrinho", this will "hold" the products
 */
export const CART_DATA = 'CART_DATA';

/**
 *  Change is complete status and message
 */
export const IS_COMPLETE = 'IS_COMPLETE';

/**
 *  While the user interacts with the site, this type will be the itens in the cart
 */
export const GET_CART_ITEN = 'GET_CART_ITEN';

/**
 *  Allows the user to add some data to the cart queue
 */
export const ADD_TO_CART = 'ADD_TO_CART';

/**
 *  Allows the user to add some iten to the true cart
 */
export const ADD_ITEN_TO_CART = 'ADD_ITEN_TO_CART';

/**
 *  Update some value in cart queue
 */
export const UPDATE_VALUE_IN_CART = 'UPDATE_VALUE_IN_CART';

/**
 *  Update some iten in cart
 */
export const UPDATE_ITEN_IN_CART = 'UPDATE_ITEN_IN_CART';

/**
 *  Removes from the cart queue
 */
export const REMOVE_VALUE_IN_CART = 'REMOVE_VALUE_IN_CART';

/**
 *  Removes from the cart
 */
export const REMOVE_ITEN_IN_CART = 'REMOVE_ITEN_IN_CART';

/**
 *  Remove the exsiting data in the category data
 *  useful for not errors in screen change
 */
export const REMOVE_CATEGORY_DATA = 'REMOVE_CATEGORY_DATA';

/**
 *  Revemos everything from CART_DATA (cart queue) 
 */
export const REMOVE_ALL_ITENS = 'REMOVE_ALL_ITENS';

/**
 *  Revemos everything from CART_DATA (cart queue) 
 */
export const REMOVE_ALL = 'REMOVE_ALL';

/**
 *  Removes the scrollbar from scroll-x class
 */
export const SCROLLBAR = 'SCROLLBAR';

/**
 *  Removes the tabs 
 */
export const REMOVE_TAB = 'REMOVE_TAB';

/**
 *  Set the user address in user preferences
 */
export const USER_ADDRESS = 'USER_ADDRESS';

/**
 * Set the schedule to send the products
 */
export const USER_SCHEDULE = 'USER_SCHEDULE';

/**
 *  Sets the payment method of the user
 */
export const USER_PAYMENT = 'USER_PAYMENT';

/**
 *  Sets the delivery method in user preferences
 */
export const DELIVERY_METHOD = 'DELIVERY_METHOD';

/**
 *  Sets the user name
 */
export const USER_NAME = 'USER_NAME';

/**
 *  Sets the user phone in user preferences
 */
export const USER_PHONE = 'USER_PHONE';

/**
 *  If the user has a coupon, here it goes
 */
export const COUPON = 'COUPON';

/**
 *  Sets the card data of the user
 */
export const CARD = 'CARD';

/**
 *  For general informations
 */
export const MORE = 'MORE';

/**
 *  If the iten will be a gift, we set its daa here
 */
export const GIFT = 'GIFT';

/**
 *  Sets everything in user prefereces, usefull when refreshing the page
 */
export const USER_SET_ALL = 'USER_SET_ALL';

/**
 * Seta um campo em especifico, como o endereço 
 */
export const SET_CLIENT = 'SET_CLIENT';

/**
 * Adiciona os dados do usuário da api, como o endereço 
 */
export const SET_CLIENT_ALL = 'SET_CLIENT_ALL';

export const SET_CLIENT_INITIAL = 'SET_CLIENT_INITIAL';

