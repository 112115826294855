import React, {useState} from 'react';
import usePerg from '../../hooks/usePerg';
import {useHistory} from 'react-router-dom';
import TelInput from '../forms/inputs/TelInput';
import {getCompanyId, invalidNumberMessage, getUserData} from '../../helpers';
import {useDispatch, useSelector} from 'react-redux';
import {userPhone, setClienteAll, setClienteInicial} from '../../actions';
import {getTelefone, setClienteJson, setTelefone} from '../../json';
import useGetPrimary from '../../helpers/useGetPrimary';
import {confirmacaoGa} from '../../ga';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import br from '.././../img/br.webp';
import uruguay from '.././../img/uruguay.webp';

export default () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const traducao = useConteudoTraduzido();
    const {canal, idioma} = useSelector(state => state.companyData.data.payload);
    const {bgPrimary} = useGetPrimary();

    usePerg();

    const [phone, setPhone] = useState(getTelefone() ? getTelefone() : '');
    const [country, setCountry] = useState('');

    const save = async () => {
        setTelefone(phone);
        const telefone = phone.replace(/\D/g, '');
        dispatch(userPhone(telefone, country || 'br'));
        const cliente = await getUserData(telefone, canal);
        try {
            if (cliente.code === 200 || cliente.code === 201) {

                dispatch(setClienteAll(cliente.data));
                setClienteJson(cliente.data);

            } else if (cliente.code === 400) {
                dispatch(setClienteInicial());
                setClienteJson('{}');
            }
        } catch (e) {
            dispatch(setClienteInicial());
            setClienteJson('{}');
        }
        history.push(`/${getCompanyId}/checkout/before-confirm`);
    };

    const phoneSize = () => phone.replace(/\D/g, '').length;

    const handleSubmit = (e) => {
        e.preventDefault();
        if ((phoneSize() < 11 && getCompanyId !== "6840") || (idioma !== 'br' && !country)) {
            return;
        }
        confirmacaoGa();
        save();
    };

    return (
        <article className="phone has-text-centered">
            <form onSubmit={handleSubmit}>

                <TelInput
                    classes="pb-3"
                    phone={phone}
                    setPhone={setPhone}
                    title={traducao.dados_usuario.telefone}
                    placeholder="(xx) xxxxx-xxxx"
                    invalid={phoneSize() < 11 && getCompanyId !== "6840"}
                />
                <div className="flex-12 pt-3">

                    {
                        idioma !== 'br' ?
                            <>
                                <div
                                    className="_center"
                                    style={{justifyContent: 'flex-start'}}
                                    onClick={() => setCountry('br')}
                                >
                                    {
                                        country === 'br' ?
                                            <span className="material-icons">check_box</span>
                                            :
                                            <span className="material-icons">check_box_outline_blank</span>
                                    }
                                    <img className="country-flag pl-2" src={br} alt="Brasil"/>
                                    <span className="pl-4">Brasil</span>
                                </div>
                                <div
                                    className="_center"
                                    style={{justifyContent: 'flex-start'}}
                                    onClick={() => setCountry('uy')}
                                >
                                    {
                                        country === 'uy' ?
                                            <span className="material-icons">check_box</span>
                                            :
                                            <span className="material-icons">check_box_outline_blank</span>
                                    }
                                    <img className="country-flag pl-2" src={uruguay} alt="Uruguay"/>
                                    <span className="pl-4">Uruguay</span>
                                </div>
                            </>
                            : null
                    }
                </div>

                {
                    (phoneSize() < 11 && getCompanyId !== "6840") || (idioma !== 'br' && !country) ?
                        <button
                            onClick={() => invalidNumberMessage(phoneSize(), country)}
                            style={bgPrimary}
                            className="btn mt-4 size-normal"
                        >
                            {traducao.geral.continuar}
                        </button>
                        :
                        <button
                            type="button"
                            style={bgPrimary}
                            className="btn mt-4 size-normal"
                            onClick={save}
                        >
                            {traducao.geral.continuar}
                        </button>
                }
            </form>
        </article>
    );
};