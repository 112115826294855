/**
 * 
 * @param {*} required 
 * @param {*} catsQt 
 */
const missing = (required, catsQt, searchIn = "id") => {

    const exists = required.map( e => {
        const ids = catsQt.map( qt => qt[searchIn] );

        const inclui = ids.includes(e[searchIn]);

        return inclui ? null : e.qtd;
    });

    const missing = exists.filter( e => typeof(e) === 'string' );
    const joined = missing.join(", ");

    if ( joined !== "" )
        return `Está faltando: ${joined}`
    return null;
}

export default missing;