import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useGetPrimary from "../helpers/useGetPrimary";
import useConteudoTraduzido from "../traducao/useConteudoTraduzido";
import useMoeda from "../traducao/useMoeda";

/**
 * Exporta todas as variaveis necessarias para a bebida
 */
function useBebidasAdd() {

    const dispatch = useDispatch();

    const { cart } = useSelector(state => state.cartIten);
    const { catId } = useParams();

    const { textPrimary, bgPrimary } = useGetPrimary();
    const moeda = useMoeda();

    const [added, setAdded] = useState(0);
    const [stateHolder, setStateHolder] = useState(1);
    const [obs, setObs] = useState("");

    const traducao = useConteudoTraduzido();

    const [existiu, setExistiu] = useState(false);

    return {
        dispatch,
        cart,
        catId,
        textPrimary,
        bgPrimary,
        moeda,
        added,
        setAdded,
        stateHolder,
        setStateHolder,
        obs,
        setObs,
        traducao,
        existiu,
        setExistiu
    }
}
export default useBebidasAdd;