import React, { useEffect, useRef, useState } from 'react'
import SnackData from '../../forms/SnackData'
import SnackModalForm from '../../forms/SnackModalForm'
import CallShowModal from '../../forms/CallShowModal'
import BebidasAdd from '../../forms/inputs/BebidasAdd'
import { useDispatch, useSelector } from 'react-redux'
import NumberMinMax from '../../forms/inputs/NumberMinMax'
import AddToCartModal from '../../request/AddToCartModal'
import { saveToCart } from '../../../helpers'
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido'
import useRequiredItens from '../../../hooks/useRequiredItens'
import { confirmAlert } from 'react-confirm-alert'
import NumberInputControlled from '../../forms/inputs/NumberInputControlled'
import { MiniTitle } from '../../../styled/Titles'
import ModalCompressed from '../../forms/ModalCompressed'
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial'
import { removeTab } from '../../../actions'
import useGetPrimary from '../../../helpers/useGetPrimary'

export default ({ data = {} }) => {

    const { textPrimary } = useGetPrimary()

    const dispatch = useDispatch()
    const { cart } = useSelector(state => state.cartIten)

    const traducao = useConteudoTraduzido()
    // eslint-disable-next-line 
    const [apagar, setApagar] = useState(false)
    const [required, setRequired] = useState([1])
    const [miss, setMiss] = useState("")

    const [showModal, setShowModal] = useState(false)

    const carrinho = useCarrinhoInicial('monte', data)

    const [productCartData, setProductCartData] = useState(carrinho)

    const setObservation = (text) => {
        setProductCartData({
            ...productCartData,
            observacao: text,
        })
    }

    const send = () => {
        if (miss === null) {
            saveToCart(val => dispatch(val), productCartData, cart, false)
            dispatch(removeTab(true))
            setShowModal(false)

            setProductCartData(carrinho)
            setApagar(true)
        } else {
            confirmAlert({
                title: "Você ainda precisa completar o pedido",
                message: miss,
                buttons: [
                    {
                        label: "Entendi"
                    }
                ]
            })
        }
    }

    useRequiredItens(
        data ? data.itens : [],
        val => setRequired(val),
        productCartData && productCartData.adicionais ? productCartData.adicionais : [],
        val => setMiss(val),
        required
    )

    useEffect(() => {
        if (showModal && (data.itens.length > 0 || data.adicionais.length > 0))
            dispatch(removeTab(false))
        // eslint-disable-next-line
    }, [showModal, dispatch])

    useEffect(() => {
        if (!showModal) {
            setProductCartData(carrinho)
        }
        // eslint-disable-next-line
    }, [showModal])

    const showModalProximo = (val) => {
        if (miss === null || showModal) {
            if (showModal === 1)
                return setShowModal(2)
            else if (miss === null)
                return setShowModal(val)
        }
        else if (typeof miss === 'string') {
            return setShowModal(1)
        }
        else
            return null
    }

    const adicionaisQtd = useRef([])

    const aumentarQtd = (val) => {
        // console.log(val);
        // console.log(adicionaisQtd)

        let novosAdicionais = []

        productCartData.adicionais.forEach((el, ind) => {
            // console.log(adicionaisQtd.current[ind]);
            novosAdicionais.push({ ...el, valor: adicionaisQtd.current[ind].valor * val })
        })

        return setProductCartData({ ...productCartData, qtd: val, adicionais: novosAdicionais })
    }

    return (
        <div className="forms options-open-modal border-bottom">
            <section className="space-between center-align">

                <SnackData
                    title={data.nome}
                    icon="expand_more"
                    data={data}
                    desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                    price={data.valor}
                    showModal={() => setShowModal(1)}
                    aditionalTitle="Adicionais"
                >
                    {
                        data.itens.length > 0 || data.adicionais.length > 0 ?
                            <CallShowModal
                                setShowModal={() => setShowModal(1)}
                                showModal={showModal}
                                icon="expand_more"
                            />
                            :
                            <BebidasAdd
                                productCartData={productCartData}
                                setProductCartData={val => setProductCartData(val)}
                                data={data}
                                setShowModal={(val) => setShowModal(val)}
                                show={showModal}
                            />
                    }

                </SnackData>
                {
                    data.itens.length > 0 || data.adicionais.length > 0 ?
                        <SnackModalForm
                            title={data.nome}
                            price={data.valor}
                            subtitle={traducao.produtos.personalize + data?.nome?.toLowerCase()}
                            setShowModal={() => setShowModal(false)}
                            show={showModal === 1}
                        >
                            {
                                data.itens.map(e => (
                                    <NumberMinMax
                                        key={e.id}
                                        data={e}
                                        title={data.nome}
                                        productCartData={productCartData}
                                        setProductCartData={setProductCartData}
                                        // apagar={apagar}
                                        setApagar={setApagar}
                                        apagar={showModal === false}
                                    />
                                ))
                            }
                            {
                                data.adicionais.length > 0 ?
                                    <NumberMinMax
                                        data={data.adicionais}
                                        title="Adicionais"
                                        showTitle={false}
                                        productCartData={productCartData}
                                        setProductCartData={setProductCartData}
                                        // apagar={apagar}
                                        setApagar={setApagar}
                                        apagar={showModal === false}
                                    />
                                    : null
                            }
                        </SnackModalForm>
                        : null
                }
                <ModalCompressed
                    show={showModal === 2}
                    setShowModal={() => setShowModal(false)}
                    title={data.nome}
                    classesAdicionais="small"
                >
                    <div className="flex-12 space-between">
                        <div className="flex">
                            <div className="flex-6">
                                <MiniTitle bold size={1}>
                                    Quantidade de {data.nome_cat}
                                </MiniTitle>
                                <MiniTitle size={.9}>
                                    {data.nome}
                                </MiniTitle>
                            </div>
                            <div className="flex-6 _center">
                                <NumberInputControlled
                                    data={{
                                        containerClass: "_center",
                                        id: data.id,
                                        title: data.nome
                                    }}
                                    product={{ ...productCartData }}
                                    setValue={val => aumentarQtd(val)}
                                    getValue={() => null}
                                    value={
                                        typeof productCartData.qtd === 'number' && productCartData.qtd !== 0 ?
                                            productCartData.qtd : 1
                                    }
                                />
                            </div>
                        </div>
                        <div className="pt-3 w-100">
                            <h5
                                style={textPrimary}
                                className="size-small has-text-weight-medium is-uppercased">
                                {traducao.produtos.alguma_observacao}
                            </h5>
                            <div className="flex-12 mt-2">
                                <input
                                    value={productCartData.observacao || ""}
                                    onChange={e => setObservation(e.target.value)}
                                    type="text"
                                    className="observation"
                                    placeholder={traducao.produtos.alguma_observacao}
                                />
                            </div>
                        </div>
                        <div className="pt-2">
                            <MiniTitle>
                                Com os adicionais:
                            </MiniTitle>
                            <ul style={{ marginLeft: 5 }}>
                                {productCartData.adicionais.map((e, i) => (
                                    <li className="pb-1">
                                        <MiniTitle>{i + 1}) {e.nome} {e.qtd || 1}x</MiniTitle>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </ModalCompressed>
            </section>

            <AddToCartModal
                show={showModal && (data.itens.length > 0 || data.adicionais.length > 0)}
                buttonMessage={traducao.geral.adicionar_ao_carrinho}
                cart={productCartData}
                setShow={val => showModalProximo(val)}
                adicionar={
                    showModal === 1 ?
                        () => {
                            setShowModal(2)
                            adicionaisQtd.current = productCartData.adicionais
                        }
                        : typeof miss === 'string'
                            ? () => { setShowModal(1); send() }
                            : send
                }
            />
        </div>
    )
}