import { CATEGORY_DATA, REMOVE_CATEGORY_DATA } from '../actions/actionTypes';

/**
 *  Aqui vão estar todos os dados das categorias
 */

const initialState = {
  data: {
    payload: {}
  }
};
  
export default (state = initialState, action) => {
    switch (action.type) {
      case CATEGORY_DATA:
        return {
          ...state,
          data: action
        };
      case REMOVE_CATEGORY_DATA:
          return {
            ...state,
            data: initialState
          }
      default:
        return state;
    }
};