import React from 'react';
import { useSelector } from 'react-redux';
import { calculatePrice, getCompanyId } from '../../helpers';
import { Link } from 'react-router-dom';
import { TitlePrimary } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';
import useMoeda from '../../traducao/useMoeda';

export default ({ 
    buttonMessage, 
    show, 
    onClick, 
    to="checkout/before-confirm",
    dontGo = false,
    className =  "go-to-checkout",
    cartItensQtd= false,
    entrega = false,
}) => {

    const { cart } = useSelector(state => state.cartIten);
    const moeda = useMoeda();
    
    const { textPrimary, bgPrimary, cor_padrao } = useGetPrimary();

    const companyId = getCompanyId;

    let valor = calculatePrice(cart, true);

    if (entrega)
        valor = entrega

    return (
        <div 
            onClick={ () => onClick() } 
            className={`${ !show ? 'none-add-cart' : ''} add-to-cart show-submit z-if-none-out ${className}`}
        >
            <TitlePrimary primary={ cor_padrao }>
                { moeda }{ valor.toFixed(2) }
            </TitlePrimary>
            {
                !dontGo ? 
                <Link to={`/${companyId}/${to}`} className="btn size-small" style={ bgPrimary }>
                    { buttonMessage }
                </Link>
                :
                <button className="btn size-small" style={ bgPrimary }>
                    { buttonMessage }
                </button>
            }{
                cartItensQtd ?
                <div className="cart _center">
                    <span style={ textPrimary } className="material-icons">shopping_cart</span>
                    {
                        cart.length > 0 ?
                        <div className="tabs products-cart _center">
                            {cart.length}
                        </div>
                        : ''
                    }
                </div>
                : null
            }
        </div>
    );
}
