import api from '../api.json';

const {
    lojaApi,
    googleGeolocation,
    googleMatrix,
    usuarioApi,
    cupomApi
} = api

/**
 * This is the company api, here is where everything starts
 * 
 * @param {string|number} id
 */

 const loja = lojaApi.split(/\{.{0,}\}/);

export const companyApi = id => `${loja[0]}${id}${loja[1]}`;

/**
 * 
 * @param {*} parameters 
 * @param {*} outputFormat 
 */
export const geolocation = ( parameters, key ) => (
    `${ googleGeolocation }?address=${ parameters }&key=${ key }`
);

export const matrix = ( origin, destination, key ) => (
    `${ googleMatrix }?origins=${ origin }&destinations=${ destination }&key=${ key }`
);

export const usuario = ( canal, numero ) => (
    `${ usuarioApi }/${ canal }/${ numero }`
)

export const cupom = () => cupomApi