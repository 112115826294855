import React, { useEffect } from 'react';
import useApiItens from '../../../hooks/useApiItens';
import PromoPizzaModalCall from '../../modal/modalCallers/promo/PromoPizzaModalCall';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getCompanyId } from '../../../helpers'
import CategoriesRow from '../../general/CategoriesRow';
import Loading from '../../general/Loading';
import useGetPrimary from '../../../helpers/useGetPrimary';
import { useDispatch } from 'react-redux';
// import { removeTab } from '../../../actions';
import { TitlePrimary } from '../../../styled/Titles';
import { removeScroll } from '../../../actions';

export default () => {

    const dispatch = useDispatch();

    const { itens } = useApiItens();

    const { department, type, cat } = useParams();

    const pathname = department + "/" + type + "/" + cat;

    let { catId } = useParams();

    const companyId = getCompanyId;

    const { textPrimary, borderBottom, cor_padrao } = useGetPrimary();

    useEffect(() => {
        dispatch(removeScroll(true));
    }, [dispatch]);

    return (
        <article className="container">
            {
                itens && Array.isArray(itens) && itens.length > 1 ?
                    <>
                        <div className="is-pulled-left pr-1 pt-1">
                            <Link className="clean-button" to="/">
                                <span
                                    className="material-icons size-big"
                                    style={textPrimary}
                                >
                                    apps
                                </span>
                            </Link>
                        </div>
                        <div className="flex scroll-x">
                            {
                                itens.map((category, index) => (
                                    <div
                                        key={category.id + category.nome}
                                        className={
                                            `${catId === category.id ? 'underline-title' : ''} 
                                        ${index === 0 && !catId ? 'underline-title' : ''} 
                                        px-3 mr-2 mb-2`
                                        }
                                        style={borderBottom}
                                    >
                                        <Link to={`/${companyId}/${pathname}/${category.id}`}>
                                            <h5
                                                style={textPrimary}
                                                className="no-warap-white-space size-small has-text-weight-bold">
                                                {category.nome}
                                            </h5>
                                        </Link>
                                    </div>
                                ))
                            }
                        </div>
                    </>
                    :
                    <CategoriesRow />
            }
            <div className="mt-2">
                <div className="flex border-bottom">
                    <div className="flex-3 _center pointer" />
                    <div>
                        <TitlePrimary primary={cor_padrao} uppercase >
                            {"Escolha sua promoção"}
                        </TitlePrimary>
                    </div>
                    <div className="flex-1" />
                </div>
                {
                    itens && itens[0] && itens[0].produtos ?
                        itens[0].produtos.map(prod => (
                            <PromoPizzaModalCall
                                key={parseInt(Math.random() * 10000)}
                                data={prod}
                            />
                        ))
                        :
                        <Loading />
                }
            </div>
        </article>
    );
}