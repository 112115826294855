import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TelInput from '../forms/inputs/TelInput';
import { getCompanyId, invalidNumberMessage } from '../../helpers';
import { useDispatch } from 'react-redux';
import { userPhone } from '../../actions';
import useGetPrimary from '../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default () => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const { bgPrimary } = useGetPrimary();

    const [phone, setPhone] = useState("");

    const companyId = getCompanyId;

    const save = async () => {

        const telefone = phone.replace(/\D/g, '');


        dispatch(userPhone(telefone));
    };

    const handleSubmit = (e) => {
        if (phone.length < 8) {
            e.preventDefault();
            return;
        }
        save();
    };

    return (
        <article className="phone has-text-centered">
            <form
                onSubmit={(e) => handleSubmit(e)} action={`/${companyId}/checkout/before-confirm`}
                className="block-white"
            >
                <TelInput
                    classes="pb-3"
                    phone={phone}
                    setPhone={val => setPhone(val)}
                    title={traducao.dados_usuario.telefone}
                    placeholder={`${traducao.dados_usuario.seu_numero}...`}
                    invalid={phone.length < 8}
                />

                {
                    phone.length < 11 ?
                        <button onClick={() => invalidNumberMessage(null, phone.length)} style={bgPrimary} className="btn mt-4 size-normal">
                            {traducao.geral.continuar}
                        </button>
                        :
                        <Link to={`/${companyId}/status`} className="block">
                            <button style={bgPrimary} className="btn mt-4 size-normal" onClick={() => save()}>
                                {traducao.geral.continuar}
                            </button>
                        </Link>
                }
            </form>
        </article>
    );
};