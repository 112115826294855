import { confirmAlert } from "react-confirm-alert";
import getCompanyId from "./getCompanyId";

const invalidNumberMessage = (size, country) => {
    // console.log(country);
    let mes = ""
    if (getCompanyId === "6840" && !country) {
        mes = "Elige un pais"
    }
    if (size < 11 && getCompanyId !== "6840") {
        mes = "Esse número é inválido, favor colocar nono digito"
    }
    return confirmAlert({
        title: mes,
        buttons: [
            {
                label: "Ok",
                onClick: () => null
            }
        ]
    });
}

export default invalidNumberMessage;