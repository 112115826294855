import React from 'react';
import NumberInput from '../../forms/inputs/NumberInput';
import addAdicional from '../../../helpers/addAdicional';
import NumberMinMax from '../../forms/inputs/NumberMinMax';
import useGetPrimary from '../../../helpers/useGetPrimary';
import useMoeda from '../../../traducao/useMoeda';

export default ({ e, productCartData, setProductCartData = () => null }) => {

    const { textPrimary } = useGetPrimary();
    const moeda = useMoeda();

    return (
        !e.itens ?
            <NumberInput
                key={e.id}
                data={{
                    containerClass: "border-bottom",
                    price: e.valor,
                    min: e.qtde_escolha_obrigatoria ? e.qtde_escolha_obrigatoria : null,
                    max: e.qtde_max_escolha,
                }}
                product={e}
                getValue={
                    val => setProductCartData({
                        ...productCartData,
                        acompanhamento: addAdicional(e, productCartData.acompanhamento, val, "id")
                    })
                }
            >
                <p className="flex-6">
                    <label className="mini-title size-normal-small text-bold">
                        {e.nome}
                    </label>
                    <small className="mini-title mt-0">
                        {e.descricao}
                    </small>
                    {
                        e.valor ?
                            <small className="price">
                                {moeda}{e.valor.toFixed(2)}
                            </small>
                            : ''
                    }
                </p>
            </NumberInput>
            :
            <div key={e.tipo_acompanhamento_nome}>
                <h5 style={textPrimary} className="has-text-weight-bold">
                    {e.tipo_acompanhamento_nome}
                </h5>
                <NumberMinMax
                    data={e}
                    setProductCartData={setProductCartData}
                    productCartData={productCartData}
                    field="acompanhamento"
                    max="qtde_max_escolha"
                    min="qtde_escolha_obrigatoria"
                    showTitle={false}
                    parenteName
                    parent="tipo_acompanhamento_nome"
                    idName="id"
                />
            </div>
    );
}