import React, { useState, useEffect } from 'react';
import ModalForm from '../../forms/ModalForm';
import Button from '../../forms/Button';
import Row from '../../general/Row';
import RegistrarNovoEndereco from './RegistrarNovoEndereco';
import { useDispatch, useSelector } from 'react-redux';
import { userAddress } from '../../../actions';
import useGetPrimary from '../../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import { setEndereco } from '../../../json';
import { calculatePrice } from '../../../helpers';


export default ({
    show,
    setShow,
    userAddressData,
    setSuccess,
}) => {
    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const [abrirNovoEndereco, setAbrirNovoEndereco] = useState(false);
    const [dadosNovoEndereco, setDadosNovoEndereco] = useState({});

    const { textPrimary } = useGetPrimary();
    const [enderecoDeUsuario, setEnderecoDeUsuario] = useState(userAddressData);

    const { desc_tx_entrega } = useSelector(state => state.companyData.data.payload);
    const { cart } = useSelector(state => state.cartIten);
    let valor = calculatePrice(cart, true);

    useEffect(() => {
        if (userAddressData.length > 0)
            setEnderecoDeUsuario(userAddressData);
    }, [userAddressData]);

    const data = { title: "Endereço de entrega" };


    const abrirRegistrar = (e = {}, index) => {
        setAbrirNovoEndereco(!abrirNovoEndereco);
        const id = e.id ? e.id : 0;

        const dados = index ? { ...e, index } : { ...e, id };

        setDadosNovoEndereco(dados);
    };

    const save = (val) => {
        let info = val;
        if (desc_tx_entrega && valor >= desc_tx_entrega) {
            if (info.regiao && info.regiao.valor) {
                info.regiao.oldValor = val.regiao.valor;
                info.regiao.valor = 0;
            } else if (info.km && info.km.valor) {
                info.km.oldValor = val.km.valor;
                info.km.valor = 0;
            }
        }
        dispatch(userAddress(info));

        if (enderecoDeUsuario.length > 0) {

            setEnderecoDeUsuario([...enderecoDeUsuario, info]);
            setEndereco([...enderecoDeUsuario, info]);
        }
        else {
            setEnderecoDeUsuario([info]);
            setEndereco([info]);
        }

        setShow(false);
    };

    const selecionar = (val) => {
        let info = val;
        if (desc_tx_entrega && valor >= desc_tx_entrega) {
            if (info.regiao && info.regiao.valor) {
                info.regiao.oldValor = val.regiao.valor;
                info.regiao.valor = 0;
            } else if (info.km && info.km.valor) {
                info.km.oldValor = val.km.valor;
                info.km.valor = 0;
            }
        }        
        dispatch(userAddress(info));
        let enderecoAtualizado = enderecoDeUsuario;
        enderecoAtualizado.splice(info.index, 1, info);
        setEnderecoDeUsuario(enderecoAtualizado);
        setShow(false);
    };

    const excluirEndereco = val => {
        let enderecoAtualizado = enderecoDeUsuario;
        enderecoAtualizado.splice(val, 1);
        setEnderecoDeUsuario(enderecoAtualizado);
        setAbrirNovoEndereco(false);
    };

    return (
        <ModalForm
            data={data}
            show={show}
            setShowModal={() => setShow(false)}
            closeIcon={false}
        >

            {
                enderecoDeUsuario.map((e, index) => {

                    let nome = "";
                    if (e.nome) nome = e.nome;
                    else if (e.endereco) nome = e.endereco;
                    else nome = traducao.dados_usuario.sem_nome;

                    return (
                        <div key={e.endereco + Math.random()}>
                            {
                                <Row
                                    title={nome}
                                    titleClass="has-text-weight-medium size-small"
                                    onClick={() => abrirRegistrar(e, index)}
                                >
                                    <Button
                                        click={() => abrirRegistrar(e, index)}
                                        classes="clean-button flex-end"
                                        bg={false}
                                    >
                                        <span className="material-icons size-big">keyboard_arrow_right</span>
                                    </Button>
                                </Row>
                            }
                        </div>
                    );
                })
            }
            <Row
                title={traducao.dados_usuario.cadastratar_endereco}
                titleClass="has-text-weight-medium size-small"
                onClick={() => abrirRegistrar()}
            >
                <Button bg={false} click={() => abrirRegistrar()} classes="clean-button flex-end">
                    <span style={textPrimary} className="material-icons size-big">add</span>
                </Button>

            </Row>

            <RegistrarNovoEndereco
                show={abrirNovoEndereco}
                setShow={setAbrirNovoEndereco}
                addressData={dadosNovoEndereco}
                save={save}
                selecionar={selecionar}
                excluir={excluirEndereco}
                setSuccess={setSuccess}
            />

            <div className={`buttons ${enderecoDeUsuario.length > 4 ? '_center' : 'bottom-only'}`}>
                <Button
                    bg={false}
                    title={traducao.geral.cancelar}
                    classes="button is-outlined"
                    click={() => setShow(false)}
                />
            </div>
        </ModalForm>
    );
};