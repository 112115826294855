import React, { useState, useEffect } from 'react';
import FormPromoGeral from '../../../forms/FormPromoGeral';
import { useParams } from 'react-router';
import Loading from '../../../general/Loading';
import AddToCartConfirm from '../../../request/AddToCartConfirm';
import { useDispatch } from 'react-redux';
import { removeTab } from '../../../../actions';
import { MiniTitle } from '../../../../styled/Titles';
import useGetPrimary from '../../../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../../../traducao/useConteudoTraduzido';
import useCarrinhoInicial from '../../../../hooks/useCarrinhoInicial';
import TituloProduto from '../../../general/TituloProduto';
import useMoeda from '../../../../traducao/useMoeda';

export default ({
    data = {}
}) => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const inicial = useCarrinhoInicial('promo_geral', data);

    const [mostrarTab, setMostrarTab] = useState(false);
    const { cor_padrao } = useGetPrimary();

    const [ativo, setAtivo] = useState({});

    const { catId } = useParams();

    const [productCartData, setProductCartData] = useState(inicial);
    useEffect(() => {
        setMostrarTab(data.conjuntos ? productCartData.itens.length === data.conjuntos.length : 0);
        dispatch(removeTab(false));
        // console.log(productCartData?.nome === "promo %" ? productCartData : false);
        // eslint-disable-next-line
    }, [productCartData, catId])
    
    const moeda = useMoeda();
    
    return (
        <div className="border-top">
            <TituloProduto
                nome={data.nome}
                descricao={data.descricao}
                valor={data.valor ?? ''}
                moeda={moeda}
                voltar={false}
            />
            {
                data.conjuntos ?
                    <div> {
                        data.conjuntos.map((element, index) => (
                            <div key={index}>
                                <MiniTitle uppercase="capitalize" bold color={cor_padrao} size={.9}>
                                    {index + 1}) {element.tp_conjunto}
                                </MiniTitle>
                                {
                                    element.produto.map((e) => {
                                        return (
                                            <FormPromoGeral
                                                key={e.id}
                                                data={{ ...e, index, gValor: data.valor, }}
                                                productCartData={productCartData}
                                                setProductCartData={setProductCartData}
                                                valorProduto={element.valor_produto}
                                                ativo={ativo}
                                                setAtivo={setAtivo}
                                                desconto={{tipo: data.tipo, desconconto: data.desconto}}
                                            />
                                        )
                                    })
                                }
                            </div>
                        ))
                    }
                        <AddToCartConfirm
                            show={mostrarTab}
                            setShow={setMostrarTab}
                            buttonMessage={traducao.geral.adicionar_ao_carrinho}
                            cart={productCartData}
                            submit={null}
                            clear={() => setProductCartData(inicial)}
                        />
                    </div>
                    :
                    <Loading />
            }
        </div>
    );
}