import React from 'react'
import { Erro } from '../../../../styled/Text';
import Select from '../../../forms/inputs/Select';

export default ({
    tipo,
    traducao,
    entrega,
    setData,
    address,
    errMessage
}) => {

    return (
        <>
       { 
        tipo === 'regiao' ? 
                <Select
                    title={ traducao.dados_usuario.regiao }
                    opt={ entrega.regioes ? entrega.regioes : [] }
                    setSelect={ val => setData( "regiao", val ) }
                    select={ address.regiao && address.regiao.id ? address.regiao.id : address.regiao && address.regiao !== "fff" ? address.regiao : "fff" }
                    mostrar="regiao"
                    valor="id"
                    semSeta={ false }
                    placeholder={ traducao.dados_usuario.regiao }
                />
            : null

        }
        <Erro>
            { errMessage.regiao }
        </Erro>
        </>
    );
}

/* <Text 
title={ traducao.dados_usuario.regiao }
initialValue={ address.regiao ? address.regiao : '' } 
placeholder={ traducao.dados_usuario.regiao }
setValue={  val => setData("regiao", val) }
/> */