import { 
	CART_DATA, 
	ADD_TO_CART, 
	UPDATE_VALUE_IN_CART, 
	REMOVE_VALUE_IN_CART,
	REMOVE_ALL,
	IS_COMPLETE
} from "../actions/actionTypes";

const initialState = {
	cart: [],
	isComplete: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case CART_DATA:
			return {
				...state,
				cart: action
			};
		case ADD_TO_CART:

			const tryIt = state.cart.concat(action.payload);

			const cart = tryIt.filter((elem, index, self) => {
				return index === self.indexOf(elem);
			});
			
			return {
				...state, 
				cart: cart
			};
		case UPDATE_VALUE_IN_CART:

			let oldCart = state.cart;

			const toUpdate = oldCart.reduce((e, i) => e.id === action.payload.id ? i : false, {});

			const index = oldCart.indexOf(toUpdate);

			oldCart.splice(index, 1);

			const newCart = oldCart.concat(action.payload)

			return {
				cart: newCart
			}

		case REMOVE_VALUE_IN_CART: 

				let untouchedState = state.cart;

				const touchingState = untouchedState.reduce((e, i) => e.id === action.payload.id ? i : false, 0);

				const removeIndex = untouchedState.indexOf(touchingState);

				const removedItemCart = untouchedState.splice(removeIndex, 1);

			return {
				...state,
				removedItemCart
			}

		case REMOVE_ALL: 
			return {
				...state,
				cart: []
			}
		case IS_COMPLETE:
				const isComplete = action.payload;
			return {
				...state,
				isComplete
			}
		default:
			return state;
	}
}