function enderecoConsulta(end) {

    let novoEndereco = {
        ...end,
        regioes: {},
        metodo_entrega: 'consulta'
    }
    
    novoEndereco.metodo_entrega = "consulta";
    novoEndereco.km = {}
    novoEndereco.endereco_completo = `${ novoEndereco.endereco }, ${ novoEndereco.numero }, ${ novoEndereco.cidade }, ${ novoEndereco.estado }`

    return novoEndereco
}

export default enderecoConsulta;