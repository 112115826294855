import React from 'react';
import CartList from '../request/CartList';
import { calculatePrice, finalizarHelper } from '../../helpers';
import DadosEntrega from './DadosEntrega';
import { DefaultContainer } from '../../styled/Containers.tsx';
import api from '../../api.json';
import FinishTabs from '../request/FinishTabs';
import valorDaEntrega from '../../distancia/valorDaEntrega';
import { Agendar, Coupon, DadosPessoais, MostrarErro, PagamentoTroco, TabelaValores } from './confirmation-components/geral';
import { useConfirmationVariaveis } from '../../hooks/useConfirmationVariaveis';

const finalPedido = api.finalPedido;

const Confirmation = () => {

    const {
        traducao, moeda,
        desabilitar, setDesabilitar,
        submitErro, setSubmitErro,
        history, cart, preferences, canal,
        como_cobrar_pizza, tx_pizza_sabores, pagamento,
        pergunta_final_texto, pergunta_final,
        pergunta_final_sim_nao, avaliacao,
        desc_tx_proc_vl, desc_tx_proc,
        desc_tx_entrega,
    } = useConfirmationVariaveis();

    const subTotal = calculatePrice(cart, true);

    let valorEntrega = preferences.metodo_entrega === 'entrega' ? valorDaEntrega(preferences.entrega) : 0;

    let total = 0;

    if (subTotal >= desc_tx_proc_vl) {
        const valor = subTotal * (desc_tx_proc / 100);
        total = subTotal - valor;
    }


    if (preferences.acrescimo > 0)
        total = total + (total * (preferences.acrescimo / 100));
    if (preferences.desconto > 0)
        total = total - (total * (preferences.desconto / 100));

    if (valorEntrega !== undefined && valorEntrega !== null) {
        if (total === 0) total = subTotal + valorEntrega;
        else total += valorEntrega;
    }


    if (preferences.coupon && preferences.coupon.valor_do_pedido_com_desconto) {
        total = preferences.coupon.valor_do_pedido_com_desconto + valorEntrega;
    }


    const telaAvaliacao = avaliacao || pergunta_final_sim_nao || pergunta_final_texto;

    return (
        <DefaultContainer as="article">

            <MostrarErro submitErro={submitErro} />

            <CartList cart={cart} />

            <TabelaValores
                total={total}
                moeda={moeda}
                traducao={traducao}
                subTotal={subTotal}
                preferences={{ ...preferences, desc_tx_proc, desc_tx_proc_vl }}
                valorEntrega={valorEntrega}
            />

            <PagamentoTroco preferences={preferences} traducao={traducao} moeda={moeda} />

            <DadosPessoais preferences={preferences} traducao={traducao} />

            <DadosEntrega mostrar_metodo />

            <Agendar preferences={preferences} />

            <Coupon preferences={preferences} traducao={traducao} />


            <div className="after-add-to-cart"></div>
            <FinishTabs

                buttonMessage={telaAvaliacao ? 'Prosseguir' : 'Finalizar'}

                onClick={() => finalizarHelper(
                    cart,
                    preferences,
                    {
                        canal,
                        como_cobrar_pizza,
                        tx_pizza_sabores,
                        desc_tx_proc_vl,
                        desc_tx_proc,
                        desc_tx_entrega,
                    },
                    setSubmitErro,
                    history,
                    finalPedido,
                    pagamento.valor_minimo_pedido,
                    moeda,
                    setDesabilitar,
                    pergunta_final_texto, pergunta_final,
                    pergunta_final_sim_nao, avaliacao,
                )}
                entregaP={total}
                desabilitar={desabilitar}
            />
        </DefaultContainer>
    );
};

export default Confirmation;