// eslint-disable-next-line no-unused-vars
import { PromoPizzaCarrinho } from "../../../typing/PizzaPromo"

/**
 * * Verifica se falta algum item a ser incluido na promoção
 * @param {PromoPizzaCarrinho} productCartData 
 * @returns 
 */
export const faltaItem = (productCartData) => {
    const temFaltando = []

    productCartData.itens.forEach((pizza) => {
        if (pizza.sabor) { // tem algum sabor nessa pizza?
            if (pizza.sabor.length === 0 || !pizza.sabor) { // se nao tiver nada no array
                temFaltando.push(pizza.nome) // retorna o nome
            }
        }
        return true
    })

    // const temFaltando = qtdSabores.filter((semSabor) => typeof semSabor === 'string')

    return temFaltando
}
