import { getCompanyId } from "../helpers";

/**
 * 
 * @param {*} id 
 */
const setDefaultApp = id => {
    if ( !!id && typeof id === 'string' && id.length > 0 )
        localStorage.setItem("@defaultApp/pedido.alphi@hash", id);
}

const getDefaultApp = () => localStorage.getItem("@defaultApp/pedido.alphi@hash");

const setTelefone = telefone => {
    localStorage.setItem(`@telefoneUsuario-${ getCompanyId }-@hash31415926`, telefone);
}

const getTelefone = () => localStorage.getItem(`@telefoneUsuario-${ getCompanyId }-@hash31415926`);

const setEndereco = end => {
    const endereco = JSON.stringify( end );
    localStorage.setItem( `@endrecoUsuario-${ getCompanyId }-@hash31415926`, endereco );
}

const getEndereco = () => {
    const endereco = localStorage.getItem( `@enderecoUsuario-${ getCompanyId }-@hash31415926` );
    return JSON.parse( endereco );
}
/**
 * Altera a cor padrão no localstorage
 * @param {string} color 
 * @param {string|number} companyId 
 */
const setThemeColor = (color, companyId) => {
    localStorage.setItem(`@themeColor-${companyId}-@hash31415926`, color);
    return color;
}

/**
 * Pega a cor padrão no localstorage
 */
const getThemeColor = () => {
    return localStorage.getItem(`@themeColor-${getCompanyId}-@hash31415926`);
}

/**
 * Atualiza o carrinho
 * @param {object[]} cart 
 */
const setCart = cart => {

    sessionStorage.removeItem(`@cart-${getCompanyId}-@hash31415926`);

    const cartJson = JSON.stringify(cart);
    sessionStorage.setItem(`@cart-${getCompanyId}-@hash31415926`, cartJson);
}

/**
 * Pega os valores do carrinho
 */
const getCart = () => {
    const cart = sessionStorage.getItem(`@cart-${getCompanyId}-@hash31415926`);
    return JSON.parse(cart);
}

/**
 * Exclui todos o svalores do carrinho
 */
const removeCart = () => {
    sessionStorage.removeItem(`@cart-${getCompanyId}-@hash31415926`);
}

/**
 * Atualiza os dados do usuario e salva no sessionStorage
 * @param {object} userData 
 */
const setUserPreferences = userData => {
    
    sessionStorage.removeItem(`@userPreferences-${getCompanyId}-has31415926`);

    const preferencesJson = JSON.stringify(userData);
    sessionStorage.setItem(`@userPreferences-${getCompanyId}-has31415926`, preferencesJson);
}

/**
 * Pega os dados do usuario na sessionStorage
 */
const getUserData = () => {
    const user = sessionStorage.getItem(`@userPreferences-${getCompanyId}-has31415926`);

    return JSON.parse(user);
}

/**
 * Altera o caminho para as bebidas
 * @param {string} path 
 */
const setPergBebidas = path => {
    
    localStorage.removeItem(`@perg_bebidas-${getCompanyId}-hash31415926`);

    const perg = JSON.stringify(path);
    localStorage.setItem(`@perg_bebidas-${getCompanyId}-hash31415926`, perg);
}

/**
 * Pega o caminho das bebidas
 */
const getPergBebidas = () => {
    const perg = localStorage.getItem(`@perg_bebidas-${getCompanyId}-hash31415926`);

    return JSON.parse(perg);
}

/**
 * Atualiza a pergunta de categorias
 * @param {object} dados 
 */
const setPergCategoria = dados => {
    
    localStorage.removeItem(`@perg_cat-${getCompanyId}-hash31415926`);

    const perg = JSON.stringify( dados );
    localStorage.setItem(`@perg_cat-${getCompanyId}-hash31415926`, perg);
}

/**
 * Pega o objeto com o dados da pergunta de categoria
 */
const getPergCategoria = () => {
    const perg = localStorage.getItem(`@perg_cat-${getCompanyId}-hash31415926`);

    return JSON.parse(perg);
}

/**
 * Atualiza os dados de endereço e compras do cliente
 * @param {object} cliente 
 */
const setClienteJson = cliente => {
    sessionStorage.removeItem(`@cliente-${getCompanyId}-hash31415926`);

    const clienteString = JSON.stringify(cliente);

    sessionStorage.setItem(`@cliente-${getCompanyId}-hash31415926`, clienteString);   
}

/**
 * Pega os dados do cliente
 */
const getClienteJson = () => {
    const cliente = sessionStorage.getItem(`@cliente-${getCompanyId}-hash31415926`);

    return JSON.parse(cliente);
}

/**
 * Remove todos os dados do cliente
 */
const removeCliente = () => {
    sessionStorage.removeItem(`@cliente-${getCompanyId}-@hash31415926`);
}

/**
 * Conecta os 2 ids
 * @param {string|number} id1 id da filial 1
 * @param {string|number} id2 id da filial 2
 */
const setFiliais = ( id1, id2 ) => {
    
    const filiais = JSON.stringify({ filiais: [ parseInt(id1), parseInt(id2) ] })

    sessionStorage.setItem(`@filial-${ id1 }-hash31415926`, filiais);  
    sessionStorage.setItem(`@filial-${ id2 }-hash31415926`, filiais);
}

/**
 * Pega os dados das filiais
 */
const getFiliais = () => {
    
    const filiais = sessionStorage.getItem(`@filial-${ getCompanyId }-hash31415926`);  

    return JSON.parse(filiais); 
}

/**
 * Seta a mensagem para enviar pelo whatsapp
 * @param {string} mes mensagem para enviar ao wpp
 */
const setWhatsapp = mes => {
    sessionStorage.setItem(`@whats-${ getCompanyId }-hash31415926`, mes);  
}

/**
 * Pega a mensagem do whatsapp
 */
const getWhatsapp = () => {
    
    return sessionStorage.getItem(`@whats-${ getCompanyId }-hash31415926`);
}

const setResultadoFinal = (data) => {
    localStorage.setItem(`@ultimo-pedido-final-${getCompanyId}-hash314159`, data)
}

const getResultadoFinal = () => {
    return JSON.parse(localStorage.getItem(`@ultimo-pedido-final-${getCompanyId}-hash314159`))
}


export { 
    setResultadoFinal,
    getResultadoFinal,
    setDefaultApp,
    getDefaultApp,
    setThemeColor,
    getThemeColor,
    setCart, 
    getCart,
    removeCart,
    setUserPreferences,
    getUserData,
    setPergBebidas,
    getPergBebidas,
    setClienteJson,
    getClienteJson,
    removeCliente,
    setPergCategoria,
    getPergCategoria,
    setFiliais,
    getFiliais,
    setWhatsapp,
    getWhatsapp,
    setTelefone,
    getTelefone,
    setEndereco,
    getEndereco,
}