import { useEffect } from "react";
import {
  setPergBebidas,
  getUserData,
  getCart,
  setPergCategoria,
  setDefaultApp,
} from "../json";
import { useDispatch, useSelector } from "react-redux";
import { asyncSetData, addItenToCart, userSetAll } from "../actions/index";
import { companyApi } from "../tratar-api/index.js";
import getCompanyId from "../helpers/getCompanyId";
import initGA from "../ga";

export default () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.companyData);
  const { img } =
    selector && selector.data && selector.data.payload
      ? selector.data.payload
      : "";
  const { redirect_menuflow } =
    selector && selector.data && selector.data.payload
      ? selector.data.payload
      : "";
  const data = selector.data.payload;
  const id = getCompanyId;

  useEffect(() => {
    initGA(selector.data.payload.id_pixel);
  }, [selector]);

  useEffect(() => {
    dispatch(asyncSetData(companyApi(id)));
    setDefaultApp(id);
  }, [id, dispatch]);

  const scrollbar = useSelector((state) => state.removeScroll);

  useEffect(() => {
    const cartObject = getCart();

    dispatch(addItenToCart(cartObject !== null ? cartObject : []));

    const user = getUserData();

    dispatch(userSetAll(user));
  }, [dispatch]);

  useEffect(() => {
    const { departamento, perg_bebida } = selector.data.payload;

    if (perg_bebida) {
      const bebidasCategory = departamento
        ? departamento.map((dpt) =>
            dpt.categorias.filter((e) => e.tipo === "bebidas")
          )
        : [];

      if (bebidasCategory && bebidasCategory[0] && bebidasCategory[0][0]) {
        const id = bebidasCategory[0] ? bebidasCategory[0][0].id : "bebidas";

        const caminho = `/${getCompanyId}/${1}/bebidas/${id}`;

        setPergBebidas(caminho);
      }
    }
  }, [selector]);

  useEffect(() => {
    const { departamento, perg_categoria, perg_id_categoria } =
      selector.data.payload;

    if (perg_categoria === true) {
      const [categoria] = departamento
        ? departamento.map((dpt) =>
            dpt.categorias.filter((e) => e.id_categoria === perg_id_categoria)
          )
        : [];

      if (categoria[0] && categoria[0].nome) {
        const dados = {
          categoria: categoria[0].nome,
          caminho: `/${getCompanyId}/${1}/${categoria[0].tipo}/${
            categoria[0].id
          }`,
        };

        setPergCategoria(dados);
      }
    }
  }, [selector]);

  return {
    img,
    scrollbar,
    data,
    redirect_menuflow,
  };
};
