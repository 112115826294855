import React from 'react';
import {Link} from 'react-router-dom';
import {MiniTitle} from '../../styled/Titles';
import {grey, greyLight} from '../../style-js';
// import { clickGa } from '../../ga';
// import { getCompanyId } from '../../helpers';
import styled from 'styled-components';
import {getCompanyId} from "../../helpers";


const HrefLink = styled(Link)``;

export default ({
                    isCircle = false,
                    title = "",
                    path,
                    image = null,
                    img_ativo = null,
                    active = false,
                    outerLink = {},
                }) => {

    const imageActive = () => {
        return active && img_ativo ? img_ativo : image
    }
    // const tamanho = title.length > 15;

    // const ga = () => {
    //     clickGa(
    //         'Entrar categoria',
    //         'Usuario entrou em uma categoria na página principal',
    //         `Usuário entrou em ${ title } na loja ${ getCompanyId }`
    //      );
    // }

    return (
        <HrefLink to={path} {...outerLink}>
            <div className="category-block">
                <div className={
                    `${isCircle ? 'circle' : 'square'} ${active ? 'active' : ''} ${!image ? 'bg' : ''}`
                }>
                    {
                        image !== null && image !== "" ?
                            <img src={imageActive()} alt={title}/>
                            :
                            <span className="material-icons-outlined">camera_alt</span>
                    }
                </div>
                <MiniTitle
                    color={!active ? greyLight : grey}
                    size={getCompanyId === "8844" ? .75 : .7}
                    uppercase="capitalize"
                    className="text-center"
                >
                    {
                        getCompanyId === "8844" ?
                            <strong>{title}</strong>
                            : title
                    }
                </MiniTitle>
            </div>
        </HrefLink>
    );
}
