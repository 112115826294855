import { confirmAlert } from "react-confirm-alert";
import { removeTab } from "../actions";

const save = (setAdded, setAdicionaisList, dispatch, stateHolder) => {
    setAdded(stateHolder);

    setAdicionaisList(false);
    dispatch(removeTab(true));
}

const addProdutoNoCarrinho = (
    productCartDataHolder,
    stateHolder,
    addItenToCart,
    dispatch,
    setAdicionaisList,
    miss,
    data,
    setAdded,
    setProductCartData,
    inicial,
) => {

    const iguais = productCartDataHolder.filter(e => (!e.adicionais || e.adicionais.length === 0) && (!e.acompanhamento || e.acompanhamento.length === 0));
    const diferentes = productCartDataHolder.filter(e => (e.adicionais && e.adicionais.length > 0) || (e.acompanhamento && e.acompanhamento.length > 0));

    const salvar = [];

    if (iguais.length > 0) {

        let obssArray = iguais.map(e => e.observacao || null);

        let obss = "";

        obssArray.forEach((e, i) => typeof e === 'string' && e.length > 0 ? obss += ` ${e}${i + 1 < obssArray.length ? ', ' : ''}` : null);

        const comQtd = { ...iguais[0], qtd: iguais.length, observacao: obss };
        salvar.push(comQtd);
    }
    if (diferentes.length > 0)
        salvar.push(...diferentes);

    if (miss === null) {
        dispatch(addItenToCart(salvar));
        setAdicionaisList(false);
        dispatch(removeTab(true));
        save(setAdded, setAdicionaisList, dispatch, stateHolder);
        setProductCartData(inicial);
    } else {
        confirmAlert({
            title: "Você ainda precisa completar o pedido",
            message: miss,
            buttons: [
                {
                    label: "Entendi"
                }
            ]
        });
    }
    // dispatch(addItenToCart(salvar));
}

export default addProdutoNoCarrinho;