import React, { useEffect, useRef } from 'react';
import { replacer } from '../../helpers';
import { useDispatch } from 'react-redux';
import { removeScroll } from '../../actions';
import useMoeda from '../../traducao/useMoeda';

export default ({
    show,
    setShowModal,
    price,
    children,
    titleUppercase,
    closeIcon = true,
    iconLeft = false,
    title = "",
    subtitle,
    iconRight,
    iconRightClasses = "",
    fechar = null,
    setCor = () => null,
}) => {

    let display = show ? "show" : "none";

    const ref = useRef(null);
    const moeda = useMoeda();

    const dispatch = useDispatch();

    useEffect(() => {
        if (ref.current !== null) {
            const bg = window.getComputedStyle(ref.current, null).getPropertyValue('background-color');
            // const bg = ref.current.style.backgroundColor;
            setCor(bg);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        dispatch(removeScroll(!show));

        if (typeof ref.current.scrollTo === 'function')
            ref.current.scrollTo(0, 0);

    }, [show, dispatch]);

    const formId = replacer(title);

    return (
        <>
            <div ref={ref} id={formId} className={`form-group-modal ${display}`} style={{ backgroundColor: "#fff" }}>
                <header className="container center-justify space-between border-bottom">
                    {
                        iconLeft ?
                            <button type="button" className="clean-button" onClick={() => setShowModal(false)}>
                                <span className="material-icons is-size-2 text-grey">keyboard_arrow_left</span>
                            </button>
                            : <span className="blanc-35"></span>
                    }
                    <div className="text-center flex-7">
                        <h6 className={`size-normal-small ${titleUppercase ? 'uppercase' : ''} has-text-weight-bold`}>
                            {title}
                        </h6>
                        <p className="subtitle">
                            <small>
                                {subtitle}
                            </small>
                        </p>
                        <p className="price">
                            {
                                typeof price === 'number' ?
                                    moeda + price.toFixed(2)
                                    : price
                            }
                        </p>
                    </div>
                    {
                        closeIcon ?
                            <button
                                type="button"
                                className="clean-button mt-1"
                                onClick={typeof fechar === 'function' ? () => fechar(false) : () => setShowModal(false)}
                            >
                                <span className={`material-icons size-bigger ${iconRightClasses.length > 0 ? iconRightClasses : 'has-text-grey'}`}>
                                    {
                                        iconRight ?
                                            iconRight
                                            : 'expand_less'
                                    }
                                </span>
                            </button>
                            : <span className="blanc-35"></span>
                    }

                </header>

                <div className="container modal-bottom">
                    {children}
                </div>
            </div>
            <div className={`close-modal-bg ${display}`} onClick={() => setShowModal(false)}></div>
        </>
    );
}