import React, { useState } from 'react';
import ScheduleForm from './modals/ScheduleForm';
import { useSelector } from 'react-redux';
import { TitlePrimary, MiniTitle } from '../../styled/Titles';
import { ContainerMargin } from '../../styled/Containers.tsx';
import useGetPrimary from '../../helpers/useGetPrimary';
import { Label } from '../../styled/Label';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

/**
 *  Vai mostrar o agendamento
 *  @params {object} entrega os dados da entrega
 */
export default ({ entrega, erro }) => {

    // Para abrir o modal 
    const [modal, setModal] = useState(false);

    const traducao = useConteudoTraduzido();

    const { cor_padrao } = useGetPrimary();

    // Os dados de agendamento do usuário
    const { agendar } = useSelector(state => state?.userData ?? {});

    return (
        <ContainerMargin as="section" top={1.5}>

            <TitlePrimary uppercase primary={cor_padrao} className={erro.agendar ? 'has-text-danger' : ''}>
                {traducao.dados_usuario.agendar_horario}
            </TitlePrimary>

            <Label as="h3" onClick={() => setModal(true)}>
                {traducao.dados_usuario.desejo_agendar}
            </Label>


            { agendar && agendar.dia ? <MiniTitle>{traducao.geral.dia}: {agendar.dia}</MiniTitle> : ''}
            { agendar && agendar.hora ? <MiniTitle mt={5}>{traducao.dados_usuario.hora}: {agendar.hora}</MiniTitle> : ''}
            { agendar && agendar.periodo ? <MiniTitle mt={5}>{traducao.geral.periodo}: {agendar.periodo}</MiniTitle> : ''}


            {/* O modal para os agendamentos */}
            {
                Array.isArray(entrega.agendamento.dias_permitidos) ?

                    <ScheduleForm
                        schedules={entrega.agendamento}
                        show={modal}
                        setShow={val => setModal(val)}
                    />
                    : null
            }

            <hr />

        </ContainerMargin>
    );
};