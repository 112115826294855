import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import useApiItens from '../../../../hooks/useApiItens';
import { DefaultContainer } from '../../../../styled/Containers.tsx';
import CategoriesRow from '../../../general/CategoriesRow';
import PizzaPromoItem from './PizzaPromoItem';


/**
 * * Componente inicial da promoção de pizza
 */
const PromocaoDePizzaModalCall = () => {
    // Primeiro pega os dados da api
    const { itens } = useApiItens();

    // e o parametro do id da categoria
    const { catId } = useParams();
    // e um estado para definir a categoria
    const [ promoItens, setPromoItens ] = useState( [] );

    useEffect(() => {
        // vemos os produtos segundo o id da categoria
        const myItens = itens && itens[0] && itens[0].produtos ?
                itens[0].produtos.filter( promo => promo.id === parseInt( catId ) )
            : {};
        // e arrumamos o estado
        setPromoItens( myItens );

    }, [itens, catId]);

    return (
        <article>
            <DefaultContainer>
                <CategoriesRow />
            </DefaultContainer>
            {
                promoItens  && promoItens.length > 0 ?
                promoItens.map( promo => (
                    <PizzaPromoItem
                        key={ promo.nome }
                        promoItens={ promo }
                    />
                ))
                : null
            }
        </article>
    );
}

export default PromocaoDePizzaModalCall