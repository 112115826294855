import React, { useEffect } from 'react';
import NumberInputControlled from './NumberInputControlled';
import SnackData from '../SnackData';
import ModalCompressed from '../ModalCompressed';
import NumberSimple from './NumberSimple';
import AddCalculation from '../AddCalculation';
import { MiniTitle } from '../../../styled/Titles';
import useProdutoAdd from '../../../hooks/useProdutoAdd';
import useExistiu from '../../../hooks/useExistiu';
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial';
import { getCompanyId, saveToCart } from '../../../helpers';
import { ContainerColumn } from '../../../styled/Containers.tsx';

export default ({
   productCartData,
   setProductCartData,
   data,
   setShowModal,
   show,
}) => {

   const {
      dispatch, cart, existiu, setExistiu,
      cor_padrao, stateHolder, setStateHolder,
      traducao, added, setAdded, obs, setObs,
   } = useProdutoAdd();

   const inicial = useCarrinhoInicial('produto', data);

   const add = val => {
      setProductCartData({
         ...productCartData,
         ...val,
         noTabs: true,
      });
      setShowModal(!show);
   };

   useExistiu(cart, data, setAdded, existiu, setExistiu, setStateHolder, setProductCartData, () => null, inicial);

   useEffect(() => {
      setProductCartData({ ...productCartData, observacao: obs });
      // eslint-disable-next-line
   }, [obs]);

   const salvarNoCarrinho = () => {
      setShowModal(false);
      setAdded(stateHolder);
      saveToCart(dispatch, productCartData, cart, false);
   };

   return (
      <>

         <ContainerColumn column={4} direction="column" onClick={() => setShowModal(true)}>
            <NumberSimple
               setValue={val => setStateHolder(val)}
               data={{
                  containerClass: `flex-end flex-12`,
                  id: data.id,
                  title: data.nome
               }}
               getValue={val => add(val)}
               value={added}
            />
         </ContainerColumn>

         <ModalCompressed
            title={data.nome}
            price={data.valor}
            subtitle={traducao.produtos.personalize + data.nome}
            setShowModal={() => setShowModal(false)}
            show={show}
            classesAdicionais={((data.adicional_itens && data.adicional_itens.length === 0) && (data.acompanhamento_itens && data.acompanhamento_itens.length === 0)) ? '' : 'small'}
         >
            <section className="space-between center-align h-80">
               <SnackData
                  price={data.valor}
                  desconto={{ valor_tem_desconto: data.valor_tem_desconto, valor_sem_desconto: data.valor_sem_desconto }}
                  data={{}}
                  title={traducao.produtos.preco_unitario}
                  showImg={false}
               >
                  <div className="flex-end flex-5 flex-column _center space-between">

                     <MiniTitle bold>
                        {traducao.produtos.quantidade}
                     </MiniTitle>

                     <NumberInputControlled
                        setValue={val => setStateHolder(val)}
                        data={{
                           containerClass: `${stateHolder > 0 ? '_center' : 'flex-end'} flex-12`,
                           id: data.id,
                           title: data.nome
                        }}
                        getValue={
                           val => setProductCartData({
                              ...productCartData,
                              ...val,
                           })
                        }
                        value={stateHolder}
                     />
                  </div>

                  {
                     getCompanyId !== "9085" ?
                        <div className="pt-3 w-100">
                           <MiniTitle bold color={cor_padrao} uppercase="uppercase">
                              {traducao.produtos.alguma_observacao}
                           </MiniTitle>
                           <div className="flex-12 mt-2">
                              <input
                                 value={obs}
                                 onChange={e => setObs(e.target.value)}
                                 type="text"
                                 className="observation"
                                 placeholder={traducao.produtos.alguma_observacao}
                              />
                           </div>
                        </div>
                        : null
                  }

                  <AddCalculation
                     show={stateHolder > 0}
                     productCartData={productCartData}
                     toCart={salvarNoCarrinho}
                     cartMessage={traducao.geral.adicionar_ao_carrinho}
                  />
               </SnackData>
            </section>
         </ModalCompressed>
      </>
   );
};
