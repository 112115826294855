import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import useGetPrimary from '../../helpers/useGetPrimary';
import { Price, Desconto } from '../../styled/Text';
import useMoeda from '../../traducao/useMoeda';

export default ({
    data = {},
    title,
    price,
    showImg = true,
    children,
    showModal,
    top = false,
    desconto = {},
}) => {

    const [expand, setExpand] = useState(false);
    const moeda = useMoeda();

    const { textPrimary } = useGetPrimary();

    const windowSize = window.innerWidth;
    const heightSize = window.innerHeight;

    const style = {
        visibility: "visible",
        marginLeft: -windowSize * .4,
        marginTop: -windowSize * .4,
        top: heightSize / 2,
        left: windowSize / 2,
        width: windowSize * .8,
        height: windowSize * .8
    }

    const txtProduto = () => {
        confirmAlert({
            title: "Sobre nós",
            message: <div dangerouslySetInnerHTML={{ __html: data.txt_produto }} />,
            buttons: [
                {
                    label: "Fechar",
                    onClick: () => showModal(false)
                }
            ]
        });
    }
    return (
        <>
            {
                data.img && data.img !== "" && showImg ?
                    <figure className="pb-1 flex-3 _center">
                        <img
                            onClick={() => setExpand(!expand)}
                            className="snack-image"
                            src={data.img}
                            alt={title}
                        />
                        {
                            data.txt_produto ?
                                <span
                                    style={textPrimary}
                                    className="pointer pt-2 has-text-weight-medium produto-"
                                    onClick={txtProduto}
                                >
                                    Saber mais
                        </span>
                                : null
                        }
                        <img
                            className={`snack-big ${expand ? 'expand' : ''}`}
                            src={data.img}
                            alt={title}
                            style={expand ? style : { width: 0, height: 0, visibility: "hidden" }}
                        />
                        <div
                            onClick={() => setExpand(false)}
                            className={`expand-bg ${expand ? 'active' : 'none'}`}
                        />
                    </figure>
                    : ''
            }
            {
                (typeof data.img === 'undefined' || !data.img || !data.img === "") && showImg ?
                    <figure className="pb-1 flex-3 _center" onClick={() => setExpand(!expand)}>
                        <span className="snack-image _center material-icons-outlined">
                            camera_alt
                        </span>
                        <span
                            className={`snack-big material-icons-outlined ${expand ? 'expand' : ''}`}
                            alt={title}
                            style={expand ? style : { width: 0, height: 0, visibility: "hidden" }}
                        >
                            camera_alt
                        </span>
                        <div
                            onClick={() => setExpand(false)}
                            className={`expand-bg ${expand ? 'active' : 'none'}`}
                        ></div>
                    </figure>
                    : ''
            }
            <div className={` ${top ? 'top-m6px' : ''}
                ${data.img || showImg ?
                    'flex-5 center-justify text-left flex-column'
                    : 'flex-6'
                }`
            }
                onClick={typeof (showModal) === 'function' ? () => showModal(true) : () => null}
            >
                <h6 className="has-text-weight-bold">
                    {title}

                </h6>
                <p className="subtitle">
                    <small>
                        {data.descricao}
                    </small>
                </p>
                <p>
                    {
                        desconto.valor_tem_desconto && typeof desconto.valor_sem_desconto === 'number' ?
                            <Desconto>
                                {moeda}{desconto.valor_sem_desconto.toFixed(2)}
                            </Desconto>
                            : null
                    }
                    {
                        price && price !== "" ?
                            <Price bold={desconto.valor_tem_desconto}>
                                {moeda}{price.toFixed(2)}
                            </Price>
                            : null
                    }
                </p>
            </div>

            { children}
        </>
    );
}