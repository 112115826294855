import React from 'react';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import InputForm from '../forms/InputForm';
import RadioInput from '../forms/inputs/RadioInput';

export default ({
    data,
    setProductCartData,
    productCartData,
}) => {

    const traducao = useConteudoTraduzido();

    return(
        data && data.tipo_massa && Array.isArray(data.tipo_massa) ?
        <InputForm title={ traducao.produtos.tipo_massa }>
            
            {
                data.tipo_massa.map( massa => (
                    <RadioInput 
                        key={massa.tipo_massa} 
                        title={ traducao.produtos.tipo_massa }
                        data={{
                            label: massa.tipo_massa.nome,
                            title: massa.tipo_massa.nome,
                            id: massa.id,
                            price: massa.valor,
                        }}
                        getValue={
                            val => setProductCartData(
                                productCartData = { 
                                    ...productCartData, 
                                    massa: val 
                                }
                            )
                        }
                    />   
                ))
            }      
        </InputForm>
        : ''
    );
}