import React, { useState } from 'react';
import SnackData from '../../forms/SnackData';
import BebidasAdd from '../../forms/inputs/BebidasAdd';
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial';

export default ({ data = {} }) => {

    const carrinhoInicial = useCarrinhoInicial( 'bebidas', data );

    const [ showModal, setShowModal ] = useState(false);

    const [ productCartData, setProductCartData ] = useState( carrinhoInicial );

    return(
        <div className="forms options-open-modal border-bottom">
        <section className="space-between center-align">
        
            <SnackData 
                key={ data.id }
                data={ data }
                price={ data.valor }
                desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                title={ data.nome }
                showImg={ true }
                showModal={ val => setShowModal(val) }
            >
                <BebidasAdd
                    productCartData={ productCartData }
                    setProductCartData={ val => setProductCartData(val) }
                    data={ data }
                    setShowModal={ val => setShowModal(val) }
                    show={ showModal }
                    hasModal={ false }
                />
            </SnackData>
        

        </section>
    </div>
    );
}