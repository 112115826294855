import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { confirmAlert } from 'react-confirm-alert';
import { getBebidas, getCategoria } from '../helpers';
import { getPergBebidas, getPergCategoria } from '../json';
import { useSelector } from "react-redux";

export default () => {

    const { perg_bebida, perg_categoria, perg_id_categoria } = useSelector( state => state.companyData.data.payload );
    const { cart } = useSelector( state => state.cartIten);

    const history = useHistory();
    const [ holder, setHolder ] = useState( false );

    useEffect(() => {

        const bebidas = getBebidas(cart);

        const path = getPergBebidas();

        if ( perg_bebida === true  && bebidas.length < 1 && path ) {
            
            confirmAlert({
                title: "Você não incluiu nenhuma bebida, deseja incluir?",
                buttons: [
                    {
                        label: "Não",
                        onClick: () => null
                    }, {
                        label: "Sim",
                        onClick: () => history.push(path)
                    }
                ]
            });
        }
        // eslint-disable-next-line
    }, [holder]);

    useEffect(() => {

        const itenNaCategoria = getCategoria( cart, perg_id_categoria );

        const categoria = getPergCategoria();

        if ( perg_categoria === true  && itenNaCategoria < 1 && categoria ) {

            const path = categoria.caminho !== null ? categoria.caminho : "/";
            
            confirmAlert({
                title: `Você não incluiu ${ categoria.categoria }, deseja incluir uma?`,
                buttons: [
                    {
                        label: "Não",
                        onClick: () => {
                            setHolder( true );
                        }
                    }, {
                        label: "Sim",
                        onClick: () => history.push(path)
                    }
                ]
            });
        }
        // eslint-disable-next-line
    }, []);

}