import React from 'react';

export const Success = (
   props = {
      success: {
         show: false,
         success: true,
         title: "",
      },
      setSuccess: () => null,
   }
) => {

   React.useEffect(() => {
      if (props.success.show) {
         setTimeout(() => {
            props.setSuccess({ ...props.success, show: false });
         }, 4500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.success]);

   return (
      <aside className={`dialog-alert ${props.success.show ? 'active' : ''}`}>
         <p className={`message ${props.success.success ? 'is-success' : ''}
         ${typeof props.success.success !== 'undefined' && props.success.success === false ? 'is-danger' : ''}`}>
            {
               props.success.success === true ?
                  props.success.title
                  :
                  props.success.title
            }
         </p>
      </aside>
   );
};
