import React from 'react';
import { calculatePriceItem, calculatePrice } from '../../helpers';
import useGetPrimary from '../../helpers/useGetPrimary';
import useMoeda from '../../traducao/useMoeda';

export default ({
    show,
    productCartData,
    cartMessage = "Adicionar ao carrinho",
    toCart,
}) => {

    const { textPrimary, bgPrimary } = useGetPrimary();
    const moeda = useMoeda();

    return (
        <div className="flex-12 space-evenly" onClick={() => toCart()}>
            {
                show ?
                    <>
                        <p style={textPrimary} className="size- has-text-weight-bold">
                            { moeda }{Array.isArray(productCartData) ? calculatePrice(productCartData, true).toFixed(2) : calculatePriceItem(productCartData).toFixed(2)}
                        </p>

                        <button style={bgPrimary} type="button" className="btn size-small">
                            {cartMessage}
                        </button>
                    </>
                    : null
            }
        </div>
    );
}

