/**
 * Pega a entrea e retorna o valor
 * @param {object} entrega Objeto com os dados da entrega
 */
function valorDaEntrega ( entrega = {} ) {

    let valorEntrega;

    if ( entrega && (entrega.metodo_entrega === 'regiao' || entrega.tipo === 'regiao') ) 
        valorEntrega = entrega.regiao ? entrega.regiao.valor : 0;

    else if ( entrega && ( entrega.metodo_entrega === 'km' || entrega.metodo_entrega === 'fixo' || entrega.tipo === 'fixo' ) ) 
        valorEntrega = entrega.km ? entrega.km.valor : 0;
    
    else 
        valorEntrega = 0;

    return valorEntrega;
}

export default valorDaEntrega;
