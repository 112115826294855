/**
 *  Este arquivo irá perguntar se é para entregar ou para buscar no local.
 *  Caso o estabelecimento permita a entrega e o usuário escolha essa opção,
 *  ele irá abrir um modal com os endereços cadastrados na conta do usuário.
 */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AdicionarNovoEndereco from './modals/AdicionarNovoEndereco'
import RadioInputControlled from '../forms/inputs/RadioInputControlled'
import { deliveryMethod, setClienteAll } from '../../actions'
import { TitlePrimary } from '../../styled/Titles'
import DadosEntrega from './DadosEntrega'
import { ContainerMargin } from '../../styled/Containers.tsx'
import { getClienteJson } from '../../json'
import useGetPrimary from '../../helpers/useGetPrimary'
import { Erro } from '../../styled/Text'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import ModalForm from '../forms/ModalForm'
import { Success } from '../modal/Success'

const RetirarBalcao = ({
   data = {},
   show = false,
   setShow = () => null,
   setDeliver = () => null,
   deliver = {},
   enderecos = [],
}) => {
   const traducao = useConteudoTraduzido()

   const escolherEFechar = (val, endereco, id) => {
      setDeliver({ ...val, endereco, id_endereco: id })
   }

   return (
      enderecos.length > 1 ?
         <ModalForm
            data={data}
            show={show}
            setShowModal={() => setShow(false)}
            closeIcon={true}
         >
            {
               enderecos?.map((endereco) => (
                  <RadioInputControlled
                     title={traducao.geral.entrega}
                     selected={deliver}
                     selected_verify='id_endereco'
                     data={{
                        label: `${endereco?.endereco ?? ''}, ${endereco?.numero ?? ''}, ${endereco?.bairro ?? ''}`,
                        id: "balcao",
                        // subtitle: `${endereco.endereco}, ${endereco.numero}, ${endereco.bairro}`,
                        id_endereco: endereco.id,
                     }}
                     getValue={(val) => escolherEFechar(
                        val,
                        `${endereco?.endereco}, ${endereco?.numero}, ${endereco?.bairro}`,
                        endereco.id
                     )}
                  />
               ))
            }
         </ModalForm>
         :
         <div
            onClick={() => escolherEFechar(
               {
                  id: 'balcao',
                  endereco: `${enderecos[0]?.endereco ?? ''}, ${enderecos[0]?.numero}, ${enderecos[0]?.bairro}`,
                  id_endereco: enderecos[0].id
               },
               `${enderecos[0]?.endereco}, ${enderecos[0]?.numero}, ${enderecos[0]?.bairro}`,
               enderecos[0].id
            )}
         >
            {
               enderecos[0] &&
               <p>
                  {enderecos[0].endereco}, {enderecos[0].numero} - {enderecos[0].bairro}
               </p>
            }
         </div>
   )
}

export default ({ erro = false }) => {

   const dispatch = useDispatch()
   const traducao = useConteudoTraduzido()

   const { cor_padrao } = useGetPrimary()

   const [success, setSuccess] = React.useState({ show: false, success: true, title: "" })

   // Busca as opções de entrega
   const { entrega, endereco } = useSelector(state => state.companyData.data.payload)
   // Busca os dados de entrega do usuário
   const entregarDados = useSelector(state => state.userData && state.userData.entrega ? state.userData.entrega : {})
   // Busca o metodo de entrega do usuário, se tiver
   const { metodo_entrega } = useSelector(state => state.userData ? state.userData : {})
   // Abre o modal com mais endereços
   const [openNewAddress, setOpenNewAddress] = useState(false)
   // Pega o metodo de entrega
   const [deliver, setDeliver] = useState({ id: metodo_entrega })
   // Os dados do usuário vindo do servidor
   const user = useSelector(state => state.cliente)

   const [cliente, setCliente] = useState({})
   const [balcao, setBalcao] = useState(false)

   const adicionarOuAbrirBalcao = () => {
      if (endereco && endereco.length > 1) {
         setBalcao(true)
      } else {
         setDeliver({
            id: 'balcao',
            endereco: `${endereco[0]?.endereco ?? ''}, ${endereco[0]?.numero}, ${endereco[0]?.bairro}`,
            id_endereco: endereco[0].id
         })
      }
   }

   // Faz a ação para abrir o modal e setar o metodo para "entrega"
   const deliveryAction = () => {
      setOpenNewAddress(true)
      setDeliver({ id: "entrega" })
   }
   // Altera o metodo de entrega no redux toda vez que é mudado o estado "deliver"
   useEffect(() => {
      dispatch(deliveryMethod(deliver))
   }, [deliver, dispatch])

   useEffect(() => {
      dispatch(setClienteAll(cliente))
      // eslint-disable-next-line
   }, [cliente])

   useEffect(() => {
      setCliente(getClienteJson())
   }, [])

   return (
      <ContainerMargin as="section" top={erro.entrega || erro.endereco ? 0 : 1.5}>
         <TitlePrimary as="h6" uppercase primary={cor_padrao} className={erro.entrega || erro.endereco ? 'has-text-danger' : ''}>
            {traducao.geral.entrega}
         </TitlePrimary>
         <Success
            success={success}
            setSuccess={setSuccess}
         />

         {
            erro.entrega || erro.endereco ?
               <Erro>
                  {erro.entrega}
                  {erro.endereco}
               </Erro>
               : null
         }
         <div>
            {
               // Verifica se já existe "entrega" no componente vindo da api e se permite entregar
               entrega && entrega.permitir_entrega ?
                  <div>
                     {/* O radio Button para selecionar essa opção */}
                     <RadioInputControlled
                        title={traducao.geral.entrega}
                        data={{
                           label: entregarDados && entregarDados.nome ? entregarDados.nome : "Entregar no endereço",
                           id: 'entrega'
                        }}
                        selected={deliver}
                        getValue={() => deliveryAction()}
                     />
                     <div className="flex-column" onClick={() => deliveryAction()}>

                        {/* Se já tiver no redux uma opção de entrega selecionada, então mostramos aqui */}
                        <DadosEntrega finalizar={false} mostrar_metodo={false} />

                     </div>
                     {
                        // Já foi carregado user.enderecos? Então aqui é o modal para eles
                        user ?
                           <>
                              <AdicionarNovoEndereco
                                 show={openNewAddress}
                                 setShow={val => setOpenNewAddress(val)}
                                 userAddressData={user.enderecos && Array.isArray(user.enderecos) ? user.enderecos : []}
                                 setSuccess={setSuccess}
                              />
                           </>
                           : ''
                     }
                  </div>
                  : null // Caso não tenha carregado a opção de entrega ou não permita entregas, retorna nulo
            }{
               // Permite pegar no balcão? Se sim, mosta um radio button para selecionar essa opção
               entrega && entrega.permitir_balcao ?
                  <>
                     <RadioInputControlled
                        title={traducao.geral.entrega}
                        selected={deliver}
                        selected_verify="id"
                        data={{
                           label: traducao.geral.retirar,
                           id: "balcao",
                           subtitle: `Escolhe o local de retirada`
                        }}
                        getValue={adicionarOuAbrirBalcao}
                     />
                     <RetirarBalcao
                        setDeliver={setDeliver}
                        setShow={setBalcao}
                        data={{ title: 'Onde você gostaria de retirar seu pedido?' }}
                        deliver={deliver}
                        show={balcao}
                        enderecos={endereco}
                     />
                     <hr />
                  </>
                  : null
            }{
               // Permite consumir no local? Se sim, mosta um radio button para selecionar essa opção
               entrega && entrega.permitir_consumo_local ?
                  <>
                     <RadioInputControlled
                        title={traducao.geral.entrega}
                        selected={deliver}
                        data={{
                           label: traducao.geral.consumir,
                           id: "local"
                        }}
                        getValue={val => setDeliver(val)}
                     />
                     <hr />
                  </>
                  : ''
            }
         </div>
      </ContainerMargin>
   )
}