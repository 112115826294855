/**
 * Verifica se a quantidade de acomponhamentos exede o limite
 * @param {object[]} carrinho o produto
 * @param {object[]} qtdMax a qauntidade de acompanhamentos
 */
function verificaQuantidadeMaxima( carrinho, qtdMax ) {
    
    if ( carrinho[0] && carrinho[0].acompanhamento ) {
        let qtd = {};
        
            carrinho[0].acompanhamento.forEach(prod => {

                qtdMax.forEach( max => {

                    if ( prod.cat === max.nome ) {

                        qtd[max.nome] = qtd[max.nome] ? qtd[max.nome] + prod.qtd : prod.qtd;

                    }
                })
            });
        return qtd;
    }
}

export default verificaQuantidadeMaxima;