/**
 * Um polyfill para normalize
 * @param {string} txt texto para ser normalizado
 */
function normalize( txt ) {
    if ( txt.normalize && typeof txt.normalize === 'function' )
        return txt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    else 
        return txt;
}

export default normalize;