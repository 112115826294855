/**
 * The same of findDepartment, but here tou can define the id
 * name at you own wish, was created thinking in id_adicional
 * 
 * @param {int} findId the id we wanna find
 * @param {object} departments the departments the id can be in
 * @param {string} toFind the id name
 */                                 
const findId = (findId, departments, toFind = "id_adicional") => {
    // loop the departments
    for (let i in departments) {
        // check if the id exists
        if (parseInt(departments[i][toFind]) === parseInt(findId))
            return i; // returns the index
    }
    // returns false
    return false;
}

// exports the hardwork
export default findId;