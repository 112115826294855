import { CarrinhoInicial } from "./CarrinhoInicial"

export class PromoItensBordas {
    bordas = []
}

export class PromoItensConjunto {
    /**
     * @type PromoItensBordas[]
     */
    produto = []
}

export class PromoItens {
    /** 
    * @type PromoItensConjunto[]
    */
    conjuntos = []
}

export class PromoPizzaCarrinhoItens {
    /**
     * @type any
     */
    borda

    index = 0

    sabor = []

    nome = ""
}

export class PromoPizzaCarrinho extends CarrinhoInicial {
    /**
     * @type PromoPizzaCarrinhoItens[]
     */
    itens = []
}

export class PizzaDataProps {
    data = {}
    setShowModal
    showModal
    arrayProdutos
    produtoIndex

    /**
     * @type PromoPizzaCarrinho
     */
    productCartData = PromoPizzaCarrinho

    index = 0

    setProductCartData = () => { }
    toNext = () => { }
}

export class ProximoModal {
    valor = 0
    produtoIndex = 0
    conjunto = 0
    borda = false
    tem_borda = false
}