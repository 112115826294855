import React from 'react';
import { telefoneMask } from '../../../mascara/telefone';

export default ({ 
    title, 
    complement, 
    placeholder, 
    classes,
    setPhone,
    phone,
    invalid
}) => {


    function allowSubmit( e ) {
        
        // eslint-disable-next-line 
        const number = /[0-9]|\(|\)|\-\+/;

        if (e.match(number)) {

            const mascarado = telefoneMask(e);
            setPhone( mascarado );
        }
        else if (e === "")
            setPhone(e);
    }

    return(
        <div>
            <h6 className={`size-normal-small text-grey has-text-weight-bold ${classes}`}>
                {
                    title
                }
                <small className="has-text-weight-medium is-lowercase">{complement}</small>
            </h6>

            <input 
                type="tel"
                id="phone"
                value={ phone }
                onChange={ e => allowSubmit( e.target.value ) }
                className={`input ${invalid ? 'invalid' : ''}`} 
                placeholder={ placeholder } 
            />
        </div>
    );
}