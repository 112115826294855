import React, { useRef } from 'react'
import useGetPrimary from '../../../helpers/useGetPrimary'
import { TextoPequeno } from '../../../styled/Text'
import ModalForm from '../../forms/ModalForm'


export default ({ show, setShow, pix }) => {

    const ref = useRef(null)
    const { cor_padrao } = useGetPrimary()

    const data = { title: "Chave pix" }

    const copiarPix = () => {
        if (ref.current) {

            ref.current.select()
            ref.current.setSelectionRange(0, 99999)
            document.execCommand("copy")
        }
    }

    return (
        <ModalForm data={data} show={show} setShowModal={setShow} closeIcon={false}>

            <TextoPequeno>
                Para realizar o pagamento por pix, clique no icone
                <span className="material-icons" style={{ fontSize: '1.3rem' }}>content_copy</span>
                para copiar a chave e cole ela no formulario de pagamento de seu banco
            </TextoPequeno>

            <div className="field has-addons">
                <div className="control">
                    <input ref={ref} type="text" className="input" readOnly value={pix} />

                </div>
                <div className="control">
                    <button style={{ color: cor_padrao, border: 'none' }} className="button" type="button" onClick={copiarPix}>
                        <span className="material-icons">content_copy</span>
                    </button>
                </div>
            </div>
        </ModalForm>
    )
}