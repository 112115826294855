import React from 'react';
import { replacer } from '../../../helpers';
import { FullWidthContainer } from '../../../styled/Containers.tsx';
import { Label } from '../../../styled/Label';
import { Price } from '../../../styled/Titles';
import useMoeda from '../../../traducao/useMoeda';

/**
 *  Radio input controlado pelo componente pai
 * 
 *  @param {object} data pode conter label, subtitle ou price 
 *  @param {string} title o título do campo 
 *  @param {function} getValue o valor de retorno do campo
 *  @param {object} product o produto a ser retornado por getValue
 *  @param {boolean} selected a verificação se o campo foi selecionado 
 */
export default ({
    data,
    title,
    getValue,
    product = {},
    selected,
    selected_verify="id"
}) => {
    // Tranforma title num nome valido para id ou outras propriedades
    const name = replacer(title);
    // O value do campo input (O random é para casos que tenham dois com o mesmo valor)
    const value = replacer(data.label) + (parseInt(Math.random() * 550));

    const moeda = useMoeda();

    // Coloca o nome em lowercase em caso de vir todo uppercased
    let nome = data.label?.toLowerCase();
    // Tira espaços descenessários de nome
    nome.trim();

    return (
        <FullWidthContainer>
            <Label
                onClick={() => getValue({
                    title: data.label,
                    id: data.id,
                    ...product
                })}
            >
                <p className="first-capital flex-11">
                    {nome}
                </p>
                <p className="_center mb-m5px">
                    <input
                        checked={selected && selected[selected_verify] === data[selected_verify]}
                        readOnly
                        type="radio"
                        name={name}
                        value={value}
                        className={`${selected && selected[selected_verify] === data[selected_verify] ? 'checked' : ''}`}
                    />

                    <span></span>
                </p>
            </Label>
            {
                data.subtitle ?
                    <small className="mini-title mt-0">
                        {data.subtitle}
                    </small>
                    : null
            }
            {
                data.price ?
                    <Price>
                        {moeda}{parseFloat(data.price).toFixed(2)}
                    </Price>
                    : null
            }
        </FullWidthContainer>
    );
}