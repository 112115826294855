/**
 * The same of findDepartment, but here tou can define the id
 * name at you own wish, was created thinking in id_adicional
 * 
 * @param {int} findId the id we wanna find
 * @param {object} departments the departments the id can be in
 * @param {string} toFind the id name
 */                                 
const findId = ( produto, promoPizza = false ) => {
    // loop the produto
    for (let i in produto) {
        // check if the id exists
        if (parseInt( produto[i].qtd) === 0 || ( promoPizza === false && produto[i].qtd === undefined ) )
            return i; // returns the index
    }
    // returns false
    return false;
}

// exports the hardwork
export default findId;