/**
 * When the pizza sabor is chosen, we send the user to the end of the page
 * 
 * @param {string} id 
 */
function rollPage(id, to) {
    // find the element
    const element = document.querySelector("#"+id);
    const goTo = document.querySelector("#"+to);
    // get its height and multiply 
    // (we want to be sure the user will go to the end of page)
    const size =  goTo.offsetTop + 55;
    // roll to bottom smoothly
    if ( typeof element.scrollTo === 'function' )
    element.scrollTo({top: size, behavior: "smooth"});
}
// everything done
export default rollPage;