/**
 * 
 * @param {object} produto 
 */
function dadosAdicionais(produto) {
    if ((produto.tipo === "promo_geral" || produto.tipo === "promo_pizza") && !produto.descritiva && produto.itens) {
        let itensArray = Object.keys(produto.itens).map(i => {

            let saboresArray = [];

            if (produto.itens[i].sabor)
                saboresArray = produto.itens[i].sabor.map(sabor => `1/${produto.itens[i].sabor.length} ${sabor.nome}`);

            let sabores = saboresArray.length > 0 ? "<strong>sabores:</strong> \n  " + saboresArray.join("\n  ") + "\n" : '';

            let borda = produto.itens[i].borda && produto.itens[i].borda.nome ? " <strong>borda:</strong> " + produto.itens[i].borda.nome : '';

            return (
                `<strong>${produto.itens[i].nome
                }</strong> ${produto.itens[i].observacao && produto.itens[i].observacao.length > 0 ?
                    `${sabores} ${borda} (${produto.itens[i].observacao})`
                    : sabores + borda
                }${`<em>${produto.observacao ? 'Obs: ' + produto.observacao : ''}</em>`
                }`
            );
        });

        let itens = itensArray.join("\n");
        return itens;
    }

    if (
        (
            produto.adicionais
            && produto.adicionais.length > 0
        )
        ||
        (
            produto.acompanhamento
            && produto.acompanhamento.length > 0
        )
    ) {


        const adic = produto.adicionais ? produto.adicionais.map(ad => `${ad.nome} ${ad.qtd ? ad.qtd : 1}x\n`) : [];

        const acomp = produto.acompanhamento ? produto.acompanhamento.map(ac => `${ac.nome} ${ac.qtd ? ac.qtd : 1}x\n`) : [];

        const obs = produto.observacao && produto.observacao.length > 1 ? "<strong>Observação:</strong> \n" + produto.observacao : "";

        return adic.join("") + acomp.join("") + obs;
    }
    return produto.observacao ? `<strong>observação:</strong> ${produto.observacao}` : "";
}

export default dadosAdicionais;