// eslint-disable-next-line no-unused-vars
import { PromoItens, ProximoModal } from "../../../typing/PizzaPromo"

/**
 * * Não tem borda, quando proximo é um number
 * @param {number} proximo 
 * @param {PromoItens} promoItens 
 * @returns boolean
 */
export const naoEhBorda = (proximo, promoItens) => {
    /**
     * * O proximo produto do conjunto
     */
    const conjuntoProximo = promoItens?.conjuntos[proximo]

    return (
        typeof proximo === 'number' // o proximo é um número?
        && conjuntoProximo?.tp_conjunto === 'pizza' // esse conjunto é de pizza?
    )
}

/**
 * * Nao tem borda, quanto proximo é objeto
 * @param {string | number | ProximoModal} proximo 
 * @param {PromoItens} promoItens 
 * @returns boolean
 */
export const naoEhBordaObjeto = (proximo, promoItens) => {

    const conjuntoProximoValor = promoItens?.conjuntos[proximo.valor]

    const bordaIndefinidaOuVerdadeiro =
        typeof proximo.tem_borda === 'undefined'
        || proximo.tem_borda === true

    return (
        typeof proximo === 'object' // o proximo é um objeto?
        && conjuntoProximoValor?.tp_conjunto === 'pizza' // esse conjunto é de pizza?
        && !proximo.borda
        && bordaIndefinidaOuVerdadeiro
    )
}

/**
 * * Tem borda, quando proximo é string
 * @param {string | number | ProximoModal} proximo 
 * @param {boolean} sabor 
 * @returns boolean
 */
export const estaNaBorda = (proximo, sabor) => {
    return (
        typeof proximo === 'string' // proximo é uma string?
        && proximo.charAt(0) === "b"  // o primeiro caracter é um b?
        && sabor === false // e não é um sabor?

    )
}

/**
 * * Tem borda, quando proximo é objeto
 * @param {string | number | ProximoModal} proximo 
 * @param {boolean} sabor 
 * @returns boolean
 */
export const estaNaBordaObjeto = (proximo, sabor) => {
    return (
        typeof proximo === 'object' // o proximo é um objeto?
        && sabor === false
        && proximo.borda
    )
}

export const validacaoBordaFinal = (proximo, productCartData, showModal) => {
    return (
        typeof proximo === 'object' // proximo é um objeto
        && (showModal.borda === true || showModal.tem_borda === false) // ou tem ou nao tem borda
        && productCartData.itens && productCartData.itens.length > 0 // tem itens
    )
}