import styled from 'styled-components';

export const ButtonIcone = styled.button`
    width: 100%;
    height: 100%;
    border: none;
    background: rgba(255,255,255,.3);
    cursor: pointer;
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;


export const Select = styled.input`
    &:checked+span:after,  &.checked+span:after {
    background: ${(props) => props.dark ? '#000' : '#4f4f4f'} !important;
    }
`;