import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import Header from "./routes/headerRoute";
import "./app.sass";
import "react-confirm-alert/src/react-confirm-alert.css";
import AddToCart from "./components/request/AddToCart";
import Loading from "./components/general/Loading";
import useInitApp from "./hooks/useInitApp";
import { ContainerBackground, ContainerCenteredAll } from "./styled/Containers";
import { MiniTitle } from "./styled/Titles";
import useGetPrimary from "./helpers/useGetPrimary";
import useConteudoTraduzido from "./traducao/useConteudoTraduzido";
import happy_chicekns from "./img/happy_chicekns.jpg";
import "./fcm";
import { getCompanyId } from "./helpers";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "styled-components";
import { GlobalTheme } from "./theme";

export const App = () => {
  const { img, scrollbar, redirect_menuflow, data } = useInitApp();
  const traducao = useConteudoTraduzido();
  const { cor_padrao } = useGetPrimary();

  useEffect(() => {
    if (redirect_menuflow) {
      window.location.href = redirect_menuflow;
    }
  }, [redirect_menuflow]);

  if (data.nome) {
    return (
      <ThemeProvider theme={GlobalTheme(cor_padrao)}>
        <Helmet
          htmlAttributes={{
            lang:
              data.idioma === null || data.idioma === "br"
                ? "pt-br"
                : data.idioma,
          }}
        >
          <meta charSet={"utf-8"} />
          <title>{data.nome}</title>
          <link rel="icon" href={data.logo} />
        </Helmet>
        <style>
          .react-confirm-alert-button-group button:first-child,
          .react-confirm-alert-button-group button:only-child{" "}
          {`{
									border: 1px solid ${cor_padrao};
									background: #fffff !important;
									color: ${cor_padrao} !important;
								}`}
          .react-confirm-alert-button-group button:nth-child(2){" "}
          {`{
									background: ${cor_padrao} !important;
									color: #fffff;
								}`}
          .react-confirm-alert h1{" "}
          {`{
								color: ${cor_padrao} !important;
							}`}
        </style>
        <div
          className={`App ${scrollbar ? "allow-scroll" : "disallow-scroll"}`}
        >
          <Corpo traducao={traducao} img={img} data={data} />
        </div>
      </ThemeProvider>
    );
  }

  return <Loading inicial />;
};

const Corpo: React.FC<{ data: any; traducao: any; img: string }> = ({
  data,
  traducao,
  img,
}) => {
  const { bgPrimary } = useGetPrimary();

  if (data.ativo !== false && getCompanyId !== "7242") {
    return (
      <BrowserRouter>
        <Header />
        <ContainerBackground img={img} />
        <div className="_content">
          <Routes />
        </div>
        <AddToCart buttonMessage={traducao.geral.adicionar_ao_carrinho} />
        <div className="after-add-to-cart" />
      </BrowserRouter>
    );
  } else if (getCompanyId !== "7242") {
    return (
      <article
        className="finish has-text-white has-text-centered"
        style={bgPrimary}
      >
        <ContainerCenteredAll>
          <MiniTitle
            as="h2"
            color="#fff"
            className="text-weight-bold size-normal"
          >
            {traducao.validacoes.entrar_contato_alphi}
          </MiniTitle>
          <p>{data.txt_desativar ? data.txt_desativar : ""}</p>
        </ContainerCenteredAll>
      </article>
    );
  } else {
    return (
      <ContainerCenteredAll>
        <img src={happy_chicekns} alt="Não disponivel" />
      </ContainerCenteredAll>
    );
  }
};
