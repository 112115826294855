import React from 'react'
import { useSelector } from 'react-redux'
import { MiniTitle, Price } from '../../styled/Titles'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import useMoeda from '../../traducao/useMoeda'

const MostrarDado = props => (
   props.dado ?
      <p className="has-text-grey size-small">{props.desc}{props.dado}</p>
      : null
)

export default ({ mostrar_metodo = false, finalizar = true }) => {

   const { metodo_entrega, entrega } = useSelector(state => state.userData ? state.userData : {})
   const tipoEntrega = useSelector(state => state.companyData.data.payload.entrega.tipo)
   const { endereco } = useSelector(state => state.companyData.data.payload)

   const traducao = useConteudoTraduzido()
   const moeda = useMoeda()

   return (
      <div>
         {
            mostrar_metodo ?
               <>
                  <MiniTitle size={.95} className="pr-1">
                     {traducao.geral.dados_entrega}
                  </MiniTitle>
                  <MiniTitle uppercase="uppercase" bold mt={5} size={.95}>
                     {metodo_entrega}
                  </MiniTitle>
               </>
               : null
         }{
            metodo_entrega === "entrega" ?
               entrega ?
                  <div>
                     <p className="has-text-grey size-small">
                        {entrega.endereco},{entrega.numero}
                     </p>

                     <MostrarDado desc={traducao.dados_usuario.regiao + ": "} dado={entrega.regiao.regiao} />

                     <p className="has-text-grey size-small">{entrega.cidade}, {entrega.estado}</p>

                     <MostrarDado desc="Complemento: " dado={entrega.complemento} />
                     <MostrarDado desc="Quadra: " dado={entrega.quadra} />
                     <MostrarDado desc="Lote: " dado={entrega.lote} />
                     {
                        tipoEntrega !== 'regiao' ?
                           <MostrarDado
                              desc="Distância: "
                              dado={
                                 entrega.km && typeof entrega.km.km === 'number' ?
                                    entrega.km.km.toFixed(2) + "km"
                                    : false
                              }
                           />
                           : null
                     }

                     {
                        tipoEntrega === 'regiao' ?
                           entrega.regiao ?
                              <>
                                 <Price
                                    style={{
                                       fontSize: entrega.regiao?.oldValor ? '.9rem' : '.85rem'
                                    }}
                                    className={entrega.regiao?.oldValor ? 'has-text-weight-bold' : ''}
                                 >
                                    {moeda}{entrega.regiao?.valor?.toFixed(2) || "0,00"}
                                 </Price>
                                 {
                                    entrega.regiao.oldValor ?
                                       <strong
                                          style={{ fontSize: '.85rem', textDecoration: 'line-through' }}
                                          className="text-danger has-font-weight-bold pl-1"
                                       >
                                          {moeda}{entrega.regiao?.oldValor?.toFixed(2) || "0,00"}
                                       </strong>
                                       : null
                                 }
                              </> : null
                           :
                           entrega.km && typeof (entrega.km.valor) === 'number' ?
                              <>
                                 <Price
                                    className={entrega.km?.oldValor ? 'has-text-weight-bold' : ''}
                                 >
                                    {moeda}{entrega.km.valor.toFixed(2)}
                                 </Price>
                                 {
                                    entrega.km.oldValor ?
                                       <strong
                                          style={{ fontSize: '.85rem', textDecoration: 'line-through' }}
                                          className="text-danger has-font-weight-bold pl-1"
                                       >
                                          {moeda}{entrega.km?.oldValor?.toFixed(2) || "0,00"}
                                       </strong>
                                       : null
                                 }
                              </>
                              : null
                     }
                  </div>
                  : null
               : null
         }
         {
            metodo_entrega === "balcao" ?
               <div>
                  {
                     endereco[0] && finalizar &&
                     <p>
                        {endereco[0].endereco}, {endereco[0].numero} - {endereco[0].bairro}
                     </p>
                  }
               </div>
               : null
         }
         <hr />
      </div>
   )
}