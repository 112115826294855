import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { userSetAll } from "../actions";
import { getUserData } from "../json";
import useConteudoTraduzido from "../traducao/useConteudoTraduzido";
import useMoeda from "../traducao/useMoeda";

export function useConfirmationVariaveis() {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();
    const moeda = useMoeda();

    const [desabilitar, setDesabilitar] = useState(false);
    const [submitErro, setSubmitErro] = useState(null);
    
    const history = useHistory();

    useEffect(() => {
        const data = getUserData();

        dispatch(userSetAll(data));
        // eslint-disable-next-line
    }, []);

    const { cart } = useSelector(state => state.cartIten);
    const preferences = useSelector(state => state.userData);
    const { 
        canal, como_cobrar_pizza, 
        tx_pizza_sabores, pagamento, 
        pergunta_final_texto, pergunta_final,
        pergunta_final_sim_nao, avaliacao, 
        desc_tx_proc, desc_tx_proc_vl,
        desc_tx_entrega
    } = useSelector(state => state.companyData.data.payload);


    return {
        traducao,
        moeda,
        desabilitar, setDesabilitar,
        submitErro, setSubmitErro,
        history,
        cart, preferences,
        canal, como_cobrar_pizza, tx_pizza_sabores, 
        pagamento, pergunta_final_texto, pergunta_final,
        pergunta_final_sim_nao, avaliacao,
        desc_tx_proc, desc_tx_proc_vl,
        desc_tx_entrega
    }
}