const portugues = {
    dados_usuario: {
        nome_endereco: "Nome do endereço",
        regiao: "Região",
        endereco: "Endereço",
        numero: "Número",
        complemento: "Complemento",
        bairro: "Bairro",
        cidade: "Cidade",
        sua_cidade: "Sua cidade",
        estado: "Estado",
        seu_estado: "Seu estado",
        cadastrar_novo_endereco: "Cadastrar novo endereço",
        nome_cartao: "Nome do cartão",
        numero_cartao: "Número do cartão",
        vencimento_cartao: "Vencimento",
        codigo_cartao: "Código",
        taxa_entrega: "Taxa de entrega",
        acrescimo_pagamento: "Acrescimo do pagamento",
        desconto_pagamento: "Desconto do pagamento",
        desconto_cupom: "Desconto do cupom",
        pagamento: "Pagamento",
        troco: "Troco para",
        nome_cliente: "Nome",
        telefone: "Telefone",
        nao_precisa_troco: "Não precisa de troco",
        numero_de_telefone: "Número de telefone",
        seu_numero: "Seu numero",
        sem_nome: "Sem nome",
        pesente: "Presente",
        desejo_enviar_presente: "Desejo enviar como presente",
        de: "De",
        para: "Para",
        mensagem: "Mensagem",
        outras_informacoes: "Outras informacoes",
        cadastratar_endereco: "Cadastrar novo endereço",
        agendar_horario: "Agendar horário",
        desejo_agendar: "Desejo agendar horário",
        hora: "Hora",
        nome_completo: "Nome completo",
        metodo_de_entrega: "Método de entrega",
        cep: "CEP",
        desconto_compras: "Compras maiores de "
    },
    validacoes: {
        entrar_contato_alphi: "Esse Link está desativado, entre em contato com o estabelecimento para obter maiores informações.",
        enderecos_encontrados: "Endereços encontrados",
        enderecos_similares: "Encontramos {%s} endereços similares, qual deles é o seu?",
        confirme_endereco: "Confirme seu endereço",
        dia_valido: "Coloque um dia válido",
        horario_valido: "Defina um horário válido",
        periodo_valido: "Defina um periodo válido",
        definir_horario: "Definir horário",
        endereco_invalido: "Endereço inválido",
        numero_invalido: "Número inválido",
        bairro_invalido: "Bairro inválido",
        cidade_invalido: "Cidade inválida",
        estado_invalido: "Estado inválido",
        regiao_invalido: "Região inválida",
        definir_nome: "Digite seu nome",
        definir_entrega: "Defina um metodo de entrega",
        definir_endereco: "Defina um endereço para a entrega",
        definir_pagamento: "Defina um metodo de pagamento",
        precisa_completar: "Você precisa completar",
        verifique: "Verifique os campos",
        maximo_items: "Você já adicionou o máximo de itens desse tipo",
        recarregue_sua_pagina: "Isto está demorando muito, verifique sua conexão e recarregue a página se necessário",
    },
    geral: {
        manha: "Manhã",
        tarde: "Tarde",
        noite: "Noite",
        dia: "Dia",
        definir_dia: "Definir dia",
        periodo: "Periodo",
        aplicavel: "(Se aplicável)",
        horario: "Horário",
        bem_vindo: "Seja bem vindo",
        observacao_pedido: "Referência",
        confirmar: "Confirmar",
        subtotal: "Subtotal",
        cupom_de_desconto: "Cupom de desconto",
        desconto_de: "Desconto de",
        codigo_promocional: "Coloque o código promocional, se tiver",
        validar_cupom: "Válidar cupom",
        dados_entrega: "Dados da entrega/retirada", 
        entrega: "Entrega",
        retirar: "Retirar no local",
        consumir: "Consumir no local",
        retorno_whatsapp: "Olá, acabei de fazer um Pedido pelo aplicativo virtual.",
        realizado_com_sucesso: "Pedido realizado com sucesso!",
        em_breve_recebera: "Em breve você receberá seu pedido",
        acompanhar_pelo_icone: " A Qualquer momento você poderá acompanhar seu pedido clicando nesse ícone em sua página principal",
        enviar_pelo_whatsapp: "Enviar meu pedido pelo WhatsApp",
        voltar_cardapio: "Voltar ao cardápio",
        cnpj: "CNPJ",
        email: "Email",
        pagamento: "Pagamento",
        pagar_online: "Pagar online",
        acrescimo_de: "Acrescimo de",
        cartoes_aceitos: "Cartões aceitos",
        sim: "Sim",
        nao: "Não",
        adicionar_ao_carrinho: "Adicionar ao carrinho",
        continuar: "Continuar",
        carregando: "Carregando",
        pedidos: "Pedidos",
        cupom: "Cupom",
        valor: "Valor", // não foi substituido ainda
        sem_pedidos: "Sem pedidos cadastrados",
        pesquise_o_produto: "Pesquise o produto",
        pesquise_aqui: "Pesquise aqui",
        pesquise_sua_promocao: "Pesquise sua promoção",
        cancelar: "Cancelar",
        salvar: "Salvar",
        excluir: "Excluir",
        consultar_pedidos: "Consultar meus pedidos",
        fechado: "Fechado",

    },
    produtos: {
        incluiu_o_sabor: "Você incluiu o sabor",
        deseja_incluir_mais: "Deseja incluir mais algum?",
        preco_unitario: "Preço unitário",
        quantidade: "Quantidade",
        alguma_observacao: "Alguma observação?",
        proximo: "Ir para o próximo",
        proximo_passo: "Próximo passo",
        ate_adicionais: "Até {%s} adicionais",
        personalize: "Personalize seu ",
        escolha_adicionais: "Escolha adicionais e insira observações para seus itens antes de adicionar ao carrinho ou clique em fechar para prosseguir seu pedido",
        adicionais: "Adicionais", // não colocado ainda
        bebida: "Bebida",   // não colocado ainda
        escolha_bebida: "Escolha uma bebida",
        acompanhamento: "Acompanhamentos",
        tipo_massa: "Tipo de massa",
        borda: "Borda",
        escolher_sabores: "Escolha sabores",
        refazer_pedido: "Adicionar pedido ao carrinho",
        busca_rapida: "Busca Rápida"
    }
}

export default portugues;