import React from 'react'
import {useHistory, useParams} from 'react-router'
import SnackData from '../../../forms/SnackData'
import {getCompanyId, proximoPizzaPromo} from '../../../../helpers'
import AddToCartConfirm from '../../../request/AddToCartConfirm'
import useRequiredPromo from '../../../../hooks/useRequiredPromo'
import {useDispatch, useSelector} from 'react-redux'
import BebidasAdd from '../../../forms/inputs/BebidasAdd'
import Loading from '../../../general/Loading'
import useConteudoTraduzido from '../../../../traducao/useConteudoTraduzido'
import useMoeda from '../../../../traducao/useMoeda'
import TituloProduto from '../../../general/TituloProduto'
import SwitchPizzaPromo from './SwitchPizzaPromo'
import useCarrinhoInicial from '../../../../hooks/useCarrinhoInicial'
import {TitlePrimary} from '../../../../styled/Titles'
import useRemoveScroll from '../../../../hooks/useRemoveScroll'


/**
 * * Segunda parte da promoção de pizza
 * * Representa cada item do loop
 *
 * @param {object} props The promo itens
 * @returns {React.FunctionComponent}
 */
const PizzaPromoItem = (props = {promoItens: {}}) => {
    const promoItens = props.promoItens
    const promo = promoItens ? promoItens : {}

    const moeda = useMoeda()
    const history = useHistory()
    const dispatch = useDispatch()
    const traducao = useConteudoTraduzido()
    const {department, type, cat} = useParams()
    const {cart} = useSelector((state) => state.cartIten)
    const cartDataInicial = useCarrinhoInicial('promo_pizza', promo)

    const [showModal, setShowModal] = React.useState(false)
    const [showAddToCart] = React.useState(true)
    const [productCartData, setProductCartData] = React.useState(cartDataInicial)
    const [required, setRequired] = React.useState([1])
    const [miss, setMiss] = React.useState("")


    useRemoveScroll(showModal, showAddToCart)
    useRequiredPromo(
        promoItens,
        val => setRequired(val),
        productCartData && productCartData.itens ? productCartData.itens : [],
        val => setMiss(val),
        required,
        true
    )

    React.useEffect(() => {
        setProductCartData(cartDataInicial)
        // eslint-disable-next-line
    }, [promoItens, setProductCartData])

    const toNext = () => {
        proximoPizzaPromo(
            showModal,
            setShowModal,
            cart,
            promoItens,
            productCartData,
            dispatch,
            miss,
            history,
            getCompanyId,
            department,
            type,
            cat
        )
    }

    const size = () => promoItens?.conjuntos?.length ?? 1

    return (
        promoItens ?
            <section>
                <TituloProduto {...promoItens} moeda={moeda}/>
                {
                    promoItens?.tipo !== 3 && promoItens.conjuntos ?
                        promoItens.conjuntos.map((promo, index) => {
                            promo.index = index
                            const alreadyInCart = productCartData?.itens?.filter(e => e.index === index) ?? null

                            return (
                                <div key={index}>

                                    <header className="pl-3">
                                        <TitlePrimary as="h3">
                                            {getCompanyId == "39" && promo.tp_conjunto === "pizza"
                                                ? index + 1 + ") " +ordinario(index + 1) + " pizza"
                                                :
                                                index + 1 + ") escolha uma " + promo.tp_conjunto
                                            }
                                        </TitlePrimary>
                                    </header>

                                    <SwitchPizzaPromo
                                        index={index}
                                        promo={promo}
                                        toNext={toNext}
                                        showModal={showModal}
                                        promoItens={promoItens}
                                        setShowModal={setShowModal}
                                        alreadyInCart={alreadyInCart}
                                        productCartData={productCartData}
                                        setProductCartData={setProductCartData}
                                    />
                                </div>
                            )
                        })
                        : null
                }{
                promoItens && promoItens && (promoItens.tipo === 3 || !promoItens.conjuntos) ?
                    <div className="forms options-open-modal border-bottom container">
                        <section className="space-between center-align">
                            <SnackData
                                title={promoItens.nome}
                                icon="expand_more"
                                data={promoItens}
                                price={promoItens.valor}
                                desconto={{
                                    valor_sem_desconto: promoItens.valor_sem_desconto,
                                    valor_tem_desconto: promoItens.valor_tem_desconto
                                }}
                                showModal={() => setShowModal(!showModal)}
                                aditionalTitle="Adicionais"
                            >
                                <BebidasAdd
                                    productCartData={productCartData}
                                    setProductCartData={val => setProductCartData(val)}
                                    data={promoItens}
                                    setShowModal={() => setShowModal(!showModal)}
                                    show={showModal}
                                />
                            </SnackData>
                        </section>
                    </div>
                    : null
            }

                <AddToCartConfirm
                    show={productCartData.itens && productCartData.itens.length > 0 && showAddToCart && promoItens && promoItens.tipo !== 3}
                    setShow={() => setShowModal(false)}
                    buttonMessage={
                        showModal + 1 !== size() ?
                            traducao.produtos.proximo_passo
                            : traducao.geral.adicionar_ao_carrinho
                    }
                    cart={productCartData}
                    submit={miss}
                    next={() => toNext()}
                    index={{atual: showModal, tamanho: size()}}
                    ayw={true}
                />
            </section>
            : <Loading/>
    )
}

function ordinario(numero) {
    switch (numero) {
        case 1:
            return "Primeira"
        case 2:
            return "Segunda"
        case 3:
            return "Terceira"
        case 4:
            return "Quarta"
        case 5:
            return "Quinta"
        case 6:
            return "Sexta"
        case 7:
            return "Setima"
        case 8:
            return "Oitava"
        case 9:
            return "Nona"
        case 10:
            return "Decima"
    }
}

export default PizzaPromoItem