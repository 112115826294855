import { useSelector } from "react-redux";
import espanhol from "./es";
import portugues from "./pt";

function useConteudoTraduzido() {
    
    const { idioma } = useSelector( state => state.companyData.data.payload );
    
    switch( idioma ) {
        case 'br':
            return portugues;
        case 'es':
            return espanhol;
        default:
            return portugues;
    }
}

export default  useConteudoTraduzido;