import React, { useEffect, useState } from 'react';

import useItens from '../../../hooks/useItens';
import CategoriesRow from '../../general/CategoriesRow';
import { DefaultContainer } from '../../../styled/Containers.tsx';
import MonteDois from './MonteDois';

export default () => {

	const { category, Data } = useItens();
	const [ item, setItem ] = useState( [] );

	const search = window.location.search;

	useEffect(() => {

		if ( search && search.includes( 'monte' ) && category ) {

			const inicial = search.indexOf("=");
			const fim = search.length;
			const index = parseInt( search.substring(inicial+1, fim) );
		
			setItem( [ category[index] ] );
			
		}
		// eslint-disable-next-line
	}, [category]);

	return (
		<div>
			<DefaultContainer>
				<CategoriesRow />
			</DefaultContainer>
			
			<article>
				{
					item && item.length > 0 && item[0] !== undefined ?
						item.map( ( monte, index ) => (
								<MonteDois 
									key={ index } 
									data={{
										...monte,
										adicionais: Data.adicionais ? Data.adicionais : [] 
									}} 
								/>
						))
					: "Não há produtos nessa categoria"
				}
			</article>


		</div>
	);
}