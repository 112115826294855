import findId from "./findId";
import findNull from "./findNull";
 /**
  *  Checks if already exists the adicional product and 
  *  set it or reset if has more than one
  * 
  * @param {object} element the element to check if exists value
  * @param {object[]} adicionais the array to include
  * @param {object} val the value to include
  * @param {string} idName the field to search
  * 
  * @returns {object[]} adicional
  */
const addAdicional = (element, adicionais, val, idName = "id_adicional", promoPizza = false) => {
    const alreadyExists = findId(element[idName], adicionais, idName);

    let adicional = [];

    const iguais = Array.isArray(val) ? val.filter( e => e.nome === element.nome ) : [];

    let qtd = iguais.length > 0 ? iguais.length : val.qtd;

    let holderQtd = Array.isArray(val) ? val.length : 1;

    if ( qtd === 0 && Array.isArray(val) )
        qtd = 1;

    if (val && val[0] && val[0].cat )
        element.cat = val[0].cat;

    if (alreadyExists === false) {
        adicional = [
            ...adicionais,
            { ...element, qtd, holderQtd }
        ]
    } else {
        adicionais.splice(alreadyExists, 1);
        adicional = [
            ...adicionais,
            { ...element,  qtd, holderQtd }
        ]
    }

    const isZero = findNull(adicional, promoPizza);

    if (isZero !== false) {
        adicionais.splice(isZero, 1);
        adicional = [
            ...adicionais
        ]
    }

    return adicional;
}

export default addAdicional;