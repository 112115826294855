import React from 'react'
import MonteModalCall from '../../modal/modalCallers/MonteModalCall'
import useItens from '../../../hooks/useItens'
import { useParams } from 'react-router'
import { getCompanyId } from '../../../helpers'
import CategoriesRow from '../../general/CategoriesRow'
import { DefaultContainer } from '../../../styled/Containers.tsx'
import PromoPizzaModalCall from '../../modal/modalCallers/promo/PromoPizzaModalCall'
import TituloProduto from '../../general/TituloProduto'
import MonteMinimalModalCall from '../../modal/modalCallers/MonteMinimalModalCall'

export default () => {

    const { category, id_categoria, Data } = useItens()
    const { department, type, cat } = useParams()

    return (
        <article>
            <DefaultContainer>
                <CategoriesRow />
            </DefaultContainer>

            <DefaultContainer>

                {
                    !Data.minimal ?
                        category && Array.isArray(category) ?
                            // eslint-disable-next-line
                            category.map((ct, index) => {
                                if (ct.categorias) {
                                    return (
                                        <div key={ct.nome}>
                                            <TituloProduto
                                                voltar={false}
                                                nome={ct.nome}
                                                descricao={ct.descricao}
                                            />
                                            {
                                                ct.categorias.map(monte => (
                                                    <MonteModalCall
                                                        key={monte.nome}
                                                        data={{
                                                            ...monte,
                                                            id_categoria,
                                                            adicionais: ct.adicionais ? ct.adicionais : [],
                                                            img: monte.img && monte.img.length > 0 ? monte.img : ct.img,
                                                            nome_cat: ct.nome,
                                                        }}
                                                    />
                                                ))
                                            }
                                        </div>
                                    )
                                } else if (
                                    ct?.itens
                                    && !(Data.minimal && ct?.itens.length === 0)
                                ) {
                                    // console.log(ct);
                                    return (
                                        <>
                                            <PromoPizzaModalCall
                                                toLink={`/${getCompanyId}/${department}/${type}/${cat}/monte_2?monte=${index}`}
                                                data={{
                                                    nome: ct.nome,
                                                    valor: ct.valor,
                                                    img: ct.img,
                                                    descricao: ct.descricao
                                                }}
                                            />
                                        </>
                                    )
                                }
                            })
                            : null
                        :
                        <MonteMinimalModalCall
                            Data={Data}
                        />
                }

            </DefaultContainer>
        </article>
    )
}