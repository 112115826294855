import { useEffect } from 'react';
import { missProduto } from '../helpers';

/**
 * Search and set the required itens
 * 
 * @param {object[]} data the data to find by required itens
 * @param {function} setRequired the function to set the required state
 * @param {object[]} productCartData the place wehre we will search if exists
 * @param {function} setMiss the funtion to set what is missing
 * @param {number[]} required the required ids
 */
const useRequiredItens = (
    data,
    setRequired,
    productCartData,
    setMiss,
    required,
) => {
    useEffect(() => {

		if (data) {
            let req = data.map( e => {

                if (e.qtde_escolha_obrigatoria > 0 && e.escolha_obrigatoria === true) {
                    return ({ 
                        qtd: e.qtde_escolha_obrigatoria ? e.qtde_escolha_obrigatoria : 0, 
                        nome: e.tipo_acompanhamento_nome
                    })
                }
                else return [];
            });

            setRequired(req);
		} 
		// eslint-disable-next-line
    }, [data]);
    
	// eslint-disable-next-line
	useEffect(() => {
		if (productCartData) {

			setMiss( missProduto(required, productCartData) );
		}
	});

}

export default useRequiredItens;