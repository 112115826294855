import { confirmAlert } from "react-confirm-alert"
import saveToCart from "./saveToCart"

/**
 * 
 * @param {boolean|number} showModal 
 * @param {Function} setShowModal 
 * @param {Array} itensList 
 * @param {Array} miss 
 * @param {Function} dispatch 
 * @param {Array} productCartData 
 * @param {Array} cart 
 * @param {boolean} adicionais
 * @param {() => void} history
 * @param {string} caminho
 */
function next(
    showModal,
    setShowModal,
    itensList,
    miss,
    dispatch,
    productCartData,
    cart,
    adicionais = false,
    history = false,
    caminho
) {
    if (adicionais && productCartData?.adicionais?.length === 0 && showModal === false) {
        setShowModal(0)
    }
    else if (showModal !== "adicionais" && showModal + 1 !== itensList.length && showModal !== false) {
        setShowModal(showModal + 1)
    }
    else if (showModal + 1 === itensList.length && showModal !== false && adicionais) {
        setShowModal("adicionais")
    }
    else {
        if (!miss) {
            setShowModal(false)
            saveToCart(val => dispatch(val), productCartData, cart)

            if (showModal === false)
                confirmAlert({
                    title: "Adicionado com sucesso",
                    message: miss,
                    buttons: [
                        {
                            label: "Ok"
                        }
                    ]
                })

            if (history !== false)
                history.push(caminho)

        } else {

            setShowModal(false)

            confirmAlert({
                title: "Você ainda precisa completar o pedido",
                message: miss,
                buttons: [
                    {
                        label: "Entendi"
                    }
                ]
            })
        }
    }
}

export default next