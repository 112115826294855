import React, { useState, useEffect } from 'react';
import { replacer } from '../../../helpers';
import { Desconto, Price } from '../../../styled/Text';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import useMoeda from '../../../traducao/useMoeda';
// import { useParams } from 'react-router';

export default ({
    data,
    title,
    getValue,
    product = {},
    isChecked = false,
}) => {
    const name = replacer(title);
    const value = replacer(product.nome) + (parseInt(Math.random() * 550));
    const moeda = useMoeda();

    const traducao = useConteudoTraduzido();

    const [expand, setExpand] = useState(false);
    const [obs, setObs] = useState("");

    const windowSize = window.innerWidth;
    const heightSize = window.innerHeight;

    const style = {
        visibility: "visible",
        marginLeft: -windowSize * .4,
        marginTop: -windowSize * .4,
        top: heightSize / 2,
        left: windowSize / 2,
        width: windowSize * .8,
        height: windowSize * .8
    };

    function setValue() {

        let valor = data.price;

        if (!data.price)
            valor = product.valor;

        if (typeof data.price === 'object') {
            valor = data.price.valor;
        }

        getValue({
            title: data.label,
            id: data.id,
            ...product,
            valor: valor,
            observacao: obs
        });
    }

    useEffect(() => {
        if (obs !== "") setValue();
        // eslint-disable-next-line
    }, [obs]);

    return (
        <>
            <div className="w-100 _center mb-1 mt-1" onClick={() => setValue()}>
                <figure className="flex-3 _center" onClick={() => setExpand(!expand)}>
                    <img
                        className="snack-image"
                        src={product.img}
                        alt={product.nome}
                    />
                    <img
                        className={`snack-big ${expand ? 'expand' : ''}`}
                        src={product.img}
                        alt={title}
                        style={expand ? style : { width: 0, height: 0, visibility: "hidden" }}
                    />
                    <div
                        onClick={() => setExpand(false)}
                        className={`expand-bg ${expand ? 'active' : 'none'}`}
                    ></div>
                </figure>
                <div className="flex-8">
                    <label
                        htmlFor={value}
                        className="mini-title size-normal-small space-between w-100 is-lowercase 
                        capitalized pointer has-text-weight-medium"
                    >
                        {data.label}
                    </label>
                    {
                        data.subtitle !== null ?
                            <small className="mini-title mt-0">
                                {data.subtitle}
                            </small>
                            : ''
                    }
                    {
                        typeof data.price === 'number' ?
                            <span className="price">
                                {moeda}{parseFloat(data.price).toFixed(2)}
                            </span>
                            : ''
                    }
                    {
                        typeof data.price === 'object' ?
                            <>
                                <Price>
                                    {moeda}{parseFloat(data.price.valor).toFixed(2)}
                                </Price>
                                <Desconto>
                                    {moeda}{data.price.sem_desconto.toFixed(2)}
                                </Desconto>
                            </>
                            : null
                    }
                </div>
                <p className="flex-1 _center mb-m5px">
                    <input
                        type="radio"
                        readOnly
                        checked={isChecked}
                        name={name}
                        id={value}
                        value={value}
                        className={`none ${isChecked ? "checked" : ""}`}
                    />
                    <span data-checked={`${isChecked}`}></span>
                </p>
            </div>
            {
                isChecked ?
                    <div className="flex-12 mt-2">
                        <input
                            type="text"
                            className="observation"
                            placeholder={traducao.produtos.alguma_observacao}
                            value={obs}
                            onChange={e => setObs(e.target.value)}
                        />
                    </div>
                    : ''
            }
        </>
    );
};