import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import useItens from '../../../../hooks/useItens';
import CategoriesRow from '../../../general/CategoriesRow';
import ProdutoModalCall from './ProdutoModalCall';

export default () => {

    const { Data } = useItens();
    const [ item, setItem ] = useState( Data );
    const { catId } = useParams();

    useEffect(() => {

        if ( catId ) {
            const id = parseInt( catId );

            const produto = Data.itens.filter(
                prod => prod.id === id
            );

            setItem( produto );
        }
        // eslint-disable-next-line
    }, [catId]);

    return (
        <article className="container">

            <CategoriesRow />

            <section>
                {
                    item && Array.isArray( item ) ?
                        item.map(e => (
                            <ProdutoModalCall key={e.id} data={e} />
                        ))
                    : null
                }

            </section>
        </article>
    );
}