import { geolocation } from "../tratar-api/index.js";
import { googleApi } from "../tratar-api/keys";
import Axios from "axios";

/**
 * 
 * @param {*} address 
 * @param {*} empresa 
 * @param {*} entrega 
 * @param {*} carregando 
 * @param {*} erro 
 * @param {*} setModalSimilares 
 * @param {*} setEnderecosSimilares 
 * @param {*} escolherEndereco 
 * @param {*} salvar 
 */
const getGeolocationKm = async (
    address, 
    empresa, 
    entrega, 
    carregando, 
    erro, 
    setModalSimilares, 
    setEnderecosSimilares, 
    salvar,
) => {

    if ( address.km && address.km.valor ) {
        salvar( address );
        return;
    }

    else {

        carregando( true );

        let { endereco, numero, bairro, cidade, estado } = address;
        estado = estado ? estado : empresa[0].estado;

        const endereco_completo = `${endereco}, ${numero} - bairro: ${bairro || "Não informado"}, ${cidade}, ${estado}`;

        address.endereco_completo = endereco_completo;

        const search = endereco_completo.split(" ").join("+");

        await Axios.get( geolocation( search, googleApi ) )
        .then( e => {

            carregando( false );

            setModalSimilares( true );
            setEnderecosSimilares(e.data.results);
            
        })
        .catch( e => {
            erro( "Houve um problema ao processar esse endereço ou ele não existe, confira se seus dados estão corretos" );

            carregando(false);
        });
    }
}

export default getGeolocationKm;
