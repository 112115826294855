import React, { useState, useEffect } from 'react';
import SnackData from './SnackData';
import BebidasAdd from './inputs/BebidasAdd';
import { TitlePrimary } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';

export default ({ data }) => {

    const [showModal, setShowModal] = useState(false);
    const { cor_padrao } = useGetPrimary();

    const [productCartData, setProductCartData] = useState({});

    useEffect(() => {
        setProductCartData({
            type: "promo_geral",
            tipo: "promo_geral",
            id: data.id,
            nome: data.nome,
            descricao: data.descricao,
            img: data.img,
            valor_tem_desconto: data.valor_tem_desconto,
            valor: data.valor,
            valor_sem_desconto: data.valor_sem_desconto,
            cobrar_bebida: data.cobrar_bebida,
            descritiva: true,
        });
    }, [data]);

    return (
        <div className="forms options-open-modal">
            <TitlePrimary primary={cor_padrao} className="border-top">
                {data.nome}
            </TitlePrimary>

            <section className="space-between center-align">
                <SnackData
                    title={data.nome}
                    icon="expand_more"
                    data={data}
                    price={data.valor}
                    desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                    showModal={() => setShowModal(!showModal)}
                    aditionalTitle="Adicionais"
                >
                    <BebidasAdd
                        productCartData={productCartData}
                        setProductCartData={val => setProductCartData(val)}
                        data={data}
                        setShowModal={() => setShowModal(!showModal)}
                        show={showModal}
                    />
                </SnackData>
            </section>
        </div>
    );
}