import { confirmAlert } from "react-confirm-alert"
// eslint-disable-next-line no-unused-vars
import { PromoItens, PromoPizzaCarrinho, ProximoModal } from "../typing/PizzaPromo"
import { faltaItem } from "./helper-dos-helpers/proximoPizzaPromo/faltaItem"
import { handleBorda, temBorda } from "./helper-dos-helpers/proximoPizzaPromo/handleBorda"
import { estaNaBorda, estaNaBordaObjeto, naoEhBorda, naoEhBordaObjeto, validacaoBordaFinal } from "./helper-dos-helpers/proximoPizzaPromo/validacao"
import next from "./next"
import saveToCart from "./saveToCart"


/**
 * manda para a próxima opção da promoção
 * 
 * @param {string | boolean | ProximoModal} showModal Qual o estado atual do modal
 * @param {function(string | boolean | ProximoModal)} setShowModal    Muda o estado do modal
 * @param {any[]} cart  Carrinho
 * @param {PromoItens} promoItens Os itens da promoção atual
 * @param {PromoPizzaCarrinho} productCartData O pedido atual 
 * @param {(any) => void} dispatch 
 * @param {string} miss 
 * @param {() => void} history 
 * @param {string} getCompanyId 
 * @param {string} department 
 * @param {string} type 
 * @param {string} cat 
 */
function promoPizzaPromo(
    showModal,
    setShowModal,
    cart,
    promoItens,
    productCartData,
    dispatch,
    miss,
    history,
    getCompanyId,
    department,
    type,
    cat
) {
    // primeiro criamos as variaveis necessárias
    let proximo = showModal // será o proximo valor do modal
    let sabor = false // se estamos no sabor da pizza 

    // Verifica se está na borda
    if (typeof proximo === 'string') {
        const problem = handleBorda(proximo, promoItens, productCartData, setShowModal)
        if (problem === "deu ruim") {
            return
        }
    }
    if (naoEhBorda(proximo, promoItens)) {
        proximo = `b${proximo}` // coloca um b antes do proximo id para dizer que vamos à borda
        setShowModal(proximo) // definimos o showModal para a borda
        sabor = true // dizemos que o atual é um sabor
    }

    if (naoEhBordaObjeto(showModal, promoItens)) {
        proximo = { ...showModal, borda: true } // borda = true
        setShowModal(proximo) // definimos o showModal para a borda
        sabor = true
    }

    if (estaNaBorda(showModal, sabor)) {
        proximo = parseInt(showModal.substring(1)) // tira o b e tranforma num inteiro
        setShowModal(proximo) // defino o proximo modal
    }

    if (estaNaBordaObjeto(showModal, sabor)) {
        proximo = { ...showModal, borda: false } // borda = true
        setShowModal(proximo) // definimos o showMOdal para a borda
    }

    const cartItens = productCartData?.itens

    miss = cartItens?.length === promoItens.conjuntos.length
        ? miss
        : `${miss ? miss : ''} Está faltando algum item`


    if (typeof proximo === 'number' && !isNaN(proximo)) {
        // se proximo for numero, usamos o next
        const temFaltando = faltaItem(productCartData)

        if (temFaltando.length === 0) {
            next(
                proximo,
                val => setShowModal(val),
                promoItens.conjuntos,
                miss,
                val => dispatch(val),
                productCartData,
                cart,
                false,
                history,
                `/${getCompanyId}/${department}/${type}/${cat}`
            )
        } else {
            confirmAlert({
                title: "Você não adicionou sabor nenhum",
                message: `Adicione algum sabor na pizza ${temFaltando.join(', ')}`,
                buttons: [
                    { label: "Ok" }
                ]
            })
            return
        }
    }
    else if (validacaoBordaFinal(proximo, productCartData, showModal)) {

        const indexAtual = showModal.conjunto

        const este = productCartData.itens.find((prod) => prod.index === indexAtual)

        if (!temBorda(este)) {
            // Se não tiver, mostra uma mensagem
            confirmAlert({
                title: "Adicione uma borda",
                message: "Você não adicionou nenhuma borda, se não quiser nenhuma mesmo, escolha: \"Sem borda\"",
                buttons: [
                    {
                        label: "Ver bordas",
                        onClick: () => {
                            console.log("é aqui ");

                            setShowModal({
                                borda: true,
                                conjunto: indexAtual,
                                produtoIndex: este.produtoIndex,
                            })
                        }
                    }
                ]
            })
            return
        }
        next(
            proximo.valor,
            val => setShowModal(val),
            promoItens.conjuntos,
            miss,
            val => dispatch(val),
            productCartData,
            cart,
            false,
            history,
            `/${getCompanyId}/${department}/${type}/${cat}`
        )
    } else if (isNaN(proximo) || proximo === false) {
        const produtosExistentes = productCartData.itens.map((prod) => prod.index)

        if (produtosExistentes.length === 0) {
            return
        }

        const maior = Math.max(...produtosExistentes)

        const proximoConjunto = promoItens?.conjuntos[maior + 1]

        if (!!proximoConjunto) {
            // setShowModal({
            //     borda: false,
            //     conjunto: maior + 1,
            //     produtoIndex: maior + 1,
            // })
            return
        }
        else {
            history.push(`/${getCompanyId}/${department}/${type}/${cat}`)
            saveToCart(val => dispatch(val), productCartData, cart)

            confirmAlert({
                title: "Adicionado com sucesso",
                message: miss,
                buttons: [
                    {
                        label: "Ok"
                    }
                ]
            })
            // next(
            //     proximo?.valor,
            //     val => setShowModal(val),
            //     promoItens.conjuntos,
            //     miss,
            //     val => dispatch(val),
            //     productCartData,
            //     cart,
            //     false,
            //     history,
            //     `/${getCompanyId}/${department}/${type}/${cat}`
            // )
        }
    }
}

export default promoPizzaPromo

