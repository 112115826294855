import React, { useRef } from 'react';
import InputForm from '../forms/InputForm';
import RadioInput from '../forms/inputs/RadioInput';
import { replacer } from '../../helpers';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default ({
    data,
    setProductCartData,
    productCartData,
    showPrice = true,
    index = null,
    cor = false,
}) => {
    const normalized = replacer(data.tamanho);
    const id = index !== null ? `${normalized}-${index}-borda` : normalized + "-borda";
    const traducao = useConteudoTraduzido();

    const ativo = useRef({});

    const adicionar = (val) => {
        setProductCartData({
            ...productCartData,
            borda: val,
        });

        ativo.current = val;
    };

    return (
        <div id={id}>
            <InputForm title="Escolha a borda">
                {
                    data && data.bordas && data.bordas.length > 0 ?
                        data.bordas.map((borda) => (
                            <RadioInput
                                key={borda.id_borda + normalized + Math.random()}
                                title={traducao.produtos.borda}
                                data={{
                                    subtitle: borda.descricao,
                                    label: borda.nome,
                                    price: showPrice ? borda.valor : null,
                                    id: borda.id_borda,
                                }}
                                showPrice={showPrice}
                                product={borda}
                                getValue={adicionar}
                                ativo={ativo.current}
                                dark={cor}
                            />
                        ))
                        :
                        <RadioInput
                            title="Sem borda"
                            data={{
                                label: "Sem borda",
                                price: 0,
                            }}
                            product={{ nome: "sem borda" }}
                            getValue={
                                (val) => setProductCartData({
                                    ...productCartData,
                                    borda: val,
                                })
                            }
                            dark={cor}
                        />
                }
            </InputForm>
        </div>
    );
};