import { useState } from "react";
import { useSelector } from "react-redux";
import useGetPrimary from "../helpers/useGetPrimary";
import useConteudoTraduzido from "../traducao/useConteudoTraduzido";

function useEnderecoVariaveis() {

    const { cor_padrao } = useGetPrimary();

    const { 
        endereco, 
        entrega, 
        permitir_quadra, 
        permitir_lote,
        tira_cep,
    } = useSelector( state => state.companyData.data.payload );
    const [ errMessage, setErrMessage ] = useState( {} );
    const traducao = useConteudoTraduzido()
    const [ carregando, setCarregando ] = useState( false );
    const [ modalSimilares, setModalSimilares ] = useState( false );
    const [ enderecosSimilares, setEnderecosSimilares ] = useState( [] );

    const [ address, setAddress ] = useState( {} );

    return {
        cor_padrao,
        tira_cep,
        permitir_quadra, 
        permitir_lote,
        endereco, 
        entrega,
        errMessage, setErrMessage,
        traducao,
        carregando, setCarregando,
        modalSimilares, setModalSimilares,
        enderecosSimilares, setEnderecosSimilares,
        address, setAddress
    }
}

export default useEnderecoVariaveis;