import React, { useState } from 'react'
import Cart from '../request/Cart'
import { useHistory } from 'react-router'
import { getCompanyId } from '../../helpers'
import useGetPrimary from '../../helpers/useGetPrimary'
import { useDispatch, useSelector } from 'react-redux'
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import { Link } from 'react-router-dom'
import Info from './Info'
import { userAddress } from '../../actions'



const HeaderSimple = ({ status = false }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const traducao = useConteudoTraduzido()
    const { entrega, cartoes_aceitos, motivo_fechado } = useSelector((state) => state.companyData.data.payload)
    const { bgPrimary } = useGetPrimary()
    const [showModal, setShowModal] = useState(false)
    const [mostrarAviso, setMostrarAviso] = useState(false)

    let name = "Concluir pedido"

    const entregaTexto = () => {
        let textoEntrega = ""
        textoEntrega = entrega && entrega.texto_tempo_entrega ? entrega.texto_tempo_entrega : ''
        if (textoEntrega && textoEntrega.length > 100)
            textoEntrega = textoEntrega.substring(0, 59) + `<span style="color: rgb(101, 192, 229); font-size: 1.09em">Ver mais...</span>`
        return textoEntrega
    }

    const voltar = () => {
        if (!status) {
            const voltarPara = history.location.pathname?.match(/confirmation/i) ? '/' + getCompanyId + '/checkout/before-confirm' : '/' + getCompanyId
            history.push(voltarPara)
        } else {
            history.push(`/${getCompanyId}`)
            dispatch(userAddress({}))
        }
    }

    return (
        <>
            <header className="header simple" style={bgPrimary}>
                <section className="header-line">

                    <button onClick={voltar} className="clean-button bg-transparent header-left">
                        <span className="material-icons">keyboard_arrow_left</span>
                    </button>

                    <div className="brand flex-8">
                        <div className="description">
                            {
                                !status ?
                                    <>
                                        <h2> {name} </h2>
                                        <div className="deliver"
                                             dangerouslySetInnerHTML={{
                                                 __html: entrega.texto_tempo_entrega ? entregaTexto() : ''
                                             }}
                                        />
                                    </>
                                    :
                                    <h2>{
                                        typeof status === 'string'
                                            ? status
                                            : traducao.geral.consultar_pedidos}
                                    </h2>
                            }

                        </div>
                    </div>
                    <Info
                        texto_tempo_entrega={entrega ? entrega.texto_tempo_entrega : null}
                        cartoes_aceitos={cartoes_aceitos}
                        aberto={mostrarAviso}
                        controller={setMostrarAviso}
                        motivo_fechado={motivo_fechado}
                        traducao={traducao}
                    />
                    {
                        status && typeof status !== 'string' ?
                            <Link to={`/${getCompanyId}`} className="flex-2">
                                <span className="material-icons is-size-3">
                                    home
                                </span>
                            </Link>
                            :
                            <span className="blanc-50 flex-1"></span>
                    }

                </section>
            </header>
            <div className="header-bottom"></div>
            <Cart show={showModal} setShowModal={(val) => setShowModal(val)} />
        </>
    )
}

export default HeaderSimple
