import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userName, userSetAll, userGeneral } from '../actions';
import { setUserPreferences, getUserData } from '../json';

/**
 * * The hook to be used in BeforeConfirmation page
 * 
 * @returns {{
 *  name: string, 
 *  setName: function, 
 *  usuario: object, 
 *  entrega: object, 
 *  permitir_presente: boolean, 
 *  nome: string, 
 *  obs: string, 
 *  setObs: function, 
 *  aberto: boolean
 * }}
 */
const useBeforeConfirmation = () => {

    const dispatch = useDispatch();
    const [usuario, cliente] = useSelector((state) => [state.userData, state.cliente]);
    const [{ aberto }, { entrega, permitir_presente }] = useSelector((state) => [state.companyData.data.aberto, state.companyData.data.payload]);

    const [obs, setObs] = useState("");
    const [name, setName] = useState(cliente?.nome ?? '');
    const [cpfNota, setCpfNota] = useState("");
    const [cpfCnpj, setCpfCnpj] = useState("");


    const saveCpfCnpjTipo = (valor) => {
        setCpfCnpj(valor);
        dispatch(userGeneral('MORE', { key: "cpf_ou_cnpj", value: valor }));
    };

    const saveCpfCnpjValor = (valor) => {
        setCpfNota(valor);
        dispatch(userGeneral('MORE', { key: "cpf_cnpj_valor", value: valor }));
    };

    useEffect(() => {
        const data = getUserData();
        // console.log(data);

        if (data) {
            const telefone = data.telefone ? data.telefone : usuario.telefone;
            const pais = usuario.pais;
            delete data.entrega;
            delete data.metodo_entrega;

            dispatch(userSetAll({ ...data, telefone, pais }));
        }
        // eslint-disable-next-line
    }, [dispatch]);



    // eslint-disable-next-line
    useEffect(() => {
        setUserPreferences({
            ...usuario,
            cartao: [],
        });

        if (!name && cliente?.nome !== undefined)
            setName(cliente.nome);
    });

    useEffect(() => {
        dispatch(userName(name));
    }, [name, dispatch]);

    useEffect(() => {
        dispatch(userGeneral('MORE', { key: "observacao_geral", value: obs }));
    }, [obs, dispatch]);

    return {
        obs,
        name,
        aberto,
        cpfCnpj,
        cpfNota,
        usuario,
        entrega,
        permitir_presente,
        nome: cliente && cliente.nome ? cliente.nome : null,
        setCpfCnpj: saveCpfCnpjTipo,
        setCpfNota: saveCpfCnpjValor,
        setName,
        setObs,
    };
};

export default useBeforeConfirmation;
