import normalize from "./normalize";

/**
 * This function will get the turnInId param
 * and will... well turn it in one valid id
 * 
 * @param {string} turnInId 
 * @returns {string}
 */
const replacer = (turnInId = "") => {
    turnInId = `id-${turnInId}`;
    // Some browsers do not support the replaceAll method
    // Here I verify if it supports it and return the right option
    if (typeof(String.replaceAll) !== "undefined") {

        // eslint-disable-next-line
        turnInId = turnInId.replaceAll(/\'|\"|\\|\/\s|\.|\$|\*|\,|\&/g, "");
        turnInId = turnInId.split("(").join("-");
        turnInId = turnInId.split(")").join("-");
        // normalize, lowercase and return
        return normalize(turnInId).toLowerCase();

    } else {
        // eslint-disable-next-line
        turnInId = turnInId.replace(/\'|\"|\\|\/|\.|\,|\&/g, "");
        // take spaces
        turnInId = turnInId.split(" ").join("-");
        turnInId = turnInId.split("(").join("_");
        turnInId = turnInId.split(")").join("2");
        turnInId = turnInId.split("$").join("s5");
        turnInId = turnInId.split("*").join("");
        // normalize, lowercase and return
        return normalize(turnInId).toLowerCase();
    }
}
// we don't want to keep all our work jailed here, right?
export default replacer;