import React from 'react';
import SnackData from '../../../forms/SnackData';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { pizzaPath } from '../../../../helpers';

export default ({
    data = {},
    toLink = null
}) => {

    const { pathname } = useLocation();
    const history = useHistory();

    return (
        <div className="forms options-open-modal border-bottom">
            <section className="space-between center-align">
                <SnackData
                    data={data}
                    title={data.nome}
                    price={data.valor}
                    desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                    showModal={() => history.push(toLink === null ? pizzaPath(data, pathname) : toLink)}
                    showImg
                >
                    <Link
                        className="open-modal flex-end flex-4 flex-column has-text-right"
                        to={toLink === null ? pizzaPath(data, pathname) : toLink}
                    >
                        <span className="material-icons text-grey size-bigger">
                            keyboard_arrow_right
                        </span>
                    </Link>
                </SnackData>
            </section>
        </div>
    );
};