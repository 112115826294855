import styled from 'styled-components';
import { grey, success } from '../style-js';

export const Text = styled.p`
  font-weight: 500 !important;
  font-size: 1rem;
`;

export const TextoNormal = styled.p`
    font-size: .9rem;
    text-align: ${({ align }) => align ? align : 'left'};
    color: ${grey};
`;

export const TextoPequeno = styled.p`
    font-size: .77rem;
    text-align: 'left';
    color: ${({ primary }) => primary ? primary : grey};
    font-weight: 500;
`;
export const Erro = styled.em`
  font-size: .75rem;
  color: hsl(348, 100%, 61%);
  font-style: italic;
  font-weight: ${({ bold }) => bold ? 600 : 400}
`;

export const Price = styled.span`
    font-size: .85rem;
    color: ${success};
    font-weight: ${({ bold }) => bold ? 600 : 400}
`;
export const Aviso = styled.span`
  font-size: .75rem;
  color: #ED6E59;
  font-style: italic;
  font-weight: ${({ bold }) => bold ? 600 : 400};
  padding: 10px;
`;

export const Desconto = styled.span`
  font-size: .75rem;
  color: hsl(348, 100%, 61%);
  font-weight: 400;
  text-decoration: line-through;
  padding-right: 2px;
`;