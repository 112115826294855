import React, { useState, useEffect } from 'react';
import ModalForm from '../../forms/ModalForm';
import Button from '../../forms/Button';
import Select from '../../forms/inputs/Select';
import { useDispatch } from 'react-redux';
import { userSchedule } from '../../../actions';
import { Erro } from '../../../styled/Text';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';

export default ({ show, setShow, schedules }) => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();

    const data = { title: traducao.dados_usuario.desejo_agendar };

    const [schedule, setSchedule] = useState({
        dia: "",
        periodo: "",
        hora: "",
    });

    const [horario, setHorario] = useState([]);
    const [dias, setDias] = useState([]);
    const [erro, setErro] = useState({});
    const [tipo, setTipo] = useState("");

    const save = () => {

        if (schedule.dia && (schedule.periodo || schedule.hora)) {
            dispatch(userSchedule(schedule));
            setShow(false);

        } else {
            setErro({
                dia: !schedule.dia || schedule.dia === "" || schedule.dia === "fff" ? traducao.validacoes.dia_valido : undefined,
                hora: tipo === "horario" && (!schedule.hora || schedule.hora === "" || schedule.hora === "fff") ? traducao.validacoes.horario_valido : undefined,
                periodo: tipo === "periodo" && (!schedule.periodo || schedule.periodo === "" || schedule.periodo === "fff") ? traducao.validacoes.periodo_valido : undefined
            });
        }
    };

    const cancel = () => {
        setShow(false);
        dispatch(userSchedule([]));
    };

    useEffect(() => {

        if (typeof schedules.dias_permitidos.filter === 'function') {
            const horario = schedules.dias_permitidos.filter(dia => dia.value === schedule.dia);


            if (horario[0] && Array.isArray(horario[0].range)) {

                setTipo("horario");
                setHorario(horario[0].range);

            } else if (horario[0] && !Array.isArray(horario[0].range)) {

                setTipo("periodo");

                const hora = [];

                if (horario[0].range.tM) hora.push({ texto: traducao.geral.manha, value: "manha" });
                if (horario[0].range.tT) hora.push({ texto: traducao.geral.tarde, value: "tarde" });
                if (horario[0].range.tN) hora.push({ texto: traducao.geral.noite, value: "noite" });

                setHorario(hora);
            } else {
                // console.log(schedules.dias_permitidos);
                setTipo("horario");
                setDias([{
                    texto: schedules.dias_permitidos.texto,
                    value: schedules.dias_permitidos.value
                }]);
            }
        }
        // eslint-disable-next-line
    }, [schedule]);

    if (Array.isArray(schedules.dias_permitidos)) {

        return (
            <ModalForm
                data={data}
                show={show}
                setShowModal={() => setShow(false)}
                closeIcon={false}
            >
                {
                    erro.dia ?
                        <Erro>
                            {erro.dia}
                        </Erro>
                        : null
                }
                <Select
                    title="Dia"
                    opt={Array.isArray(schedules.dias_permitidos) ? schedules.dias_permitidos : dias}
                    select={schedule.dia ? schedule.dia : ''}
                    setSelect={val => setSchedule({ ...schedule, dia: val })}
                    placeholder={traducao.geral.definir_dia}
                    mostrar="texto"
                    valor="value"
                />

                {
                    erro.periodo ?
                        <Erro>
                            {erro.periodo}
                        </Erro>
                        : null
                }{
                    tipo === "periodo" ?
                        <Select
                            title={traducao.geral.periodo}
                            complement={` (${traducao.geral.aplicavel})`}
                            opt={horario}
                            select={schedule.periodo ? schedule.periodo : ''}
                            setSelect={val => setSchedule({ ...schedule, periodo: val })}
                            placeholder={traducao.geral.periodo}
                            mostrar="texto"
                            valor="value"
                        />
                        : null
                }

                {
                    erro.hora ?
                        <Erro>
                            {erro.hora}
                        </Erro>
                        : null
                }{
                    tipo === "horario" ?
                        <Select
                            title={traducao.geral.horario}
                            complement={` (${traducao.geral.aplicavel})`}
                            opt={horario}
                            select={schedule.hora ? schedule.hora : ''}
                            setSelect={val => setSchedule({ ...schedule, hora: val })}
                            placeholder={traducao.validacoes.definir_horario}
                        />
                        : null
                }

                <div className="buttons bottom">
                    <Button
                        title={traducao.geral.cancelar}
                        classes="button is-outlined is-primary"
                        click={() => cancel()}
                        bg={false}
                    />
                    <Button
                        title={traducao.geral.salvar}
                        classes="button has-text-light"
                        click={() => save()}
                    />
                </div>
            </ModalForm>
        );
    }
    else {
        return <></>;
    }
};