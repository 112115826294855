import React from 'react'
import { useSelector } from 'react-redux'
import { calculatePrice } from '../../helpers'
import { TitlePrimary } from '../../styled/Titles'
import useGetPrimary from '../../helpers/useGetPrimary'
import useMoeda from '../../traducao/useMoeda'
import valorDaEntrega from '../../distancia/valorDaEntrega'

export default ({
    buttonMessage,
    onClick,
    entregaP = false,
    desabilitar = false
}) => {

    const { cart } = useSelector((state) => state.cartIten)
    const { acrescimo, desconto, coupon, entrega } = useSelector((state) => state?.userData ?? {})
    const moeda = useMoeda()

    const { bgPrimary, cor_padrao } = useGetPrimary()

    let valor = calculatePrice(cart, true)

    if (acrescimo > 0) {
        valor = valor + (valor * parseFloat((acrescimo / 100).toFixed(2)))
    }
    if (desconto > 0) {
        valor = valor - (valor * (desconto / 100))
    }

    if (entregaP !== false) {
        valor = entregaP
    }

    if (coupon && coupon.valor_do_pedido_com_desconto)
        valor = coupon.valor_do_pedido_com_desconto

    if (entrega && !entregaP) {

        const val = valorDaEntrega(entrega)
        valor = valor + val
    }

    return (
        <div className="add-to-cart show-submit z-if-none-out">
            <TitlePrimary primary={cor_padrao}>
                {moeda}{valor.toFixed(2)}
            </TitlePrimary>

            <button
                type="button"
                className="btn size-small"
                style={bgPrimary}
                onClick={desabilitar === false ? () => onClick() : () => null}
            >
                {!desabilitar ? buttonMessage : 'Carregando...'}
            </button>

        </div>
    )
}
