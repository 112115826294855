/**
 * 
 * @param {*} required 
 * @param {*} catsQt 
 */
const missing = (required, catsQt, searchIn = "id") => {

    const exists = required.map( e => {
        const ids = catsQt.map( qt => qt[searchIn] );

        const inclui = ids.includes(e[searchIn]);

        const reverso = catsQt.reverse();

        const index = reverso.findIndex( cat => cat[searchIn] === e[searchIn] );

        const faltando = catsQt[index] ? catsQt[index].qtd : 0;

        return inclui && ( e.qtd === 0 || faltando >= e.qtd ) ? null : `${ e.nome } (${ e.qtd - faltando }x)`;
    });

    const missing = exists.filter( e => typeof(e) === 'string' && e.indexOf('undefined') === -1 );
    const joined = missing.join(", ");

    if ( joined !== "" )
        return `Está faltando: ${joined}`
    return null;
}

export default missing;