import React, { useEffect } from 'react';
import { replacer } from '../../helpers';
import { useDispatch } from 'react-redux';
import { removeScroll } from '../../actions';

export default ({ 
    show, 
    setShowModal, 
    children, 
    title = "",
    classesAdicionais = "",
}) => {

    let display = show ? "show" : "none";

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(removeScroll( !show ));

    }, [show, dispatch]);

    const formId = replacer(title);

    return (
        <>
        <form id={formId} className={`form-group-modal-compressed ${display} ${classesAdicionais}`}>
            <header className="container _center space-between border-bottom">
                <div className="text-center flex-10">
                    <h6 className={`size-small has-text-weight-bold`}>
                        { title }
                    </h6>
                </div>
            </header>

            <div className="container h-100">
                { children }
            </div>
        </form>
        <div className={`close-modal-bg ${display}`} onClick={() => setShowModal(false)}></div>
        </>
    );
}