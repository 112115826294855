import React, {useState, useEffect} from 'react';
import Cart from '../request/Cart';

import {useSelector} from 'react-redux';
import Info from './Info.tsx';
import {Link, useLocation} from 'react-router-dom';
import {getCompanyId} from '../../helpers';
import {getThemeColor, setThemeColor} from '../../json';
import GoToCheckout from '../request/GoToCheckout';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import {confirmAlert} from 'react-confirm-alert';
import whats from '../../img/whats.png';
import mrduma from '../../img/mrduma.jpg';


function BlancOrWhats(props) {
    return (
        props.link_whatsapp ?
            <a href={`https://api.whatsapp.com/send?phone=${props.num_whatsapp}`} target="_blank"
               rel="noopener noreferrer">
                <img src={whats} alt="whatsapp" style={{width: 35, paddingLeft: 7}}/>
            </a>
            :
            <span className="blanc-50"></span>
    );
}

export default () => {

    const companyId = getCompanyId;

    const [data, setData] = useState({});
    const traducao = useConteudoTraduzido();

    const FullData = useSelector(state => state.companyData);
    const scroll = useSelector(state => state.removeScroll);
    const tabs = useSelector(state => state.removeTabs);
    const [aberto, setAberto] = useState({aberto: true});
    const [color, setColor] = useState(getThemeColor(companyId));
    const {cart} = useSelector(state => state.cartIten);
    const {pathname} = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [mostrarAviso, setMostrarAviso] = useState(true);

    useEffect(() => {
        if ((
                (data.entrega && data.entrega.texto_tempo_entrega
                    && data.entrega.texto_tempo_entrega !== "")
                || (data.cartoes_aceitos && data.cartoes_aceitos !== "")
                || (data.importante && data.importante !== "")
            )
            && !window.location.pathname.includes("lojas")
        ) {
            // chamaInfo();
        }
        // eslint-disable-next-line
    }, [data, aberto]);

    useEffect(() => {
        setData(FullData.data.payload);
        setAberto(FullData.data.aberto);
    }, [FullData]);

    useEffect(() => {
        setColor(data.cor_padrao ? data.cor_padrao : "#1898DA");
        setThemeColor(data.cor_padrao ? data.cor_padrao : "#1898DA");
        // eslint-disable-next-line
    }, [data, color]);

    let changeHeader = {
        backgroundColor: companyId !== '9432' ? color : '#591427',
        backgroundImage: companyId !== '9432' && data.img_topo ? `url(${data.img_topo})` : companyId !== '9432' ? "" : `url(${mrduma})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "center"
    };

    const productsInCart = cart && cart.length ? cart.length : 0;
    const isHome = pathname.split('/').length <= 3;

    const empresa = () => {
        confirmAlert({
            title: "Sobre nós",
            message: "",// <div dangerouslySetInnerHTML={{ __html: data.txt_empresa }} />,
            buttons: [
                {label: "Fechar"}
            ]
        });
    };

    const entregaTexto = () => {
        let textoEntrega = "";
        textoEntrega = data.entrega && data.entrega.texto_tempo_entrega ? data.entrega.texto_tempo_entrega : '';

        if (textoEntrega && textoEntrega.length > 100)
            textoEntrega = textoEntrega.substring(0, 59) + `<span style="color: rgb(101, 192, 229); font-size: 1.09em">Ver mais...</span>`;

        return textoEntrega;
    };

    return (
        <>
            <header className={`header ${companyId !== '9432' ? 'background-cover' : 'bg-bigger'}`}
                    style={changeHeader}>
                <section className="header-line">
                    {
                        !isHome ?
                            <Link to={`/${companyId}`} className="clean-button bg-transparent header-left flex-2">
                                <span className="material-icons">keyboard_arrow_left</span>
                            </Link>
                            :
                            <div className="flex-2">
                                <div
                                    className={`info `}
                                    onClick={() => setMostrarAviso(!mostrarAviso)}
                                >
                                    <span>i</span>
                                </div>
                            </div>
                    }

                    <div className="brand min-height-80">
                        {
                            companyId !== '9432' && companyId !== '9108' ?
                                <img src={data.logo} alt={data.nome} className={`app-logo`}/>
                                : null
                        }
                    </div>

                    <button className="clean-button bg-transparent _center cart flex-2"
                            onClick={() => setShowModal(!showModal)}>
                        <span className={`material-icons ${data.img_topo ? 'header-shadow' : ''}`}>shopping_cart</span>
                        {
                            productsInCart > 0 ?
                                <span className="products-cart">{productsInCart}</span>
                                : ''
                        }
                    </button>

                </section>
                <section className="header-line">
                    <div className="flex-2">
                        {
                            !isHome ?
                                <div
                                    className="info"
                                    onClick={() => setMostrarAviso(!mostrarAviso)}
                                >
                                    <span>i</span>
                                </div>
                                :
                                <div>
                                    {
                                        data.txt_empresa ?
                                            <span className="material-icons pointer" onClick={empresa}>storefront</span>
                                            :
                                            <BlancOrWhats link_whatsapp={data.link_whatsapp}
                                                          num_whatsapp={data.num_whatsapp}/>
                                    }
                                </div>
                        }

                    </div>
                    <div className="brand flex-8">
                        {
                            companyId !== '9432' && companyId !== '9108' ?
                                <div className={`description ${data.img_topo ? 'header-shadow' : ''}`}>
                                    <h2>
                                        {data.nome}
                                    </h2>
                                    <div
                                        className={`deliver ${data.img_topo ? 'header-shadow' : ''}`}
                                        dangerouslySetInnerHTML={{
                                            __html: entregaTexto()
                                        }}
                                        onClick={() => setMostrarAviso(!mostrarAviso)}
                                    />
                                </div>
                                : null
                        }
                    </div>
                    <Link to={`/${getCompanyId}/status`} className="flex-2 _center pointer">
                  <span className={`material-icons`}>
                     receipt
                  </span>
                    </Link>
                </section>
            </header>
            <Info
                texto_tempo_entrega={data.entrega ? data.entrega.texto_tempo_entrega : null}
                cartoes_aceitos={data.cartoes_aceitos ? data.cartoes_aceitos : null}
                aberto={aberto.aberto}
                motivo_fechado={data.motivo_fechado}
                traducao={traducao}
                controller={setMostrarAviso}
                mostrarAviso={mostrarAviso}
            />
            <div className="header-bottom"></div>
            <Cart show={showModal} setShowModal={(val) => setShowModal(val)}/>

            <GoToCheckout
                cartItensQtd
                dontGo
                buttonMessage="Finalizar pedido"
                show={
                    cart.length > 0
                    && FullData
                    && FullData.data
                    && FullData.data.payload
                    && aberto.aberto
                    && scroll
                    && tabs
                    && (
                        typeof FullData.data.payload.comprar_mais === 'undefined'
                        || FullData.data.payload.comprar_mais === false
                    )
                }
                onClick={() => setShowModal(true)}
            />
        </>
    );
};
