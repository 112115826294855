import React, { useEffect } from 'react';
import NumberInputControlled from './NumberInputControlled';
import SnackData from '../SnackData';
import ModalCompressed from '../ModalCompressed';
import { addItenToCart } from '../../../actions';
import { calculatePriceItem } from '../../../helpers';
import NumberSimple from './NumberSimple';
import { MiniTitle } from '../../../styled/Titles'
import useBebidasAdd from '../../../hooks/useBebidasAdd';

export default ({
    productCartData,
    setProductCartData,
    data,
    setShowModal,
    aditionalTitle,
    hasModal = false,
    show,
    initial = 0,
    adicionaisOpen,
    setAdicionaisOpen
}) => {

    /**
     * Pega todas as varaiveis necessarias
     */
    const {
        dispatch, cart, catId, textPrimary, bgPrimary,
        moeda, added, setAdded, stateHolder, setStateHolder,
        obs, setObs, traducao, existiu, setExistiu
    } = useBebidasAdd();

    const setObservation = e => {
        setObs(e);
        setProductCartData({ ...productCartData, observacao: e });
    }

    const add = val => {
        setProductCartData({
            ...productCartData,
            ...val,
        });
        setShowModal(!show);
    }

    const toCart = () => {
        dispatch(addItenToCart(productCartData));

        setAdded(stateHolder);
        setShowModal(false);
    }

    useEffect(() => {
        setAdded(initial);
        // eslint-disable-next-line
    }, [initial]);

    useEffect(() => {
        setAdded(initial);
        // eslint-disable-next-line
    }, [catId]);

    // eslint-disable-next-line
    useEffect(() => {
        const existe = cart.filter(e => e.id === data.id);

        if (existe.length > 0) {
            setExistiu(true);
        } else {

            if (existiu === true && existe.length === 0) {
                setAdded(0);
                setStateHolder(0);
                setExistiu(false);
            }
        }
    });

    useEffect(() => {
        setAdded(0);
        // eslint-disable-next-line
    }, [productCartData]);

    return (
        <>
            <div
                className={
                    `flex-4 flex-column ${hasModal && added > 0 && setAdicionaisOpen
                        ? '_center'
                        : 'flex-end'
                    }`
                }
                onClick={() => setShowModal(true)}
            >
                <NumberSimple
                    setValue={val => setStateHolder(val)}
                    data={{
                        containerClass: `${hasModal && added > 0 && setAdicionaisOpen
                                ? '_center'
                                : 'flex-end'
                            } flex-12`,

                        id: data.id,
                        title: data.nome
                    }}
                    getValue={
                        val => add(val)
                    }
                    value={added}
                />
                {
                    hasModal &&
                        stateHolder > 0 && setAdicionaisOpen ?
                        <button
                            style={bgPrimary}
                            className="button has-text-white mt-1 flex-12"
                            type="button"
                            onClick={() => setAdicionaisOpen(!adicionaisOpen)}
                        >
                            {aditionalTitle}
                        </button>
                        : null
                }
            </div>

            <ModalCompressed
                title={data.nome}
                price={data.valor}
                subtitle={traducao.produtos.personalize + data.nome}
                setShowModal={() => setShowModal(false)}
                show={show}

            >
                <section className="space-between center-align h-80">
                    <SnackData
                        price={data.valor}
                        data={{}}
                        desconto={{ valor_sem_desconto: data.valor_sem_desconto, valor_tem_desconto: data.valor_tem_desconto }}
                        title={traducao.produtos.preco_unitario}
                        showImg={false}
                        showModal={() => null}
                        top
                    >
                        <div className="flex-end mt-1 flex-5 flex-column _center" style={{ marginTop: 7 }}>

                            <MiniTitle bold>
                                {traducao.produtos.quantidade}
                            </MiniTitle>

                            <NumberInputControlled
                                setValue={val => setStateHolder(val)}
                                data={{
                                    containerClass: `${stateHolder > 0 ? '_center' : 'flex-end'} flex-12`,
                                    id: data.id,
                                    title: data.nome
                                }}
                                getValue={
                                    val => setProductCartData({
                                        ...productCartData,
                                        ...val,
                                    })
                                }
                                value={stateHolder}
                            />
                            <div className="flex-12 space-evenly pt-3">
                                {
                                    hasModal && stateHolder > 0 && setAdicionaisOpen ?
                                        <button
                                            style={bgPrimary}
                                            className="button has-text-white mt-1 flex-12"
                                            type="button"
                                            onClick={() => setAdicionaisOpen(!adicionaisOpen)}
                                        >
                                            {aditionalTitle}
                                        </button>
                                        : null
                                }
                            </div>
                        </div>
                        <div className="pt-3 w-100">
                            <h5
                                style={textPrimary}
                                className="size-small has-text-weight-medium is-uppercased">
                                {traducao.produtos.alguma_observacao}
                            </h5>
                            <div className="flex-12 mt-2">
                                <input
                                    value={obs}
                                    onChange={e => setObservation(e.target.value)}
                                    type="text"
                                    className="observation"
                                    placeholder={traducao.produtos.alguma_observacao}
                                />
                            </div>
                        </div>

                        <div className="flex-12 space-evenly" onClick={toCart}>

                            {
                                stateHolder > 0 ?
                                    <>
                                        <p style={textPrimary} className="size- has-text-weight-bold">
                                            {moeda}{calculatePriceItem(productCartData).toFixed(2)}
                                        </p>

                                        <button style={bgPrimary} type="button" className="btn size-small">
                                            {traducao.geral.adicionar_ao_carrinho}
                                        </button>
                                    </>
                                    : null
                            }

                        </div>
                    </SnackData>
                </section>
            </ModalCompressed>
        </>
    );
}
