import React, { useEffect, useState } from 'react';
import useGetPrimary from '../../helpers/useGetPrimary';
import { getThemeColor } from '../../json';
import logo from '../../logo192.png'
import { Aviso } from '../../styled/Text';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default ({ inicial = false }) => {

    const traducao = useConteudoTraduzido();

    const { cor_padrao } = useGetPrimary();
    const [ recarregue, setRecarregue ] = useState( "" );

    const cor = cor_padrao && cor_padrao !== "" ? cor_padrao : getThemeColor();

    useEffect(() => {
        if ( inicial ) {
            setTimeout(() => {
                setRecarregue( traducao.validacoes.recarregue_sua_pagina );
            }, 9*1000);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <div className="loading-screen">
            { 
            inicial && window.location.host === 'pedido.alphi.com.br' ? 
                <img src={ logo } alt="alphi.com.br" style={{ maxWidth: 250 }} />
                : null
                }
            <p className="pb-2">{ traducao.geral.carregando }...</p>
            <span style={{ borderColor: cor }} className="loading"></span>
            {
                inicial && recarregue.length > 0 ? 
                <Aviso>
                    { recarregue }
                </Aviso>
                : null
            }
        </div>
    )
}