import verificaQuantidadeMaxima from "./verificaQuantidadeMaxima";

const produtos = (prod) => {

    const qtds = prod.map(e => e.qtd);

    const soma = qtds.reduce((ant, atu) => ant + atu, 0);

    return soma;
}

/**
 * 
 * @param {*} required 
 * @param {*} cartData 
 */
const missProduto = (required, cartData) => {

    let exists = [];

    cartData.forEach(cart => {

        required.forEach(req => {


            let faltando = []

            if (cart.acompanhamento) {
                faltando = cart.acompanhamento.filter(e => {
                    return e.cat ? e.cat === req.nome : false;
                });
            }

            const totalProdutos = produtos(faltando);

            exists.push(
                req && totalProdutos < req.qtd ? 
                `${req.qtd - totalProdutos}x ${req.nome} em ${cart.nome} ${cart.index + 1}` 
                : null
            );

        });
    });

    const x = verificaQuantidadeMaxima(cartData, required);

    // console.log(x);

    let maximo = null;
    
    if ( x && typeof x === 'object' && Object.keys(x).length > 0 ) {
        Object.keys(x).forEach( el => {
            required.forEach( req => {
                if ( el === req.nome ){
                    if ( x[el] > req.qtd ){
                        maximo = `${ el } excedeu a quantidade permitida`;
                    }
                }
            })
        });
    }

    const missing = exists.filter(e => typeof (e) === 'string' && e.indexOf('undefined') === -1);
    const joined = missing.join(", ");

    if ( joined !== "" && maximo === null)
        return `Está faltando o(s) acompanhamento(s): ${joined}`;
    else if ( maximo !== null )
        return maximo;
    return null;
}

export default missProduto;