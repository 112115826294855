import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { removeScroll } from '../../actions';

export default (
   {
      show,
      setShowModal,
      data,
      children,
      titleUppercase,
      closeIcon = true,
      iconLeft = false
   }
) => {

   let display = show ? "show" : "none";

   const ref = useRef(null);

   const dispatch = useDispatch();

   useEffect(() => {

      dispatch(removeScroll(!show));

      if (typeof ref.current.scrollTo === 'function')
         ref.current.scrollTo(0, 0);

   }, [show, dispatch]);

   const formId = "form" + parseInt(Math.random() * 550);

   return (
      <>
         <div ref={ref} className={`form-group-modal ${display}`}>
            <header className="container center-justify space-between border-bottom">
               {
                  iconLeft ?
                     <button type="button" className="clean-button no-pad mt-1" onClick={() => setShowModal(false)}>
                        <span className="material-icons is-size-3 text-grey">keyboard_arrow_left</span>
                     </button>
                     : <span className="blanc-35"></span>
               }
               <div className="text-center flex-8">
                  <h6 className={`size-normal-small ${titleUppercase ? 'uppercase' : ''} has-text-weight-bold`}>
                     {data.title}
                  </h6>
                  <p className="subtitle">
                     <small>
                        {data.subtitle}
                     </small>
                  </p>
                  <p className="price">
                     {data.price}
                  </p>
               </div>
               {
                  closeIcon ?
                     <button type="button" className="clean-button" onClick={() => setShowModal(false)}>
                        <span className="material-icons text-grey size-bigger">expand_less</span>
                     </button>
                     : <span className="blanc-35"></span>
               }

            </header>

            <div className="container modal-bottom" id={formId}>
               {children}
            </div>
         </div>
         <div className={`close-modal-bg ${display}`} onClick={() => setShowModal(false)}></div>
      </>
   );
};