import React from 'react'
import { useHistory } from 'react-router';
import useGetPrimary from '../../helpers/useGetPrimary';
import { MiniTitle, Price, TitlePrimary } from '../../styled/Titles';

export default ({
    nome = "",
    descricao = "",
    moeda = "",
    valor,
    voltar = true,
}) => {

    const { cor_padrao } = useGetPrimary();
    const history = useHistory();

    return (
        <div className="flex border-bottom">
            {
                voltar ?
                    <div className="flex-3 _center pointer" onClick={() => history.goBack()}>
                        <span className="material-icons size-bigger">
                            keyboard_arrow_left
                        </span>
                    </div>
                    : 
                    <div className="flex-3"/>
            }

            <div>
                <TitlePrimary primary="rgba(0,0,0,.9)" bold >
                    {nome}
                </TitlePrimary>
                <MiniTitle color={cor_padrao} bold>
                    {descricao}
                </MiniTitle>
                {
                    valor && valor !== 0 ?
                        <Price>
                            {moeda}{typeof valor === 'number' ? valor.toFixed(2) : valor}
                        </Price>
                        : null
                }
            </div>
            <div className="flex-1" />
        </div>
    );
}