import { COMPANY_DATA } from '../actions/actionTypes';

/**
 * Aqui serão adicionados todos os dados da loja
 * Como nome, os produtos, etc. tudo relativo à loja
 */

const initialState = {
  data: {
    payload: {}
  }
};

export default (state = initialState, action) => {
    switch (action.type) {
      case COMPANY_DATA:
        return {
          ...state,
          data: action
        };
      default:
        return state;
    }
};