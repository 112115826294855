const calcSabores = (item, como_cobrar_pizza, tx_pizza_sabores = 0) => {

    const como_cobrar = como_cobrar_pizza ? como_cobrar_pizza : 'divide';

    const precos = item.sabor.map(e => e ? e.valor : 0);

    let soma = precos.reduce((prev, cur) => prev + cur, 0);

    switch (como_cobrar) {
        case "divide":
            soma = soma / item.sabor.length;
            break;
        case "dividecara":
            if (item.sabor.length > 2)
                soma = Math.max(...precos);

            else
                soma = soma / item.sabor.length;
            break;
        case "cara":
            soma = Math.max(...precos);
            break;
        default:
            soma = soma / item.sabor.length;
            break;
    }
    if (item.sabor.length >= 2 && tx_pizza_sabores)
        soma += tx_pizza_sabores;
    return soma;
};

export default calcSabores;