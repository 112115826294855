import React, { useState, useEffect } from 'react';
import Text from '../forms/inputs/Text';
import { useDispatch } from 'react-redux';
import { userPresente } from '../../actions';
import { TitlePrimary, MiniTitle } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default () => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido(); 

    const { cor_padrao } = useGetPrimary();

    const [ from, setFrom ] = useState( "" );
    const [ to, setTo ] = useState( "" );
    const [ message, setMessage ] = useState( "" );

    useEffect(() => {
        if (from.length > 2 && to.length > 2)
            dispatch( userPresente( { de: from, para: to, mensagem: message } ));
    }, [from, to, message, dispatch]);

    return (
        <section className="my-5 mt-6">

            <TitlePrimary as="h6" uppercase primary={ cor_padrao }>
                { traducao.dados_usuario.presente }
            </TitlePrimary>

            <MiniTitle bold>
                { traducao.dados_usuario.desejo_enviar_presente }
            </MiniTitle>
            
            <Text 
                value={ from }
                setValue={ val => setFrom(val) }
                placeholder={ traducao.dados_usuario.de }
            />
            <Text 
                value={ to }
                setValue={ val => setTo(val) }
                placeholder={ traducao.dados_usuario.para }
            />
            <Text 
                value={ message }
                setValue={ val => setMessage(val) }
                placeholder={ traducao.dados_usuario.mensagem }
            />
        </section>
    );
}