import React, { useEffect, useState } from 'react';
import BebidasModalCall from '../../modal/modalCallers/BebidasModalCall';
import useApiItens from '../../../hooks/useApiItens';
import Loading from '../../general/Loading';
import { DefaultContainer } from '../../../styled/Containers.tsx';
import CategoriesRow from '../../general/CategoriesRow';
import { MiniTitle } from '../../../styled/Titles';
import useGetPrimary from '../../../helpers/useGetPrimary';
import TituloProduto from '../../general/TituloProduto';

export default () => {

    const { itens, Data } = useApiItens();
    const [totalItens, setTotalItens] = useState([]);
    const { cor_padrao } = useGetPrimary();

    useEffect(() => {
        setTotalItens(itens
            && itens[0]
            && itens[0].produtos ?
            itens[0].produtos : [])

        // eslint-disable-next-line
    }, [itens]);

    return (
        <article>
            <DefaultContainer>
                <CategoriesRow />
            </DefaultContainer>

            <DefaultContainer className="mt-3">
                <TituloProduto
                    voltar={false}
                    nome={Data.nome}
                    descricao={Data.descricao}
                />
                {
                    totalItens && totalItens.length > 0 ?
                        totalItens
                            .map((e, index) => (
                                <div key={index}>
                                    <div className="flex border-bottom">
                                        <div className="flex-3" />
                                        <div>
                                            <MiniTitle color={cor_padrao} as="h4" bold>
                                                {e.nome}
                                            </MiniTitle>
                                            <MiniTitle mt={2} as="h5" color={cor_padrao} bold>
                                                {e.descricao}
                                            </MiniTitle>
                                        </div>
                                        <div className="flex-1" />
                                    </div>
                                    {
                                        e.produtos ?
                                            e.produtos.map(bebida => (
                                                <BebidasModalCall
                                                    key={bebida.id}
                                                    data={bebida}
                                                />
                                            ))
                                            : null
                                    }
                                </div>
                            ))
                        :
                        <Loading />
                }
            </DefaultContainer>
        </article>
    );
}