import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { userGeneral } from '../../actions';
import { finalizar } from '../../helpers';
import useGetPrimary from '../../helpers/useGetPrimary';
import { DefaultContainer } from '../../styled/Containers.tsx';
import { MiniTitle } from '../../styled/Titles';
import useMoeda from '../../traducao/useMoeda';
import Button from '../forms/Button';
import RadioInputControlled from '../forms/inputs/RadioInputControlled';
import Text from '../forms/inputs/Text';
import { MostrarErro } from './confirmation-components/geral';

export default () => {

    const [starAval, setStarAval] = useState(0);
    const [dicaAval, setDicaAval] = useState("");

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const moeda = useMoeda();

    const [desabilitar, setDesabilitar] = useState(false);
    const [submitErro, setSubmitErro] = useState(null);
    const [perguntaSimNao, setPerguntaSimNao] = useState({});
    const [perguntaTexto, setPerguntaTexto] = useState("");

    const { cart } = useSelector((state) => state.cartIten);
    const preferences = useSelector(state => state.userData);

    const primary = useGetPrimary();

    const estrelas = [
        { text: 'ruim', value: 1 },
        { text: 'medio', value: 2 },
        { text: 'bom', value: 3 },
        { text: 'muito bom', value: 4 },
        { text: 'excelente', value: 5 },
    ]

    const setAvaliacao = (star, dica) => {

        setStarAval(star);
        setDicaAval(dica);

        dispatch(userGeneral('MORE', { key: 'avaliacao', value: { estrela: star, comentarios: dica } }));
    }

    const setDadosPergunta = (simNao, texto) => {

        const sim_nao = simNao.id === 'sim';

        setPerguntaSimNao(simNao);
        setPerguntaTexto(texto);

        dispatch(userGeneral('MORE', { key: 'pergunta', value: { sim_nao, texto: texto } }));

    }

    return (
        <DefaultContainer>

            <MostrarErro submitErro={submitErro} />


            {
                location.state.data?.pergunta_final_sim_nao
                    || location.state.data?.pergunta_final_texto
                    ?
                    <>
                        <MiniTitle size={1} bold color={primary.cor_padrao}>
                            {location.state.data.pergunta_final}
                        </MiniTitle>

                        {
                            location.state.data.pergunta_final_sim_nao ?

                                <>
                                    <RadioInputControlled
                                        getValue={(val) => setDadosPergunta(val, perguntaTexto)}
                                        selected={perguntaSimNao}
                                        data={{ label: "Sim", id: "sim" }}
                                        product={{}}
                                    />
                                    <RadioInputControlled
                                        getValue={(val) => setDadosPergunta(val, perguntaTexto)}
                                        selected={perguntaSimNao}
                                        data={{ label: "Não", id: "nao" }}
                                        product={{}}
                                    />
                                </>

                                : null
                        }

                        {
                            location.state.data.pergunta_final_texto ?
                                <Text
                                    title=""
                                    placeholder={location.state.data.pergunta_final}
                                    initialValue={perguntaTexto}
                                    classes="pt-5 mt-2"
                                    setValue={(val) => setDadosPergunta(perguntaSimNao, val)}
                                />
                                : null
                        }

                    </>
                    : null
            }

            {
                location.state.data?.avaliacao ?
                    <>
                        <MiniTitle size={1} bold color={primary.cor_padrao}>
                            Você está gostando dos nossos serviços?
                        </MiniTitle>

                        <div className="_center pt-4">
                            <MiniTitle className="flex-12 has-text-centered pb-3" size={.95} bold>
                                O quando você achou do serviço?
                            </MiniTitle>
                            {
                                estrelas.map((star) => (
                                    <span
                                        key={star.text}
                                        className={`material-icons ${star.value <= starAval.value ? 'has-text-warning' : ''}`}
                                        onClick={() => setAvaliacao(star, dicaAval)}
                                    >
                                        grade
                                    </span>
                                ))
                            }
                            <p className="flex-12 has-text-centered py-2">
                                {starAval.text}
                            </p>
                        </div>

                        <Text
                            title="Tem alguma dica de como melhorar nossos serviços?"
                            placeholder="Nos dê sua avaliação..."
                            initialValue={dicaAval}
                            classes="pt-5 mt-2"
                            setValue={(val) => setAvaliacao(starAval, val)}
                        />
                    </>
                    : null
            }



            <div className="_center">
                <Button
                    type="button"
                    click={desabilitar === false ? () => finalizar(
                        cart,
                        preferences,
                        location.state.data.dados,
                        setSubmitErro,
                        history,
                        location.state.data.finalPedido,
                        location.state.data.min,
                        moeda,
                        setDesabilitar,
                    ) : () => null}
                    classes="button has-text-light mt-5"
                >
                    Finalizar
                </Button>
            </div>

        </DefaultContainer>
    );
}