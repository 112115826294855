import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userGeneral } from '../../actions';
import { setUserPreferences, setFiliais } from '../../json';
import Lojas from '../category/Lojas';
import { getCompanyId } from '../../helpers';


/**
 * 
 * @returns {React.FunctionComponent}
 */
const EscolherLoja = () => {

   const dispatch = useDispatch();

   const { lojas } = useSelector(state => state.companyData.data.payload);
   const userPreferences = useSelector(state => state.userData);

   const setFilial = (id) => {

      setFiliais(getCompanyId, id);

      dispatch(userGeneral('MORE', { key: "filial", value: id }));
      setUserPreferences(userPreferences);
   };
   // console.log(lojas);
   return (
      <div className="container grid_lojas">
         {
            lojas
               && Array.isArray(lojas) ?
               lojas.map(e => (
                  <div onClick={() => setFilial(e.id)}>
                     <Lojas
                        key={e.id}
                        path={`/${e.id}`}
                        title={e?.first_name ?? e.bairro + ' - ' + e.cidade}
                        image={e.avatar}
                        quebrarLinha={false}
                        id={e.id}
                     />
                  </div>
               ))
               : ''
         }
      </div>
   );
};

export default EscolherLoja;

