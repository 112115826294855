import React, { useEffect, useState } from 'react';
import SnackData from '../SnackData';
import SnackModalForm from '../SnackModalForm';
import InputForm from '../InputForm';
import ProdutoAdic from '../../modal/ProdutoAdic';
import AddToCartModal from '../../request/AddToCartModal';
import ProdutoAcomp from '../../modal/modalCallers/ProdutoAcomp';
import { useDispatch } from 'react-redux';
import { removeTab } from '../../../actions';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';
import { getCompanyId } from '../../../helpers';
import { useGetPrimary } from '../../../helpers'

export default ({
   index,
   productCartData,
   setHolder,
   holder,
   data,
   setModal,
   modal
}) => {
   const traducao = useConteudoTraduzido();
   const dispatch = useDispatch();
   const { bgPrimary } = useGetPrimary()

   const [product, setProduct] = useState({});
   const [obs, setObs] = useState("");
   const [dadosModal, setDadosModal] = useState("adicional_itens");

   useEffect(() => {
      dispatch(removeTab(false));
      // eslint-disable-next-line
   }, [modal]);

   useEffect(() => {
      const dataNoQtd = { ...productCartData, qtd: 1 };
      setHolder([...holder, dataNoQtd]);
      setProduct(dataNoQtd);
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      let newHolder = holder;
      newHolder[index] = { ...product, qtd: 1, index };
      setHolder(newHolder);
      // eslint-disable-next-line
   }, [product]);

   const setObservation = val => {
      setObs(val);
      setProduct({ ...product, observacao: val });
   };

   const setAdicAcomp = adicAcomp => {
      setModal(index);
      setDadosModal(adicAcomp);
   };

   return (
      <>
         <SnackData title={`${data.nome} ${index + 1}`} data={{}} showImg={false}>
            <>
               {
                  product.adicionais ?
                     <small className="size-small has-text-grey pre-wrap">
                        {
                           product.adicionais.map(e => `${e.nome} ${e.qtd ? e.qtd : 1}x\n`)
                        }{
                           product.acompanhamento.map(e => `${e.nome} ${e.qtd ? e.qtd : 1}x\n`)
                        }
                     </small>
                     : null
               }
               {
                  getCompanyId !== "9085" ?
                     <div className="flex-column _center">
                        <input
                           value={obs}
                           onChange={e => setObservation(e.target.value)}
                           type="text"
                           className="observation mt-1"
                           placeholder={traducao.produtos.alguma_observacao}
                        />
                     </div>
                     : null
               }
               <div className="flex-column flex-12">
                  {
                     data.adicional_itens && data.adicional_itens.length > 0 ?
                        <button
                           style={bgPrimary}
                           className="button has-text-white flex-12 mt-1 px-1 size-normal-small"
                           type="button"
                           onClick={() => setAdicAcomp("adicional_itens")}
                        >
                           Incluir adicionais
                        </button>
                        : null
                  }
                  {
                     data.acompanhamento_itens && data.acompanhamento_itens.length > 0 ?
                        <button
                           style={bgPrimary}
                           className="button has-text-white flex-12 mt-1 px-1 size-normal-small"
                           type="button"
                           onClick={() => setAdicAcomp("acompanhamento_itens")}
                        >
                           {
                              data.acompanhamento_itens.length === 1 ?
                                 data.acompanhamento_itens[0].tipo_acompanhamento_nome || 'Acompanhamentos'
                                 : 'Acompanhamentos'
                           }
                        </button>
                        : null
                  }
               </div>
               <hr />
            </>
         </SnackData>
         <SnackModalForm
            title={data.nome}
            data={data}
            subtitle={`${dadosModal === "adicional_itens" ? 'Adicionais' : 'Acompanhamento'}`}
            setShowModal={() => setModal(false)}
            show={modal === index}
         >
            <InputForm
               border={false}
               title={`${dadosModal === "adicional_itens" ? 'Adicionais' : 'Acompanhamento'}`}
            >
               {
                  data[dadosModal].map((e, index) => (
                     dadosModal === "adicional_itens" ?
                        <ProdutoAdic
                           key={e.id_adicional}
                           data={e}
                           productCartData={product}
                           setProductCartData={setProduct}
                        />
                        : <ProdutoAcomp
                           key={index}
                           e={e}
                           setProductCartData={setProduct}
                           productCartData={product}
                        />
                  ))
               }
            </InputForm>
         </SnackModalForm>

         {
            !navigator.userAgent.indexOf('Safari') > -1 ?
               <AddToCartModal
                  className="translate-safari"
                  buttonMessage="Concluir"
                  cart={product}
                  show={modal === index}
                  setShow={() => setModal(false)}
                  noDispatch={true}
               />
               : null
         }

      </>
   );
};
