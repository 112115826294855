import styled from 'styled-components';
import {grey, success} from '../style-js';

interface iTitlePrimary {
    primary?: string;
    uppercase?: boolean;
}

export const TitlePrimary = styled.h2<iTitlePrimary>`
  display: block;
  margin-top: 10px;
  font-size: .9rem;
  margin-bottom: 15px;
  color: ${({primary}) => primary ? primary : grey};
  font-weight: 700 !important;
  text-transform: ${({uppercase}) => uppercase ? 'uppercase' : 'none'};
`;

interface iMinitTitle {
    mt?: number;
    size?: number | string;
    uppercase?: string;
    bold?: boolean;
}

export const MiniTitle = styled.h6<iMinitTitle>`
  display: block;
  margin-top: ${({mt}) => mt !== undefined ? mt : 10}px;
  font-size: ${({size}) => size ? size : '0.8'}rem;
  color: ${({color}) => color ? color : grey} !important;
  text-transform: ${({uppercase}) => uppercase ? uppercase : 'none'};
  font-weight: ${({bold}) => bold ? '600' : '400'};
`;

export const Price = styled.span`
  font-size: .85rem;
  color: ${success};
`;
