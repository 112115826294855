/**
 *  Google analytcs
 */
import ReactGA from 'react-ga'
import { getCompanyId } from './helpers'
import pixel from './pixel'
import axios from 'axios'
// import { getTelefone } from './json'

// const baseURL = process.env.NODE_ENV  ? 'https://app.alphi.com.br/' : 'http://192.168.68.1:3001/'
const baseURL = 'https://app.alphi.com.br/'


const api = axios.create({ baseURL })

const callApi = async (categoria, acao, descricao) => {

    // const telefone = getTelefone()

    await api.post(`usuario-online`, {
        categoria,
        acao,
        descricao: descricao || "Sem descrição",
        usuario: "42 984179233",//telefone,
        loja: getCompanyId,
    })
}


/**
 * 
 * @param {*} category categoria do click
 * @param {*} action ação executada
 * @param {*} label descição da ação
 */
export const clickGa = (category, action, label = '') => {
    // if (process.env.NODE_ENV === 'production') {
    // ReactGA.event({
    //     category,
    //     action,
    //     label
    // })


    callApi(category, action, label)

    // }
}

/**
 * 
 * @param {*} location 
 */
export const ouvirHistoricoGa = (location) => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)

    callApi('Entrar página', 'Usuario entrou numa página', location)

    if (window.fbq)
        window.fbq('trackCustom', 'Pagina', { pagina: location.pathname })
}

/**
 * 
 */
export const retornarGa = () => {
    clickGa(
        getCompanyId,
        'Usuario retornou para a página principal',
    )
    if (window.fbq)
        window.fbq('trackCustom', 'Pagina inicial', { descricao: "Usuário retornou à página inicial" })
}
export const telefoneGa = () => {
    clickGa(
        getCompanyId,
        'Usuario entrou para colocar seu telefone',
        'Usuario entrou /:id/checkout/phone '
    )
    if (window.fbq)
        window.fbq('trackCustom', 'telefone', { descricao: "Usuário acabou de colocar o telefone" })
}
/**
 * 
 */
export const confirmacaoGa = () => {
    clickGa(
        getCompanyId,
        "Usuário colocou telefone",
        "Entrou na rota /:id/checkout/before-confirm para confirmar seu pedido e colocar os dados (nome, endereco, etc.)"
    )
    if (window.fbq)
        window.fbq('trackCustom', 'confirmacao', { descricao: "Usuário entrou na rota de confirmação dos dados" })
}

/**
 * 
 * @param {string} adicRem valores: adicionou ou removeu 
 * @param {string} prod nome do produto 
 * @param {number} qtd quantidade 
 */
export const adicionarOuRemoverProduto = (adicRem, prod, qtd = 1) => {
    clickGa(
        getCompanyId,
        `Usuario ${adicRem} um produto`,
        `No cliente ${getCompanyId}, um usuário ${adicRem} o produto ${prod} (qtd atual: ${qtd})`
    )

    if (window.fbq) {
        window.fbq('trackCustom', 'produto', {
            descricao: `Um usuário ${adicRem} o produto ${prod}, (qtd atual: ${qtd})`
        })
    }
}


/**
 * 
 * @param {strin} mes mensagem final 
 */
export const mensagemFinal = mes => {
    clickGa(
        getCompanyId,
        'Usuario enviou pedido',
        `No cliente ${getCompanyId}, ${mes}`
    )
    if (window.fbq && mes !== 'um usuário completou com sucesso o pedido') {
        window.fbq('trackCustom', 'finalizar', {
            description: mes,
        })
    }
}

export const purchase = (mes, moeda, valor) => {
    if (window.fbq) {
        window.fbq('track', 'Purchase', {
            description: mes,
            currency: moeda === 'R$' ? 'BRL' : moeda,
            value: valor,
        })
    }
}

export const cupomGa = mes => {
    clickGa(
        'Cupom',
        'Usuario adicionou cupom',
        `No cliente ${getCompanyId}, ${mes}`
    )
}

/**
 * 
 */
export default (pixelId = null) => {
    if (process.env.NODE_ENV === 'production') {

        ReactGA.initialize('UA-126687755-1')
        ReactGA.pageview(window.location)

        if (!!pixelId && typeof pixelId === 'string' && pixelId.length > 2)
            pixel(pixelId)
    } else {
        return
    }
}