import React from 'react';
import { TitlePrimary } from '../../../styled/Titles';
import useGetPrimary from '../../../helpers/useGetPrimary';

const Text = ({
    title = "",
    complement,
    placeholder,
    classes,
    initialValue,
    setValue,
    classNameInput,
    id = "texto" + parseInt(Math.random() * 10000),
    primary = false,
    uppercase = false,
}) => {

    const { cor_padrao } = useGetPrimary();

    return (
        <div>
            <TitlePrimary
                as="h6"
                className={classes}
                uppercase={uppercase}
                primary={primary ? cor_padrao : false}
            >
                {title}
                <small className="has-text-weight-medium is-lowercase">{complement}</small>
            </TitlePrimary>
            
            <input
                id={id}
                value={initialValue}
                onChange={e => setValue(e.target.value)}
                type="text"
                className={`input ${classNameInput} `}
                placeholder={placeholder}
            />
        </div>
    );
};

export default Text;
