import calculatePrice from "./calculatePrice";
import addPromo from "./addPromo";
import { calculatePriceItem } from ".";



const calcular = (verificar, productCartData, pizza, inicial) => {

    if (verificar) {

        const itens = addPromo(productCartData.itens, pizza, "index");

        const borda = pizza.borda && pizza.borda.valor && !pizza.cobrar_borda ? pizza.borda.valor : 0;

        return inicial + calculatePrice(itens, true) - borda;
    }
}

function cobrarPizza(productCartData, pizza, inicial, campo = "cobrar_vl_pizza") {

    if (pizza[campo]) {

        const valor = calcular(true, productCartData, pizza, inicial);

        return valor > 0 ? valor : productCartData.valor;

    } else if (pizza.cobrar_borda && campo === "cobrar_vl_pizza") {

        // console.log(productCartData);

        const precos = productCartData.itens ?
            productCartData.itens.map(promo => calculatePriceItem([promo]))
            : [0];

        const borda = pizza.borda?.valor ? pizza.borda.valor : 0;

        const soma = precos.reduce((ant, atu) => {

            const anterior = ant || 0;
            const atual = atu || 0;

            return anterior + atual;
        }, 0);

        const valorFinal = soma + inicial + borda;

        return valorFinal > 0 ? valorFinal : productCartData.valor;
    }

    return productCartData.valor;
}

export default cobrarPizza;