import React from 'react'
import RadioInputControlled from '../forms/inputs/RadioInputControlled'
import {MiniTitle} from '../../styled/Titles'
import Text from '../forms/inputs/Text'
// import { useSelector } from 'react-redux';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido'
import {normalize} from '../../helpers'
import {Erro} from "../../styled/Text";

export default ({
                    dados,
                    value,
                    setValue,
                    troco,
                    setTroco,
                    cartoes_aceitos,
                    valor
                }) => {

    // const { cartoes_aceitos } = useSelector( state => state.companyData.data.payload );
    const id = normalize(dados && dados.nome ? dados.nome : "").toLowerCase()
    const traducao = useConteudoTraduzido()

    let subtitulo = ""
    if (dados.descricao) {
        subtitulo = dados.descricao + " "
    }

    if (dados.acrescimo || dados.desconto)
        subtitulo += `(${dados.acrescimo ? traducao.geral.acrescimo_de : traducao.geral.desconto_de} ${dados.acrescimo ? dados.acrescimo : dados.desconto}%)`
    //  if ( id.indexOf('cartao') > -1 && cartoes_aceitos && cartoes_aceitos !== "" )
    //      subtitulo += `\n ${ traducao.geral.cartoes_aceitos }: ${ cartoes_aceitos }`;
    if (normalize(dados.nome).toLowerCase().match('cartao de') && cartoes_aceitos) {
        subtitulo += cartoes_aceitos
    }
    return (
        <>
            <RadioInputControlled
                title={traducao.dados_usuario.pagamento}
                data={{
                    label: dados && dados.nome ? dados.nome : "",
                    id: id,
                    subtitle: subtitulo ? subtitulo : ""
                }}
                product={{acrescimo: dados.acrescimo, desconto: dados.desconto}}
                getValue={val => setValue(val)}
                selected={value}
            />

            {
                dados && dados.nome && dados.nome.toLowerCase() === 'dinheiro' && value.id === 'dinheiro' ?
                    <div className="mt-2 ml-3 flex-column">
                        <div class={"mb-3"}>
                            {troco > 0 && troco < valor &&
                                <Erro>
                                    O troco não pode ser menor que o valor do pedido
                                </Erro>
                            }
                        </div>
                        <div className="columns is-mobile">
                            <MiniTitle>
                                {traducao.dados_usuario.troco}:
                            </MiniTitle>
                            <Text
                                key="troco"
                                classNameInput="top-m6px"
                                placeholder={traducao.dados_usuario.troco}
                                initialValue={troco}
                                setValue={val => setTroco(val)}
                            />
                        </div>
                        <div className="columns is-mobile" onClick={() => setTroco("0")}>
                            <p className="flex-6">
                                <label htmlFor="t" className="mini-title size-small">
                                    {traducao.dados_usuario.nao_precisa_troco}
                                </label>
                            </p>
                            <p className="mt-m1">
                                <input
                                    checked={troco === "0"}
                                    onChange={() => setTroco("0")}
                                    type="checkbox"
                                    name="troco"
                                    id="t"
                                    value={"0"}
                                />
                                <span></span>
                            </p>
                        </div>
                    </div>
                    : null
            }
            <hr/>
        </>
    )
}