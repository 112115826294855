import React, { useState } from 'react';
import Text from '../forms/inputs/Text';
import { useDispatch, useSelector } from 'react-redux';
import { userGeneral } from '../../actions';
import Button from '../forms/Button';
import generateJson from '../../tratar-api/generateJson.ts';
import Axios from 'axios';
import { cupom } from '../../tratar-api';
import { Erro, Price } from '../../styled/Text';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import useMoeda from '../../traducao/useMoeda';
import Carregando from '../general/Carregando';
import { cupomGa } from '../../ga';

export default ({ setErro, usuario, erro }) => {

    const dispatch = useDispatch();
    const traducao = useConteudoTraduzido();
    const moeda = useMoeda();

    const [carregando, setCarregando] = useState(false);

    const [value, setValue] = useState("");
    const [message, setMessage] = useState(null);
    const [sucesso, setSucesso] = useState(null);

    const { cart } = useSelector(state => state.cartIten);
    const { canal, como_cobrar_pizza, tx_pizza_sabores } = useSelector(state => state.companyData.data.payload);
    const preferencias = useSelector(state => state.userData);

    const validarCoupon = async () => {

        const mensagemDeErro = {
            metodoEntrega: !usuario.metodo_entrega || usuario.metodo_entrega === "" ? traducao.validacoes.definir_entrega : undefined,
            endereco: usuario.metodo_entrega === "entrega" && !usuario.entrega ? traducao.validacoes.definir_endereco : undefined,
            metodoPagamento: !usuario.metodo_pagamento || usuario.metodo_pagamento === "" ? traducao.validacoes.definir_pagamento : undefined,
        }

        setErro(mensagemDeErro);

        if (!mensagemDeErro.metodoEntrega && !mensagemDeErro.endereco && !mensagemDeErro.metodoPagamento) {

            setCarregando(true);

            const { enviar } = generateJson(
                cart,
                preferencias,
                {
                    canal,
                    como_cobrar_pizza,
                    tx_pizza_sabores,
                    cupom: value
                },
            );

            await Axios.post(
                cupom(),
                enviar
            ).then(
                ({ data }) => {

                    setCarregando(false);

                    if (data.success && typeof data.data !== 'string' && (!data.data.erro || data.data.erro === "false")) {

                        cupomGa("um usuário adicionou um cupom com sucesso");

                        dispatch(userGeneral('COUPON', { coupon: value, ...data.data }));
                        setSucesso(`${traducao.geral.desconto_de} ${moeda}${data.data.vl_desconto.toFixed(2)}`);
                        setMessage(null);
                    }
                    else {

                        const erro = data.data.texto_cupom ? data.data.texto_cupom : "Ops, houve um problema no cupom, verifique o cupom e tente novamente";

                        cupomGa(`um usuário adicionou um cupom com o erro: ${erro}`);

                        setMessage(erro);
                    }
                }
            ).catch(e => {

                setCarregando(false);

                const erro = "Ops, houve um problema no processamento, verifique o cupom e tente novamente";

                cupomGa(`um usuário adicionou um cupom com um erro de retorno do servidor, isso pode ser cors ou outra coisa que impediu o retorno do json`);

                setMessage(erro);
            });

        }
    }

    const cupomMaiusculo = val => {
        const valor = val.toUpperCase();
        setValue(valor);
    }

    return (
        <section className="my-5 mt-6">
            {
                (erro.metodoEntrega || erro.endereco || erro.metodoPagamento) && value.length > 3 ?
                    <Erro>
                        {erro.metodoEntrega} {erro.endereco} {erro.metodoPagamento}
                    </Erro>
                    : null
            }
            <Text
                uppercase
                primary
                value={value}
                setValue={val => cupomMaiusculo(val)}
                title={traducao.geral.cupom_de_desconto}
                placeholder={traducao.geral.codigo_promocional}
                classes="uppercase"
            />
            {
                value.length > 2 ?
                    <Button classes="btn mt-2 py-2 flex-6" click={() => validarCoupon()}>
                        {traducao.geral.validar_cupom}
                    </Button>
                    : null
            }
            <br />
            {
                message ?
                    <>
                        <Erro bold>
                            {message}
                        </Erro>
                    </>
                    : null
            }{
                sucesso ?
                    <Price>
                        {sucesso}
                    </Price>
                    : null
            }
            <Carregando carregando={carregando} />
        </section>
    );
}