import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Header from '../components/header/header'
import HeaderSimple from '../components/header/HeaderSimple'
import {PagarPix} from "../components/body/PagarPix";

export default () => {
    return (
        <Switch>
            <Route path="/:id/pagar-pix" >
                <HeaderSimple status="Pagar com pix" />
            </Route>
            <Route path="/:id/status-pedido">
                <HeaderSimple status="Status do pedido" />
            </Route>
            <Route path="/:id/checkout/finish" />
            <Route path="/:id/status">
                <HeaderSimple status />
            </Route>
            <Route path="/:id/checkout/:any" component={HeaderSimple} />
            <Route path="/" component={Header} />
        </Switch>
    )
}