import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import getCompanyId from '../../helpers/getCompanyId'
import { findDepartment } from '../../helpers'
import { Link } from 'react-router-dom'
import useGetPrimary from '../../helpers/useGetPrimary'
import { retornarGa } from '../../ga'

export default () => {

    const { textPrimary, textPrimaryLight, borderPrimary } = useGetPrimary()

    const { departamento } = useSelector(state => state.companyData.data.payload)

    const ref = useRef(null)

    const [categorias, setCategorias] = useState([])

    const { department, cat } = useParams()

    const dptIndex = findDepartment(department, departamento)

    useEffect(() => {
        setCategorias(departamento && departamento[dptIndex] ?
            departamento[dptIndex].categorias
            : []
        )

    }, [departamento, dptIndex])

    useEffect(() => {
        if (categorias.findIndex) {
            const ind = categorias.findIndex(e => e.id === cat)

            if (ref.current && ind) {

                const goTo = ref.current.childNodes[ind]

                if (goTo && typeof ref.current.scrollTo === 'function')
                    ref.current.scrollTo(goTo.offsetLeft - 20, 0)
            }
        }

    }, [cat, categorias])


    return (
        <div className="columns is-mobile pl-1 mb-2">
            <div className="is-pulled-left pr-1 pt-1">
                <Link className="clean-button" to={`/${getCompanyId}`} onClick={retornarGa}>
                    <span
                        className="material-icons size-big"
                        style={textPrimary}
                    >
                        apps
                    </span>
                </Link>
            </div>
            <div className="flex scroll-x" ref={ref}>
                {
                    categorias ?
                        categorias.map((element, index) => (
                            <div
                                key={element.id}
                                className={
                                    `px-3 mr-2 mb-2 has-text-centered ${cat === element.id ? 'underline-title' : ''}`
                                }
                                style={borderPrimary}
                            // onClick={() => scroll(index)}
                            >
                                <Link to={`/${getCompanyId}/${department}/${element.tipo}/${element.id}`}>
                                    {
                                        element.nome ?
                                            <h5
                                                className={`no-warap-white-space has-text-weight-bold ${element.nome.length > 15 ? 'size-smaller' : 'size-small'}`}
                                                style={textPrimary}
                                            >
                                                {element.nome}
                                            </h5>
                                            : null
                                    }
                                </Link>
                            </div>
                        ))
                        : null
                }
            </div>
            {
                departamento && departamento[dptIndex] && departamento[dptIndex].categorias.length > 2 ?
                    <div
                        className="is-inline pl-1 pt-1 mr-m20px"
                        style={textPrimaryLight}
                    >
                        <span className="material-icons arrows">navigate_before</span>
                        <span className="material-icons arrows">navigate_before</span>
                    </div>
                    : null
            }
        </div>
    )
}