// @ts-nocheck
import React, { useEffect, useState } from 'react'
import SnackData from '../../forms/SnackData'
import SnackModalForm from '../../forms/SnackModalForm'
import AditionalOption from '../AditionalOption'
import Sabores from '../Sabores'
import EdgeOption from '../EdgeOption'
import DoughOption from '../DoughOption'
import CallShowModal from '../../forms/CallShowModal'
import { useSelector } from 'react-redux'
import AddToCartModal from '../../request/AddToCartModal'
import useGetPrimary from '../../../helpers/useGetPrimary'
import { MiniTitle } from '../../../styled/Titles'
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido'
import BebidaEmPizza from '../BebidaEmPizza'
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial'
import { Success } from '../Success'


const MODAL_SABORES = 1
const MODAL_BORDAS = 2
const MODAL_BEBIDAS = 3
const MODAL_OBSERVACAO = 'obs'

export default ({ data = {} }) => {

    const traducao = useConteudoTraduzido()
    const { cor_padrao } = useGetPrimary()

    const [showModal, setShowModal] = useState(false)
    const [showInCart, setShowInCart] = useState(false)
    const [obs, setObs] = useState("")
    const { como_cobrar_pizza, tx_pizza_sabores, obrigar_borda } = useSelector(state => state.companyData.data.payload)
    const [cor, setCor] = useState(false)

    const inicial = useCarrinhoInicial('pizza', { ...data, como_cobrar_pizza, tx_pizza_sabores })

    const [productCartData, setProductCartData] = useState(inicial)
    const [bebidas, setBebidas] = useState([])

    const [success, setSuccess] = React.useState({
        show: false,
        success: true,
        title: "",
    })

    const next = () => {
        if (showModal === MODAL_SABORES && data && data.bordas?.length > 0)
            return 'Escolher borda'
        else if (
            showModal === MODAL_SABORES
            && data
            && (
                !data.bordas
                || data.bordas.length === 0
            ) && data.adicionais.length > 0
        )
            return 'Escolher adicionais'
        else if (
            showModal === MODAL_BORDAS
            && (
                (data.bebidas && data.bebidas.length > 0)
                || bebidas.length > 0)
        )
            return 'Escolher bebida'

        return traducao.geral.adicionar_ao_carrinho
    }

    const setObservation = e => {
        setObs(e)
        setProductCartData({ ...productCartData, observacao: e })
    }

    useEffect(() => {
        if (showModal === false) {
            setProductCartData(inicial)
            setObs("")
            setBebidas([])
        }
        // eslint-disable-next-line
    }, [showModal])

    const getBebidas = () => {
        let beb = []
        let allBebs = []
        productCartData.sabor.forEach(b => {
            const cat = b.category
            // const tam = productCartData.tamanho;
            data.pizzas.forEach(pz => {
                if (pz.nome === cat && beb !== false) {
                    allBebs.push(pz.bebidas)
                    if (pz.bebidas && beb !== false) {
                        beb = pz.bebidas
                    } else {
                        beb = false
                    }
                }
            })
        })

        // Se não tover nenhum item sem bebidas...
        if (allBebs.findIndex((b) => b?.length === 0) === -1) {
            return beb
        }
        return []
    }

    const proximo = () => {

        const beb = getBebidas()
        if (Array.isArray(beb)) {
            setBebidas(beb)
        }
        if (
            showModal === MODAL_SABORES // Esta em sabores
            && (data.adicionais.length > 0 // tem adicionais
                || data.bordas.length > 0) // tem bordas
        ) {
            return MODAL_BORDAS
        }
        else if (
            showModal === MODAL_BORDAS // Esta em bordas
            && (
                (data.bebidas && data.bebidas.length > 0) // tem bebidas
                || (beb && beb.length > 0 // tem beb
                )
            )) {
            return MODAL_BEBIDAS
        }
        else if (
            showModal === MODAL_BEBIDAS     // Esta em bebidas
            || (
                showModal === MODAL_BORDAS  // Esta em bordas
                && (!data.bebidas           // Nao tem bebidas
                    || data.bebidas.length === 0 // Nao tem bebidas
                ))
            || (
                showModal === MODAL_SABORES
                && !(
                    data.adicionais.length > 0 // Tem adicionais
                    || data.bordas.length > 0  // Tem bordas
                )
            )) {
            if (
                (obrigar_borda
                    && productCartData.borda
                    && productCartData.borda.nome)
                || !obrigar_borda
            ) {
                return MODAL_OBSERVACAO
            } else {

                setSuccess({
                    show: true,
                    success: false,
                    title: "Você precisa escolher uma borda",
                })
                return MODAL_BORDAS
            }
        }
        else {
            return false
        }
    }

    return (
        <div className="forms options-open-modal border-bottom">
            <section className="space-between center-align">

                <SnackData
                    data={data}
                    title={data.tamanho}
                    showModal={() => setShowModal(1)}
                >
                    <CallShowModal
                        setShowModal={() => setShowModal(1)}
                        showModal={showModal}
                        icon="expand_more"
                    />
                </SnackData>

                <SnackModalForm
                    title={data.tamanho}
                    subtitle={data.descricao}
                    data={data}
                    show={showModal === MODAL_SABORES}
                    setShowModal={() => setShowModal(false)}
                    setCor={setCor}
                >

                    <DoughOption
                        data={data}
                        setProductCartData={val => setProductCartData(val)}
                        productCartData={productCartData}
                    />
                    <Sabores
                        data={data}
                        setProductCartData={val => setProductCartData(val)}
                        productCartData={productCartData}
                        ready={val => setShowInCart(val)}
                        showModal={showModal}
                        next={data.adicionais && data.bordas && (data.adicionais.length > 0 || data.bordas.length > 0) ? () => setShowModal(2) : () => null}
                    />
                </SnackModalForm>

                <SnackModalForm
                    title={data.tamanho}
                    subtitle={data.descricao}
                    data={data}
                    show={showModal === MODAL_BORDAS}
                    setShowModal={() => setShowModal(false)}
                >
                    <EdgeOption
                        data={data}
                        setProductCartData={val => setProductCartData(val)}
                        productCartData={productCartData}
                        cor={cor}
                    />
                    <AditionalOption
                        data={data}
                        setProductCartData={val => setProductCartData(val)}
                        productCartData={productCartData}
                    />
                </SnackModalForm>

                <SnackModalForm
                    title={data.tamanho}
                    subtitle={data.descricao}
                    data={data}
                    show={showModal === MODAL_BEBIDAS}
                    setShowModal={() => setShowModal(false)}
                >

                    {
                        data.bebidas && data.bebidas.length > 0 ?
                            <BebidaEmPizza
                                bebidas={data.bebidas}
                                setProductCartData={val => setProductCartData(val)}
                                productCartData={productCartData}
                            />
                            : null
                    }
                    {
                        bebidas && bebidas.length > 0 ?

                            <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                                Refrigerante grátis
                            </MiniTitle>
                            : null
                    }
                    {
                        bebidas && bebidas.length > 0 ?
                            <BebidaEmPizza
                                bebidas={bebidas}
                                setProductCartData={val => setProductCartData(val)}
                                productCartData={productCartData}
                            />
                            : null
                    }
                    <div className="pt-3 w-100">

                        <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                            {traducao.produtos.alguma_observacao}
                        </MiniTitle>

                        <div className="flex-12 mb-1">
                            <input
                                value={obs}
                                onChange={e => setObservation(e.target.value)}
                                type="text"
                                className="observation"
                                placeholder={traducao.produtos.alguma_observacao}
                            />
                        </div>
                    </div>
                </SnackModalForm>

                <SnackModalForm
                    title={data.tamanho}
                    subtitle={data.descricao}
                    data={data}
                    show={showModal === MODAL_OBSERVACAO}
                    setShowModal={() => setShowModal(false)}
                >
                    <div className="pt-3 w-100">

                        <MiniTitle uppercase="uppercase" color={cor_padrao} bold>
                            {traducao.produtos.alguma_observacao}
                        </MiniTitle>

                        <div className="flex-12 half-margin">
                            <input
                                value={obs}
                                onChange={e => setObservation(e.target.value)}
                                type="text"
                                className="observation"
                                placeholder={traducao.produtos.alguma_observacao}
                            />
                        </div>
                    </div>
                </SnackModalForm>
            </section>

            <Success
                success={success}
                setSuccess={setSuccess}
            />

            <AddToCartModal
                buttonMessage={next()}
                cart={productCartData}
                show={
                    showModal === MODAL_OBSERVACAO
                    || ((
                        (productCartData.sabor.length > 0 && showModal === MODAL_SABORES)
                        || (obrigar_borda && productCartData.borda && showInCart)
                        || (!obrigar_borda && showInCart)

                    )
                        && (
                            showModal !== MODAL_BEBIDAS
                            || (
                                showModal === MODAL_BEBIDAS
                                && bebidas.length > 0
                                && productCartData.bebida)
                            || bebidas.length === 0
                        )
                        && showModal !== false)
                }
                noDispatch={showModal !== 'obs'}
                setShow={() => setShowModal(proximo())}
            />

        </div>
    )
}