import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App.tsx';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import Store from './store';
import { getCompanyId } from './helpers';
import { getDefaultApp } from './json';

if ( getCompanyId === "" )
  window.location = getDefaultApp();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
