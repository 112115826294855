import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useDispatch } from 'react-redux'
import { removeTab, addItenToCart } from '../../../actions'
import useCarrinhoInicial from '../../../hooks/useCarrinhoInicial'
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido'
import NumberInputControlled from '../../forms/inputs/NumberInputControlled'
import SnackData from '../../forms/SnackData'
import TituloProduto from '../../general/TituloProduto'
import AddToCartConfirm from '../../request/AddToCartConfirm'
import { getCompanyId } from '../../../helpers'
import Whatsapp from '../../../img/whats.png'


function MonteMinimalModalCall({ Data }) {

    const celular = "+55 73 8131-4550"

    const traducao = useConteudoTraduzido()
    const dispatch = useDispatch()
    const carrinho = useCarrinhoInicial('monte', Data)

    const [productCartData, setProductCartData] = React.useState(carrinho)

    const aumentarQtd = (val, prdt) => {
        let novosAdicionais = productCartData.adicionais

        let usado = false
        let remover = null

        novosAdicionais.forEach((item, index) => {
            if (item.id === prdt.id && item.nome === prdt.nome && !usado) {
                novosAdicionais[index].qtd = val.qtd
                usado = true

                if (!novosAdicionais[index].qtd) {
                    remover = index
                }
            }
        })
        if (!usado && val.qtd > 0) {
            novosAdicionais.push({ ...val, ...prdt })
        }
        if (typeof remover === 'number') {
            novosAdicionais.splice(remover, 1)
        }

        return setProductCartData({ ...productCartData, adicionais: [...novosAdicionais] })
    }

    const verifyQuantity = () => {
        return productCartData.adicionais
            .map((item) => item.qtd)
            .reduce((anterior, este) => anterior + este, 0)
    }

    function adicionarAoCarrinho() {
        if (verifyQuantity() >= Data.minimal) {

            const group = Math.floor(Math.random() * 100)
            for (let item of productCartData.adicionais) {
                dispatch(addItenToCart({
                    ...item,
                    tipo: "monte",
                    parent: productCartData.nome,
                    groupInCart: group,
                }))
            }
            setProductCartData(carrinho)
        } else {
            confirmAlert({
                title: "Você ainda precisa completar o pedido",
                message: "A quantidade minima para este combo é de: " + Data.minimal,
                buttons: [
                    {
                        label: "Entendi"
                    }
                ]
            })
        }
    }

    React.useEffect(() => {
        if (productCartData.adicionais.length > 0) {
            dispatch(removeTab(false))
        } else {
            dispatch(removeTab(true))
        }
        // eslint-disable-next-line
    }, [productCartData])

    return (
        <div className="forms options-open-modal border-bottom">
            <TituloProduto
                nome={Data.nome}
                descricao={Data?.descricao ?? "Quantidade na categoria: " + Data.minimal}
                voltar={true}
            />
            <section className="space-between center-align">

                {

                    Data?.itens?.map((category) => (
                        getCompanyId === "10087" &&
                            Data.id === 'monte_3474' ?
                            <a
                                target="blank"
                                rel="noreferer noopener"
                                className="space-between center-align flex-12"
                                href={`https://api.whatsapp.com/send?phone=${celular}&text=Olá,+gostaria+de+pedir+um+${category.nome}`}
                            >
                                <SnackData
                                    title={category.nome}
                                    icon="expand_more"
                                    data={category}
                                    desconto={{ valor_sem_desconto: category.valor_sem_desconto, valor_tem_desconto: category.valor_tem_desconto }}
                                    price={category.valor}
                                    aditionalTitle="Adicionais"
                                >
                                    {
                                        <div className='flex-2'>
                                            <img src={Whatsapp} alt='wahtsapp' width={30} height={30} />
                                        </div>

                                    }
                                </SnackData>
                            </a>
                            :
                            <SnackData
                                title={category.nome}
                                icon="expand_more"
                                data={category}
                                desconto={{ valor_sem_desconto: category.valor_sem_desconto, valor_tem_desconto: category.valor_tem_desconto }}
                                price={category.valor}
                                aditionalTitle="Adicionais"
                            >

                                <NumberInputControlled
                                    data={{
                                        containerClass: "_center flex-3",
                                        id: category.id,
                                        title: category.nome
                                    }}
                                    product={{ ...productCartData }}
                                    setValue={() => null}
                                    getValue={(val) => aumentarQtd(val, category)}
                                    value={
                                        productCartData
                                            ?.adicionais
                                            ?.filter((item) => (
                                                item.id === category.id && item.nome === category.nome
                                            ))[0]
                                            ?.qtd
                                        ?? 0
                                    }
                                />

                            </SnackData>
                    ))
                }

                <AddToCartConfirm
                    show={productCartData.adicionais.length}
                    setShow={() => setProductCartData(carrinho)}
                    buttonMessage={traducao.geral.adicionar_ao_carrinho}
                    cart={productCartData}
                    index={{ atual: 1, tamanho: 1 }}
                    dispatchAqui={false}
                    next={adicionarAoCarrinho}
                />
            </section>
        </div >
    )
}

export default MonteMinimalModalCall