import React, { useEffect, useState } from 'react';
import useApiItens from '../../../hooks/useApiItens';
import PromoGeralModalCall from '../../modal/modalCallers/promo/PromoGeralModalCall';
import { useParams } from 'react-router';
import { findDepartment, normalize } from '../../../helpers';
import FormPromoDescritiva from '../../forms/FormPromoDescritiva';
import CategoriesRow from '../../general/CategoriesRow';
import Text from '../../forms/inputs/Text';
import { DefaultContainer } from '../../../styled/Containers.tsx';
import useConteudoTraduzido from '../../../traducao/useConteudoTraduzido';

export default () => {

    const { itens } = useApiItens();
    const traducao = useConteudoTraduzido();

    const [tp1, setTp1] = useState([]);
    const [search, setSearch] = useState("");

    let { catId } = useParams();

    const catIndex = findDepartment(catId, itens && itens[0] ? itens[0].produtos : '');

    const data = itens && itens[0] && itens[0].produtos && itens[0].produtos[catIndex] ?
        itens[0].produtos[catIndex]
        : '';

    useEffect(() => {

        const produtos = itens && itens[0] ? itens[0].produtos : [];

        if (produtos.length > 0) {
            const tipo1 = produtos.filter(pd => pd.tipo === 1);
            setTp1(tipo1);
        }

    }, [itens]);

    useEffect(() => {
        const query = data.tipo === 1 ? tp1.filter(e => {
            const normalized = normalize(e.nome).toLowerCase();
            const searchNormalized = normalize(search).toLowerCase();
            return normalized.includes(searchNormalized);
        }) : null

        if (search !== "")
            setTp1(query);
        else
            setTp1(data.tipo === 1 ? itens[0].produtos.filter(pd => pd.tipo === 1) : []);
        // eslint-disable-next-line
    }, [search]);

    return (
        <article>
            <section>
                <DefaultContainer>
                    <CategoriesRow />
                </DefaultContainer>

                <div className="mt-3 container">
                    {
                        data.tipo === 1
                            && itens
                            && itens[0]
                            && itens[0].produtos
                            && itens[0].produtos.length > 15 ?
                            <div className="mb-3">
                                <Text
                                    title={traducao.geral.pesquise_o_produto}
                                    placeholder={traducao.geral.pesquise_aqui + "..."}
                                    initialValue={search}
                                    setValue={val => setSearch(val)}
                                />
                            </div>
                            : null
                    }

                    {
                        itens
                            && itens[0]
                            && itens[0].produtos ?
                            itens[0].produtos.map((prod, index) => {
                                if (prod.tipo !== 1)
                                    return <PromoGeralModalCall key={index} data={prod} />
                                else
                                    return <FormPromoDescritiva key={index} data={prod} />
                            })
                            : null
                    }
                </div>
            </section>
        </article>
    );
}