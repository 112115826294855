import { REMOVE_TAB } from '../actions/actionTypes';

/**
 *  Remove e adiciona a barra inferior
 */

const initialState = true;
  
export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_TAB:
            return action.payload;
        default:
            return state;
    }
}