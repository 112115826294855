import React from 'react';
import useGetPrimary from '../../helpers/useGetPrimary';
import { getThemeColor } from '../../json';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

export default ({ carregando }) => {

    const { cor_padrao, textPrimary } = useGetPrimary();
    const traducao = useConteudoTraduzido();

    const cor = cor_padrao && cor_padrao !== "" ? cor_padrao : getThemeColor();

    return (
        carregando ? 
            <div className="carregando-endereco">
                <p style={ textPrimary } className="pb-2 size-big">{ traducao.geral.carregando }...</p>
                <span style={{ borderColor: cor }} className="loading"></span>
            </div>
        : null
    )
}