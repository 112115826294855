import React, { useRef, useEffect } from 'react';
import { replacer } from '../../helpers';

export default (
    { 
        show, 
        setShowModal, 
        price, 
        children, 
        titleUppercase, 
        closeIcon = true, 
        iconLeft = false,
        title = "",
        subtitle,
        index,
        id=null
    }
) => {

    const ref = useRef();

    let display = show ? "show" : "none";

    const formId = replacer(title) + "-" + index;

    useEffect(() => {
        if ( typeof ref.current.scrollTo === 'function' )
        ref.current.scrollTo(0,0);
    }, [show])

    return (
        <>
        <div ref={ ref } id={ id ? id : formId } className={`form-group-modal ${display}`}>
            <header className="container center-justify space-between border-bottom">
                {
                    iconLeft ?
                    <button type="button" className="clean-button" onClick={ () => setShowModal(false) }>
                        <span className="material-icons is-size-2 text-grey">keyboard_arrow_left</span>
                    </button>
                    : <span className="blanc-35"></span>
                }
                <div className="text-center flex-7">
                    <h6 className={`size-normal-small ${titleUppercase ? 'uppercase' : ''} has-text-weight-bold`}>
                        { title }
                    </h6>
                    <p className="subtitle">
                        <small>
                            { subtitle }
                        </small>
                    </p>
                    <p className="price">
                        { price }
                    </p>
                </div>
                {
                    closeIcon ?
                    <button type="button" className="clean-button" onClick={ () => setShowModal(false) }>
                        <span className="material-icons text-grey size-bigger">expand_less</span>
                    </button>
                    : <span className="blanc-35"></span>
                }

            </header>

            <div className="container modal-bottom">
                { children }
            </div>
        </div>
        <div className={`close-modal-bg ${display}`} onClick={() => setShowModal(false)}></div>
        </>
    );
}