import styled from 'styled-components';
import { color } from '../style-js';

export const ContainerRow = styled.div`
  display: flex !important;
  margin-bottom: -5px !important;
  flex-wrap: wrap;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const ContainerCenteredAll = styled.article`
  width: 100vw;
  height: 100vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center !important;
  background-color: ${color};
`;

interface iContainerMargin {
  top: string | number;
  left: string | number;
  right: string | number;
  bottom: string | number;
}

export const ContainerMargin = styled.div<iContainerMargin>`
  margin-top: ${({ top }) => top ? top : '0'}rem;
  margin-left: ${({ left }) => left ? left : '0'}rem;
  margin-right: ${({ right }) => right ? right : '0'}rem;
  margin-bottom: ${({ bottom }) => bottom ? bottom : '0'}rem;
`;

interface iContainerBackground {
  img: string;
}
export const ContainerBackground = styled.div<iContainerBackground>`
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: .075;
  z-index: 0;
`;

export const ContainerDepartamentos = styled.section`
  display: flex;
  margin-right: -0.75rem;
  margin-top: -0.75rem;
  margin-left: -0.1rem;
  overflow-x: auto;
  padding-bottom: 15px !important;
`;

export const DefaultContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  position: relative;
  width: auto;
  max-width: 100vw !important;
`;

export const ContainerIcone = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 32%;
  width: 32%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface iContainerColumn {
  column: number;
  direction: string;
}

export const ContainerColumn = styled.div<iContainerColumn>`
  flex: 0 0 ${({ column }) => column * 8.25}%;
  width: ${({ column }) => column * 8.25}%;
  flex-direction: ${({ direction }) => direction || 'row'};
`;