import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {calculatePrice, getCompanyId, useGetPrimary} from '../../helpers';
import {useLimparDados} from '../../hooks/useLimparDados';
import {getCart, getResultadoFinal} from '../../json';
import {ContainerMargin} from '../../styled/Containers.tsx';
import {MiniTitle, TitlePrimary} from '../../styled/Titles';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';
import useMoeda from '../../traducao/useMoeda';
import ModalCompressed from '../forms/ModalCompressed';
import Cart from '../request/Cart';
import FaseList from './FaseList';
import {Status} from './status.class';
import {fases} from './status.fase';
import './statusPedido.sass';
import {getStatus} from "./FinalizarOPedido";

const StatusPedido = () => {
    const moeda = useMoeda();
    const traducao = useConteudoTraduzido();
    const {cor_padrao, bgPrimary, textPrimary} = useGetPrimary();
    const {
        nome,
        logo,
        telefone,
        tel_confirma_pedido
    } = useSelector((state) => (state?.companyData?.data?.payload));
    const valor = calculatePrice(getCart(), true);
    const statusSettings = getResultadoFinal();
    const history = useHistory();

    const [status, setStatus] = React.useState(new Status());
    const [interval, setInverval] = React.useState();
    const [mostrarCarrinho, setMostrarCarrinho] = React.useState(false);

    const [showModalWhatsapp, setShowModalWhatsapp] = React.useState(false);

    let celular = '';

    const replaceStuff = (rep) => {
        if (typeof rep.replace === 'function') {
            return rep.replace(/\+\s-\(\)/g);
        } else {
            return rep;
        }
    };

    if (typeof tel_confirma_pedido === 'string') {
        celular = replaceStuff(tel_confirma_pedido);

    } else if (typeof telefone === 'string') {
        celular = replaceStuff(telefone);

    } else {
        celular = null;
    }


    const dadosUsuario = useLimparDados();

    React.useEffect(() => {
        getStatus(statusSettings, setStatus);
        const timer = setInterval(() => getStatus(statusSettings, setStatus), 70000);
        setInverval(timer);
        return () => clearInterval(timer);
    }, []);


    React.useEffect(() => {
        if ((status.status === 'finalizado' || status.status === 'retirado' || status.status === 'cancelado') && interval) {
            clearInterval(interval);
        }
    }, [interval, status]);

    React.useEffect(() => {
        if (celular) {
            setShowModalWhatsapp(true);
        }
    }, [celular]);

    return (
        <article className="container checkout forms">
            <ContainerMargin>
                <section className='white-box'>
                    <MiniTitle bold size={1}>
                        Pedido enviado!
                    </MiniTitle>
                    <p>
                        Seu pedido foi enviado, você pode ver as etapas aqui
                    </p>
                </section>
                <section className='white-box mt-5'>
                    <div className='_center'>
                        <MiniTitle size={1} bold>
                            Status do pedido
                        </MiniTitle>
                    </div>
                    <ul className='status-lista'>
                        {status.payment === 'pending' ?
                            <li>
                                <span className='material-icons icone-ativo'>
                                    refresh
                                </span>
                                <MiniTitle size={1}>
                                    Aguardando aprovação do pagamento
                                </MiniTitle>
                            </li>
                            : ''}
                        {
                            fases.map((fase, index) => (
                                <FaseList
                                    fase={fase}
                                    status={status}
                                    index={index}
                                />)
                            )
                        }

                    </ul>
                </section>
            </ContainerMargin>

            <div
                className="add-to-cart show-submit z-if-none-out"
                style={{flexWrap: 'wrap', height: 180}}>
                <div className='flex flex-12' style={{alignItems: 'center'}}>
                    <img src={logo} className='app-logo' alt={nome} style={{width: 50, height: 50}}/>
                    <div style={{marginLeft: 15}}>
                        <MiniTitle size={1} bold>
                            {nome}
                        </MiniTitle>
                        <TitlePrimary style={{fontSize: '1.06rem'}} primary={cor_padrao}>
                            Total: {moeda}{dadosUsuario?.valor_total ?? dadosUsuario?.valor ?? valor.toFixed(2)}
                        </TitlePrimary>
                        {
                            !!dadosUsuario?.valorEntrega &&
                            <TitlePrimary style={{fontSize: '.7rem'}}>
                                Entrega: {moeda}{dadosUsuario.valor_entrega}
                            </TitlePrimary>
                        }
                    </div>
                </div>
                {
                    !!celular &&
                    <a
                        href={`https://api.whatsapp.com/send?phone=${celular}`}
                        className="btn size-small button has-text-weight-bold"
                        target="blank"
                        rel="noreferer noopener"
                        style={{backgroundColor: '#53a653'}}
                    >
                        Conversar no whatsapp
                    </a>
                }

                <button
                    type="button"
                    className="btn size-small"
                    style={bgPrimary}
                    onClick={() => setMostrarCarrinho(!mostrarCarrinho)}
                >
                    Ver pedido
                </button>
                <div className='flex-12 _center'>
                    <button
                        onClick={() => history.push(`/${getCompanyId}`)}
                        className="button has-text-weight-bold"
                        style={{...bgPrimary, color: '#fff', textShadow: '1px 1px rgba(0,0,0,.5)'}}
                    >
                        Fazer outro pedido/ voltar ao inicio
                    </button>
                </div>
            </div>
            <div style={{marginBottom: 100}}></div>

            <ModalCompressed
                titleUppercase
                title="Pedido finalizado!!"
                show={showModalWhatsapp}
                setShowModal={() => setShowModalWhatsapp(!showModalWhatsapp)}
                closeIcon={false}
            >
                <MiniTitle>
                    Gostaria de enviar seu pedido para o whatsapp do estabelecimento?
                </MiniTitle>

                <div className='_center'>
                    <div className="mt-5">
                        <a
                            href={`https://api.whatsapp.com/send?phone=${celular}&text=${statusSettings.whatsapp}`}
                            target="blank"
                            rel="noreferer noopener"
                            className="button has-text-weight-bold"
                            style={{...textPrimary}}
                        >
                            {traducao.geral.enviar_pelo_whatsapp}
                        </a>
                    </div>

                    <div className="mt-5">
                        <button
                            className="button has-text-weight-bold text-danger"
                            onClick={() => setShowModalWhatsapp(false)}
                            type="button"
                        >
                            Ver status do pedido
                        </button>
                    </div>
                </div>


            </ModalCompressed>
            <Cart show={mostrarCarrinho} setShowModal={setMostrarCarrinho} esconder/>
        </article>
    );
};

export default StatusPedido;