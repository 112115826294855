import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Category from '../category';
import { findDepartment, normalize } from '../../helpers';
import getCompanyId from '../../helpers/getCompanyId';
import { useHistory, useParams } from 'react-router-dom';
import { ContainerDepartamentos, DefaultContainer } from '../../styled/Containers.tsx';
import { removeTab } from '../../actions';
import Button from '../forms/Button';
import { TitlePrimary } from '../../styled/Titles';
import useGetPrimary from '../../helpers/useGetPrimary';
import useConteudoTraduzido from '../../traducao/useConteudoTraduzido';

/**
 *  Lista as categorias para venda, como pizzas, bebidas, produtos etc.
 */
export default () => {

    const dispatch = useDispatch();

    const [pesquisa, setPesquisa] = useState("");

    const { cor_padrao } = useGetPrimary();

    const traducao = useConteudoTraduzido();

    // Os departamentos existentas na empresa
    const { departamento, modelo_cat } = useSelector(state => state.companyData.data.payload);
    // Pega da rota o parametro do departamento
    const { department } = useParams();
    // Acha o index do departamento no array em relação ao id pego anteriormente
    const dptIndex = findDepartment(department, departamento);

    // Verifica como deve mostrar as categorias
    const size = modelo_cat ? modelo_cat === 'linear' : false;

    const history = useHistory();

    useEffect(() => {
        dispatch(removeTab(true));
        // eslint-disable-next-line
    }, []);

    const pesquisar = event => {
        event.preventDefault();

        const normalized = normalize(pesquisa).toLowerCase();

        history.push(`/${getCompanyId}/pesquisa?${normalized}`);
    }

    const formularioPesquisaIDs = ["8261", "8728"]
    return (
        <article>
            {/* Coloca uma barra de pesquisa no cliente 8261 */}
            {
                formularioPesquisaIDs.includes(getCompanyId) ?
                    <DefaultContainer>
                        <TitlePrimary as="h6" primary={cor_padrao}>
                            {traducao.produtos.busca_rapida}
                        </TitlePrimary>
                        <form onSubmit={pesquisar} className="space-evenly">
                            <input
                                value={pesquisa}
                                onChange={e => setPesquisa(e.target.value)}
                                type="text"
                                className={`input flex-8`}
                                placeholder={"Ex: geléia"}
                            />
                            <p className="flex-3">
                                <Button type="submit" click={() => null} classes="button has-text-light">
                                    Pesquisar
                                </Button>
                            </p>
                        </form>
                    </DefaultContainer>
                    : null
            }

            {
                // Mostra os departamentos se tiver mais de 1
                Object.keys(departamento).length > 1 ?
                    <ContainerDepartamentos className="scroll-x category-grid is-department">
                        {
                            Object.keys(departamento).map((e, index) => (
                                <Category
                                    key={departamento[e].id}
                                    isCircle
                                    title={departamento[e].nome}
                                    image={departamento[e].img}
                                    img_ativo={departamento[e].img_ativo ? departamento[e].img_ativo : null}
                                    path={`/${getCompanyId}/${departamento[e].id}`}
                                    active={(parseInt(department) === departamento[e].id) || (!department && index === 0)}
                                />
                            ))
                        }
                    </ContainerDepartamentos>
                    : null
            }

            {/* Mostra as categorias do departamento em questão */}
            <DefaultContainer className={`container category-grid ${size ? 'is-column' : ''}`}>
                {
                    departamento
                        && departamento[dptIndex] ?
                        departamento[dptIndex].categorias.map(e => (
                            <Category
                                key={e.id}
                                path={`/${getCompanyId}/${department ? department : 1}/${e.tipo}/${e.id}`}
                                title={e.nome}
                                image={e.img}
                            />
                        ))
                        : null
                }
                {
                    getCompanyId === "10288" &&
                        <Category
                            outerLink={{
                                href: `https://whatsa.me/5591983412273/?t=Dieta%20personalizada`,
                                as: 'a',
                                target: 'blank',
                        }}
                            title={'Dieta personalizada'}
                            image={null}
                    />
                }
            </DefaultContainer>

            <div className="after-add-to-cart"></div>
        </article>
    );
}
