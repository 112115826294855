// eslint-disable-next-line no-unused-vars
import { finalizar, getCompanyId } from "."

export default (
    cart,
    preferences,
    dados,
    setSubmitErro,
    history,
    finalPedido,
    min, moeda,
    desabilitar,
    pergunta_final_texto,
    pergunta_final,
    pergunta_final_sim_nao,
    avaliacao,
) => {

    const telaAvaliacao = avaliacao || pergunta_final_sim_nao || pergunta_final_texto

    if (telaAvaliacao) {

        desabilitar(true)

        history.push({
            pathname: 'aval/',
            state: {
                data: {
                    dados,
                    finalPedido, min,
                    pergunta_final_texto,
                    pergunta_final,
                    pergunta_final_sim_nao,
                    avaliacao,
                }
            }
        })
    } else {
        finalizar(
            cart,
            preferences,
            dados,
            setSubmitErro,
            history,
            finalPedido,
            min,
            moeda,
            desabilitar,
            // getCompanyId === "36" || getCompanyId === "7434"
            // ? "/status-pedido"
            // : 
            
        )
    }
}