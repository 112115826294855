import Axios from 'axios';
import { usuario } from '../tratar-api';

const getUser = async (number, canal) => {
    
    let user = {};
    
    try {
        user = await Axios.get(usuario(canal, number));
    }
    catch( e ) {
        user = {};
    }
    return user.data;
}

export default getUser;