import Axios from "axios";

export interface iStatus {
    invoice_id: string
    order_id: string
    payment: string
    pix_qrcode: string
    pix_qrcode_text: string
    status: string
    type: string
}

export interface iStatusSettings {
    data: string
    success: boolean
    code: number
    url: string
    whatsapp: string
}

export function getStatus(statusSettings: iStatusSettings, setStatus: (data: any) => void) {
    Axios.get(statusSettings.url
        ?.replace(
            'http://alphi_2.0_pedido',
            'https://api.alphi.com.br'
        )
    )
        .then(({data}) => {
            setStatus(data.data);
        })
        .catch((err) => {
            console.log('erro status', err);
        });
}
