import React from 'react'
import Text from '../../../forms/inputs/Text'
import InputTwoColumns from '../../../forms/InputTwoColumns'

export default ({
    entrega,
    traducao,
    address,
    errMessage,
    setData,
    endereco,
}) => {
    return (
        <>
            {
                (
                    !entrega.remover_estado // remover estado é falso
                    && !entrega.remover_cidade // e remover cidade é falso 
                )
                    // ) ||
                // (
                //     !endereco[0].cidade // não tem cidade definida
                //     && !endereco[0].estado // nem estado definido
                // ) ?
                ?
                    // mostra um ao lado do outro
                    <InputTwoColumns
                        left={{
                            title: traducao.dados_usuario.cidade,
                            placeholder: traducao.dados_usuario.sua_cidade,
                            initial: address.cidade ? address.cidade : '',
                            invalid: errMessage.cidade,
                            classes: errMessage.cidade ? 'invalid' : ''
                        }}
                        setLeft={val => setData("cidade", val)}
                        right={{
                            title: traducao.dados_usuario.estado,
                            placeholder: traducao.dados_usuario.seu_estado,
                            initial: address.estado ? address.estado : '',
                            invalid: errMessage.estado,
                            classes: errMessage.estado ? 'invalid' : ''
                        }}
                        setRight={val => setData("estado", val)}
                    />
                    : null
            }{
                (
                    entrega.remover_estado // é pra remover o estado
                    && !entrega.remover_cidade // e não é pra remover a cidade
                ) 
                // || 
                // (
                //     !endereco[0].cidade  // não tem cidade
                //     && endereco[0].estado // e tem estado
                // ) 
                ?
                    // mostro a cidade
                    <Text
                        title={traducao.dados_usuario.cidade}
                        initialValue={address.cidade}
                        placeholder={traducao.dados_usuario.sua_cidade}
                        setValue={val => setData('cidade', val)}
                    /> : null
            }{
                (
                    !entrega.remover_estado // não é pra remover estado
                    && entrega.remover_cidade // e é pra remover a cidade
                ) 
                // || 
                // (
                //     !endereco[0].estado // não tem estado
                //     && endereco[0].cidade // e tem cidade
                // ) 
                ?
                    <Text
                        title={traducao.dados_usuario.estado}
                        initialValue={address.estado ? address.estado : ''}
                        placeholder={traducao.dados_usuario.seu_estado}
                        setValue={val => setData('estado', val)}
                    /> : null
            }
        </>
    );
}