import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCompanyId, normalize } from '../../helpers';
import useGetPrimary from '../../helpers/useGetPrimary';
import { DefaultContainer } from '../../styled/Containers.tsx';
import SnackData from '../forms/SnackData';
import TituloProduto from '../general/TituloProduto';

export default () => {

    const pesquisa = useRef( normalize( window.location.search.substring(1).toLocaleLowerCase() ) );
    const [ listaProdutos, setListaProdutos ] = useState( [] );
    const produtos = useSelector( state => state.companyData.data.payload );

    const { textPrimary } = useGetPrimary();

    useEffect(() => {
        
        let adicionarProdutos = [];

        produtos.departamento.forEach( dpt => {

            dpt.categorias.forEach( cat => {

                if ( cat.itens && Array.isArray( cat.itens ) ) {
                    cat.itens.forEach( prod => {

                        const normalized = normalize( prod.nome ).toLowerCase();

                        if ( normalized.includes( pesquisa.current )){
                            adicionarProdutos.push({ ...prod, departamento: dpt.id, tipo: cat.tipo, categoria: cat.id });
                        }
                    })
                }
            })
        });

        setListaProdutos( adicionarProdutos );

    }, [pesquisa, produtos]);

    return (
        <DefaultContainer>
            <TituloProduto
                nome={ "Pesquisa de produto" }
                descricao={ `${ listaProdutos.length } resultados para: ${ pesquisa.current }` }
                moeda={ null }
                valor={ null }
            />

            <div className="forms options-open-modal">
                <section className="space-between center-align">
                {
                    listaProdutos && listaProdutos.length > 0 ? 
                        listaProdutos.map(( produto, ind ) => (
                            <>
                            <SnackData
                                key={ ind }
                                title={ produto.nome }
                                data={ produto }
                                price={ produto.valor }
                                desconto={{ valor_sem_desconto: produto.valor_sem_desconto, valor_tem_desconto: produto.valor_tem_desconto }}
                            >
                                <Link to={ `/${ getCompanyId }/${ produto.departamento }/${ produto.tipo }/pesquisa/${ produto.categoria }/${ produto.id }` }>
                                    <span style={ textPrimary } className="flex-3 _center material-icons size-big">
                                        keyboard_arrow_right
                                    </span>
                                </Link>
                            </SnackData>
                            <hr className="flex-12" />
                            </>
                        ))
                    : "Não temos nenhum produto com esse nome"
                }
                </section>
            </div>
        </DefaultContainer>
    );
}