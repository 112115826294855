import styled from 'styled-components';
import { grey } from '../style-js';

export const Label = styled.label`
    display: block; 
    width: 100%;
    margin-top: 10px;
    font-size: .87em;
    color: ${ grey };
    cursor: pointer;
    font-weight: 500 !important;
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
`;