import React from 'react';
// import { adicionarOuRemoverProduto } from '../../../ga';
import { replacer } from '../../../helpers';
import useMoeda from '../../../traducao/useMoeda';

export default ({
    data,
    title,
    getValue,
    product = {},
    showPrice = true,
    ativo = false,
}) => {
    const name = replacer(title);
    const value = replacer(data.label) + (parseInt(Math.random() * 550));
    const moeda = useMoeda();

    const adicionar = () => {
        getValue({
            title: data.label,
            id: data.id,
            ...product
        });
    }

    return (
        <div className="w-100">
            <label
                htmlFor={value}
                className="mini-title size-normal-small space-between w-100 is-lowercase 
                    capitalized pointer has-text-weight-medium"
                onClick={adicionar}
            >
                <p className='max-90'>
                    {data.label}
                </p>
                <p className="_center mb-m5px">
                    <input className={ativo && ativo.id && ativo.id === data.id ? 'checked' : '' } type="radio" name={name} id={value} value={value} />
                    <span></span>
                    {/* <Select dark={dark} className={ativo && ativo.id && ativo.id === data.id ? 'checked' : '' } type="radio" name={name} id={value} value={value} /> */}
                </p>
            </label>
            {
                data.subtitle !== null ?
                    <small className="mini-title mt-0">
                        {data.subtitle}
                    </small>
                    : ''
            }
            {
                data.price && showPrice ?
                    <span className="price">
                        {moeda}{parseFloat(data.price).toFixed(2)}
                    </span>
                    : ''
            }
        </div>
    );
}