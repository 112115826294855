import {
    USER_ADDRESS,
    USER_SCHEDULE,
    USER_PAYMENT,
    DELIVERY_METHOD,
    USER_NAME,
    USER_PHONE,
    COUPON,
    CARD,
    MORE,
    GIFT,
    USER_SET_ALL,
} from '../actions/actionTypes';
import { normalize } from '../helpers';

const initialState = {
    nome: "",
    telefone: "",
    pais: "",
    coupon: [],
    entrega: [],
    metodo_pagamento: "",
    acrescimo: 0,
    desconto: 0,
    metodo_entrega: "",
    presente: [],
    agendar: [],
    troco: 0,
    observacao_geral: "",
    cartao: [],
    email: "",
    avaliacao: {
        estrela: [],
        comentarios: "",
    },
    pergunta: {
        sim_nao: null,
        texto: "",
    },
}

export default (state = initialState, action) => {
    switch(action.type) {
        case USER_SET_ALL:
            return action.payload
            
        case USER_ADDRESS:

            const address = {
                metodo_entrega: state.entrega && state.entrega.metodo_entrega ? state.entrega.metodo_entrega : "",
                ...action.payload
            };

            return {
                ...state,
                entrega: address
            };
        case DELIVERY_METHOD:

            return {
                ...state,
                metodo_entrega: action.payload.id,
                endereco_balcao: action.payload?.endereco ?? null,
            }
        case USER_PHONE:

            return {
                ...state,
                telefone: action.payload.phone,
                pais: action.payload?.country ?? "br",
            }
        case COUPON:

            return {
                ...state,
                coupon: action.payload
            }
        case USER_NAME:

            return {
                ...state,
                nome: action.payload
            }
        case USER_SCHEDULE:

            const schedule = action.payload;

            return {
                ...state,
                agendar: schedule
            }
        case USER_PAYMENT:

            let { id, acrescimo, desconto, troco } = action.payload;

            let pagamento = id ? normalize(id).toLowerCase() : "";
            pagamento = pagamento.split(" ").join("_");

            const floatTroco = troco && typeof( troco ) === 'string' && troco.includes(",") ?  troco.replace(",", ".") : troco;

            return {
                ...state,
                metodo_pagamento: pagamento,
                acrescimo,
                desconto,
                troco: troco && troco !== "" ? parseFloat(floatTroco) : 0
            }
        case CARD: 

            const card = action.payload;

            return {
                ...state,
                cartao: card,
                email: card.email ? card.email : state.email,
            }
        case MORE: 

            const moreInfo = action.payload;
            
            return {
                ...state,
                [moreInfo.key]: moreInfo.value
            }
        case GIFT: 

            const { de, para, mensagem } = action.payload;
            
            return {
                ...state,
                presente: {
                    de,
                    para,
                    mensagem
                }
            }
        default:
            return state;
    }
}