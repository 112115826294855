import enderecoConsulta from "./enderecoConsulta";
import getEnderecoComRegiao from "./getEnderecoComRegiao";
import getGeolocation from "./getGeolocation";
import getGeolocationKm from "./getGeolocationKm";
import getCompanyId from "../helpers/getCompanyId";

/**
 * 
 * @param {*} addressData 
 * @param {*} errMessage 
 * @param {*} save 
 * @param {*} selecionar 
 * @param {*} setShow 
 * @param {*} d 
 */
export const salvarEndereco = (addressData, errMessage, save, selecionar, setShow, d) => {

    if (
        !addressData.endereco
        && !addressData.regiao
        && d !== undefined && !errMessage.g
    ) {
        save(d);
        setShow(false);
    }
    else if (d !== undefined && !errMessage.erro) {

        selecionar(d);
        setShow(false);
    }
    setShow(false);
}

export const salvarEFechar = async (
    address,
    endereco,
    entrega,
    setCarregando,
    setErrMessage,
    setModalSimilares,
    setEnderecosSimilares,
    salvar,
    traducao
) => {
    if (
        address.endereco && address.numero
        && ((getCompanyId !== "6840" && address.bairro) || getCompanyId === "6840")
        && address.cidade
        && address.estado
        && address.numero
        && ((
            entrega.tipo === 'regiao'
            && (
                address.regiao !== null
                && address.regiao !== undefined
                && address.regiao !== "fff"
                && address.regiao !== ""
                && ((Array.isArray(address.regiao) && address.regiao.length > 0) || !Array.isArray(address.regiao))
            )) || entrega.tipo !== 'regiao'
        )
    ) {

        if (entrega.tipo === 'fixo' || (entrega.tipo === 'km' && entrega.tp_calculo === 0)) {
            await getGeolocationKm(
                address,
                endereco,
                entrega,
                setCarregando,
                setErrMessage,
                setModalSimilares,
                setEnderecosSimilares,
                salvar
            );
        }

        else if (entrega.tipo === 'km' && entrega.tp_calculo === 1) {
            await getGeolocation(
                address,
                endereco,
                setErrMessage,
                setCarregando,
                setModalSimilares,
                setEnderecosSimilares,
                salvar
            );
        }
        else if (entrega.tipo === 'consulta') {
            salvar(enderecoConsulta(address))
        }
        else
            salvar(getEnderecoComRegiao(address, entrega.regioes));

    } else {

        const erros = {
            nome: !address.nome ? "Seria interessante nomear este endereço" : undefined,
            endereco: !address.endereco ? traducao.validacoes.endereco_invalido : undefined,
            numero: !address.numero || address.numero.match(/\D/g) ? traducao.validacoes.numero_invalido : undefined,
            bairro: !address.bairro ? traducao.validacoes.bairro_invalido : undefined,
            cidade: !address.cidade ? traducao.validacoes.cidade_invalido : undefined,
            estado: !address.estado ? traducao.validacoes.estado_invalido : undefined,
            regiao: entrega.tipo === 'regiao' && (!address.regiao || address.regiao === "fff" || Array.isArray(address.regiao)) ? traducao.validacoes.regiao_invalido : undefined,
        };

        setErrMessage(erros);
    }
}