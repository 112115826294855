import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { calculatePrice } from '../../helpers'
import { addItenToCart, removeTab } from '../../actions'
import { confirmAlert } from 'react-confirm-alert'
import useGetPrimary from '../../helpers/useGetPrimary'
import useMoeda from '../../traducao/useMoeda'

export default ({
    buttonMessage,
    cart,
    show,
    setShow,
    submit = "",
    next,
    index,
    ayw = false,
    dispatchAqui = true,
    clear = false
}) => {

    const dispatch = useDispatch()
    const moeda = useMoeda()

    const { textPrimary, bgPrimary } = useGetPrimary()

    const [efeito, setEfeito] = useState(false)

    const addToCart = () => {
        if (typeof (next) === 'function' && (index.atual + 1 !== index.tamanho || ayw))
            return next()

        if (submit === null) {
            if (dispatchAqui) {
                dispatch(addItenToCart(cart))
                if (typeof clear === 'function')
                    clear()
            }
            else next()

            setShow(false)
            dispatch(removeTab(true))

        } else {
            confirmAlert({
                title: "Você ainda precisa completar o pedido",
                message: submit,
                buttons: [
                    {
                        label: "Entendi"
                    }
                ]
            })
        }
    }

    useEffect(() => {

        setEfeito(!efeito)
        setTimeout(() => setEfeito(false), 800)
        // eslint-disable-next-line
    }, [cart])

    return (
        <>
            <div className={`${!show ? 'none-add-cart' : ''} add-to-cart show-submit z-if-none-out z-max`}>
                <p style={textPrimary} className="text-bold">
                    {moeda}{calculatePrice(cart, true).toFixed(2)}
                </p>
                <button
                    style={bgPrimary}
                    type="button"
                    className={`btn ${efeito ? 'tremer' : ''}`}
                    onClick={() => addToCart()}
                >
                    {buttonMessage}
                </button>
            </div>
            <div className={`after-add-to-cart ${!show ? 'none' : ''}`}></div>
        </>
    )
}
