import React from 'react';
import { useParams } from 'react-router-dom';
import PizzaCategoryPage from './types/PizzaCategoryPage';
import MonteCategoryPage from './types/MonteCategoryPage';
import ProdutoCategoryPage from './types/ProdutoCategoryPage';
import BebidasCategoryPage from './types/BebidasCategoryPage';
import PromoGeralCategoryPage from './types/PromoGeralCategoryPage';
import PromoPizzaCategoryPage from './types/PromoPizzaCategoryPage';

export default () => {

    const { type } = useParams();

    switch(type) {
        case "monte":
            return <MonteCategoryPage />
        case "produto":
            return <ProdutoCategoryPage />
        case "pizza":
            return <PizzaCategoryPage />
        case "promo_pizza":
            return <PromoPizzaCategoryPage />
        case "promo_geral":
            return <PromoGeralCategoryPage />
        case "bebidas":
            return <BebidasCategoryPage />
        default:
            return <ProdutoCategoryPage />
    }
}